import React from 'react';
import PropTypes from 'prop-types'
import R14 from '../R14';
import InputField from './InputField';
import TextInputField from './TextInputField';
import TimePickerModal from './TimePickerModal';
import SelectMenu from './SelectMenu';
import View from './View';
import PopUpMenu from './PopUpMenu';
import PopUpMenuItem from './PopUpMenuItem';
import Touchable from './Touchable';
import Icon from './Icon';
import AnimatedView from './AnimatedView';
import AnimatedText from './AnimatedText';

import StyleSheet from './StyleSheet';
import { Colors } from './Theme';

export default R14.connectForm(
  /**
  * A text input field component
  */
  class TimePickerField extends React.Component {
    static propTypes = {
      /** Name for the time picker input. */
      name: PropTypes.string.isRequired,
      /** Label for the time picker input. */
      label: PropTypes.string,
      /** Value of the time picker input. */
      value: PropTypes.string,
      /** The time picker field varient to use */
      variant: PropTypes.oneOf(["outlined", "filled"]),
      /** The mode to open the time picker calendar */
      mode: PropTypes.oneOf(["dropdown", "modal"]),
      /** If true, disable the time picker input */
      disabled: PropTypes.bool,
      /** Handler to be called when value of the time picker is changed  */
      onValueChange: PropTypes.func,
      /** Handler to be called when the user taps the time picker input  */
      onPress: PropTypes.func,
      /** Handler to be called when the time picker input gains focus  */
      onFocus: PropTypes.func,
      /** Handler to be called when the time picker input loses focus  */
      onBlur: PropTypes.func,
      /** The name of the icon to be displayed to the left of the time picker input  */
      icon: PropTypes.string,
      /** The name of the icon to be displayed to the right of the time picker input  */
      iconRight: PropTypes.string,
    }
    static defaultProps = {
      variant: "outlined",
      mode: 'modal',
      icon: 'clock'
    }
    constructor(props) {
      super(props);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleCancelPress = this.handleCancelPress.bind(this);
      this.handleConfirmPress = this.handleConfirmPress.bind(this);
      this.ref = React.createRef();
      this.state = {
        timePickerVisible: false,
        time: null
      };
    }
    handleFocus() {
      // Set initial time if it is set
      let newState = { timePickerVisible: true };
      if(! this.state.time && this.props.form.elmts[this.props.name].value){
        newState.time = this.props.form.elmts[this.props.name].value;
      }
      this.setState(newState);
    }
    handleCancelPress() {
      this.setState({ timePickerVisible: false });
    }
    handleConfirmPress(time) {
      if (time) this.props.form.elmts[this.props.name].value = time;
      this.setState({ timePickerVisible: false, time: time });
    }
    render() {
      return (
        <React.Fragment>
          <TextInputField
            {...this.props}
            autoComplete={false}
            ref={this.ref}
            onFocus={this.handleFocus}
            validator='time'
          />
          <TimePickerModal
            visible={this.state.timePickerVisible}
            time={this.state.time}
            onCancelPress={this.handleCancelPress}
            onConfirmPress={this.handleConfirmPress}
          />
        </React.Fragment>

      );
    }
    
  }
);