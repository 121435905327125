import React from 'react';
import View from '../View';
import ScrollView from '../ScrollView';
import StyleSheet from '../StyleSheet';

export default class FlatListBase extends React.PureComponent {
  constructor(props) {
    /** @todo Replace web implementation with react-window? */
    super(props);
    this._scrollViewRef = React.createRef();
    if (this.props.horizontal) throw new Error("Horizontal flatlist not supported at this time.");
  }

  getItemKey(item, index) {

  }

  scrollToOffset(params) {
    let scrollToParams = {
      animated: params.animated === false ? false : true
    };
    if (this.props.horizontal) scrollToParams.x = params.offset || 0;
    else scrollToParams.y = params.offset || 0;
    this._scrollViewRef.current.scrollTo(scrollToParams);
  }

  renderItems() {
    let rowArr = [];
    let numCols = this.props.numColumns || 1;
    let numRows = Math.ceil(this.props.data.length / numCols);

    let index = 0;
    for (let row = 0; row < numRows; row++) {
      let colArr = [];
      let keyArr = [];

      for (let col = 0; col < numCols; col++) {
        if (index >= this.props.data.length) break;
        let key = (this.props.keyExtractor) ?
          this.props.keyExtractor(this.props.data[index], index) :
          (this.props.key || index);
        keyArr.push(key);
        colArr.push(
          <View style={styles.item} key={key}>
            {this.props.renderItem({
              item: this.props.data[index],
              index: index
            })}
          </View>

        );
        index++;
      }
      rowArr.push(
        <View key={keyArr.join('-')} style={styles.row} onLayout={this.props.onLayout}>
          {colArr}
        </View>
      );

    }
    return rowArr;
  }

  render() {
    // if (!this.props.data || !this.props.data.length) return null;

    return (
      <ScrollView
        {...this.props}
        ref={this._scrollViewRef}
      >
        {this.props.ListHeaderComponent &&
          <View style={this.props.ListHeaderComponentStyle}>
            {this.props.ListHeaderComponent}
          </View>
        }
        {!this.props.data.length && this.props.ListEmptyComponent}
        {this.renderItems()}
        {this.props.ListFooterComponent &&
          <View style={this.props.ListFooterComponentStyle}>
            {this.props.ListFooterComponent}
          </View>
        }
      </ScrollView>
    );

  }
}
const styles = StyleSheet.create({
  row: {
    flex: 0,
    flexDirection: "row"
  },
  item: {
    flex: 1,
  }
});