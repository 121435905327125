import React from "react";
import R14, { View, StyleSheet, Image, Colors } from "../core";

export default class CharacterImage extends React.Component {
  constructor(props) {
    super(props);
  }
  createDynamicStyles() {
    return StyleSheet.create({
      characterImage: {
        height: this.height,
        width: this.width,
        ...StyleSheet.padding(this.padding),
        // borderTopWidth: 1,
        // borderLeftWidth: 1,
        // borderRightWidth: 1,
        // borderStyle: "solid",
        // borderRadius: 4,
        // borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
        backgroundColor: "#FFFFFF",
        overFlow: "hidden",
      },
      image: {
        flex: 1,
        backgroundPosition: "center",
      },
    });
  }
  get padding() {
    return this.props.padding || 10;
  }
  get height() {
    return this.props.height || 48;
  }
  get width() {
    return this.props.width || 48;
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <View style={[dynamicStyles.characterImage, this.props.style]}>
        <Image
          resizeMode="contain"
          width={this.props.width - this.padding * 2}
          height={this.props.height - this.padding * 2}
          style={[dynamicStyles.image]}
          source={`data:image/png;base64,${this.props.imageBase64}`}
        />
      </View>
    );
  }
}
// const styles = StyleSheet.create({
//   characterImage: {
//     height: 48,
//     width: 48,

//     borderTopWidth: 1,
//     borderLeftWidth: 1,
//     borderRightWidth: 1,
//     borderStyle: "solid",
//     borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
//     borderTopLeftRadius: 4,
//     borderTopRightRadius: 4,
//     backgroundColor: "#FFFFFF",
//     overFlow: "hidden",
//   },
//   image: {
//     flex: 1,
//     backgroundPosition: "center",
//   },
// });
