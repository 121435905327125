import React from "react";
import ProgressIndicator from "./ProgressIndicator";

function RootComponents({ progressIndicatorVariant = null }) {
  return (
    <React.Fragment>
      <ProgressIndicator variant={progressIndicatorVariant} />
    </React.Fragment>
  );
}
export default RootComponents;
