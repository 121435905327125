import React from "react";
import StyleSheet from "../StyleSheet";

export default class FormBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind();
  }
  handleSubmit(event) {
    // Forms will be handled by js, so don't actually submit them
    // but still use form and submit buttons
    event.preventDefault();
    event.stopPropagation();
  }
  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={StyleSheet.className([this.props.style])}
        autoComplete={this.props.autoComplete === false ? "off" : "on"}
      >
        {this.props.children}
      </form>
    );
  }
}
