import React from 'react';
import PropTypes from 'prop-types';
import StyleSheet from './StyleSheet';
import View from './View';
import AnimatedView from './AnimatedView';
import IconButton from './IconButton';
import { Colors } from './Theme';

export default class Checkbox extends React.PureComponent {
  static propTypes = {
    /** If true the user won't be able to toggle the checkbox. Default value is false. */
    disabled: PropTypes.bool,
     /** The value of the checkbox. If true the checkbox will be turned on. Default value is false. */
    value: PropTypes.bool,
    /** Invoked with the new value when the value changes. */
    onValueChange: PropTypes.func,
    /** The checkbox icon to be displayed when the checkbox value is false. */
    iconBlank: PropTypes.string,
    /** The checkbox icon to be displayed when the checkbox value is true. */
    iconMarked: PropTypes.string,
    /** The checkbox color to be displayed when the checkbox value is false. */
    colorBlank: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The checkbox color to be displayed when the checkbox value is true. */
    colorMarked: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }
  static defaultProps = {
    value: false,
    iconBlank: "checkboxBlank",
    iconMarked: "checkboxMarked",
    colorBlank: Colors.onBackground,
    colorMarked: Colors.primary
  }
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (this.props.autoFocus) this.autoFocus();
  }
  autoFocus() {
    setTimeout(() => {
      if (!this.elmt.ref.current) return;
      this.ref.current.focus();
    }, 350);
  }
  handleValueChange(value) {
    if (this.props.onValueChange) this.props.onValueChange(value);
  }
  handleChange() {
    if( this.props.onPress) this.props.onPress();
    this.handleValueChange(this.props.value ? false : true);
  }
  render() {
    let styles = this.styles();
    return (
      <View
        style={styles.checkbox}
      >
        <AnimatedView
          style={[styles.handle, this.props.value && styles.handleOn]}
          in={this.props.value ? true : false}
          enter={{
            // transform: [{ scale: 1 }],
            opacity: 1
          }}
          exit={{
            // transform: [{ scale: .95 }],
            opacity: .6
          }}
          duration={100}
        >
          <IconButton
            icon={this.props.value ? this.props.iconMarked : this.props.iconBlank}
            onPress={this.handleChange}
            color={this.props.value ? this.props.colorMarked : this.props.colorBlank}
            ref={this.ref}
            disabled={this.props.disabled}
          />
        </AnimatedView>
      </View>
    );
  }
  styles() {
    return StyleSheet.create({
      checkbox: {
        marginLeft: -6
      },
      input: {
        position: "absolute",
        left: -18,
        top: -17,
        width: 68,
        height: 48,
        margin: 0,
        opacity: 0,
        cursor: "pointer"
      }
    });
  }
}