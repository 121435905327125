import socketIOClient from "socket.io-client";
export default class R14Io {
  constructor(R14, app, options) {
    // if (!options.config || !options.config.io)
    //   throw "IO Error: No default config options found.";
    this._socket = null;
    this._config =
      options.config && options.config.io ? options.config.io : null;
    if (this._config) {
      if (!this._config.url) throw new Error("IO Error: No default config url found.");
      this._socket = socketIOClient(this._config.url, {
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: 10,
      });
    }
  }
  on(event, callback) {
    if(! this._socket) throw new Error("IO Error: No socket connection found with on.");
    return this._socket.on(event, callback);
  }
  off(event) {
    if(! this._socket) throw new Error("IO Error: No socket connection found with off.");
    this._socket.off(event);
  }
  emit(event, data, callback = null) {
    if(! this._socket) throw new Error("IO Error: No socket connection found with emit.");
    this._socket.emit(event, data, callback);
  }
}
