import React from 'react';
import R14 from '../R14';

/** A declaritive style redirect. */
export default class Redirect extends React.PureComponent {
  componentDidMount(){
    R14.instance.app.nav.to(this.props.to, this.props.params || {});
  }
  render(){
    return null;
  }
}