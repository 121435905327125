import React from "react";
import R14, { StyleSheet, Colors, View, Text, Image } from "../core";
import CharacterCompareView from "./CharacterCompareView";

export default R14.connect(
  class FieldCharactersReviewView extends React.Component {
    constructor(props) {
      super(props);
    }
    parseValue(char) {
      return char.updatedValue !== null && char.updatedValue !== undefined
        ? char.updatedValue
        : char.value;
    }
    hasChanged(char) {
      return this.parseValue(char) !== char.value;
    }
    render() {
      let item = this.props.item;
      let value = this.props.app.dm.manualEntry
        .filterAnnotations(item.annotations, {
          type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_CHARACTER,
        })
        .map((char) => {
          return char.updatedValue !== null && char.updatedValue !== undefined
            ? char.updatedValue
            : char.value;
        })
        .join("");
      let field = this.props.app.dm.manualEntry
        .filterAnnotations(item.annotations, {
          type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD,
        })
        .at(0);
      return (
        <View style={styles.characterReviewView}>
          <View style={styles.imageContainer}>
            {field && field.imageBase64 && (
              <Image
                resizeMode='contain'
                height={64}
                width={"100%"}
                style={styles.image}
                source={`data:image/png;base64,${field.imageBase64}`}
              />
            )}
          </View>
          <View style={styles.valueContainer}>
            <Text style={styles.valueText}>{value}</Text>
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  // characterText: {
  //   fontSize: 26,
  //   lineHeight: 26,
  //   textAlign: "center",
  //   // height: 28,
  //   fontFamily: "monospace",
  //   color: Colors.onSurface,
  //   backgroundColor: Colors.surface,
  // },
  // characterReviewView: {
  //   flex: 1,
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   justifyContent: "center",
  //   marginLeft: -8,
  //   marginBottom: 16,
  // },
  characterReviewView: {
    flex: 1,
    flexDirection: "row",
  },
  imageContainer: {
    flex: 1,
    height: 64,
    width: "50%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overFlow: "hidden",
    marginRight: 4,
    // backgroundColor: Colors.surface,
  },
  image: {
    backgroundPosition: "center",
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.7),
    //backgroundColor: StyleSheet.color("#FFFFFF").rgba(0.7),
  },
  valueContainer: {
    flex: 1,
    minHeight: 64,
    width: "50%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overFlow: "hidden",
    marginLeft: 4,
    ...StyleSheet.padding(8),
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.7),
    //backgroundColor: StyleSheet.color("#FFFFFF").rgba(0.9),
  },
  // itemName: {
  //   paddingBottom: 4,
  // },
  // itemNameText: {
  //   fontSize: 12,
  // },
  valueText: {
    fontFamily: "monospace",
    // color: "#010101",
    wordBreak: "break-all",
  },
});
