import React from "react";
import PropTypes from "prop-types";
import R14, {
  StyleSheet,
  View,
  TextInputField,
  HiddenInputField,
} from "../core";
import CharacterImage from "./CharacterImage";
import FieldImage from "./FieldImage";

export default R14.connectForm(
  class FieldEntryField extends React.Component {
    static propTypes = {
      // /** Name for the character entry fields. */
      // name: PropTypes.string.isRequired,
      // /** Value of the character entry fields. */
      // value: PropTypes.any,
    };
    constructor(props) {
      super(props);
      this._field = this.initField();
      this._characters = this.initCharacters();
    }
    initField() {
      return this.props.app.dm.manualEntry
        .filterAnnotations(this.props.item.annotations, {
          type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD,
        })
        .at(0);
    }
    initCharacters() {
      return this.props.app.dm.manualEntry.filterAnnotations(
        this.props.item.annotations,
        {
          type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_CHARACTER,
        }
      );
    }
    get field() {
      return this._field;
    }
    get characters() {
      return this._characters;
    }
    render() {
      return (
        <View>
          <FieldImage
            field={this.field}
            characters={this.characters}
            idx={this.props.app.dm.manualEntry.queueIdx}
          />
          <HiddenInputField name='annotationUuid' />
          <TextInputField
            name='updatedValue'
            autoFocus
            autoSelect
            label={
              this.props.item
                ? this.props.app.dm.manualEntry.getLabel(
                    this.props.item,
                    this.props.app.dm.manualEntry.queueIdx
                  )
                : null
            }
            style={styles.textInputField}
            required={
              this.field.required === true ? "Field value required." : null
            }
            helper={this.field.required === true ? "Required" : null}
            autoFocus
          />
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  textInputField: {
    // minHeight: 22,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: 24,
  },
});
