import R14 from "../R14";
import Dimensions from "../components/Dimensions";

export default class ViewportUiDomain extends R14.Domain{
	constructor() {
    super();
    this.handleResize = this.handleResize.bind(this);

    Dimensions.addEventListener('change', this.handleResize);

    let {height, width} = Dimensions.get('window');

		this.state = {
      height: height,
      width: width
		};
  }
  handleResize({window}){
		this.setState({
			height: window.height,
      width: window.width
		});
  }
  get height(){
    return this.state.height
  }
  get width(){
    return this.state.width
  }
	
}