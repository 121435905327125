import ThemeUiDomain from "../domains/ThemeUiDomain";
import { Color } from "./StyleSheet";
import R14 from "../R14";

/** @todo Figure out how to make theme use the state */
const Theme = new Proxy(function () {}, {
  get: (obj, prop) => {
    let instance = getThemeInstance();
    return instance[prop];
  },
  set: (obj, prop, val) => {
    let instance = getThemeInstance();
    instance[prop] = val;
    return true;
    // if(prop in instance){
    //   instance[prop] = val;
    //   return true;
    // }
    // return false;
  },
});
function getThemeInstance() {
  let global = R14.getGlobalVariable();
  let instance = global._r14Theme;
  if (!instance) {
    instance = new ThemeUiDomain();
    global._r14Theme = instance;
  }
  return instance;
}
export const Colors = new Proxy(function () {}, {
  get: function (obj, prop) {
    return Theme.colors[prop];
  },
});
export default Theme;

export class ThemeColorValue {
  constructor(themeDomain, type, key, componentKey, options = {}) {
    this._themeDomain = themeDomain;
    this._type = type;
    this._key = key;
    this._componentKey = componentKey;
    this._options = options;
  }
  default(val) {
    this._options.default = val;
    return this;
  }
  setOption(key, value) {
    this._options[key] = value;
  }
  toString() {
    let theme = this._themeDomain.theme;
    let doLog = false;
    let colorValue = null;
    switch (this._type) {
      case "color":
        if (this._componentKey) {
          if (
            theme.components &&
            theme.components[this._componentKey] &&
            theme.components[this._componentKey].colors &&
            theme.components[this._componentKey].colors[this._key]
          ) {
            colorValue = theme.components[this._componentKey].colors[this._key];
          } else if (this._options.default) {
            colorValue = this._options.default;
            if (typeof this._options.default === "string")
              colorValue = this._options.default;
            else if (this._options.default.toString)
              colorValue = this._options.default.toString();
          }
        }
        if (!colorValue && theme.colors && theme.colors[this._key]) {
          colorValue = theme.colors[this._key];
        }
        if (
          colorValue &&
          "alpha" in this._options &&
          this._options.alpha !== null &&
          this._options.alpha !== undefined &&
          this._options.alpha >= 0 &&
          this._options.alpha < 1
        ) {
          colorValue = Color.hexToRgba(
            theme.colors[this._key],
            this._options.alpha
          );
        }
    }
    return colorValue || this._options.default || null;
  }
}
