import PropTypes from "prop-types";
import ScrollViewBase from "./base/ScrollViewBase";
// import StyleSheet from './StyleSheet';

export default ScrollViewBase;

// /** A view for allowing scrolling. */
// export default class ScrollView extends ScrollViewBase {
//   constructor(props) {
//     super(props);
//   }
//   static propTypes = {
//     /** When false, the view cannot be scrolled. */
//     scrollEnabled: PropTypes.bool,
//   };
//   static defaultProps = {
//     scrollEnabled: true,
//   };
// }
