import React from "react";
import StyleSheet from "../StyleSheet";
import Theme, { Colors } from "../Theme";

export default class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePress = this.handlePress.bind(this);
    this._contentSize = null;
    this._isUpdatingContentSize = false;
    this._updateContentSizeTimeout = null;
    this._lastScrollHeight = null;
    this._width = null;
    this._isContentInitialized = false;
    this.state = {
      height: null,
      updateContentSize: false,
    };
    this._autoFocusTimeout = null;
    this._autoSelectTimeout = null;
    this._contentSizeTimeout = null;
  }
  componentDidMount() {
    this.autoFillFix();
    if (this.props.autoFocus) this.autoFocus();
    if (this.props.autoSelect) this.autoSelect();
    // const computed = window.getComputedStyle(this.ref.current, null);
    // console.log('lineHeight',computed.lineHeight);

    if (this.shouldUpdateContentSize)
      this._contentSizeTimeout = setTimeout(() => {
        if (this.props.onContentSizeChange) this.updateContentSize();
      }, 50);

    //this.updateContentSize();
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate',prevState.height, this.state.height);
    if (
      this.props.onContentSizeChange &&
      prevState.height !== this.state.height
    )
      this.updateContentSize();
  }
  componentWillUnmount() {
    if (this._autoFocusTimeout) clearTimeout(this._autoFocusTimeout);
    if (this._autoSelectTimeout) clearTimeout(this._autoSelectTimeout);
    if (this._contentSizeTimeout) clearTimeout(this._contentSizeTimeout);
  }
  get shouldUpdateContentSize() {
    /**
     * @todo Figure out how to implement autosize and this.props.onContentSizeChange
     */
    return this.props.multiline && this.props.onContentSizeChange
      ? true
      : false;
  }
  updateContentSize() {
    /**
     * @todo Figure out how to implement autosize and this.props.onContentSizeChange
     */
    let target = this.ref.current;

    // Reset field height
    target.style.height = "inherit";

    // Get the computed styles for the element
    const computed = window.getComputedStyle(target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      //+ parseInt(computed.getPropertyValue('padding-top'), 10)
      target.scrollHeight +
      //+ parseInt(computed.getPropertyValue('padding-bottom'), 10)
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    target.style.height = `${height}px`;

    if (!this._isContentInitialized) return false;
    if (this.ref.current) {
      // Cache the width
      if (this._width === null) this._width = this.ref.current.offsetWidth;
      if (this._isUpdatingContentSize) {
        if (this.state.height === 0) {
          if (this.ref.current.scrollHeight !== this._lastScrollHeight) {
            this._lastScrollHeight = this.ref.current.scrollHeight;
            this.props.onContentSizeChange({
              nativeEvent: {
                contentSize: {
                  width: this._width,
                  height: this.ref.current.scrollHeight,
                },
              },
            });
          }
          this.setState({ height: null });
        } else if (this.state.height === null) {
          this._isUpdatingContentSize = false;
        }
      } else {
        this._isUpdatingContentSize = true;
        this.setState({ height: 0 });
      }
    }
    // }, 10);
  }
  autoFocus() {
    this._autoFocusTimeout = setTimeout(() => {
      this.focus();
    }, 350);
  }
  focus() {
    if (!this.ref.current) return;
    this.ref.current.focus();
  }
  autoSelect() {
    this._autoSelectTimeout = setTimeout(() => {
      this.select();
    }, 350);
  }
  select() {
    if (!this.ref.current) return;
    this.ref.current.select();
  }
  handleChange(event) {
    if (this.props.onContentSizeChange) this.updateContentSize();
    if (this.props.onChangeText)
      return this.props.onChangeText(event.target.value);
  }
  handlePress(e) {
    //e.preventDefault();
    if (this.props.onPress) this.props.onPress(this.normalizeEvent(e));
  }
  handleKeyUp(event) {
    if (this.props.handleKeyUp) this.props.handleKeyUp(event.target.value);
    //if (this.props.onContentSizeChange) this.updateContentSize();
    //this.updateContentSize();
  }
  handleKeyDown(event) {
    if (this.props.onKeyPress) this.props.onKeyPress(event);
    if (this.props.onKeyDown) this.props.onKeyDown(event);
  }
  autoFillFix() {
    if (!this.props.onAutoFill) return;
    // A little bit of a hacky fix for autoFill bug: https://github.com/facebook/react/issues/1159
    this.ref.current.addEventListener(
      "animationstart",
      ({ target, animationName }) => {
        switch (animationName) {
          case "onAutoFillStart":
            this.props.onAutoFill("start");
            break;
          case "onAutoFillCancel":
            this.props.onAutoFill("cancel");
            break;
        }
      }
    );
  }
  render() {
    let type = "text";
    let tag = this.props.multiline ? "textarea" : "input";
    if (this.props.secureTextEntry) type = "password";

    let color = StyleSheet.parseColor(this.props.color || Colors.onBackground);
    let dynamicStyles = StyleSheet.create({
      input: {
        "-webkit-text-fill-color": `${color} !important`,
        color: `${color} !important`,
      },
    });
    let autoComplete = "on";
    if (this.props.autoComplete === false)
      autoComplete = type === "password" ? "new-password" : "off";
    return React.createElement(tag, {
      type: type,
      name: this.props.name,
      ref: this.ref,
      placeholder: this.props.placeholder || null,
      value: this.props.value !== null ? `${this.props.value}` : "",
      autoComplete: autoComplete,
      onChange:
        (this.props.onChangeText || this.props.onContentSizeChange) &&
        this.handleChange,
      onFocus: this.props.onFocus || null,
      onBlur: this.props.onBlur || null,
      autoFocus: this.props.autoFocus || null,
      readOnly: this.props.editable === false,
      disabled: this.props.disabled || null,
      onClick: this.props.onPress || null,
      onKeyDown:
        this.props.onKeyPress || this.props.onKeyDown
          ? this.handleKeyDown
          : null,
      onKeyUp:
        this.props.onKeyUp || this.props.onContentSizeChange
          ? this.handleKeyUp
          : null,
      className: StyleSheet.className([
        styles.input,
        this.props.style,
        dynamicStyles.input,
      ]),
    });
  }
}

const styles = StyleSheet.create({
  input: {
    display: "block",
    boxSizing: "border-box",
    outline: "none",
    resize: "none",
    border: 0,
    placeholder: {
      color: "red",
    },
  },
});
