import React from "react";
import R14, {
  Colors,
  View,
  StyleSheet,
  Text,
  DataTable,
  ShowHideText,
  IconButton,
  ScrollView,
  Card,
  FadeView,
  Button,
} from "../core";
import TimeDurationLabel from "./TimeDurationLabel";

export default R14.connect(
  class ManualEntryUserSessionMetricDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.activityLog = this.props.app.dm.activityLog;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.state = {
        visibleMetricRows: [],
      };
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      // return {
      //   pageData: [],
      //   totalRows: 0
      // };
      let filter = {};
      // if(this.props.resourceUid) filter.resourceUid = { eq: this.props.resourceUid };

      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };

      let res = await this.manualEntryDomain.findUserSessionMetrics({
        page: page,
        resultsPerPage: rowsPerPage,
        filter: filter,
        sort: [
          {
            field: sortColumnName,
            order: sortDirection.toUpperCase(),
          },
        ],
        totalCount: options.totalCount || false,
      });
      return {
        pageData: res.nodes.map((row) => ({
          startAt: row.startAt,
          endAt: row.endAt,
          manualEntryUserSessionUuid: row.manualEntryUserSessionUuid,
          paymentTotal: row.paymentTotal,
          baseCurrencyRateToUsd: row.baseCurrencyRateToUsd,
          currencyRateToUsd: row.currencyRateToUsd,
          currency: row.currency,
          metrics: this.manualEntryDomain.parseUserMetricFields(row),
        })),
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("manualEntryUserSessionMetrics");
    }
    get manualEntryDomain() {
      return this.props.app.dm.manualEntry;
    }
    formatPayoutValue(userSessionMetric) {
      let ret = null;
      if (!userSessionMetric.paymentTotal) return null;
      if (
        !userSessionMetric.currencyRateToUsd ||
        !userSessionMetric.baseCurrencyRateToUsd
      )
        return "N/A";
      // Calculate the payout
      let paymentTotal =
        (userSessionMetric.paymentTotal /
          userSessionMetric.baseCurrencyRateToUsd) *
        userSessionMetric.currencyRateToUsd;

      if (isNaN(paymentTotal)) return "N/A";
      let currencySymbol = "";
      let currencyCode = "";
      if (userSessionMetric.currency) {
        if (userSessionMetric.currency.symbol)
          currencySymbol = userSessionMetric.currency.symbol;
        if (userSessionMetric.currency.code)
          currencyCode = ` ${userSessionMetric.currency.code}`;
      }
      ret = `${currencySymbol}${this.props.app.dm.manualEntry.formatCurrency(
        paymentTotal
      )}${currencyCode}`;

      return ret;
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      let visibleMetricsRow = this.isVisibleMetricsRow(
        row.manualEntryUserSessionUuid
      );
      switch (columnName) {
        case "startAt":
          let date = new Date(cell);
          let shortDate = this.props.app.utils.date.toShortDate(date);
          let time = this.props.app.utils.date.toTime(date);
          ret = (
            <View>
              <Text style={[styles.cellText]}>
                {shortDate} {time}
              </Text>
            </View>
          );
          break;
        case "endAt":
          let duration = "N/A";
          let { startAt, endAt } = row;
          if (startAt && endAt) {
            if (startAt === endAt) {
              let totalModeSeconds = 0;
              [
                this.manualEntryDomain.MODE_CHARACTERS,
                this.manualEntryDomain.MODE_FIELD_CHARACTERS,
                this.manualEntryDomain.MODE_FIELD,
              ].forEach((mode) => {
                if (
                  row.metrics[mode] &&
                  row.metrics[mode][this.manualEntryDomain.METRIC_TYPE_SECONDS]
                )
                  totalModeSeconds +=
                    row.metrics[mode][
                      this.manualEntryDomain.METRIC_TYPE_SECONDS
                    ];
              });
              endAt = new Date(endAt);
              endAt.setSeconds(endAt.getSeconds() + totalModeSeconds);
            }
            ret = (
              <TimeDurationLabel
                numberLabelTextStyle={styles.durationNumberLabel}
                startAt={new Date(startAt)}
                endAt={new Date(endAt)}
              />
            );
          } else ret = <Text style={style}>{duration}</Text>;
          break;
        case "manualEntryUserSessionUuid":
          ret = <ShowHideText text={content} maxCharacters={16} />;
          break;
        case "paymentTotal":
          let payout = this.formatPayoutValue(row);
          if (!payout) ret = null;
          else ret = <Text style={style}>{payout}</Text>;
          break;
        case "controls":
          ret = (
            <Button
              title={visibleMetricsRow ? "Hide Metrics" : "View Metrics"}
              color={visibleMetricsRow ? Colors.secondary : Colors.primary}
              style={styles.metricsRowExpandButton}
              key='expand'
              variant='outlined'
              uppercase={false}
              size='small'
              onPress={() =>
                visibleMetricsRow
                  ? this.hideMetricsRow(row.manualEntryUserSessionUuid)
                  : this.showMetricsRow(row.manualEntryUserSessionUuid)
              }
            />
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    calculateMetricRate(total, totalSeconds) {
      return ((total / totalSeconds) * 60).toFixed(2);
    }
    showMetricsRow(manualEntryUserSessionUuid) {
      if (this.isVisibleMetricsRow(manualEntryUserSessionUuid)) return;
      let visibleMetricRows = this.state.visibleMetricRows;
      visibleMetricRows.push(manualEntryUserSessionUuid);
      this.setState({ visibleMetricRows });
    }
    hideMetricsRow(manualEntryUserSessionUuid) {
      if (!this.isVisibleMetricsRow(manualEntryUserSessionUuid)) return;
      let visibleMetricRows = this.state.visibleMetricRows;
      visibleMetricRows.splice(
        visibleMetricRows.indexOf(manualEntryUserSessionUuid),
        1
      );
      this.setState({ visibleMetricRows });
    }
    isVisibleMetricsRow(manualEntryUserSessionUuid) {
      return this.state.visibleMetricRows.includes(manualEntryUserSessionUuid);
    }
    rowBottomRenderer({ row, columns }) {
      let visible = this.isVisibleMetricsRow(row.manualEntryUserSessionUuid);
      let cards = [];
      [
        this.manualEntryDomain.MODE_CHARACTERS,
        this.manualEntryDomain.MODE_FIELD_CHARACTERS,
        this.manualEntryDomain.MODE_FIELD,
      ].forEach((mode) => {
        if (!row.metrics[mode]) return;
        let cardRows = [];
        let metrics = row.metrics[mode];
        let totalSeconds = metrics[this.manualEntryDomain.METRIC_TYPE_SECONDS];
        if (!totalSeconds) return;
        let metricTypes = null;
        switch (mode) {
          case this.manualEntryDomain.MODE_CHARACTERS:
            metricTypes = [
              this.manualEntryDomain.METRIC_TYPE_ANNOTATION_SETS,
              this.manualEntryDomain.METRIC_TYPE_CHARACTERS,
              this.manualEntryDomain.METRIC_TYPE_CHARACTER_UPDATES,
              this.manualEntryDomain.METRIC_TYPE_KEYSTROKES,
            ];
            break;
          case this.manualEntryDomain.MODE_FIELD_CHARACTERS:
            metricTypes = [
              this.manualEntryDomain.METRIC_TYPE_FIELDS,
              this.manualEntryDomain.METRIC_TYPE_FIELD_UPDATES,
              this.manualEntryDomain.METRIC_TYPE_CHARACTER_UPDATES,
              this.manualEntryDomain.METRIC_TYPE_KEYSTROKES,
            ];
            break;
          case this.manualEntryDomain.MODE_FIELD:
            metricTypes = [
              this.manualEntryDomain.METRIC_TYPE_FIELDS,
              this.manualEntryDomain.METRIC_TYPE_FIELD_UPDATES,
              this.manualEntryDomain.METRIC_TYPE_KEYSTROKES,
            ];
            break;
          default:
            throw new Error("Unknown character set mode.");
        }
        if (!metricTypes) return;
        metricTypes.forEach((metricType) => {
          metricType in metrics &&
            cardRows.push({
              label: this.manualEntryDomain.getMetricSessionLabel(metricType),
              total: metrics[metricType],
              rate: this.calculateMetricRate(metrics[metricType], totalSeconds),
            });
        });

        cards.push(
          <Card
            title={this.manualEntryDomain.getModeLabel(mode)}
            style={styles.metricsCardTable}
            titleTextStyle={styles.metricsCardTableTitleTextStyle}
          >
            <View style={styles.metricsCardTableHeader}>
              <View style={styles.metricsCardTableHeaderNameColumn}>
                <Text style={styles.metricsCardTableHeaderColumnText}>
                  Name
                </Text>
              </View>
              <View style={styles.metricsCardTableHeaderTotalColumn}>
                <Text style={styles.metricsCardTableHeaderColumnText}>
                  Total
                </Text>
              </View>
              <View style={styles.metricsCardTableHeaderRateColumn}>
                <Text style={styles.metricsCardTableHeaderColumnText}>RPM</Text>
              </View>
            </View>
            {cardRows.map((row) => (
              <View style={styles.metricsCardTableRow}>
                <View style={styles.metricsCardTableNameColumn}>
                  <Text style={styles.metricsCardTableColumnText}>
                    {row.label}
                  </Text>
                </View>
                <View style={styles.metricsCardTableTotalColumn}>
                  <Text style={styles.metricsCardTableColumnText}>
                    {row.total}
                  </Text>
                </View>
                <View style={styles.metricsCardTableRateColumn}>
                  <Text style={styles.metricsCardTableColumnText}>
                    {row.rate}
                  </Text>
                </View>
              </View>
            ))}
          </Card>
        );
      });
      return (
        <FadeView visible={visible}>
          <View style={styles.metricsCardsView}>{cards}</View>
        </FadeView>
      );
    }
    rowKeyExtractor(row) {
      return row.manualEntryUserSessionUuid;
    }
    // async handleRemovePress(uid, dataTable) {
    //   if (window.confirm("Are you sure?")) {
    //     await this.props.app.dm.eventTask.delete(uid);
    //     dataTable.refresh({ indicator: true });
    //   }
    // }
    // async handleSearchPress() {}
    // async handleRunPress(uid) {
    //   this.props.app.ui.progressIndicator.show();
    //   await this.eventTaskDomain.run(uid);
    //   await this.dataTable.refresh();
    //   this.props.app.ui.progressIndicator.hide();
    // }
    // async handleShowLogPress(uid) {
    //   this.props.app.nav.to("eventTaskLog", { uid: uid });
    // }
    render() {
      return (
        <ScrollView>
          <DataTable
            name='manualEntryUserSessionMetrics'
            columns={{
              startAt: {
                label: "Date",
                sortable: true,
                width: 240,
              },
              endAt: {
                label: "Duration",
                sortable: false,
                width: 160,
              },
              manualEntryUserSessionUuid: {
                label: "Session UUID",
                sortable: false,
                //width: 240,
              },
              paymentTotal: {
                label: "Payout",
                sortable: true,
                width: 160,
              },
              controls: {},
            }}
            searchable
            scrollEnabled
            maxHeight={300}
            autoRefresh={30}
            initializer={this.initializer}
            rowKeyExtractor={this.rowKeyExtractor}
            pageLoader={this.pageLoader}
            cellRenderer={this.cellRenderer}
            rowBottom={this.rowBottomRenderer}
            initialRowsPerPage={10}
            headerTitle={this.props.title || "History"}
            onRowPress={this.handleRowPress}
            paddingBottom={72} // Make space for the FAB
            initialSortColumnName='startAt'
            initialSortDirection='desc'
          />
        </ScrollView>
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: 300,
  },
  durationNumberLabel: {
    fontSize: 14,
    //fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  metricsRowExpandButton: {
    width: 144,
    ...StyleSheet.margin(-8, 0, -20, "auto"),
  },
  metricsCardsView: {
    flex: 1,
    flexDirection: "row",
    ...StyleSheet.padding(16, 0, 0, 0),
    ...StyleSheet.margin(0, -16, -16, 0),
  },
  metricsCardTable: {
    flex: 0,
    width: "33.3333%",
    minWidth: "33.3333%",
    // marginRight: "auto"
  },
  metricsCardTableTitleTextStyle: {
    fontSize: 18,
    fontWeight: 500,
  },
  metricsCardTableHeader: {
    flex: 1,
    flexDirection: "row",
  },

  metricsCardTableHeaderNameColumn: {
    width: "50%",
  },
  metricsCardTableHeaderTotalColumn: {
    width: "15%",
    textAlign: "right",
  },
  metricsCardTableHeaderRateColumn: {
    width: "35%",
    textAlign: "right",
  },
  metricsCardTableHeaderColumnText: {
    fontSize: 14,
    fontWidth: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  metricsCardTableNameColumn: {
    width: "50%",
  },
  metricsCardTableTotalColumn: {
    width: "15%",
    textAlign: "right",
  },
  metricsCardTableRateColumn: {
    width: "35%",
    textAlign: "right",
  },
  metricsCardTableColumnText: {},
  metricsCardTableRow: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    ...StyleSheet.padding(4, 8, 4, 8),
    ...StyleSheet.margin(8, -8, 8, -8),
    borderRadius: 4,
  },
});
