import React from "react";
import SvgIcon from "./SvgIcon";
import { ReactComponent as PencilIconSvg } from "../../assets/icons/material-community-icons/pencil.svg";
import { ReactComponent as AccountCircleIconSvg } from "../../assets/icons/material-community-icons/account-circle.svg";
import { ReactComponent as DocumentsIconSvg } from "../../assets/icons/material-community-icons/file-document-box-multiple-outline.svg";
import { ReactComponent as BrainIconSvg } from "../../assets/icons/material-community-icons/brain.svg";
import { ReactComponent as FileOutlineIconSvg } from "../../assets/icons/material-community-icons/file-outline.svg";
import { ReactComponent as FileHiddenIconSvg } from "../../assets/icons/material-community-icons/file-hidden.svg";
import { ReactComponent as FileDownloadIconSvg } from "../../assets/icons/material-community-icons/file-download.svg";
import { ReactComponent as AccountSettingsIconSvg } from "../../assets/icons/material-community-icons/account-settings.svg";
import { ReactComponent as PowerPlugIconSvg } from "../../assets/icons/material-community-icons/power-plug.svg";
import { ReactComponent as CodeBracesIconSvg } from "../../assets/icons/material-community-icons/code-braces.svg";
import { ReactComponent as MenuIconSvg } from "../../assets/icons/material-community-icons/menu.svg";
import { ReactComponent as MenuUpIconSvg } from "../../assets/icons/material-community-icons/menu-up.svg";
import { ReactComponent as MenuDownIconSvg } from "../../assets/icons/material-community-icons/menu-down.svg";
import { ReactComponent as SettingOutlineIconSvg } from "../../assets/icons/material-community-icons/settings-outline.svg";
import { ReactComponent as AccountMultipleIconSvg } from "../../assets/icons/material-community-icons/account-multiple.svg";
import { ReactComponent as AccountIconSvg } from "../../assets/icons/material-community-icons/account.svg";
import { ReactComponent as FilterVariantIconSvg } from "../../assets/icons/material-community-icons/filter-variant.svg";
import { ReactComponent as DotsVerticalIconSvg } from "../../assets/icons/material-community-icons/dots-vertical.svg";
import { ReactComponent as DotsHorizontalIconSvg } from "../../assets/icons/material-community-icons/dots-horizontal.svg";
import { ReactComponent as MagnifyIconSvg } from "../../assets/icons/material-community-icons/magnify.svg";
import { ReactComponent as CheckboxBlankOutlineSvg } from "../../assets/icons/material-community-icons/checkbox-blank-outline.svg";
import { ReactComponent as CheckboxMarkedSvg } from "../../assets/icons/material-community-icons/checkbox-marked.svg";
import { ReactComponent as CheckboxMultipleBlankOutlineSvg } from "../../assets/icons/material-community-icons/checkbox-multiple-blank-outline.svg";
import { ReactComponent as CheckboxMultipleMarkedSvg } from "../../assets/icons/material-community-icons/checkbox-multiple-marked.svg";
import { ReactComponent as CheckCircleOutlineSvg } from "../../assets/icons/material-community-icons/check-circle-outline.svg";
import { ReactComponent as RadioboxBlankIconSvg } from "../../assets/icons/material-community-icons/radiobox-blank.svg";
import { ReactComponent as RadioboxMarkedIconSvg } from "../../assets/icons/material-community-icons/radiobox-marked.svg";

import { ReactComponent as FormatListCheckboxSvg } from "../../assets/icons/material-community-icons/format-list-checkbox.svg";
import { ReactComponent as CalendarIconSvg } from "../../assets/icons/material-community-icons/calendar.svg";
import { ReactComponent as EmailOutlineIconSvg } from "../../assets/icons/material-community-icons/email-outline.svg";

import { ReactComponent as StopIconSvg } from "../../assets/icons/material-community-icons/stop.svg";
import { ReactComponent as StopCircleIconSvg } from "../../assets/icons/material-community-icons/stop-circle.svg";
import { ReactComponent as StopCircleOutlineIconSvg } from "../../assets/icons/material-community-icons/stop-circle-outline.svg";
import { ReactComponent as MinusIconSvg } from "../../assets/icons/material-community-icons/minus.svg";

import { ReactComponent as PlayIconSvg } from "../../assets/icons/material-community-icons/play.svg";
import { ReactComponent as PlayCircleIconSvg } from "../../assets/icons/material-community-icons/play-circle.svg";
import { ReactComponent as PlayCircleOutlineIconSvg } from "../../assets/icons/material-community-icons/play-circle-outline.svg";

import { ReactComponent as ConsoleIconSvg } from "../../assets/icons/material-community-icons/console.svg";
import { ReactComponent as ConsoleNetworkIconSvg } from "../../assets/icons/material-community-icons/console-network.svg";

import { ReactComponent as ClockOutlineIconSvg } from "../../assets/icons/material-community-icons/clock-outline.svg";
import { ReactComponent as ViewDashboardIconIconSvg } from "../../assets/icons/material-community-icons/view-dashboard-outline.svg";
import { ReactComponent as DeveloperBoardIconSvg } from "../../assets/icons/material-community-icons/developer-board.svg";
import { ReactComponent as CalendarClockIconSvg } from "../../assets/icons/material-community-icons/calendar-clock.svg";
import { ReactComponent as ServerIconSvg } from "../../assets/icons/material-community-icons/server.svg";
import { ReactComponent as MonitorDashboardIconSvg } from "../../assets/icons/material-community-icons/monitor-dashboard.svg";
import { ReactComponent as HeartPulseIconSvg } from "../../assets/icons/material-community-icons/heart-pulse.svg";
import { ReactComponent as DashboardCheckIconSvg } from "../../assets/icons/material-community-icons/database-check.svg";

import { ReactComponent as DatabaseIconSvg } from "../../assets/icons/material-community-icons/database.svg";
import { ReactComponent as DatabaseImportIconSvg } from "../../assets/icons/material-community-icons/database-import.svg";
import { ReactComponent as DatabaseExportIconSvg } from "../../assets/icons/material-community-icons/database-export.svg";
import { ReactComponent as ChartLineIconSvg } from "../../assets/icons/material-community-icons/chart-line.svg";

import { ReactComponent as CogsIconSvg } from "../../assets/icons/material-community-icons/cogs.svg";
import { ReactComponent as SourceBranchIconSvg } from "../../assets/icons/material-community-icons/source-branch.svg";
import { ReactComponent as EyeCheckOutlineIconSvg } from "../../assets/icons/material-community-icons/eye-check-outline.svg";

import { ReactComponent as AccountGroupIconSvg } from "../../assets/icons/material-community-icons/account-group.svg";
import { ReactComponent as CloudSyncIconSvg } from "../../assets/icons/material-community-icons/cloud-sync.svg";
import { ReactComponent as CloudIconSvg } from "../../assets/icons/material-community-icons/cloud.svg";

import { ReactComponent as RestoreIconSvg } from "../../assets/icons/material-community-icons/restore.svg";
import { ReactComponent as ImportIconSvg } from "../../assets/icons/material-community-icons/import.svg";
import { ReactComponent as ExportIconSvg } from "../../assets/icons/material-community-icons/export.svg";
import { ReactComponent as ApplicationImportIconSvg } from "../../assets/icons/material-community-icons/application-import.svg";
import { ReactComponent as ApplicationExportIconSvg } from "../../assets/icons/material-community-icons/application-export.svg";

import { ReactComponent as CubeOutlineIconSvg } from "../../assets/icons/material-community-icons/cube-outline.svg";
import { ReactComponent as ShieldAccountIconSvg } from "../../assets/icons/material-community-icons/shield-account.svg";
import { ReactComponent as HelpCircleOutlineIconSvg } from "../../assets/icons/material-community-icons/help-circle-outline.svg";
import { ReactComponent as MessageTextOutlineIconSvg } from "../../assets/icons/material-community-icons/message-text-outline.svg";
import { ReactComponent as GitIconSvg } from "../../assets/icons/material-community-icons/git.svg";
import { ReactComponent as NpmIconSvg } from "../../assets/icons/material-community-icons/npm.svg";
import { ReactComponent as ChevronDoubleUpIconSvg } from "../../assets/icons/material-community-icons/chevron-double-up.svg";
import { ReactComponent as ChevronDoubleDownIconSvg } from "../../assets/icons/material-community-icons/chevron-double-down.svg";
import { ReactComponent as EyeIconSvg } from "../../assets/icons/material-community-icons/eye.svg";
import { ReactComponent as RobotIconSvg } from "../../assets/icons/material-community-icons/robot.svg";
import { ReactComponent as LanguagePythonIconSvg } from "../../assets/icons/material-community-icons/language-python.svg";
import { ReactComponent as FolderOutlineIconSvg } from "../../assets/icons/material-community-icons/folder-outline.svg";
import { ReactComponent as CursorMoveIconSvg } from "../../assets/icons/material-community-icons/cursor-move.svg";
import { ReactComponent as ArrowUpBoldCircleOutlineIconSvg } from "../../assets/icons/material-community-icons/arrow-up-bold-circle-outline.svg";
import { ReactComponent as ArrowUpBoldIconSvg } from "../../assets/icons/material-community-icons/arrow-up-bold.svg";
import { ReactComponent as ChevronUpIconSvg } from "../../assets/icons/material-community-icons/chevron-up.svg";
import { ReactComponent as ChartBubbleIconSvg } from "../../assets/icons/material-community-icons/chart-bubble.svg";
import { ReactComponent as CloseCircleSvg } from "../../assets/icons/material-community-icons/close-circle.svg";
import { ReactComponent as CheckboxMarkedCircleSvg } from "../../assets/icons/material-community-icons/checkbox-marked-circle.svg";
import { ReactComponent as MagnifyMinusOutlineSvg } from "../../assets/icons/material-community-icons/magnify-minus-outline.svg";
import { ReactComponent as MagnifyPlusOutlineSvg } from "../../assets/icons/material-community-icons/magnify-plus-outline.svg";
import { ReactComponent as MagnifyMinusSvg } from "../../assets/icons/material-community-icons/magnify-minus.svg";
import { ReactComponent as MagnifyPlusSvg } from "../../assets/icons/material-community-icons/magnify-plus.svg";
import { ReactComponent as FileDocumentIconSvg } from "../../assets/icons/material-community-icons/file-document.svg";

import { ReactComponent as FileDocumentBoxRemoveSvg } from "../../assets/icons/material-community-icons/file-document-box-remove.svg";
import { ReactComponent as FileDocumentBoxCheckSvg } from "../../assets/icons/material-community-icons/file-document-box-check.svg";
import { ReactComponent as WindowRestore } from "../../assets/icons/material-community-icons/window-restore.svg";
import { ReactComponent as WindowClose } from "../../assets/icons/material-community-icons/window-close.svg";
import { ReactComponent as WindowMinimize } from "../../assets/icons/material-community-icons/window-minimize.svg";
import { ReactComponent as WindowMaximize } from "../../assets/icons/material-community-icons/window-maximize.svg";

import { ReactComponent as KeyboardOutlineIconSvg } from "../../assets/icons/material-community-icons/keyboard-outline.svg";

import { ReactComponent as FitToPageOutlineSvg } from "../../assets/icons/material-community-icons/fit-to-page-outline.svg";
import { ReactComponent as ArrowExpandSvg } from "../../assets/icons/material-community-icons/arrow-expand.svg";
import { ReactComponent as MicrophoneMessageSvg } from "../../assets/icons/material-community-icons/microphone-message.svg";
export default class MaterialCommunityIcons extends React.PureComponent {
  get svgIcon() {
    let SvgIcon = null;
    switch (this.props.name) {
      case "arrow-expand":
        SvgIcon = ArrowExpandSvg;
        break;
      case "close-circle":
        SvgIcon = CloseCircleSvg;
        break;
      case "checkbox-marked-circle":
        SvgIcon = CheckboxMarkedCircleSvg;
        break;
      case "magnify-minus-outline":
        SvgIcon = MagnifyMinusOutlineSvg;
        break;
      case "magnify-plus-outline":
        SvgIcon = MagnifyPlusOutlineSvg;
        break;
      case "magnify-minus":
        SvgIcon = MagnifyMinusSvg;
        break;
      case "magnify-plus":
        SvgIcon = MagnifyPlusSvg;
        break;
      case "microphone-message":
        SvgIcon = MicrophoneMessageSvg;
        break;
      case "file-document":
        SvgIcon = FileDocumentIconSvg;
        break;
      case "file-document-box-remove":
        SvgIcon = FileDocumentBoxRemoveSvg;
        break;
      case "file-document-box-check":
        SvgIcon = FileDocumentBoxCheckSvg;
        break;
      case "chevron-up":
        SvgIcon = ChevronUpIconSvg;
        break;
      case "arrow-up-bold-circle-outline":
        SvgIcon = ArrowUpBoldCircleOutlineIconSvg;
        break;
      case "arrow-up-bold":
        SvgIcon = ArrowUpBoldIconSvg;
        break;
      case "brain":
        SvgIcon = BrainIconSvg;
        break;
      case "eye":
        SvgIcon = EyeIconSvg;
        break;
      case "chart-bubble":
        SvgIcon = ChartBubbleIconSvg;
        break;
      case "language-python":
        SvgIcon = LanguagePythonIconSvg;
        break;
      case "robot":
        SvgIcon = RobotIconSvg;
        break;
      case "chart-line":
        SvgIcon = ChartLineIconSvg;
        break;
      case "checkbox-blank-outline":
        SvgIcon = CheckboxBlankOutlineSvg;
        break;
      case "checkbox-marked":
        SvgIcon = CheckboxMarkedSvg;
        break;
      case "checkbox-multiple-blank-outline":
        SvgIcon = CheckboxMultipleBlankOutlineSvg;
        break;
      case "checkbox-multiple-marked":
        SvgIcon = CheckboxMultipleMarkedSvg;
        break;
      case "radiobox-blank":
        SvgIcon = RadioboxBlankIconSvg;
        break;
      case "radiobox-marked":
        SvgIcon = RadioboxMarkedIconSvg;
        break;
      case "chevron-double-up":
        SvgIcon = ChevronDoubleUpIconSvg;
        break;
      case "chevron-double-down":
        SvgIcon = ChevronDoubleDownIconSvg;
        break;
      case "format-list-checkbox":
        SvgIcon = FormatListCheckboxSvg;
        break;
      case "email-outline":
        SvgIcon = EmailOutlineIconSvg;
        break;
      case "folder-outline":
        SvgIcon = FolderOutlineIconSvg;
        break;
      case "pencil":
        SvgIcon = PencilIconSvg;
        break;
      case "account":
        SvgIcon = AccountIconSvg;
        break;
      case "account-circle":
        SvgIcon = AccountCircleIconSvg;
        break;
      case "account-settings":
        SvgIcon = AccountSettingsIconSvg;
        break;
      case "file-document-box-multiple-outline":
        SvgIcon = DocumentsIconSvg;
        break;
      case "file":
        SvgIcon = FileOutlineIconSvg;
        break;
      case "fileHidden":
        SvgIcon = FileHiddenIconSvg;
        break;
      case "file-download":
        SvgIcon = FileDownloadIconSvg;
        break;
      case "fit-to-page-outline":
        SvgIcon = FitToPageOutlineSvg;
        break;
      case "power-plug":
        SvgIcon = PowerPlugIconSvg;
        break;
      case "calendar":
        SvgIcon = CalendarIconSvg;
        break;
      case "clock-outline":
        SvgIcon = ClockOutlineIconSvg;
        break;
      case "code-braces":
        SvgIcon = CodeBracesIconSvg;
        break;
      case "check-circle-outline":
        SvgIcon = CheckCircleOutlineSvg;
        break;
      case "database":
        SvgIcon = DatabaseIconSvg;
        break;
      case "database-export":
        SvgIcon = DatabaseExportIconSvg;
        break;
      case "database-import":
        SvgIcon = DatabaseImportIconSvg;
        break;
      case "cogs":
        SvgIcon = CogsIconSvg;
        break;
      case "source-branch":
        SvgIcon = SourceBranchIconSvg;
        break;
      case "eye-check-outline":
        SvgIcon = EyeCheckOutlineIconSvg;
        break;
      case "menu":
        SvgIcon = MenuIconSvg;
        break;
      case "menu-up":
        SvgIcon = MenuUpIconSvg;
        break;
      case "menu-down":
        SvgIcon = MenuDownIconSvg;
        break;
      case "settings-outline":
        SvgIcon = SettingOutlineIconSvg;
        break;
      case "account-multiple":
        SvgIcon = AccountMultipleIconSvg;
        break;
      case "account-group":
        SvgIcon = AccountGroupIconSvg;
        break;
      case "filter-variant":
        SvgIcon = FilterVariantIconSvg;
        break;
      case "magnify":
        SvgIcon = MagnifyIconSvg;
        break;
      case "minus":
        SvgIcon = MinusIconSvg;
        break;
      case "dots-vertical":
        SvgIcon = DotsVerticalIconSvg;
        break;
      case "dots-horizontal":
        SvgIcon = DotsHorizontalIconSvg;
        break;
      case "stop":
        SvgIcon = StopIconSvg;
        break;
      case "stop-circle":
        SvgIcon = StopCircleIconSvg;
        break;
      case "stop-circle-outline":
        SvgIcon = StopCircleOutlineIconSvg;
        break;
      case "play":
        SvgIcon = PlayIconSvg;
        break;
      case "play-circle":
        SvgIcon = PlayCircleIconSvg;
        break;
      case "play-circle-outline":
        SvgIcon = PlayCircleOutlineIconSvg;
        break;
      case "console":
        SvgIcon = ConsoleIconSvg;
        break;
      case "console-network":
        SvgIcon = ConsoleNetworkIconSvg;
        break;
      case "view-dashboard":
        SvgIcon = ViewDashboardIconIconSvg;
        break;
      case "developer-board":
        SvgIcon = DeveloperBoardIconSvg;
        break;
      case "calendar-clock":
        SvgIcon = CalendarClockIconSvg;
        break;
      case "server":
        SvgIcon = ServerIconSvg;
        break;
      case "message-text-outline":
        SvgIcon = MessageTextOutlineIconSvg;
        break;
      case "monitor-dashboard":
        SvgIcon = MonitorDashboardIconSvg;
        break;
      case "heart-pulse":
        SvgIcon = HeartPulseIconSvg;
        break;
      case "dashboard-check":
        SvgIcon = DashboardCheckIconSvg;
        break;
      case "cloud-sync":
        SvgIcon = CloudSyncIconSvg;
        break;
      case "cloud":
        SvgIcon = CloudIconSvg;
        break;
      case "restore":
        SvgIcon = RestoreIconSvg;
        break;
      case "import":
        SvgIcon = ImportIconSvg;
        break;
      case "export":
        SvgIcon = ExportIconSvg;
        break;
      case "application-import":
        SvgIcon = ApplicationImportIconSvg;
        break;
      case "application-export":
        SvgIcon = ApplicationExportIconSvg;
        break;
      case "cube-outline":
        SvgIcon = CubeOutlineIconSvg;
        break;
      case "security-account":
      case "shield-account":
        SvgIcon = ShieldAccountIconSvg;
        break;
      case "help-circle-outline":
        SvgIcon = HelpCircleOutlineIconSvg;
        break;
      case "git":
        SvgIcon = GitIconSvg;
        break;
      case "npm":
        SvgIcon = NpmIconSvg;
        break;
      case "cursor-move":
        SvgIcon = CursorMoveIconSvg;
        break;
      case "window-restore":
        SvgIcon = WindowRestore;
        break;
      case "window-close":
        SvgIcon = WindowClose;
        break;
      case "window-minimize":
        SvgIcon = WindowMinimize;
        break;
      case "window-maximize":
        SvgIcon = WindowMaximize;
        break;
      case "keyboard-outline":
        SvgIcon = KeyboardOutlineIconSvg;
        break;
      default:
        throw `Icon ${this.props.name} not found`;
    }
    return SvgIcon;
  }
  render() {
    return <SvgIcon svg={this.svgIcon} {...this.props} />;
  }
}
