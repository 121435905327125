import React from "react";
import View from "./View";
import Card from "./Card";
import StyleSheet from "./StyleSheet";
import ScrollView from "./ScrollView";

export default class CenteredCard extends React.Component {
  render() {
    return (
      <View style={styles.screenLayout}>
        <View style={styles.scrollViewContainer}>
          <ScrollView>
            <View style={styles.centeredLayout}>
              <View style={styles.layout}>
                <View style={styles.formLayout}>
                  <Card
                    title={this.props.title}
                    titleControlsRight={this.props.titleControlsRight}
                    titleControlsLeft={this.props.titleControlsLeft}
                    surfaceStyle={styles.formSurface}
                    style={[styles.formCard, this.props.style]}
                  >
                    {this.props.children}
                  </Card>
                  {this.props.FooterComponent && (
                    <View>{this.props.FooterComponent}</View>
                  )}
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
        {this.props.SideSheetComponent}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  screenLayout: {
    // position: "absolute",
    // ...StyleSheet.absoluteFill,
    flex: 1,
    flexDirection: "row",
  },
  sidebar: {
    // position:'absolute',
    // right: 0,
    // top: 0,
    // bottom: 0
  },
  scrollViewContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
  },
  centeredLayout: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...StyleSheet.padding(8, 0, 0, 0),

    // ...StyleSheet.margin(24, 0, 24, 0),
    // screen: ({ width }) => {
    //     if (width <= 640)
    //         return {
    //             // alignItems: 'flex-start',
    //             justifyContent: "flex-start",
    //         };
    // },
  },
  formLayout: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // ...StyleSheet.padding(8, 0, 0, 0),
    // ...StyleSheet.margin(24, 0, 24, 0),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          // alignItems: 'flex-start',
          justifyContent: "flex-start",
        };
    },
  },
  formHeader: {
    paddingBottom: 24,
  },
  formCard: {
    flex: 0,
    flexDirection: "column",
    // maxWidth: 480,
    width: "100%",
    alignItems: "center",
    // ...StyleSheet.margin(0),
    // ...StyleSheet.padding(0),
    // paddingBottom: 24
  },
  formSurface: {
    width: "100%",
    // ...StyleSheet.padding(8, 16, 16, 16),
    // ...StyleSheet.margin(0, 0, 16, 0),
    // ...StyleSheet.padding(0)
  },
  layout: {
    flex: 0,
    flexDirection: "row",
  },
});
