import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";

import View from "./View";
import Text from "./Text";
import ScrollView from "./ScrollView";
import StyleSheet from "./StyleSheet";
import AnimatedView from "./AnimatedView";
import ControlsView from "./ControlsView";
import IconButton from "./IconButton";
import TextInput from "./TextInput";
import { Colors } from "./Theme";

export default forwardRef(function PaginatedView(
  {
    pageLoader,
    initializer,
    itemRenderer,
    headerTitle = null,
    headerTitleTextStyle = null,
    headerStyle = null,
    headerControlsRight = null,
    initialTotalItems = null,
    initialItemsPerPage = 10,
    initialPage = 1,
    initialData = null,
    itemLoadingRenderer = null,
    ItemLoadingComponent = null,
    searchable = false,
    pageLoadDelay = 500,
    horizontal = false,
    onScroll = null,
    onContentSizeChange = null,
  },
  ref
) {
  const [refreshToken, setRefreshToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchTextInputValue, setSearchTextInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(initialPage || 1);
  const scrollRef = useRef(null);
  const currentPageRef = useRef({
    page: initialPage || null,
    data: initialData || null,
  });
  const itemsPerPageRef = useRef(initialItemsPerPage);
  const totalItemsRef = useRef(initialTotalItems || 0);
  const forceRefresh = () => setRefreshToken(!refreshToken);
  const dynamicStyles = {};
  const handleScroll = (e) => {
    if (onScroll) onScroll(e);
  };
  const handleChangeText = (value) => {
    setSearchTextInputValue(value);
  };
  const handleHideSearchPress = () => {
    setShowSearch(false);
  };
  const handleShowSearchPress = () => {
    setShowSearch(true);
  };

  const load = async (options) => {
    setLoading(true);
    let res = await pageLoader(
      {
        page: currentPage,
        itemsPerPage: itemsPerPageRef.current,
        search: null,
      },
      options
    );
    console.log("CHECK RES?", res);
    currentPageRef.current = {
      page: currentPage,
      data: res,
    };
    setLoading(false);
  };

  useEffect(() => {
    console.log("Current page changed asdf", currentPage);
    return () => {
      // Unmount
      load({ totalCount: true });
    };
  }, []);

  useEffect(() => {
    return () => {
      console.log("Current page changed", currentPage);
      if (
        currentPageRef.current.page !== currentPage ||
        !currentPageRef.current.data
      ) {
        load();
      }
    };
  }, [currentPage]);

  // Share scroll methods with the component
  useImperativeHandle(
    ref,
    () => {
      return {};
    },
    []
  );

  const loadCurrentPage = async () => {
    let res = await pageLoader(currentPageRef.current);
    return res;
  };

  const renderHeader = () => {
    let shouldRenderSortMenu = false;
    let hasFilterForm = false;

    // let headerControlsRight = [];
    // if (this.props.headerControlsRight) {
    //   let propHeaderControls =
    //     typeof headerControlsRight === "function"
    //       ? this.props.headerControlsRight(this.dataTable)
    //       : this.props.headerControlsRight;
    //     if(Array.isArray(propHeaderControlsRight)) headerControlsRight = [...propHeaderControls];
    // }
    let controlsRight = [];
    if (typeof headerControlsRight === "function") {
      controlsRight = [...headerControlsRight()];
    } else if (Array.isArray(headerControlsRight)) {
      controlsRight = [...headerControlsRight];
    }

    if (searchable) controlsRight.unshift(renderSearchWidget());
    const headerControls =
      controlsRight && controlsRight.length ? (
        <ControlsView align='right'>{controlsRight}</ControlsView>
      ) : null;
    return (
      <View style={[styles.header, headerStyle]}>
        {((controlsRight && controlsRight.length) || headerTitle) && (
          <View style={styles.headerTitle}>
            <Text style={[styles.headerTitleText, headerTitleTextStyle]}>
              {headerTitle || null}
            </Text>
            {headerControls}
          </View>
        )}
      </View>
    );
  };

  const renderSearchWidget = () => {
    const placeholder = headerTitle ? `Search ${headerTitle}` : "Search";
    return (
      <View style={styles.searchWidget}>
        <AnimatedView
          enter={{
            transform: [{ translateX: 0 }, { scale: 1 }],
            opacity: 1,
          }}
          exit={{
            transform: [{ translateX: 80 }, { scale: 0.25 }],
            opacity: 0,
          }}
          in={showSearch}
          duration={150}
          style={styles.searchTextInputFieldContainer}
          unmountOnExit
        >
          <View style={styles.searchTextInputField}>
            <TextInput
              name='search'
              value={searchTextInputValue}
              onChangeText={handleChangeText}
              style={styles.searchTextInput}
              autoFocus
              //onBlur={this.handleBlur}
              placeholder={placeholder}
            />
            <IconButton
              icon='arrowForward'
              key='close'
              size='small'
              onPress={handleHideSearchPress}
              style={styles.hideIconButton}
            />
          </View>
        </AnimatedView>
        <IconButton
          icon='search'
          key='search'
          color={searchTextInputValue ? Colors.primary : Colors.onSurface}
          onPress={handleShowSearchPress}
        />
      </View>
    );
  };
});

// const renderFooter = () => {
//   return (
//     <View style={styles.footer}>
//       <View style={styles.footerCell}>
//         <View style={styles.footerRowControls}>
//           <View>
//             <Text style={styles.footerText}>Rows Per Page:</Text>
//           </View>
//           {/* <SelectMenu
//             name='rowsPerPage'
//             style={styles.rowsPerPageSelectMenu}
//             useValuesOnly
//             valueLabelTextStyle={styles.rowsPerPageSelectMenuText}
//             onValueChange={this.handleRowsPerPageValueChange}
//             items={this.dataTable.rowsPerPageSelectMenuItems}
//             value={this.dataTable.state.rowsPerPage}
//           />
//         </View> */}
//       </View>
//         <View style={styles.footerCell}>
//           <View style={styles.footerRowInfo}>
//             <Text style={styles.footerText}>
//               {this.dataTable.startIndex + 1} -{" "}
//               {this.dataTable.totalRows <= this.dataTable.endIndex
//                 ? this.dataTable.totalRows
//                 : this.dataTable.endIndex + 1}{" "}
//               of {this.dataTable.totalRows}
//             </Text>
//           </View>
//           <View style={styles.footerControls}>
//             <IconButton
//               icon='keyboardArrowLeft'
//               disabled={currentPage === 1}
//               onPress={loadPreviousPage}
//               size='large'
//             />
//             <IconButton
//               icon='keyboardArrowRight'
//               disabled={currentPage >= totalPages}
//               onPress={loadPreviousPage}
//               size='large'
//             />
//           </View>
//         </View>
//         </View>
//     </View>
//   );
//   }

const styles = StyleSheet.create({
  header: {
    backgroundColor: Colors.surface,
    elevation: 2,
  },
  headerTitle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    ...StyleSheet.padding(16, 16, 12, 16),
  },
  headerTitleText: {
    fontSize: 18,
    fontWeight: "500",
    lineHeight: 36,
    color: Colors.onSurface,
  },
  searchWidget: {
    position: "relative",
    overflow: "visible",
  },
  searchTextInputField: {
    flex: 1,
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    ...StyleSheet.padding(4, 16, 4, 16),
    borderRadius: 4,
    flexDirection: "row",
  },
  searchTextInputFieldContainer: {
    backgroundColor: Colors.surface,
    height: 36,
    position: "absolute",
    right: 2,
    top: -4,
    zIndex: 2,
  },
  searchTextInput: {
    backgroundColor: "transparent",
    color: Colors.onBackground,
    fontSize: 16,
    width: 144,
  },
  hideIconButton: {
    marginRight: -12,
  },
  footer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 0,
    screen: ({ width }) => {
      if (width <= 480) {
        return {
          flexDirection: "column-reverse",
          alignItems: "flex-end",
        };
      }
    },
  },
  footerText: {
    color: StyleSheet.color(Colors.onSurface).rgba(0.6),
    fontWeight: "500",
    fontSize: 14,
  },
  footerCell: {
    flex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  footerRowControls: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 480) {
        return {
          marginRight: -14,
        };
      }
    },
  },
  footerRowInfo: {
    marginLeft: 30,
  },
  footerControls: {
    height: 48,
    flex: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginLeft: 44,
  },
  rowsPerPageSelectMenu: {
    screen: ({ width }) => {
      if (width <= 480) {
        return {
          marginRight: 20,
        };
      }
    },
  },
  rowsPerPageSelectMenuText: {
    // width: 80
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
    fontWeight: "500",
  },
});
