import React from "react";
import PropTypes from "prop-types";
import View from "./View";
import Surface from "./Surface";
import SectionHeader from "./SectionHeader";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import Modal from "./Modal";
import Button from "./Button";
import IconButton from "./IconButton";
import Text from "./Text";
import R14 from "../R14";

export default R14.connect(
  class Dialog extends React.Component {
    static propTypes = {
      /** Name for the dialog. */
      name: PropTypes.string.isRequired,
      /** Title for the dialog. */
      title: PropTypes.string.isRequired,
      /** If set to true, the dialog will be visible */
      visible: PropTypes.bool.isRequired,
      /** Function to be called when ok button is pressed. */
      onOkPress: PropTypes.func,
      /** Function to be called when ok cancel is pressed. */
      onCancelPress: PropTypes.func,
      /** Function to be called when header title back button is pressed. */
      onBackPress: PropTypes.func,
      /** Function to be called when header title close button is pressed. */
      onClosePress: PropTypes.func,
      /** Function to be called when ok cancel is pressed. */
      onBackdropPress: PropTypes.func,
      /** Title of the ok button, defaults to OK. */
      okButtonTitle: PropTypes.string,
      /** Type of icon to display in the top left of the dialog */
      titleControlVariant: PropTypes.oneOf(["close", "back"]),
    };
    static defaultProps = {
      visible: false,
    };
    constructor(props) {
      super(props);
      this.handleOkPress = this.handleOkPress.bind(this);
      this.handleBackdropPress = this.handleBackdropPress.bind(this);
      this.handleCancelPress = this.handleCancelPress.bind(this);
      this.handleClosePress = this.handleClosePress.bind(this);
      this.handleBackPress = this.handleBackPress.bind(this);
    }
    handleOkPress() {
      this.props.onOkPress && this.props.onOkPress();
    }
    handleBackdropPress() {
      this.props.onBackdropPress && this.props.onBackdropPress();
    }
    handleCancelPress() {
      this.props.onCancelPress && this.props.onCancelPress();
    }
    handleBackPress() {
      this.props.onBackPress && this.props.onBackPress();
    }
    handleClosePress() {
      this.props.onClosePress && this.props.onClosePress();
    }
    renderHeaderControlLeft() {
      if (this.props.controlsLeft) return this.props.controlsLeft;
      if (!this.props.titleControlVariant) return null;
      let ret = null;
      switch (this.props.titleControlVariant) {
        case "close":
          ret = <IconButton icon='close' onPress={this.handleCancelPress} />;
          break;
        case "back":
          ret = <IconButton icon='arrowBack' onPress={this.handleBackPress} />;
          break;
      }
      return ret;
    }
    renderHeader() {
      return (
        <View htmlTag='header' style={styles.header}>
          <View style={styles.headerContent}>
            {this.renderHeaderControlLeft()}
            {this.props.title && (
              <View style={styles.headerTitle}>
                <Text>{this.props.title}</Text>
              </View>
            )}
            {this.props.headerControlsRight && (
              <View style={styles.headerControlsRight}>
                {this.props.headerControlsRight}
              </View>
            )}
          </View>
        </View>
      );
    }
    render() {
      return (
        <Modal
          animationType='fade'
          backdropStyle={styles.backdrop}
          onBackdropPress={this.handleBackdropPress}
          transparent={true}
          visible={this.props.visible}
          style={[styles.modal]}
          onRequestClose={() => {}}
          unmountOnExit
          backdrop
          scrollEnabled
        >
          <Surface style={[styles.dialog, this.props.style]} elevation={12}>
            {this.renderHeader()}
            <View style={[styles.content, this.props.contentStyle]}>
              {this.props.children}
            </View>
            {(!this.props.hideCancelButton || !this.props.hideOkButton) && (
              <View style={styles.buttons}>
                {this.props.hideCancelButton !== true && (
                  <Button
                    title={this.props.cancelButtonTitle || "Cancel"}
                    variant={this.props.cancelButtonVariant || "text"}
                    onPress={this.handleCancelPress}
                    color={this.props.cancelButtonColor}
                    style={styles.cancelButton}
                  />
                )}
                {this.props.hideOkButton !== true &&
                  this.props.OkButtonComponent}
                {this.props.hideOkButton !== true &&
                  !this.props.OkButtonComponent && (
                    <Button
                      title={this.props.okButtonTitle || "Ok"}
                      variant={this.props.okButtonVariant || "text"}
                      onPress={this.handleOkPress}
                      focus={true}
                    />
                  )}
              </View>
            )}
          </Surface>
        </Modal>
      );
    }
  }
);
const styles = StyleSheet.create({
  showModalButton: {
    ...StyleSheet.margin(16, 0, 0, 16),
  },
  modal: {},
  backdrop: {
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.8),
  },
  dialog: {
    flex: 0,
    position: "relative",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 4,
    ...StyleSheet.padding(84, 0, 0, 0),
    minWidth: 300,
    maxWidth: 480,
    minHeight: 300,
    marginTop: 80,
    marginBottom: 80,
    zIndex: 2,
    backgroundColor: Colors.background,
    overflow: "hidden",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: 280,
          minWidth: 280,
          // maxWidth: null,
          // // width: "100%",
          // // minHeight: "100%",
          // borderRadius: 0,
          // ...StyleSheet.margin(0),
        };
    },
  },
  header: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    height: 64,
    // lineHeight: 64,
    elevation: 3,

    // zIndex: 5,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 56,
          // lineHeight: 56
        };
    },
  },
  headerContent: {
    width: "auto",
    display: "flex",
    flexDirection: "row",
    // ...StyleSheet.padding(8, 4, 8, 4),
    margin: 0,
    alignItems: "center",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: Colors.surface,
    color: Colors.onSurface,
    height: 64,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 56,
        };
    },
  },
  headerTitle: {
    flex: 0,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 20,
    fontWeight: "500",
  },
  headerControlsLeft: {
    flex: 0,
    flexDirection: "row",
    margin: 0,
    alignItems: "center",
    opacity: 0.94,
  },
  headerControlsRight: {
    flex: 0,
    flexDirection: "row",
    margin: 0,
    marginLeft: "auto",
    alignItems: "center",
    opacity: 0.94,
  },
  // header: {
  //   flex: 0,
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   height: 56,
  //   elevation: 2,
  //   marginBottom: 16,
  // },
  // titleControlsRight: {
  //   ...StyleSheet.padding(0, 4, 0, 16),
  // },
  // title: {
  //   ...StyleSheet.padding(0, 16, 0, 16),
  // },
  // sectionHeader: {
  //   fontSize: 18,
  //   fontWeight: 600,
  //   ...StyleSheet.padding(0),
  //   ...StyleSheet.margin(0),
  // },
  content: {
    alignSelf: "flex-start",
    ...StyleSheet.padding(0, 16, 0, 16),
    marginBottom: "auto",
    width: "100%",
  },
  buttons: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    height: 52,
    ...StyleSheet.padding(0, 8, 0, 8),
  },
  cancelButton: {
    marginRight: 8,
  },
});
