import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import Switch from "./Switch";
import View from "./View";
import Text from "./Text";
import InputField from "./InputField";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import ReCaptcha from "./ReCaptcha";

export default R14.connectForm(
  /**
   * A hidden form field field component
   */
  class SwitchField extends React.Component {
    static propTypes = {
      /** Label for the switch. */
      label: PropTypes.string,
      /** Name for the switch field. */
      name: PropTypes.string.isRequired,
      /** If true the user won't be able to toggle the checkbox. Default value is false. */
      disabled: PropTypes.bool,
      /** The Google site key value. */
      siteKey: PropTypes.string,
      /** Invoked with the new value when the value changes. */
      onValueChange: PropTypes.func,
    };
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
    }
    handleValueChange(value) {
      this.elmt.setValue(value);
      if (this.props.onValueChange) this.props.onValueChange(value, this.elmt);
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setBlur(true);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    render() {
      return (
        <InputField
          // {...this.props}
          style={this.props.style}
          element={this.elmt}
          containerStyle={styles.inputFieldContainer}
          InputComponent={
            <View style={styles.recaptchaFieldWrapper}>
              {this.props.label && (
                <Text style={styles.labelText}>{this.props.label}</Text>
              )}
              <ReCaptcha
                {...this.props}
                onValueChange={this.handleValueChange}
              />
            </View>
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  recaptchaFieldWrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelText: {
    lineHeight: 14,
    // pointerEvents: "none",
    fontWeight: "500",
    paddingRight: 12,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  inputFieldContainer: {
    borderBottomWidth: 0,
    borderColor: StyleSheet.color(Colors.onBackground).rgba(0.4),
    borderStyle: null,
    ...StyleSheet.padding(0),
  },
});
