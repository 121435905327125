import R14ApiResult from "./R14ApiResult";

export default class R14ApiSubscription {
  constructor(r14, requestData, callback) {
    this._r14 = r14;
    this._requestData = requestData;
    this._callback = callback;
    this._keys = null;
    // this._r14.app.io.on('disconnect',this.handleDisconnect)
  }
  async subscribe() {
    let subscriber = new Promise((resolve, reject) => {
      this._r14.app.io.emit("subscribe", this._requestData, (data) => {
        if (data && data.keys) {
          for (let i in data.keys) {
            this._r14.app.io.on(data.keys[i], (res) => {
              this._callback(new R14ApiResult(res));
            });
          }
        }
        resolve(data || null);
      });
    });
    let keys = await subscriber;
    this._keys = keys && keys.keys ? keys.keys : {};
    return this;
  }
  unsubscribe() {
    return this.off();
  }
  off() {
    if (this._keys) {
      for (let i in this._keys) {
        //console.log("io.off?",  this._keys[i]);
        this._r14.app.io.off(this._keys[i]);
      }
      // console.log('-------------');
      // console.log('unsubscribe', this._keys);
      // console.log('-------------');
      this._r14.app.io.emit("unsubscribe", { keys: this._keys });
    }
  }
}
