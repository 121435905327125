import R14 from "../R14";
import React from "react";

export default class ScrollerUiDomain extends R14.DomainInstances {
  create(props) {
    let scroller = new ScrollerInstanceUiDomain(props);
    this.addInstance(props.name, scroller);
    return scroller;
  }
  remove(name) {
    this.exists(name) && this.removeInstance(name);
  }
}
class ScrollerInstanceUiDomain extends R14.Domain {
  constructor(props) {
    super();
    if (!props.name) throw "Scroller Error: Scroller must have a name.";
    this._name = props.name;
    this.items = new ScrollerItemUiDomain(this);
    this.scrollRef = React.createRef();
    this.lastScrollEvent = null;
    this.lastActiveScrollItemName = null;
    this.autoScroll = props.autoScroll === false ? false : true;
    this.state = {
      activeItemName: props.activeItemName || null,
      activeItemName: props.activeItemState || null,
      direction: props.direction || "horizontal",
      controlLeftVisible: false,
      controlRightVisible: false,
      controlTopVisible: false,
      controlBottomVisible: false,
      layout: null,
    };
  }
  get activeItem() {
    return this.state.activeItemName
      ? this.items.getInstance(this.state.activeItemName)
      : null;
  }
  async addPageData(page, items = [], options = {}) {
    if (!this.scrollRef.current)
      throw new Error(
        "Scroller error. Can't add page data. Scroll not initialized."
      );
    await this.scrollRef.current.addPageData(page, items, options);
    // this.onItemReady
  }
  async reload(options = {}) {
    if (!this.scrollRef.current)
      throw new Error("Scroller error. Can't reload. Scroll not initialized.");
    await this.scrollRef.current.reload(options);
    // this.onItemReady
  }
  addItem(props, options = {}) {
    let ret = this.items.add(props, options);
    return ret;
  }
  removeItem(props, options = {}) {
    let ret = this.items.remove(props, options);
    return ret;
  }
  setActiveItemName(activeItemName, activeItemState = null) {
    this.setState({ activeItemName, activeItemState });
  }
  triggerOnScroll() {
    this.scrollRef.current && this.scrollRef.current.triggerOnScroll();
  }
  scrollTo(params = {}) {
    this.scrollRef.current && this.scrollRef.current.scrollTo(params);
  }
  scrollToItem(params = {}) {
    this.scrollRef.current && this.scrollRef.current.scrollToItem(params);
  }
  scrollToIndex(index, options = {}) {
    this.scrollRef.current &&
      this.scrollRef.current.scrollToIndex(index, options);
  }
  scrollToEnd(options = {}) {
    this.scrollRef.current && this.scrollRef.current.scrollToEnd(options);
  }
  forceRefresh(index) {
    this.scrollRef.current && this.scrollRef.current.forceRefresh(index);
  }
  handleContentLayout(width, height) {
    this.setState({ layout: { width, height } });
  }
  handleScroll(event) {
    this.lastScrollEvent = event.nativeEvent;
    let control =
      this.state.direction === "horizontal"
        ? {
            size: "width",
            axis: "x",
            control1: "controlLeftVisible",
            control2: "controlRightVisible",
          }
        : {
            size: "height",
            axis: "y",
            control1: "controlTopVisible",
            control2: "controlBottomVisible",
          };

    let size = this.lastScrollEvent.layout[control.size];
    let contentSize = this.lastScrollEvent.contentSize[control.size];
    let offset = this.lastScrollEvent.contentOffset;
    let control1Visible = false;
    let control2Visible = false;

    if (contentSize > size) {
      if (offset[control.axis] > 16) control1Visible = true;
      if (offset[control.axis] + size < contentSize) control2Visible = true;
    }
    if (
      this.state[control.control1] !== control1Visible ||
      this.state[control.control2] !== control2Visible
    )
      this.setState({
        [control.control1]: control1Visible,
        [control.control2]: control2Visible,
      });
    this.updateActiveItemScroll();
  }
  updateActiveItemScroll(activeItem, options = {}) {
    let item = activeItem || this.activeItem;
    if (
      !item ||
      !item.active ||
      !item.itemRef ||
      !item.itemRef.current ||
      !this.lastScrollEvent
    )
      return false;

    if (this.lastActiveScrollItemName === item.name && !options.force)
      return false;
    if (!this.autoScroll && !options.force) return false;
    if (!this._m)
      item.itemRef.current.measure((x, y, width, height, pageX, pageY) => {
        let scrollSize = this.lastScrollEvent.layout;
        let ScrollContentSize = this.lastScrollEvent.contentSize;
        let scrollOffset = this.lastScrollEvent.contentOffset;
        let scrollParams = null;
        switch (this.state.direction) {
          case "horizontal":
            let scrollLeft = scrollOffset.x;
            let scrollRight = scrollOffset.x + scrollSize.width;
            let itemLeft = x;
            let itemRight = x + width;
            if (itemLeft < scrollLeft) scrollParams = { x: itemLeft - 32 };
            if (itemRight > scrollRight)
              scrollParams = { x: scrollLeft + (itemRight - scrollRight + 64) };
            break;
          case "vertical":
            let scrollTop = scrollOffset.y;
            let scrollBottom = scrollOffset.y + scrollSize.height;
            let itemTop = y;
            let itemBottom = y + height;
            if (itemTop < scrollTop) scrollParams = { y: itemTop - 32 };
            if (itemBottom > scrollBottom)
              scrollParams = {
                y: scrollTop + (itemBottom - scrollBottom + 64),
              };
        }
        if (scrollParams) {
          scrollParams.animated = true;
          this.scrollTo(scrollParams);
        }
        // if (offsetLeft >= 32)
        //   this.scrollRef.current.scrollTo({ x: offsetLeft - 32 });
      });
    this.lastActiveScrollItemName = item.name;
  }
  handleControlLeftPress() {
    let { contentOffset, layout } = this.lastScrollEvent;
    let scrollTo = contentOffset.x - layout.width * 0.8;
    if (scrollTo < 0) scrollTo = 0;
    this.scrollTo({ x: scrollTo });
  }
  handleControlRightPress() {
    let { contentOffset, contentSize, layout } = this.lastScrollEvent;
    let scrollTo = contentOffset.x + layout.width * 0.8;
    if (scrollTo > contentSize.width) scrollTo = contentSize.width;
    this.scrollTo({ x: scrollTo });
  }
  handleControlTopPress() {
    let { contentOffset, layout } = this.lastScrollEvent;
    let scrollTo = contentOffset.y - layout.height * 0.8;
    if (scrollTo < 0) scrollTo = 0;
    this.scrollTo({ y: scrollTo });
  }
  handleControlBottomPress() {
    let { contentOffset, contentSize, layout } = this.lastScrollEvent;
    let scrollTo = contentOffset.y + layout.height * 0.8;
    if (scrollTo > contentSize.height) scrollTo = contentSize.height;
    this.scrollTo({ y: scrollTo });
  }
}
class ScrollerItemUiDomain extends R14.DomainInstances {
  constructor(scroller) {
    super();
    this._scroller = scroller;
  }
  add(props, options = {}) {
    let item = new ScrollerItemUiDomainInstance(this._scroller, props, options);
    this.addInstance(props.name, item);
    return item;
  }
  remove(name) {
    this.removeInstance(name);
    return this;
  }
}
class ScrollerItemUiDomainInstance extends R14.Domain {
  constructor(scroller, props, options = {}) {
    super();
    if (!props.name) throw "Scroller Error: Scroller must have a name.";
    this._scroller = scroller;
    this._name = props.name;
    this.itemRef = React.createRef();
    this.state = {
      activeItemName: props.activeItemName || null,
    };
  }
  get active() {
    return this._scroller.state.activeItemName === this.name;
  }
  get activeState() {
    return this.active ? this._scroller.state.activeItemState : null;
  }
  get name() {
    return this._name;
  }
  updateActiveScroll(options = {}) {
    this._scroller.updateActiveItemScroll(this, options);
  }
  remove() {
    this._scroller.removeItem(this._name);
  }
}
