import React from 'react';
import PropTypes from 'prop-types'
import R14 from '../R14';

export default R14.connectForm(
  /**
  * A hidden form field field component
  */
  class HiddenInputField extends React.Component {
    static propTypes = {
      /** Name for the hidden field. */
      name: PropTypes.string.isRequired,
      /** Value of the hidden field. */
      value: PropTypes.any,
    }
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
    }
    componentWillUnmount(){
      this.elmt.remove();
    }
    render() {
      return null;
    }
  }
);