import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import AnimatedView from "./AnimatedView";
import ScrollView from "./ScrollView";
import View from "./View";

export default class ZoomableView extends React.Component {
  static propTypes = {
    /** The zoom level, 1 is no zoom */
    level: PropTypes.number.isRequired,
    /** The height of the view */
    height: PropTypes.number.isRequired,
    /** The width of the view */
    width: PropTypes.number.isRequired,
    /** Duration of the zoom */
    duration: PropTypes.number,
    /** Called when zoom has started */
    onStart: PropTypes.func,
    /** Called when zoom has ended */
    onEnd: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollViewLayout = this.handleScrollViewLayout.bind(this);
    this.handleScrollViewContentSizeChange =
      this.handleScrollViewContentSizeChange.bind(this);
    this.state = {
      translateX: 0,
      translateY: 0,
      scrollViewLayout: null,
      scrollViewContentLayout: null,
      animating: false,
      viewportCenter: null,
      level: this.props.level,
    };
    this._ref = React.createRef();
    this._scrollRef = React.createRef();
    this._prevLevel = this.props.level;
    this._prevTarget = null;
    this._prevRotate = this.props.rotate || 0;
    this._height = this.props.height;
    this._width = this.props.width;
    this._scrollOffset = { x: 0, y: 0 };
    this._animating = false;
    this._levelStateTimeout = null;
  }
  get scrollRef() {
    return this.props.scrollRef || this._scrollRef;
  }
  get padding() {
    return this.props.padding || 0;
  }
  get rotate() {
    let rotate = this.props.rotate;
    if (rotate < 0) rotate = 360 + (rotate % 360);
    else if (rotate > 0) rotate = rotate % 360;
    return rotate;
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.level !== prevProps.level ||
      this.props.rotate !== prevProps.rotate
    ) {
      this._prevLevel = prevProps.level;
      this._prevRotate = prevProps.rotate;
      this.updateZoomRotate();
    } else if (prevProps.rotate === this.props.rotate) {
      this._prevRotate = prevProps.rotate;
    }
  }
  componentWillUnmount() {
    if (this._levelStateTimeout) clearTimeout(this._levelStateTimeout);
  }

  handleScroll(event) {
    this._scrollOffset = event.nativeEvent.contentOffset;
    if (this.props.level !== this.state.level)
      this.setState({
        scale: this.props.level,
      });
    this.props.onScroll && this.props.onScroll(event);
  }
  get viewportDimensions() {
    return {
      height: this.props.height,
      width: this.props.width,
    };
  }
  setLevelState(level) {
    this._levelStateTimeout = setTimeout(() => {
      this.setState({
        level: this.props.level,
      });
      this._levelStateTimeout = null;
    }, 1);
  }
  calculateTarget(level, rotate) {
    let ret = null;
    let target = null;
    let size = null;
    let viewportLayout = this.state.scrollViewLayout;
    let viewportTarget = {
      x: viewportLayout.width / 2 + this._scrollOffset.x,
      y: viewportLayout.height / 2 + this._scrollOffset.y,
    };
    if (!viewportLayout) return null;
    switch (rotate) {
      case 0:
        // New size
        let size = {
          height: this.props.height * level,
          width: this.props.width * level,
        };
        ret = {
          x: viewportTarget.x / size.width,
          y: viewportTarget.y / size.height,
        };
        break;
      case 90:
        break;
      case 180:
        break;
      case 270:
        break;
    }
    return ret;
  }
  updateTarget() {
    let viewportLayout = this.state.scrollViewLayout;
    if (!viewportLayout) return false;
    this._prevTarget = {
      x: viewportLayout.width / 2 + this._scrollOffset.x,
      y: viewportLayout.height / 2 + this._scrollOffset.y,
    };
  }
  updateZoomRotate() {
    if (!this._prevLevel) return;

    let viewportLayout = this.state.scrollViewLayout;
    if (!viewportLayout) return false;
    // Current Size
    let size = {
      height: this.props.height * this.props.level,
      width: this.props.width * this.props.level,
    };
    // New size
    let prevSize = {
      height: this.props.height * this._prevLevel,
      width: this.props.width * this._prevLevel,
    };
    // Figure out the current zoom target (center of the viewport)
    let target = this.calculateTarget(this._prevLevel, this._prevRotate);
    let prevTarget = {
      x: viewportLayout.width / 2 + this._scrollOffset.x,
      y: viewportLayout.height / 2 + this._scrollOffset.y,
    };
    // Figure out the percentage of the document for the target
    let targetPercent = {
      x: prevTarget.x / prevSize.width,
      y: prevTarget.y / prevSize.height,
    };
    let newTarget = {
      x: size.width * targetPercent.x,
      y: size.height * targetPercent.y,
    };

    if (this.props.rotate !== this._prevRotate) {
      switch (this.props.rotate) {
        case 0:
          // New size
          switch (this._prevRotate) {
            case 270:
              newTarget = {
                y: size.width * targetPercent.x,
                x: size.height * targetPercent.y,
              };
              break;
          }

          break;
        case 90:
          switch (this._prevRotate) {
            case 0:
              newTarget = {
                y: size.width * targetPercent.x,
                x: size.height * (1 - targetPercent.y),
              };
              break;
            case 180:
              newTarget = {
                y: size.width * targetPercent.x,
                x: size.height * targetPercent.y,
              };
              break;
          }

          break;
        case 180:
          switch (this._prevRotate) {
            case 90:
              newTarget = {
                y: size.width * targetPercent.x,
                x: size.height * targetPercent.y,
              };
              break;
            case 270:
              // newTarget = {
              //   y: size.width * targetPercent.x,
              //   x: size.height * targetPercent.y,
              // };
              break;
          }

          break;
        case 270:
          switch (this._prevRotate) {
            case 180:
              newTarget = {
                y: size.width * targetPercent.x,
                x: size.height * (1 - targetPercent.y),
              };
              break;
            case 270:
              // newTarget = {
              //   y: size.width * targetPercent.x,
              //   x: size.height * targetPercent.y,
              // };
              break;
          }

          break;
      }
    }
    // Calculate the new target
    let scrollTo = {
      x: newTarget.x - viewportLayout.width / 2,
      y: newTarget.y - viewportLayout.height / 2,
    };

    this.scrollRef.current.scrollTo({ ...scrollTo, animated: false });

    this.setLevelState(this.props.level);
  }
  handleScrollViewLayout(event) {
    this.setState({
      scrollViewLayout: event.nativeEvent.layout,
      viewportCenter: this.calculateViewportCenter(event.nativeEvent.layout),
    });
    this.props.onScrollViewLayout && this.props.onScrollViewLayout(event);
  }
  handleScrollViewContentSizeChange(width, height) {
    this.setState({
      scrollViewContentLayout: { width, height },
    });
    this.props.onScrollViewContentSizeChange &&
      this.props.onScrollViewContentSizeChange(width, height);
  }
  calculateViewportCenter(scrollViewLayout = null) {
    if (!scrollViewLayout) scrollViewLayout = this.state.scrollViewLayout;
    if (!scrollViewLayout) return { x: 0, y: 0 };
    return {
      x: scrollViewLayout.width / 2,
      y: scrollViewLayout.height / 2,
    };
  }
  calculateZoomTarget() {
    let ret = { x: 0.5, y: 0.5 };
    let scrollViewLayout = this.state.scrollViewLayout;
    let scrollViewContentLayout = this.state.scrollViewContentLayout;
    let scrollOffset = this._scrollOffset;
    if (!scrollViewLayout || !scrollViewContentLayout) return ret;

    let viewportCenter = this.calculateViewportCenter();

    return ret;
  }
  calculateTranslate() {
    let zoomTarget = this.calculateZoomTarget();
    if (!this.state.animating)
      return this.state.animating
        ? {
            x:
              this.props.width /
              (this.props.level /
                (((this.props.width - this.props.width * this.props.level) *
                  zoomTarget.x) /
                  this.props.width)),
            y:
              this.props.height /
              (this.props.level /
                (((this.props.height - this.props.height * this.props.level) *
                  zoomTarget.y) /
                  this.props.height)),
          }
        : { x: 0, y: 0 };
  }

  createDynamicStyles() {
    let dynamicStyles = {};
    let viewportCenter = this.calculateViewportCenter();
    let rotate = this.rotate;
    let rotateTransforms = [];
    let height = this.props.height;
    let width = this.props.width;
    switch (rotate) {
      case 90:
        height = this.props.width;
        width = this.props.height;
        rotateTransforms = [
          {
            rotate: `${rotate}deg`,
          },
          {
            translateY: -this.props.height,
          },
        ];
        break;
      case 180:
        rotateTransforms = [
          {
            rotate: `${rotate}deg`,
          },
          {
            translateX: -this.props.width,
          },
          {
            translateY: -this.props.height,
          },
        ];
        break;
      case 270:
        height = this.props.width;
        width = this.props.height;
        rotateTransforms = [
          {
            rotate: `${rotate}deg`,
          },
          {
            translateX: -this.props.width,
          },
        ];
        break;
    }
    dynamicStyles.zoomableView = {
      height: height * this.props.level,
      width: width * this.props.level,
    };
    dynamicStyles.scrollViewContent = {
      minHeight: height * this.props.level + this.padding * 2,
      minWidth: width * this.props.level + this.padding * 2,
    };
    if (this.props.padding)
      dynamicStyles.scrollViewContent.padding = this.props.padding;
    if (this.props.backgroundColor)
      dynamicStyles.scrollViewContent.backgroundColor =
        this.props.backgroundColor;

    let translate = this.calculateTranslate();

    dynamicStyles.zoomableViewScale = {
      transform: [
        {
          scale: this.state.level,
        },
        ...rotateTransforms,
      ],
      height: this.props.height,
      width: this.props.width,
    };

    // this.props.height

    return StyleSheet.create(dynamicStyles);
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    // let zoomTarget = this.calculateZoomTarget();
    if (this._prevRotate !== this.props.rotate) {
      this.updateTarget();
    }
    return (
      <View style={styles.zoomableViewWrapper}>
        <ScrollView
          onLayout={this.handleScrollViewLayout}
          onContentSizeChange={this.handleScrollViewContentSizeChange}
          onScroll={this.handleScroll}
          ref={this.scrollRef}
          contentStyle={[
            styles.scrollViewContent,
            dynamicStyles.scrollViewContent,
            this.props.scrollViewContentStyle,
          ]}
          overflow={true}
          style={[styles.scrollView, this.props.scrollViewStyle]}
        >
          <View
            // timingFunction='linear'
            style={[
              styles.zoomableView,
              dynamicStyles.zoomableView,
              this.props.styles,
            ]}
            duration={6000}
            key='selectionIndicator'
            onLayout={this.props.onLayout || null}
          >
            {this.props.OverlayComponent ? this.props.OverlayComponent : null}
            {/* <AnimatedView
              style={[
                styles.zoomableViewScale,
                dynamicStyles.zoomableViewScale,
              ]}
              timingFunction='linear'
              onEnd={this.handleZoomEnd}
              // onStart={this.handleZoomStart}
              to={{
                transform: [
                  {
                    scale: this.props.level,
                    translateX: translate.x,
                    translateY: translate.y,
                  },
                ],
              }}
              from={{
                transform: [
                  {
                    scale: this._prevLevel,
                    translateX: 0,
                    translateY: 0,

                    // translateX:
                    //   this.props.width /
                    //   (this._prevLevel /
                    //     ((this.props.width - this.props.width * this._prevLevel) /
                    //       2 /
                    //       this.props.width)),
                    // translateY:
                    //   this.props.height /
                    //   (this._prevLevel /
                    //     ((this.props.height -
                    //       this.props.height * this._prevLevel) /
                    //       2 /
                    //       this.props.height)),
                  },
                ],
              }}
              // duration={this.props.duration || 150}
              duration={3000}
              key='zoomScale'
            >
              {this.props.children}
            </AnimatedView> */}
            <View
              style={[
                styles.zoomableViewScale,
                dynamicStyles.zoomableViewScale,
              ]}
            >
              {this.props.children}
            </View>
          </View>
        </ScrollView>
        {/* <View pointerEvents='none' style={styles.targetContainer}>
          <View style={styles.target} />
        </View> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  zoomableView: {
    position: "relative",
    flex: 0,
  },
  zoomableViewScale: {
    position: "relative",
    transformOrigin: "0% 0%",
    flex: 0,
  },
  scrollViewContent: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  scrollView: {
    ...StyleSheet.absoluteFill,
  },
  zoomableViewWrapper: {
    ...StyleSheet.absoluteFill,
  },
  targetContainer: {
    ...StyleSheet.absoluteFill,
    zIndex: 10,
    // flex: 1,
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "space-between"
    // backgroundColor: "#000000",
  },
  target: {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: 8,
    width: 8,
    marginLeft: -4,
    marginTop: -4,
    borderColor: "#FF0000",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 100,
    zIndex: 10,
  },
  // scrollView: {
  //   padding: 32
  // }
});
