import R14 from "../R14";
import React from "react";
import View from "./View";
import StyleSheet from "./StyleSheet";
import Text from "./Text";
/**
 * Component for grouping form fields
 */
export default R14.connect(
  class FieldGroup extends React.Component {
    static propTypes = {};
    static defaultProps = {};
    render() {
      /** @todo Not great using index */
      let children = this.props.children;
      if (!Array.isArray(children)) children = [children];
      let i = 1;
      return (
        <React.Fragment>
          {this.props.label && 
            <View key='fieldGroupLabel' style={[styles.fieldGroupLabel,this.props.labelStyle]}>
              <Text style={[styles.fieldGroupLabelText,this.props.labelTextStyle]}>{this.props.label}</Text>
            </View>
          }
          <View key='fieldGroup' style={[styles.fieldGroup, this.props.style]}>
            {children.map(child => (
              <View
                key={`formField${i++}`}
                style={[styles.fieldGroupField, this.props.fieldStyle]}
              >
                {child}
              </View>
            ))}
          </View>
        </React.Fragment>
      );
    }
  }
);

const styles = StyleSheet.create({
  fieldGroupLabel: {
    paddingBottom: 8
  },
  fieldGroupLabelText: {
    fontSize: 14
  },
  fieldGroup: {
    flex: 1,
    flexDirection: "row",
    marginRight: -15,
    screen: ({ width }) => {
      if (width <= 640) {
        return {
          flexDirection: "column",
          marginRight: 0
        };
      }
    }
  },
  fieldGroupField: {
    marginRight: 15,
    flex: 1,
    flexBasis: 0,
    // flexBasis: 'auto',
    screen: ({ width }) => {
      if (width <= 640) {
        return {
          marginRight: 0
        };
      }
    }
  }
});
