export default class R14ApiResult {
  constructor(res) {
    this._metadata = {
      data: res.data || {},
      errors: res.errors || [],
    };
    return new Proxy(this, {
      get: (obj, prop) => {
        if (obj[prop]) return obj[prop];
        if (prop === "then") return undefined;
        else {
          console.warn(
            `R14Api Warning: Accessing data '${prop}' directly from the result is being depreciated. Please use the data / error properties.`
          );
          return obj.data[prop];
        }
      },
      set: (obj, prop, val) => {
        console.warn("R14Api Warning: Cannot set api result values.");
      },
    });
  }
  toString() {
    return this._metadata.toString();
  }
  get data() {
    return this._metadata.data;
  }
  get errors() {
    return this._metadata.errors;
  }
}