import React from "react";
import Navigation from "../Navigation";
export default class NavigationContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <Navigation.ContainerPortal {...this.props} />;
  }
}
