import React from "react";
import R14 from "../../R14";
import PropTypes from "prop-types";
import Link from "../Link";
import StyleSheet from "../StyleSheet";
import Ripple from "../Ripple";
import ResizeObserver from "resize-observer-polyfill";

export default class TouchableBase extends React.Component {
  static propTypes = {
    /** The route the the touchable goes to on press. Can either be a route name, or a route object containing route and params. If set, press events are disabled. */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The function to be called when the touchable is pressed. */
    onPress: PropTypes.func,
    /** The function to be called when the touchable press has started. */
    onPressIn: PropTypes.func,
    /** The function to be called when the touchable press has ended. */
    onPressOut: PropTypes.func,
    /** The function to be called when the touchable press has moved. */
    onPressMove: PropTypes.func,
    /** The function to be called when the touchable press has canceled or left the touchable. */
    onPressCancel: PropTypes.func,
    /** Controls whether touchable shows touch feedback */
    feedback: PropTypes.bool,
  };
  static defaultProps = {
    feedback: true,
  };
  constructor(props) {
    super(props);
    this.POINTER_TYPE_MOUSE = "MOUSE";
    this.POINTER_TYPE_TOUCH = "TOUCH";
    this.handlePress = this.handlePress.bind(this);
    this.handlePressIn = this.handlePressIn.bind(this);
    this.handlePressOut = this.handlePressOut.bind(this);
    this.handlePressMove = this.handlePressMove.bind(this);
    this.handlePressCancel = this.handlePressCancel.bind(this);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.htmlRef = this.props.elementRef || React.createRef();
    if (this.props.onLayout || this.props.styleOnLayout) {
      /** @todo SHOULD MERGE WITH VIEW! */
      this.resizeObserver = new ResizeObserver((entries) =>
        window.requestAnimationFrame(() => this.triggerOnLayout())
      );
    }
    this._pointerType = null;
    this._press = false;
    this.state = { press: false };
    this._id = Math.random();
  }
  componentDidMount() {
    //console.log('COMPNENTN DID MOUNT!');
    if (this.props.onLayout) {
      setTimeout(() => {
        this.triggerOnLayout();
      }, 5);
      this.resizeObserver && this.resizeObserver.observe(this.htmlRef.current);
    }
    if (this.props.autoFocus) this.autoFocus();
    // if (this.props.onPressIn && this.htmlRef.current) {
    //   this.htmlRef.current.addEventListener("touchstart", this.preventDefault, {
    //     passive: true,
    //   });
    // }
  }
  autoFocus() {
    setTimeout(() => {
      this.htmlRef.current && this.htmlRef.current.focus();
    }, 350);
  }
  focus() {
    this.htmlRef.current && this.htmlRef.current.focus();
  }
  blur() {
    this.htmlRef.current && this.htmlRef.current.blur();
  }
  componentWillUnmount() {
    if (this.props.onLayout)
      this.resizeObserver && this.resizeObserver.disconnect();
    // if (this.props.onPressIn && this.htmlRef.current) {
    //   this.htmlRef.current.removeEventListener(
    //     "touchstart",
    //     this.preventDefault
    //   );
    // }
  }
  triggerOnLayout() {
    /** @todo SHOULD MERGE WITH VIEW! */
    let event = { nativeEvent: { layout: {} } };
    if (
      (!this.props.onLayout && !this.props.styleOnLayout) ||
      !this.htmlRef.current
    )
      return false;
    event = {
      nativeEvent: {
        layout: {
          x: this.htmlRef.current.offsetLeft,
          y: this.htmlRef.current.offsetTop,
          width: this.htmlRef.current.offsetWidth,
          height: this.htmlRef.current.offsetHeight,
        },
      },
    };
    if (this.props.onLayout) this.props.onLayout(event);
    // if (this.props.styleOnLayout) {
    //   let styleOnLayout = this.props.styleOnLayout(event) || null;
    //   if (styleOnLayout !== this.state.styleOnLayout) {
    //     this.setState({ styleOnLayout: styleOnLayout });
    //   }
    // }
  }
  /** @todo SHOULD MERGE WITH VIEW! */
  measureInWindow(callback) {
    let offset = R14.utils.dom.offset(this.htmlRef.current);
    let dimensions = R14.utils.dom.dimensions(this.htmlRef.current);
    callback(offset.left, offset.top, dimensions.width, dimensions.height);
  }
  measure(callback) {
    let offset = R14.utils.dom.offset(this.htmlRef.current);
    let dimensions = R14.utils.dom.dimensions(this.htmlRef.current);
    callback(
      this.htmlRef.current.offsetLeft,
      this.htmlRef.current.offsetTop,
      dimensions.width,
      dimensions.height,
      offset.left,
      offset.top
    );
  }
  normalizeEvent(e) {
    let event = {
      nativeEvent: {
        locationX: null,
        locationY: null,
        pageX: null,
        pageY: null,
        movementX: null,
        movementY: null,
        altKey: false,
        ctrlKey: false,
      },
    };
    if (!e.nativeEvent) return event;
    if (window.TouchEvent && e.nativeEvent instanceof window.TouchEvent) {
      let touch = null;
      if (e.nativeEvent.touches && e.nativeEvent.touches.length)
        touch = e.nativeEvent.touches[0];
      else if (
        // For touch end, get the last touch
        e.nativeEvent.changedTouches &&
        e.nativeEvent.changedTouches.length
      ) {
        touch = e.nativeEvent.changedTouches[0];
      }
      if (!touch) return event;
      event.nativeEvent.pageX = touch.pageX;
      event.nativeEvent.pageY = touch.pageY;
      if (this.htmlRef.current) {
        let pPos = this.htmlRef.current.getBoundingClientRect();
        if (pPos) {
          event.nativeEvent.locationX = touch.pageX - pPos.x;
          event.nativeEvent.locationY = touch.pageY - pPos.y;
        }
      }
      if (this._lastEvent) {
        event.nativeEvent.movementX =
          touch.pageX - this._lastEvent.nativeEvent.pageX;
        event.nativeEvent.movementY =
          touch.pageY - this._lastEvent.nativeEvent.pageY;
      }
    } else if (
      window.MouseEvent &&
      e.nativeEvent instanceof window.MouseEvent
    ) {
      if (this.htmlRef.current) {
        // Use getBoundingClientRect because sometimes offsetX/Y is 0
        let pPos = this.htmlRef.current.getBoundingClientRect();
        if (pPos) {
          event.nativeEvent.locationX = e.nativeEvent.pageX - pPos.x;
          event.nativeEvent.locationY = e.nativeEvent.pageY - pPos.y;
        }
      } else {
        event.nativeEvent.locationX = e.nativeEvent.offsetX;
        event.nativeEvent.locationY = e.nativeEvent.offsetY;
      }
      event.nativeEvent.pageX = e.nativeEvent.pageX;
      event.nativeEvent.pageY = e.nativeEvent.pageY;
      if ("movementX" in e.nativeEvent) {
        event.nativeEvent.movementX = e.nativeEvent.movementX;
        event.nativeEvent.movementY = e.nativeEvent.movementY;
      } else if (this._lastEvent) {
        event.nativeEvent.movementX =
          e.nativeEvent.pageX - this._lastEvent.nativeEvent.pageX;
        event.nativeEvent.movementY =
          e.nativeEvent.pageY - this._lastEvent.nativeEvent.pageY;
      }
    }
    if (e.ctrlKey) event.nativeEvent.ctrlKey = e.ctrlKey;
    if (e.altKey) event.nativeEvent.altKey = e.altKey;
    event.preventDefault = () => {
      e.preventDefault();
    };
    event.stopPropagation = () => {
      e.stopPropagation();
    };
    this._lastEvent = event;
    return event;
  }
  handlePress(e) {
    //e.preventDefault();
    if (this.props.onPress) this.props.onPress(this.normalizeEvent(e));
  }
  handleMouseDown(e) {
    if (!this._pointerType) this._pointerType = this.POINTER_TYPE_MOUSE;
    else if (this._pointerType !== this.POINTER_TYPE_MOUSE) return;
    this.handlePressIn(e, true);
  }
  handlePressIn(e, mouseEvent = false) {
    if (!this._pointerType) this._pointerType = this.POINTER_TYPE_TOUCH;
    else if (this._pointerType === this.POINTER_TYPE_TOUCH && mouseEvent)
      return;
    //e.preventDefault();
    this.setState({ press: true });
    this._press = true;
    if (this.props.onPressIn) this.props.onPressIn(this.normalizeEvent(e));
    return false;
  }
  handleMouseUp(e) {
    if (this._pointerType === this.POINTER_TYPE_TOUCH) return;
    this.handlePressOut(e);
  }
  handlePressOut(e) {
    //e.preventDefault();
    this.setState({ press: false });
    this._press = false;
    if (this.props.onPressOut) this.props.onPressOut(this.normalizeEvent(e));
  }
  handleMouseMove(e) {
    if (this._pointerType === this.POINTER_TYPE_TOUCH) return;
    this.handlePressMove(e);
  }
  handlePressMove(e) {
    // console.log('PRESS OUT', this.state.press, this._press);
    //e.preventDefault();
    if (this.state.press && this.props.onPressMove)
      this.props.onPressMove(this.normalizeEvent(e));
  }
  handleMouseLeave(e) {
    if (this._pointerType === this.POINTER_TYPE_TOUCH) return;
    this.handlePressCancel(e);
  }
  handlePressCancel(e) {
    //e.preventDefault();
    if (this.state.press && this.props.onPressCancel)
      this.props.onPressCancel(this.normalizeEvent(e));
  }
  preventDefault(e) {
    e.preventDefault();
  }
  render() {
    if (this.props.to) return <Link {...this.props} ref={this.htmlRef} />;
    let props = {
      className: StyleSheet.className([styles.touchable, this.props.style]),
    };
    if (this.props.onPress) {
      props.onClick = this.handlePress;
    }
    if (
      this.props.onPressIn ||
      this.props.onPressMove ||
      this.props.onPressCancel
    ) {
      props.onMouseDown = this.handleMouseDown;
      props.onTouchStart = this.handlePressIn;
    }
    if (
      this.props.onPressOut ||
      this.props.onPressMove ||
      this.props.onPressCancel
    ) {
      props.onMouseUp = this.handleMouseUp;
      props.onTouchEnd = this.handlePressOut;
    }
    if (this.props.onPressMove) {
      props.onMouseMove = this.handleMouseMove;
      props.onTouchMove = this.handlePressMove;
    }
    if (this.props.onPressCancel) {
      props.onMouseLeave = this.handleMouseLeave;
      props.onTouchCancel = this.handlePressCancel;
    }
    if (this.props.onKeyDown) props.onKeyDown = this.props.onKeyDown;
    if (this.props.tooltip) props.title = this.props.tooltip;

    if (this.props.onFocus) props.onFocus = this.props.onFocus;
    if (this.props.onBlur) props.onBlur = this.props.onBlur;
    if ("tabIndex" in this.props) props.tabIndex = this.props.tabIndex;

    if (typeof this.props.children === "string") {
      console.error(
        `Touchable Component Error: String '${this.props.children}' passed to View. Please enclose strings in a Text component.`
      );
    }
    return (
      <div {...props} ref={this.htmlRef}>
        {this.props.children}
        {this.props.feedback && <Ripple />}
      </div>
    );
  }
}
const styles = StyleSheet.create({
  touchable: {
    cursor: "pointer",
    position: "relative",
    outline: 0,
    "-webkit-tap-highlight-color": "transparent",
    "-webkit-touch-callout:": "none",
    touchAction: "none",
    userSelect: "none",
  },
});
