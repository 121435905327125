import R14, { StyleSheet, Colors } from "../core";
import defaultUiSettings from "../config/ui.config";
export default class ConfigsUiDomain extends R14.Domain {
  constructor() {
    super();
    this.SIDE_SHEET_POSITION_LEFT = "LEFT";
    this.SIDE_SHEET_POSITION_RIGHT = "RIGHT";
    this.load();
    this.state = this._merge();
  }
  _merge(uiConfig = null) {
    // merge the styles
    const defaultUiStyleMap = defaultUiSettings.styles.reduce((acc, style) => {
      acc[style.name] = style;
      return acc;
    }, {});
    const uiStyleMap =
      uiConfig && uiConfig.styles
        ? uiConfig.styles.reduce((acc, style) => {
            acc[style.name] = style;
            return acc;
          }, {})
        : {};
    const styleArr = Object.values({ ...defaultUiStyleMap, ...uiStyleMap });
    const styles = this._createStyles(styleArr);
    return { ...defaultUiSettings, ...uiConfig, styles };
  }
  _cleanConfig(config) {
    if (!config) return {};
    let cleanConfig = { ...config, styles: [] };
    const styles = config.styles || [];
    for (let style of styles) {
      let cleanStyle = {};
      Object.keys(style).forEach((key) => {
        if (style[key] !== null && style[key] !== undefined) {
          if (typeof style[key] === "object") {
            cleanStyle[key] = {};
            Object.keys(style[key]).forEach((subKey) => {
              if (
                style[key][subKey] !== null &&
                style[key][subKey] !== undefined
              )
                cleanStyle[key][subKey] = style[key][subKey];
            });
          } else cleanStyle[key] = style[key];
        }
      });
      cleanConfig.styles.push(cleanStyle);
    }
    return cleanConfig;
  }
  load(uiConfig = null) {
    this.setState(this._merge(this._cleanConfig(uiConfig)));
  }
  get(key) {
    return this.state[key];
  }
  get styles() {
    return this.state.styles;
  }
  get sideSheetPosition() {
    return this.state.sideSheetPosition;
  }
  get showFieldValues() {
    return this.state.showFieldValues;
  }
  get disableHistory() {
    return this.state.disableHistory;
  }
  _createStyles(styleArr) {
    const parseColor = (color) => {
      if (!color || (!color.hex && !color.themeColor)) return null;
      const colorValue = color.themeColor
        ? Colors[color.themeColor]
        : color.hex;
      return "alpha" in color &&
        color.alpha !== null &&
        color.alpha !== undefined &&
        color.alpha >= 0 &&
        color.alpha <= 1
        ? StyleSheet.color(colorValue).rgba(color.alpha)
        : colorValue;
    };
    const styles = styleArr.reduce((acc, styleSetting) => {
      let style = {};
      Object.keys(styleSetting).forEach((key) => {
        switch (key) {
          case "backgroundColor":
          case "color":
          case "borderColor":
            style[key] = parseColor(styleSetting[key]);
            break;
          case "name":
            // Do nothing
            break;
          default:
            style[key] = styleSetting[key];
            break;
        }
      });
      acc[styleSetting.name] = style;
      return acc;
    }, {});
    return StyleSheet.create(styles);
  }
  getStylesByAnnotation(prefix, annotation, active = false) {
    let stateStr = "";
    let manualEntryDomain = this.dm.manualEntry;
    switch (annotation.metadata.state) {
      case manualEntryDomain.STATE_REVIEW:
        stateStr = "Review";
        break;
      case manualEntryDomain.STATE_COMPLETE:
        stateStr = "Complete";
        break;
      case manualEntryDomain.STATE_QUEUED:
        stateStr = "Queued";
        break;
      default:
      // Do nothing
    }
    let statusStr = null;

    if (annotation.metadata.reject) statusStr = "Reject";
    else if (annotation.metadata.changed) statusStr = "Changed";
    else if (annotation.metadata.state !== manualEntryDomain.STATE_QUEUED)
      statusStr = "Accept";
    const statusArr = statusStr ? ["", statusStr] : [""];

    const configStyles = [];
    const activeArr = active ? ["", "Active"] : [""];
    activeArr.forEach((activeStr) =>
      ["", stateStr].forEach((state) =>
        statusArr.forEach((status) => {
          let styleName = `${prefix}${status}${state}${activeStr}`;
          if (this.styles[styleName]) configStyles.push(this.styles[styleName]);
        })
      )
    );
    return configStyles;
  }
}
