import React from "react";
import PropTypes from "prop-types";
import R14, {
  StyleSheet,
  View,
  ScrollView,
  TextInput,
  Image,
  Colors,
  Touchable
} from "../core";
import CharacterImage from "./CharacterImage";
import FieldImage from "./FieldImageNew";

export default R14.connect(
  class FieldCharacterTextInput extends React.Component {
    static propTypes = {
      // /** Name for the character entry fields. */
      // name: PropTypes.string.isRequired,
      // /** Value of the character entry fields. */
      // value: PropTypes.any,
    };
    constructor(props) {
      super(props);
      this.characterEntryField = this.props.app.ui.charactersEntryField.getInstance(
        this.props.annotationSetUid
      );
      this.character = this.characterEntryField.characters.getInstance(
        this.props.characterUuid
      );
    }
    createDynamicStyles() {
      let dynamicStyles = {};
      let {
        rowHeight,
        rowPadding,
        scale,
        imageHeight,
        imageWidth,
        width,
        left,
      } = this.props;
      let fontSize = this.character.fontSize;
      let fontRatio = this.character.fontRatio;
      dynamicStyles.characterTextInput = {
        height: rowHeight * imageHeight * scale + 2 * rowPadding,
        paddingTop: rowPadding,
        width: width * imageWidth * scale,
        // left: height * imageWidth * scale,
        // top: 0,
        fontSize: fontSize * imageHeight * scale * fontRatio,
        lineHeight: rowHeight * imageHeight * scale,
      };

      // let fieldCharCompareBackgroundColor = null;
      let charStateBackgroundColor = null;
      if (this.character.reject) {
        charStateBackgroundColor = StyleSheet.color(Colors.secondary).rgba(0.6);
      } else if (this.character.focus) {
        charStateBackgroundColor = StyleSheet.color(Colors.primary).rgba(0.15);
      } else if (this.character.changed) {
        charStateBackgroundColor = StyleSheet.color("#4caf50").rgba(0.2);
      } else if (this.character.lowScore) {
        charStateBackgroundColor = StyleSheet.color(Colors.secondary).rgba(
          0.25
        );
      }
      dynamicStyles.fieldCharacterCompare = {
        height: 2 * rowHeight * imageHeight * scale + 2 * (rowPadding * 2),
        //paddingTop: rowPadding,
        width: width * imageWidth * scale,
        left: left * imageWidth * scale,
        top: 0,
        // backgroundColor: fieldCharCompareBackgroundColor,
      };
      dynamicStyles.characterState = {
        height: rowHeight * imageHeight * scale + 2 * rowPadding,
        paddingTop: rowPadding,
        backgroundColor: charStateBackgroundColor,
      };
      dynamicStyles.characterInputOverlay = {
        height: rowHeight * imageHeight * scale + 2 * rowPadding,
        paddingTop: rowPadding,
        //backgroundColor: charStateBackgroundColor,
      };

      // console.log("CHECK VALUES", {
      //   rowHeight,
      //   rowPadding,
      //   scale,
      //   imageHeight,
      //   imageWidth,
      //   fontSize,
      //   fontRatio,
      //   width,
      //   left,
      // });
      return StyleSheet.create({
        ...dynamicStyles,
      });
    }
    render() {
      if (this.props.focus)
        console.log(
          "RENDER TEXT INPUT",
          this.props.characterUid,
          this.props.focus
        );

      let dynamicStyles = this.createDynamicStyles();
      return (
        <View
          style={[
            styles.fieldCharacterCompare,
            dynamicStyles.fieldCharacterCompare,
          ]}
        >
          <Touchable
            style={[styles.characterState, dynamicStyles.characterState]}
            onPress={this.character.onFocus}
          />
          <Touchable
            style={[styles.characterInputOverlay, dynamicStyles.characterInputOverlay]}
            onPress={this.character.onFocus}
          />
          <TextInput
            key={`char${this.character.uid}`}
            name={`char${this.character.uid}`}
            ref={this.character.ref}
            value={this.character.value}
            style={[
              styles.characterTextInput,
              dynamicStyles.characterTextInput,
            ]}
            onChangeText={(value) =>
              this.props.onChangeText(this.character, value)
            }
            autoSelect={this.character.focus}
            autoFocus={this.character.focus}
            onFocus={this.character.onFocus}
            onBlur={this.character.onBlur}
            // onPress={this.character.onFocus}
            autoComplete={false}
          />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  fieldCharacterCompare: {
    flex: 0,
    flexDirection: "column",
    position: "absolute",
    borderRightWidth: 1,
    borderStyle: "dashed",
    borderColor: StyleSheet.color("#999999").rgba(0.9),
    overflow: "hidden",
  },
  characterTextInput: {
    fontFamily: "'Courier Prime', 'Courier New', monospace",
    fontWeight: 400,
    backgroundColor: Colors.surface,
    //backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.3),
    overflow: "visible",
    textAlign: "center",
    ...StyleSheet.padding(0),
  },
  characterInputOverlay: {
    position:"absolute",
    left: 0,
    right: 0,
    bottom: 0
  }
  // characterCompareOverlay: {
  //   //backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.2),
  //   position: "absolute",
  //   borderRightWidth: 1,
  //   borderStyle: "dashed",
  //   borderColor: StyleSheet.color("#999999").rgba(0.9),
  //   // borderRadius: 4,
  // },
});
