import React from 'react';
import StyleSheet from './StyleSheet';
import { Colors } from './Theme';
import Link from './Link';
import Icon from './Icon';
import Text from './Text';
export default class TabBarItem extends React.Component {
  constructor(props){
    super(props);
    this.handleActive = this.handleActive.bind(this);
    this.ref = React.createRef();
  }
  handleActive(link){
    console.log(this.ref);
    this.props.onActive && this.props.onActive(this);
  }
  measure(callback){
    this.ref.current.measure(callback);
  }
  render() {
    let styles = this.styles();
    let activeStyles = [styles.active];
    let position = this.props.position || "top";
    let tabNavItemStyles = [styles.tabNavigatorItem];
    switch (position) {
      case "top":
        activeStyles.push(styles.topActive);
        tabNavItemStyles.push(styles.top);
        break;
      case "bottom":
        activeStyles.push(styles.bottomActive);
        break;
      case "left":
        activeStyles.push(styles.leftActive);
        break;
      case "responsive-left-bottom":
        activeStyles.push(styles.responsiveLeftBottomActive);
        break;
    }
    return (
      <Link
        style={tabNavItemStyles}
        activeStyle={activeStyles}
        to={this.props.to}
        onActive={this.props.onActive && this.handleActive}
        ref={this.ref}
      >
        {this.props.icon &&
          <Icon
            color={Colors.onPrimaryDark}
            name={this.props.icon}
          />
        }
        {this.props.label &&
          <Text style={styles.label}>{this.props.label}</Text>
        }
      </Link>
    );
  }
  styles() {
    let backgroundColor = StyleSheet.color(Colors.primary).rgba(.4);
    let labelColor = Colors.onPrimaryDark;
    switch (this.props.position) {
      case "top":
        backgroundColor = Colors.surface;
        labelColor = Colors.onSurface;
        break;
    }
    return StyleSheet.create({
      tabNavigatorItem: {
        flex: 0,
        flexShrink: 0,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 16,
        height: 56,
        margin: 0,
        opacity: .7,
        backgroundColor: StyleSheet.color(Colors.primary).rgba(.4),
        screen: ({ width }) => {
          if (width <= 640) return {
            display: "flex",
            flexShrink: (this.props.scrollEnabled ? 0 : 1),
            flexGrow: (this.props.scrollEnabled ? 0 : 1),
          }
        }
      },
      top: {
        height: 56,
        backgroundColor: Colors.background,
        screen: ({ width }) => {
          if (width <= 480) return {
            minWidth: "40%",
            height: 48,
            backgroundColor: Colors.surface,
          }
          else if (width <= 640) return {
            minWidth: "28.5%",
            height: 48,
            backgroundColor: Colors.surface,
          }
        }
      },
      label: {
        color: labelColor,
        fontWeight: "500",
        fontSize: 14
      },
      active: {
        borderStyle: "solid",
        borderWidth: 0,
        borderColor: Colors.primaryLight,
        backgroundColor: backgroundColor,
        opacity: .94,
      },
      leftActive: {
        borderLeftWidth: 2,
        paddingLeft: 14
      },
      responsiveLeftBottomActive: {
        borderLeftWidth: 2,
        paddingLeft: 14,
        screen: ({ width }) => {
          if (width <= 640) return {
            borderLeftWidth: 0,
            borderTopWidth: 2,
            paddingLeft: 16,
            paddingTop: 14
          }
        }
      },
      bottomActive: {
        borderTopWidth: 2,
        paddingTop: 14
      },
      topActive: {
        borderBottomWidth: 2,
        paddingBottom: 14,
        //backgroundColor: Colors.background
      },
    });
  }
}