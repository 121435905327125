import React from "react";
import R14 from "../R14";
import FormValidators from "./FormValidators";

export default class KeyMapperUiDomain extends R14.DomainInstances {
  constructor() {
    super();
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this._keyDownKeys = {};
    this._initialized = false;
  }
  handleKeyUp(e) {
    let key = this.getKeyByEvent(e);
    if (!key) return;
    if (this._keyDownKeys[key]) delete this._keyDownKeys[key];
  }
  addKeyDownKey(key) {
    this._keyDownKeys[key] = true;
  }
  isKeyDown(key) {
    return this._keyDownKeys[key] || false;
  }
  initialize() {
    document.addEventListener("keyup", this.handleKeyUp);
    this._initialized = true;
  }
  create(name, options = {}) {
    if (!this._initialized) this.initialize();
    let keyMapper = new KeyMapperUiDomainInstance(name, options, this);
    this.addInstance(name, keyMapper);
    return keyMapper;
  }
  async instance(name, options = {}) {
    if (this.exists(name)) return this.getInstance(name);
    return this.create(name, options);
  }
  getKeyByEvent(e) {
    if (!e) return null;
    let key = e.key || (e.nativeEvent && e.nativeEvent.key) || null;
    if (!key) return null;
    if (key !== "Control" && e.ctrlKey) key = `Ctrl${key}`;
    if (key !== "Alt" && e.altKey) key = `Alt${key}`;
    // Check if shirt key, and is defined in keys
    if (key !== "Shift" && e.shiftKey) key = `Shift${key}`;
    return key;
  }
  disconnect(name) {
    this.remove(name);
  }
  remove(name) {
    if (!this.exists(name)) return;
    let keyMapper = this.getInstance(name);
    if (keyMapper.isConnected) keyMapper.disconnect();
    else this.removeInstance(name);
  }
}

export class KeyMapperUiDomainInstance extends R14.Domain {
  constructor(name, options, keyMapperUiDomain) {
    super();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this._KeyMapperUiDomain = keyMapperUiDomain;
    this._actions = {};
    this._options = options;
    this._keys = {};
    this._onkeyDown = [];
    this._name = name;
    this._isConnected = false;
    this._keyDownKeys = {};
    this.init();
  }
  init() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
    this._isConnected = true;
  }
  get isConnected() {
    return this._isConnected;
  }
  get name() {
    return this._name;
  }
  onKeyDown(callback) {
    this._onkeyDown.push(callback);
    return this;
  }
  addAction(name, keys, callback, options = {}) {
    this._actions[name] = { keys, callback, ...options };
    if (!Array.isArray(keys)) keys = [keys];
    for (let key of keys) {
      if (!this._keys[key]) this._keys[key] = [];
      if (!this._keys[key].includes(name)) this._keys[key].push(name);
    }
    return this;
  }
  isActionKey(key) {
    return this._keys[key] ? true : false;
  }
  handleKeyUp(e) {
    let key = this._KeyMapperUiDomain.getKeyByEvent(e);
    if (!key) return;
    if (this._keys[key]) {
      for (let actionName of this._keys[key]) {
        this._actions[actionName] &&
          this._actions[actionName].onKeyUp &&
          this._actions[actionName].onKeyUp({ key, e, actionName });
      }
    }
  }
  handleKeyDown(e) {
    let key = this._KeyMapperUiDomain.getKeyByEvent(e);
    if (!key) return;
    let triggerKeyDown = false;
    let triggerActions = [];
    // Test if repeats should be suppressed
    if (this._keys[key]) {
      for (let actionName of this._keys[key]) {
        if (this._actions[actionName]) {
          if (
            this._options.repeat === false ||
            this._actions[actionName].repeat === false
          ) {
            if (this._KeyMapperUiDomain.isKeyDown(key)) {
              e.preventDefault();
              e.stopPropagation();
              continue;
            } else this._KeyMapperUiDomain.addKeyDownKey(key);
          }
          triggerKeyDown = true;
          !triggerActions.includes(actionName) &&
            triggerActions.push(actionName);
        }
      }
    } else triggerKeyDown = true;
    if (triggerKeyDown && this._onkeyDown.length) {
      for (let callback of this._onkeyDown) {
        callback({ key, e });
      }
    }
    triggerActions.forEach((actionName) => {
      this._actions[actionName].callback({ key, e, actionName });
    });
  }
  disconnect() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
    this._isConnected = false;
    this.ui.keyMapper.remove(this.name);
  }
  getInfo() {
    let info = { name: this.name, actions: {} };
    for (let actionName in this._actions) {
      info.actions[actionName] = this.getActionInfo(actionName);
    }
    return info;
  }
  getActionInfo(actionName) {
    let action = this._actions[actionName];
    if (!action) return null;
    let info = { name: actionName, label: action.label || null, keys: [] };
    for (let key of action.keys) {
      info.keys.push({ key, label: this.getKeyLabel(key) });
    }
    return info;
  }
  getActionKeyLabels(actionName) {
    if (!this._actions[actionName]) return null;
    return this._actions[actionName].keys.map((key) => {
      return this.getKeyLabel(key);
    });
  }
  getKeyLabel(key) {
    let label = key;
    let prefixArr = [];
    let prefixLen = 0;
    ["Ctrl", "Shift", "Alt"].forEach((val) => {
      if (label.indexOf(val) !== -1 && label !== val) {
        prefixArr.push(`${val} + `);
        prefixLen += val.length;
      }
    });
    if (prefixLen) label = label.substring(prefixLen);
    label = this.utils.str.fromCamelCase(label);
    if (prefixArr.length) label = `${prefixArr.join("")}${label}`;
    return label;
  }
}
