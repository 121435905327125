import React from 'react';
import R14 from '../R14';
import StyleSheet from './StyleSheet';
import View from './View';
import IconButton from './IconButton';
import Text from './Text';
import { Colors } from './Theme';

export default R14.connect(
  class ModalHeader extends React.Component {
    constructor(props) {
      super(props);
      this.handleBackPress = this.handleBackPress.bind(this);
      this.props.navigator.setLayoutStyles(this.layoutStyles);
    }
    handleBackPress() {
      R14.instance.app.nav.back();
    }
    renderControlLeft() {
      return this.props.controlsLeft || (
        <IconButton
          icon="arrowBack"
          onPress={this.handleBackPress}
        />
      );
    }
    render() {

      return (
        <View
          {...this.props}
          htmlTag="header"
          style={styles.modalHeader}
        >
          <View
            style={styles.content}
          >
            {this.renderControlLeft()}
            {this.props.title &&
              <View style={styles.title}>
                <Text>{this.props.title}</Text>
              </View>
            }
            {this.props.controlsRight &&
              <View style={styles.controlsRight}>
                {this.props.controlsRight}
              </View>
            }
          </View>
        </View>
      );
    }
    get layoutStyles() {
      return layoutStyles;
    }
  });
const layoutStyles = StyleSheet.create({
  offset: {
    marginTop: 64,
    screen: ({ width }) => {
      if (width <= 640) return {
        marginTop: 56,
      }
    }
  }
});
const styles = StyleSheet.create({
  modalHeader: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    height: 64,
    // lineHeight: 64,
    elevation: 3,

    // zIndex: 5,
    screen: ({ width }) => {
      if (width <= 640) return {
        height: 56,
        // lineHeight: 56
      }
    }
  },
  content: {
    width: "auto",
    display: "flex",
    flexDirection: "row",
    // ...StyleSheet.padding(8, 4, 8, 4),
    margin: 0,
    alignItems: "center",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: Colors.surface,
    color: Colors.onSurface,
    height: 64,
    screen: ({ width }) => {
      if (width <= 640) return {
        height: 56
      }
    }
  },
  title: {
    flex: 0,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 20,
    fontWeight: "500"
  },
  controlsLeft: {
    flex: 0,
    flexDirection: "row",
    margin: 0,
    alignItems: "center",
    opacity: .94,
  },
  controlsRight: {
    flex: 0,
    flexDirection: "row",
    margin: 0,
    marginLeft: "auto",
    alignItems: "center",
    opacity: .94,
  },
});