import React from 'react';
import View from '../View';

/**
 * EventView is a component with custom advanced event handling
 */
export default class EventView extends React.PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.handlePressOutside = this.handlePressOutside.bind(this);
		this.eventListeners = {};
	  this.hasMounted = false;
	}
	handlePressOutside(e) {
		if (this.props.onPressOutside) {
			if (!this.ref || !this.ref.current || !this.ref.current) return;
			if (this.props.onPressOutside && !this.ref.current.contains(e.target)) {
				// e.preventDefault();
				// e.stopPropagation();
				this.props.onPressOutside();
			}
		}
	}
	updateEventListeners() {
		this.removeEventListeners();
		if (this.props.onPressOutside && !this.eventListeners.pressOutside) {
			document.addEventListener('pointerdown', this.handlePressOutside);
			this.eventListeners.pressOutside = true;
		}
	}
	removeEventListeners() {
		if (this.eventListeners.pressOutside) {
			document.removeEventListener('pointerdown', this.handlePressOutside);
			delete this.eventListeners.pressOutside;
		}
	}
	componentDidMount() {
		this.hasMounted = true;
		this.updateEventListeners();
	}
	componentDidUpdate() {
		this.updateEventListeners();
	}
	componentWillUnmount() {
		this.removeEventListeners();
	}
	render() {
		return (
			<View
				{...this.props}
				htmlRef={this.ref}
			>
				{this.props.children}
			</View>
		);
	}
}