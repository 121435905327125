import React from "react";
import View from "./View";
import ScrollView from "./ScrollView";
import FloatingActionButton from "./FloatingActionButton";
import StyleSheet from "./StyleSheet";
import ScreenHeader from "./ScreenHeader";
import { Colors } from "./Theme";

export default class ScreenLayout extends React.PureComponent {
  render() {
    let contentComponent = (
      <View {...this.props} style={[styles.screenLayout, this.props.style]}>
        {(this.props.headerTitle ||
          this.props.HeaderTitleComponent ||
          this.props.HeaderRowTopComponent ||
          this.props.headerTitle ||
          this.props.headerControlsLeft ||
          this.props.headerControlsRight) && (
          <View style={styles.header}>
            <ScreenHeader
              style={this.props.headerStyle}
              contentStyle={this.props.headerContentStyle}
              title={this.props.headerTitle}
              TitleComponent={this.props.HeaderTitleComponent}
              controlsLeft={this.props.headerControlsLeft}
              controlsRight={this.props.headerControlsRight}
              RowTopComponent={this.props.HeaderRowTopComponent}
            />
          </View>
        )}
        <View style={styles.content}>{this.props.children}</View>
      </View>
    );

    if (this.props.scrollEnabled) {
      contentComponent = (
        <ScrollView
          onLayout={this.props.onScrollViewLayout || null}
          overflow={this.props.scrollOverflow || null}
          style={this.props.scrollViewStyle}
        >
          {contentComponent}
        </ScrollView>
      );
    }

    let component = null;

    let floatingActionButton = this.props.floatingActionButton ? (
      <FloatingActionButton {...this.props.floatingActionButton} />
    ) : null;
    let sideSheetComponent = this.props.SideSheetComponent
      ? this.props.SideSheetComponent
      : null;
    const sideSheetPosition =
      this.props.sideSheetPosition &&
      this.props.sideSheetPosition.toLowerCase() === "left"
        ? "left"
        : "right";
    let bottomSheetComponent = this.props.BottomSheetComponent
      ? this.props.BottomSheetComponent
      : null;

    // console.log("sideSheetComponent", sideSheetComponent);
    if (
      (bottomSheetComponent || sideSheetComponent) &&
      this.props.scrollEnabled
    )
      contentComponent = (
        <View style={styles.contentScrollViewLayout}>{contentComponent}</View>
      );

    if (floatingActionButton)
      contentComponent = (
        <React.Fragment>
          {floatingActionButton}
          {contentComponent}
        </React.Fragment>
      );

    if (bottomSheetComponent)
      contentComponent = (
        <View style={styles.bottomSheetLayout}>
          {contentComponent}
          {bottomSheetComponent}
        </View>
      );
    if (sideSheetComponent)
      component = (
        <View style={styles.sideSheetLayout}>
          {sideSheetPosition === "left" ? sideSheetComponent : null}
          {contentComponent}
          {sideSheetPosition === "right" ? sideSheetComponent : null}
        </View>
      );

    if (!component) component = contentComponent;

    // if (this.props.floatingActionButton) {
    //   component = (
    //     <React.Fragment>
    //       <FloatingActionButton {...this.props.floatingActionButton} />
    //       {component}
    //     </React.Fragment>
    //   );
    // }
    // if (this.props.sideSheetComponent) {
    //   component = (
    //     <React.Fragment>
    //       {component}
    //       {this.props.sideSheetComponent}
    //     </React.Fragment>
    //   );
    // }
    return component;
  }
}
const styles = StyleSheet.create({
  header: {
    flex: 0,
    backgroundColor: Colors.background,
    color: Colors.onBackground,
  },
  content: {
    flex: 1,
    position: "relative",
  },
  screenLayout: {
    flex: 1,
  },
  sideSheetLayout: {
    flex: 1,
    flexDirection: "row",
  },
  bottomSheetLayout: {
    flex: 1,
    flexDirection: "column",
  },
  contentScrollViewLayout: {
    flex: 1,
    position: "relative",
  },
});
