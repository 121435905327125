import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  FadeView,
  SubmitButton,
  TextInputField,
  View,
  Button,
  Colors,
  StyleSheet,
  Card,
  IconButton,
  Text,
  RadioButtonGroupField,
  SectionHeader,
} from "../core";

import Document from "./Document";
import DocumentAnnotation from "./DocumentAnnotation";
import AnnotationQuestionFieldGroup from "./AnnotationQuestionFieldGroup";
import DocumentAnnotationOffsetField from "./DocumentAnnotationOffsetField";
export default R14.connect(
  class DocumentAnnotationEditForm extends React.Component {
    constructor(props) {
      super(props);
      this.submitButtonRef = React.createRef();
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRejectPress = this.handleRejectPress.bind(this);
      this.handleEditPress = this.handleEditPress.bind(this);
      this.handlePreviewAnnotationOffsetChange =
        this.handlePreviewAnnotationOffsetChange.bind(this);
      this.handleAnnotationPreviewLayout =
        this.handleAnnotationPreviewLayout.bind(this);
      this.handleValueSelectionValueChange =
        this.handleValueSelectionValueChange.bind(this);
      this.state = {
        annotationPreviewScale: 1,
        annotationPreviewInitialScroll: null,
        valueVisible: this.initialValueVisible,
        editable: !this.props.shouldUseEditButton,
      };
      this.documentRef = this.props.documentRef || React.createRef();
      this._previewDocumentLayout = null;
      this.valueElmtRef = React.createRef();
      this.valueSelectionElmtRef = React.createRef();
      this.annotationQuestionFieldGroupRef = React.createRef();
      this.uiConfig = this.props.app.ui.config;
    }

    // componentDidUpdate(prevProps) {
    //   console.log("CHEDCK PROPS", this.props, prevProps);
    //   // // Auto scroll to preview annotation when annotation selection mode is disabled
    //   // if (
    //   //   prevProps.annotationSelectionModeEnabled &&
    //   //   !this.props.annotationSelectionModeEnabled
    //   // )
    //   //   this.scrollToPreviewAnnotation();

    //   // // Auto scroll to preview annotation when annotation selection mode is enabled
    //   // console.log();
    // }

    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get document() {
      return this.props.formData.document;
    }

    get annotation() {
      return this.props.formData.annotation;
    }
    get previewAnnotation() {
      let ret = null;
      if (this.props.enableDocumentFieldOffsetSelect) {
        ret = this.annotation;
      } else if (!this.props.disableDocumentPreview) {
        ret = this.annotation.offset
          ? this.annotation
          : this.annotation.parentAnnotation &&
            this.annotation.parentAnnotation.offset
          ? this.annotation.parentAnnotation
          : null;
      }
      return ret;
    }
    get showDocumentPreview() {
      return (
        (!this.props.disableDocumentPreview && this.previewAnnotation) ||
        this.props.enableDocumentFieldOffsetSelect
      );
    }

    get initialValueVisible() {
      return (
        !this.formData.valueSelections ||
        (this.formData.valueSelectionEditable &&
          this.formData.values.valueSelection ===
            "__r14_valueSelectionEditable")
      );
    }
    get initialOffsetSelectionModeEnabled() {
      return this.formData.offsetRequired &&
        this.props.enableDocumentFieldOffsetSelect &&
        !this.formData.values.offset
        ? true
        : false;
    }

    get documentAnnotationForm() {
      return this.props.app.ui.form("documentAnnotationForm");
    }
    handlePreviewAnnotationOffsetChange(offset, elmt, form) {
      this.scrollToPreviewAnnotation();
      this.props.onOffsetChange && this.props.onOffsetChange(offset);

      // Focus to the next field if offset is selected
      if (offset) {
        if (this.annotationQuestionFieldGroupRef.current)
          this.annotationQuestionFieldGroupRef.current.focus();
        if (this.valueSelectionElmtRef.current)
          this.valueSelectionElmtRef.current.focus();
        else if (this.valueElmtRef.current) {
          this.valueElmtRef.current.focus();
          this.valueElmtRef.current.select();
        }
      }
    }
    async handleSubmit(form, options = {}) {
      if (!this.state.editable) return false;
      if (!this.props.onSubmit) throw new Error("onSubmit prop required.");
      return await this.props.onSubmit(form, options);
      // let editMethod = this.isEditMode ? "update" : "create";
      // let ret = null;
      // this.props.app.ui.progressIndicator.show();
      // try {
      //   let isSuccess = await this.props.app.dm.client[editMethod](form.values);
      //   this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      //   if (isSuccess) {
      //     let dataTable = this.props.refreshDataTable || "clients";
      //     this.props.app.ui.dataTable.exists(dataTable) &&
      //       (await this.props.app.ui.dataTable(dataTable).refresh());
      //     ret = <Redirect to={this.props.redirect || "clients"} />;
      //   }
      // } catch (err) {
      //   form.addError(err.message);
      // }
      // this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      // return ret;
    }
    async handleRejectPress() {
      if (this.props.disableReject) return false;
      if (!this.state.editable) return false;
      if (!this.props.onReject) throw new Error("onReject prop required.");
      return await this.props.onReject(this.documentAnnotationForm);
      // this.documentAnnotationForm.submit({ reject: true });
    }
    async handleEditPress() {
      if (this.formData.questionFields) {
        this.documentAnnotationForm &&
          this.documentAnnotationForm.elmts.forEach(
            (elmt) => elmt.disabled && elmt.setDisabled(false)
          );
      } else {
        this.documentAnnotationForm.elements.value.setDisabled(false);
        this.documentAnnotationForm.elements.value.focus();
        this.documentAnnotationForm.elements.value.select();
      }

      this.setState({ editable: true });
    }
    handleValueSelectionValueChange(value, elmt) {
      // elmt.blurItems();
      // elmt.focus();
      let valueVisible = this.state.valueVisible;
      valueVisible = value === "__r14_valueSelectionEditable";
      this.state.valueVisible !== valueVisible &&
        this.setState({ valueVisible });
    }
    getAnnotationStyle(annotation, active = false) {
      let annotationStyles = [styles.annotation];
      switch (annotation.type) {
        case this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD:
          const annotationConfigStyles = this.uiConfig.getStylesByAnnotation(
            "annotationPreview",
            annotation,
            active
          );
          if (annotationConfigStyles.length)
            annotationStyles = [...annotationStyles, ...annotationConfigStyles];
          break;
        case this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD_GROUP:
          annotationStyles.push(styles.annotationFieldGroup);
          break;
      }
      return annotationStyles;
    }
    handleAnnotationPreviewLayout(event) {
      if (!event.nativeEvent.layout) return null;
      this._previewDocumentLayout = event.nativeEvent.layout;
      this.scrollToPreviewAnnotation();
    }
    scrollToPreviewAnnotation() {
      const annotation = this.previewAnnotation;
      if (!annotation) return false;
      if (!this._previewDocumentLayout) return false;
      let offset =
        this.documentAnnotationForm.values.offset || annotation.offset;
      if (!offset) return false;
      const previewDocumentWidth = this._previewDocumentLayout.width;
      const previewDocumentHeight = this._previewDocumentLayout.height;
      const imageWidth = this.document.image.width;
      const imageHeight = this.document.image.height;
      if (
        !previewDocumentWidth ||
        !previewDocumentHeight ||
        !imageWidth ||
        !imageHeight
      )
        return false;

      // Add 16 pixels padding

      const annotationWidth = offset.width * imageWidth;
      const annotationHeight = offset.height * imageHeight;

      // let annotationPreviewScale =
      //   annotationWidth >= annotationHeight
      //     ? (previewDocumentWidth - 32) / annotationWidth
      //     : (previewDocumentHeight - 32) / annotationHeight;

      let annotationPreviewScale =
        (previewDocumentWidth - 32) / annotationWidth;
      // Make sure height fits, otherwise scale to height
      if (annotationHeight * annotationPreviewScale > previewDocumentHeight) {
        annotationPreviewScale =
          (previewDocumentHeight - 32) / annotationHeight;
      }

      // Figure out x / y so that the annotation is centered
      const marginX =
        (previewDocumentWidth - annotationWidth * annotationPreviewScale) / 2;
      const marginY =
        (previewDocumentHeight - annotationHeight * annotationPreviewScale) / 2;
      const annotationPreviewInitialScroll = {
        x: offset.left * annotationPreviewScale * imageWidth + 32 - marginX,
        y: offset.top * annotationPreviewScale * imageHeight + 32 - marginY,
        animated: false,
      };
      this.documentRef &&
        this.documentRef.current &&
        this.documentRef.current.scrollTo(annotationPreviewInitialScroll);
      this.setState({ annotationPreviewScale, annotationPreviewInitialScroll });
    }

    renderTitleControlsRight() {
      let titleControlsRight = this.props.headerControlsRight || [];
      return titleControlsRight;
      // return [
      //   <IconButton
      //     icon='magnifyPlus'
      //     key='zoomIn'
      //     size='small'
      //     style={[
      //       styles.zoomButton,
      //       this.props.zoomLevel > 1 && styles.zoomButtonActive,
      //     ]}
      //     onPress={this.handleZoomIn}
      //   />,
      //   <IconButton
      //     icon='magnifyMinus'
      //     key='zoomOut'
      //     size='small'
      //     style={[
      //       styles.zoomButton,
      //       this.props.zoomLevel < 1 && styles.zoomButtonActive,
      //     ]}
      //     onPress={this.handleZoomOut}
      //   />,
      // ];
    }
    renderButtons() {
      let ret = null;
      if (this.state.editable) {
        ret = [];
        if (!this.props.disableReject)
          ret.push(
            <Button
              title='Reject'
              key='reject'
              style={styles.rejectButton}
              name='action'
              disabled={this.props.submitting}
              // icon='add'
              value={this.props.app.dm.manualEntry.ACTION_REJECT}
              onPress={this.handleRejectPress}
              // tooltip={`Reject [${
              //   this.keyMapper.getActionKeyLabels("reject")[0]
              // }]`}
              color={Colors.secondary}
              // style={styles.buttonRowButton}
            />
          );
        ret.push(
          <SubmitButton
            title='Accept'
            key='submit'
            disabled={this.props.submitting || this.props.disableSubmit}
            ref={this.submitButtonRef}
          />
        );
      } else {
        ret = (
          <Button
            title='Edit'
            key='edit'
            style={styles.editButton}
            name='edit'
            disabled={this.props.submitting}
            color={Colors.secondary}
            onPress={this.handleEditPress}
          />
        );
      }
      return ret;
      // return this.state.editable ? (
      //   [
      //     <Button
      //       title='Reject'
      //       key='reject'
      //       style={styles.rejectButton}
      //       name='action'
      //       value={this.props.app.dm.manualEntry.ACTION_REJECT}
      //       onPress={this.handleRejectPress}
      //       // tooltip={`Reject [${
      //       //   this.keyMapper.getActionKeyLabels("reject")[0]
      //       // }]`}
      //       color={Colors.secondary}
      //       // style={styles.buttonRowButton}
      //     />,
      //     <SubmitButton title='Accept' key='submit' />,
      //   ]
      // ) : (
      //   <Button
      //     title='Edit'
      //     key='edit'
      //     style={styles.editButton}
      //     name='edit'
      //     onPress={this.handleEditPress}
      //   />
      // );
    }
    renderSectionHeader() {
      const shouldRender =
        // this.props.enableDocumentFieldOffsetSelect ||
        // !this.props.disableDocumentPreview ||
        !this.formData.readOnly && !this.formData.questionFields;
      if (!shouldRender) return null;
      return (
        <SectionHeader
          level={3}
          style={styles.sectionHeader}
          // tooltip='Annotation'
        >
          {this.formData.label}
        </SectionHeader>
      );
    }
    renderFormFields() {
      let ret = [];
      if (this.formData.questionFields) {
        ret.push(
          <AnnotationQuestionFieldGroup
            key='annotationQuestionFieldGroup'
            ref={this.annotationQuestionFieldGroupRef}
            label={this.formData.label}
            questionFields={this.formData.questionFields}
            values={this.formData.values.values}
            autoFocus={!this.initialOffsetSelectionModeEnabled}
            required={this.formData.required}
            initialValueVisible={this.initialValueVisible}
            disabled={!this.state.editable}
            submitButtonRef={this.submitButtonRef}
            disableSubmit={this.props.disableSubmit}
            readOnly={this.formData.readOnly}
          />
        );
      } else if (this.formData.readOnly) {
        ret.push(<HiddenInputField key='hiddenInputField' name='value' />);
        // ret.push(
        //   <View key='audit' style={styles.readOnly}>
        //     <Text
        //       style={[
        //         styles.readOnlyText,
        //         !this.formData.values.value && styles.readOnlyBlankText,
        //       ]}
        //     >
        //       {this.formData.values.value || "Blank"}
        //     </Text>
        //   </View>
        // );
        ret.push(
          <Card
            key='readOnly'
            surfaceStyle={styles.readOnlyCardSurface}
            title={this.formData.label}
            titleTextStyle={[styles.readOnlyCardTitleText]}
          >
            <Text
              style={[
                styles.readOnlyText,
                !this.formData.values.value && styles.readOnlyBlankText,
              ]}
            >
              {this.formData.values.value || "Blank"}
            </Text>
          </Card>
        );
      } else {
        if (this.formData.valueSelections)
          ret.push(
            <RadioButtonGroupField
              // value={this.state.radioButton ? true : false}
              key='radioButtonGroupField'
              ref={this.valueSelectionElmtRef}
              onValueChange={this.handleValueSelectionValueChange}
              name='valueSelection'
              label={this.formData.label}
              items={this.formData.valueSelections}
              autoFocus={
                !this.initialValueVisible &&
                !this.initialOffsetSelectionModeEnabled
              }
              style={styles.ValueSelectionsFieldGroup}
              required={this.formData.required}
              helper={this.formData.required ? "Required" : null}
              maxContentHeight={
                this.formData.valueSelections.length > 6 ? 248 : null
              }
            />
          );
        ret.push(
          <FadeView visible={this.state.valueVisible} key='textInputFadeView'>
            <TextInputField
              key='textInputField'
              name='value'
              ref={this.valueElmtRef}
              label={
                this.formData.valueSelections ? "Other" : this.formData.label
              }
              multiline
              required={this.formData.required}
              helper={this.formData.required ? "Required" : null}
              style={styles.valueTextInput}
              autoFocus={
                this.state.editable &&
                this.initialValueVisible &&
                !this.initialOffsetSelectionModeEnabled
              }
              autoSelect={
                this.state.editable && !this.initialOffsetSelectionModeEnabled
              }
              disabled={!this.state.editable}
            />
          </FadeView>
        );
      }
      return ret;
    }
    renderAnnotations() {
      let previewAnnotation = this.previewAnnotation;
      if (!previewAnnotation) return null;
      let annotations = [previewAnnotation];
      return annotations.map((annotation) => {
        let offset = this.document.getAnnotationOffset(annotation);
        if (!offset) return null;
        let props = {
          key: annotation.uuid,
          width:
            offset.width *
            this.document.image.width *
            this.state.annotationPreviewScale,
          height:
            offset.height *
            this.document.image.height *
            this.state.annotationPreviewScale,
          top:
            offset.top *
            this.document.image.height *
            this.state.annotationPreviewScale,
          left:
            offset.left *
            this.document.image.width *
            this.state.annotationPreviewScale,
          onPress: this.handleAnnotationPress,
          active: true,
          activeStyle: this.getAnnotationStyle(annotation, true),
        };
        return <DocumentAnnotation {...props} />;
      });
    }
    renderDescription() {
      if (!this.formData.description) return null;
      return (
        <View
          style={[styles.description, this.uiConfig.styles.formDescription]}
        >
          {this.formData.description.split("\n").map((line, i) => (
            <View key={`line${i}`}>
              <Text
                style={[
                  styles.descriptionText,
                  this.uiConfig.styles.formDescriptionText,
                ]}
              >
                {line}
              </Text>
            </View>
          ))}
        </View>
      );
    }
    renderDocumentPreview(options = {}) {
      if (!this.showDocumentPreview) return null;
      return (
        <View
          style={styles.documentPreview}
          onLayout={this.handleAnnotationPreviewLayout}
        >
          <Document
            ref={this.documentRef}
            scale={this.state.annotationPreviewScale || 1}
            onScroll={this.props.onDocumentScroll}
            onZoomInPress={this.props.onDocumentZoomInPress}
            onZoomOutPress={this.props.onDocumentZoomOutPress}
            onZoomResetPress={this.props.onDocumentZoomResetPress}
            onRotateLeftPress={this.props.onDocumentRotateLeftPress}
            onRotateRightPress={this.props.onDocumentRotateRightPress}
            onSelectionEditPress={options.onSelectionEditPress || null}
            selectionEditIconColor={options.selectionEditIconColor || null}
            // selectionModeEnabled={this.props.annotationSelectionModeEnabled}
            // selectionOffset={this.selectionOffset}
            // onSelectionChange={this.handleSelectionChange}
            hideToolbar={options.hideToolbar === true}
            onFocus={options.onFocus || null}
            onBlur={options.onBlur || null}
            toolbarStyle={this.props.documentToolbarStyle}
            toolbarPosition={this.props.documentToolbarPosition}
            height={128}
            width={328}
            imageHeight={this.document.image.height}
            imageWidth={this.document.image.width}
            imageSource={{
              uri: this.document.image.url,
            }}
            imageAuth={
              this.props.formData.documentSet.documentImageStorageMode !==
              this.props.app.dm.manualEntry.DOCUMENT_IMAGE_STORAGE_MODE_DATASET
            }
            zoomLevel={this.props.documentZoomLevel}
            rotate={this.props.documentRotate}
            annotations={this.renderAnnotations()}
          />
        </View>
      );
    }
    render() {
      return (
        <Card
          title={this.formData.title}
          titleTooltip={this.formData.title}
          titleNumberOfLines={1}
          titleEllipsisMode='tail'
          titleControlsRight={this.renderTitleControlsRight()}
          titleTextStyle={styles.cardTitleTextStyle}
          surfaceStyle={styles.cardSurface}
          style={[styles.card, this.props.style]}
        >
          <Form
            name='documentAnnotationForm'
            style={[
              styles.form,
              !this.showDocumentPreview && styles.formNoDocumentPreview,
            ]}
            onSubmit={this.handleSubmit}
            //onBeforeSubmit={this.props.onBeforeSubmit}
            validateBeforeSubmit
            initialValues={this.formData.values}
            backgroundColor={Colors.surface}
            controlsBottomRight={this.renderButtons()}
            onBeforeNavigation={this.props.onBeforeNavigation}
            onValueChange={this.props.onValueChange}
          >
            {this.renderDescription()}
            {/* <HiddenInputField name='action' /> */}
            {/* {false ? ( */}
            <HiddenInputField name='type' />
            <HiddenInputField name='uuid' />
            {this.props.enableDocumentFieldOffsetSelect && (
              <DocumentAnnotationOffsetField
                ref={this.props.offsetFieldRef}
                label={this.annotation.name}
                required={
                  this.formData.offsetRequired
                    ? "Field selection required"
                    : false
                }
                name='offset'
                previewDocumentRenderer={(options) =>
                  this.renderDocumentPreview(options)
                }
                document={this.document}
                annotation={this.annotation}
                formData={this.props.formData}
                initialDialogVisible={this.initialOffsetSelectionModeEnabled}
                disableSelect={this.props.annotationSelectDisabled}
                // onDialogOpen={() => console.log("OFFSET Dialog open")}
                // onDialogClose={() => console.log("OFFSET Dialog close")}
                // selectionModeEnabled={this.props.annotationSelectionModeEnabled}
                onSelectPress={this.props.onAnnotationSelectPress}
                onValueChange={this.handlePreviewAnnotationOffsetChange}
              />
            )}
            {!this.props.enableDocumentFieldOffsetSelect &&
              !this.props.disableDocumentPreview &&
              this.renderDocumentPreview()}
            {this.renderSectionHeader()}
            {/* ) : (
              this.renderDocumentPreview()
            )} */}
            {this.renderFormFields()}
          </Form>
        </Card>
      );
    }
  }
);
const styles = StyleSheet.create({
  card: {
    flex: 0,
    // ...StyleSheet.padding(0),
    // ...StyleSheet.margin(0, 0, 16, 0),
  },
  cardTitleTextStyle: {
    fontSize: 16,
    paddingRight: 8,
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
  },
  cardSurface: {
    ...StyleSheet.margin(0, 0, 8, 0),
    // ...StyleSheet.padding(0),
    // ...StyleSheet.margin(0),
  },
  form: {
    flex: 0,
    ...StyleSheet.padding(0),
  },
  formNoDocumentPreview: {
    ...StyleSheet.padding(4, 0, 0, 0),
  },
  readOnly: {
    ...StyleSheet.padding(16),
    borderWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.2),
    borderRadius: 4,
    borderStyle: "solid",
    marginBottom: 32,
  },
  readOnlyBlankText: {
    color: StyleSheet.color(Colors.onSurface).rgba(0.5),
    fontStyle: "italic",
  },
  readOnlyCardTitleText: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 24,
  },
  readOnlyCardSurface: {
    ...StyleSheet.margin(0, 0, 24, 0),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.02),
  },
  description: {},
  descriptionText: {},
  sectionHeader: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 16,
  },
  documentPreview: {
    height: 144,
    width: "100%",
    position: "relative",
    flex: 1,
    ...StyleSheet.margin(0, 0, 32, 0),
    borderRadius: 4,
    // overflow: "hidden",
  },
  documentPreviewOverlay: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    ...StyleSheet.absoluteFill,
    backgroundColor: StyleSheet.color(Colors.surface).rgba(0.9),
  },
  documentPreviewOverlayText: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: Colors.onSurface,
    fontSize: 13,
    ...StyleSheet.padding(16, 16, 8, 16),
  },
  documentPreviewOverlayError: {},
  offsetFieldErrorText: {
    width: "100%",
    fontSize: 12,
    lineHeight: 20,
    color: Colors.error,
    position: "absolute",
    bottom: -20,
  },
  documentPreviewOverlayButtons: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...StyleSheet.padding(8, 16, 16, 16),
  },
  rejectButton: {
    marginRight: 8,
  },
  valueTextInput: {
    height: 80,
  },
  annotation: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.activeQueueItem,
    backgroundColor: StyleSheet.color(Colors.activeQueueItem).rgba(0.05),
  },
  annotationFieldGroup: {
    borderColor: StyleSheet.color("#666666").rgba(0.2),
    backgroundColor: StyleSheet.color("#666666").rgba(0.1),
  },
  annotationReject: {
    borderColor: Colors.secondary,
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.15),
  },
  annotationAccept: {
    borderColor: Colors.primary,
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.15),
  },
  annotationChanged: {
    borderColor: Colors.green,
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.15),
  },
});
