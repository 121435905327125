import React from "react";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import Touchable from "./Touchable";
import Icon from "./Icon";
import Text from "./Text";
import Theme from "./Theme";
export default class DrawerNavigatorItem extends React.Component {
  render() {
    let styles = this.styles();
    let activeStyles = [];
    let position = this.props.position || "top";
    let drawerNavItemStyles = [];
    let labelStyles = [];
    let iconColor = null;
    let sidebarOnBackground = Theme.componentColors("sidebar").onBackground;

    switch (this.props.type) {
      case "minimized":
        drawerNavItemStyles.push(styles.drawerNavigatorMinimizedItem);
        // iconColor = this.props.active
        //   ? this.props.activeIconColor || Colors.onPrimary
        //   : this.props.iconColor || Colors.onPrimary;
        iconColor = Colors.onPrimary;
        activeStyles.push(styles.activeMinimized);
        switch (position) {
          case "left":
            activeStyles.push(styles.leftActiveMinimized);
            break;
        }
        break;
      default:
        drawerNavItemStyles.push(styles.drawerNavigatorItem);
        iconColor = this.props.active
          ? this.props.activeIconColor || Colors.primary
          : this.props.iconColor || Colors.onBackground;

        activeStyles.push(styles.active);
        switch (position) {
          case "left":
            if (!this.props.active && sidebarOnBackground) {
              if (!this.props.iconColor) iconColor = sidebarOnBackground;
              let labelStyleSheet = StyleSheet.create({
                label: {
                  color: sidebarOnBackground,
                },
              });
              labelStyles.push(labelStyleSheet.label);
            }
            activeStyles.push(styles.leftActive);

            this.props.style && drawerNavItemStyles.push(this.props.style);
            this.props.activeStyle && activeStyles.push(this.props.activeStyle);
            this.props.labelTextStyle &&
              labelStyles.push(this.props.labelTextStyle);
            this.props.activeLabelTextStyle &&
              this.props.active &&
              labelStyles.push(this.props.activeLabelTextStyle);

            break;
        }
    }

    // Create the label
    let label = null;
    if (this.props.label) {
      if (typeof this.props.label === "function") {
        let LabelComponent = this.props.label;
        label = (
          <LabelComponent
            style={[
              styles.label,
              this.props.active && styles.activeLabel,
              labelStyles,
            ]}
            active={this.props.active}
          />
        );
      } else {
        let labelText = this.props.label;
        if (this.props.upperCaseLabel) labelText = labelText.toUpperCase();
        label = (
          <Text
            style={[
              styles.label,
              this.props.active && styles.activeLabel,
              labelStyles,
            ]}
          >
            {this.props.label}
          </Text>
        );
      }
    }
    return (
      <Touchable
        style={[
          drawerNavItemStyles,
          this.props.active && activeStyles,
        ]}
        //activeStyle={activeStyles}
        to={this.props.to}
        onPress={this.props.onPress}
      >
        {this.props.icon && (
          <Icon style={styles.icon} color={iconColor} name={this.props.icon} />
        )}
        {this.props.type !== "minimized" && label}
      </Touchable>
    );
  }
  styles() {
    let backgroundColor = StyleSheet.color(Colors.primary).rgba(0.1);
    let labelColor = Colors.onBackground;
    return StyleSheet.create({
      drawerNavigatorItem: {
        // display: "block",
        flex: 0,
        flexShrink: 0,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        ...StyleSheet.padding(0, 12, 0, 12),
        ...StyleSheet.margin(4, 8, 4, 8),
        height: 42,
        margin: 0,
        opacity: 0.7,
      },
      drawerNavigatorMinimizedItem: {
        flex: 0,
        flexShrink: 0,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        ...StyleSheet.padding(0, 20, 0, 20),
        // ...StyleSheet.margin(4, 0, 4, 0),
        height: 50,
        margin: 0,
        opacity: 0.7,
        backgroundColor: StyleSheet.color(Colors.primary).rgba(0.1),
        screen: ({ width }) => {
          if (width <= 640)
            return {
              ...StyleSheet.padding(0, 16, 0, 16),
            };
        },
      },
      label: {
        color: labelColor,
        fontWeight: "500",
        fontSize: 14,
      },
      activeLabel: {
        color: Colors.primary,
      },
      active: {
        color: Colors.primaryDark,
        borderStyle: "solid",
        borderWidth: 0,
        borderColor: Colors.primaryLight,
        backgroundColor: backgroundColor,
        opacity: 0.94,
      },
      leftActive: {
        borderRadius: 4,
      },
      bottomActive: {
        borderTopWidth: 2,
        paddingTop: 14,
      },
      activeMinimized: {
        borderStyle: "solid",
        borderLeftWidth: 2,
        paddingLeft: 18,
        borderColor: Colors.primaryLight,
        opacity: 0.94,
        backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2),
        screen: ({ width }) => {
          if (width <= 640)
            return {
              paddingLeft: 14,
            };
        },
      },
      leftActiveMinimized: {},
      icon: {
        marginRight: 24,
      },
    });
  }
}
