import React from "react";
import R14, { StyleSheet, View, Text, SectionHeader, Scroller } from "../core";

export default class NdaScroller extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll(e) {
    let scrollY = e.nativeEvent.contentOffset.y || 0;
    let scrollHeight =
      e.nativeEvent.contentSize.height - e.nativeEvent.layout.height;
    if (scrollHeight - scrollY < 10 && this.props.onScrollEnd)
      this.props.onScrollEnd(e);
  }
  render() {
    return (
      <Scroller
        name='pageScroller'
        style={[styles.scroller, this.props.style]}
        direction='vertical'
        height={240}
        onScroll={this.handleScroll}
      >
        <Scroller.Item name='nda' style={styles.scrollerItem} indicatorStyle={styles.scrollerItemIndicator}>
          <Header level={1}>NON-DISCLOSURE AGREEMENT</Header>
          <Paragraph>
            This Non-Disclosure (“Agreement”) is made and entered into as of the
            date signed by the Receiving Party below, by and between Exela
            Technologies India Private Limited (the “Company” or “Disclosing
            Party”) which expression shall, unless repugnant to the meaning or
            context, shall include its subsidiaries, successors, assigns,
            affiliates, associates and legal representatives by its duly
            authorized representative of the ONE PART
          </Paragraph>
          <Paragraph>And</Paragraph>
          <Paragraph>
            Freelancer for Teletype (the “You” or “Freelancer” or “Receiving
            Party”) of the SECOND PART.
          </Paragraph>
          <Header level={2}>Recitals</Header>
          <Paragraph>
            <Text style={styles.bold}>WHEREAS</Text> the Company is desirous to
            engage the services of the Freelancer and Freelancer has agreed to
            render its services. In the course of such engagement and performing
            the services, it is anticipated that Freelancer may be exposed to
            Company’s Confidential Information, as defined herein. This
            disclosure, exchange and delivery includes all communications in any
            form whatsoever including oral, written and electronic form either
            marked as confidential or not.
          </Paragraph>
          <Paragraph>
            <Text style={styles.bold}>AND WHEREAS</Text> the Freelancer
            acknowledges that the information so received shall be protected
            from unauthorized use, modifications or disclosure.
          </Paragraph>
          <Paragraph>
            <Text style={styles.bold}>AND WHEREAS</Text> the Company is willing
            to disclose Confidential Information and the Freelancer is willing
            to receive such information, on the terms and conditions set-forth
            below:
          </Paragraph>

          <Header level={3}>1. Confidential Information:</Header>
          <Paragraph indent={1}>
            Confidential Information shall mean all confidential, proprietary
            Information and material in any form whether physical or electronic,
            delivered, exchanged or communicated orally, written, visually or
            any other form, and shall include, but not be limited to:
          </Paragraph>
          <ListItem value='a'>
            Production processes, marketing techniques and arrangements, mailing
            lists, financial information, customer names and addresses,
            prospects, names and/or information relating to Disclosing Party’s
            business and activities and the manner in which Disclosing Party
            does business or production or development or any information
            derived there-from;
          </ListItem>
          <ListItem value='b'>
            Software including source and object code, flow chart, algorithms,
            coding sheets, routines, subroutines, compilers, assemblers,
            diagrams, sketches, design concepts and related documentation and
            manuals;
          </ListItem>
          <ListItem value='c'>
            All other materials or information related to the business or
            activities of the Disclosing Party, which are not generally known to
            others engaged in similar businesses or activities. Information
            relating to Disclosing Party’s customers or its customer’s
            customers’ business affairs, customer lists, financial information;
            know-how, pricing, service and technical records, details of
            customers or suppliers, information relating to persons employed,
            existing or planned products and/or the existence of any planned
            product, future pricing, marketing or service strategies or
            operational techniques or policies including the combination of
            functions or activities; any documentation (including sketches,
            drawings, plans, photographs, negatives, notebooks, tracings,
            reports, findings, recommendations, data, memorandums, formulations,
            specifications and measurements).
          </ListItem>
          <ListItem value='d'>
            Personal Health Information (“PHI”) including but not limited to
            medical and financial records, revenues, identification and account
            numbers and names, PINs, and passwords, or other information
            conveyed in writing or in a discussion that is indicated to be
            confidential.
          </ListItem>
          <ListItem value='e'>
            Personal Identifiable Information (“PII”) including but not limited
            to names of individuals, addresses, social security numbers, or
            other information that can be used to distinguish or trace an
            individual’s identity, either alone or when combined within other
            personal or identifying information that is linked or linkable to a
            specific individual.
          </ListItem>
          <Paragraph indent={1}>
            Such information, including all information supplied concurrently
            with the execution of this Agreement and any other information that
            the Disclosing Party or its agents may furnish to the Receiving
            Party from time-to-time is the sole property of the Disclosing Party
            and the Receiving Party shall have no rights with respect to this
            information or other data or information contained therein.
          </Paragraph>

          <Header level={3}>
            2. Personal Health Information and Personal Identifiable Information
          </Header>
          <Paragraph indent={1}>
            During the course of providing services to the Company, Receiving
            Party may receive or be provided access to PHI or PII. To the extent
            Receiving party does receive and/or is given access to PHI,
            Receiving Party agrees to comply with and adhere to all of the
            requirements set forth in the annexed Business Associate Addendum,
            the terms of which are incorporated herein by reference. To the
            extent Receiving Party does receive and/or is given access to PII,
            Receiving Party agrees to comply with and adhere to all of the
            requirements set forth in the annexed Privacy and Information
            Security Addendum, the terms of which are incorporated herein by
            reference.
          </Paragraph>

          <Header level={3}>3. Limitation on Obligations:</Header>
          <Paragraph indent={1}>
            The Receiving Party with respect to any portion of the Confidential
            Information received from the Disclosing Party under this Agreement
            shall not be liable or subject to any obligation under this
            Agreement if such information:
          </Paragraph>
          <ListItem value='(a)'>
            is generally known to the public at the time of disclosure or
            becomes generally known through no wrongful act on the part of the
            Receiving Party;
          </ListItem>
          <ListItem value='(b)'>
            is in the Receiving Party’s possession at the time of disclosure
            otherwise than as a result of Receiving Party’s breach of any legal
            obligations;
          </ListItem>
          <ListItem value='(c)'>
            becomes known to the Receiving Party through disclosure by sources
            other than the Disclosing Party having the legal right to disclose
            such Confidential Information.
          </ListItem>
          <ListItem value='(d)'>
            is independently developed by the Receiving Property without
            reference to or reliance upon the Confidential Information; or
          </ListItem>
          <ListItem value='(e)'>
            is required to be disclosed by the Receiving Party to comply with
            applicable laws or government regulations, provided that the
            Receiving Party provides prior written notice of such disclosure to
            the Disclosing Party and takes reasonable and lawful actions to
            avoid and/or minimize the extent of such disclosure.
          </ListItem>

          <Header level={3}>4. Nondisclosure & Term:</Header>
          <Paragraph indent={1}>
            The Receiving Party shall not use the Confidential Information in
            any way adverse to the interests of the Disclosing Party, and that
            all such information will, at all times, be kept confidential by the
            Receiving Party and it agrees to limit the disclosure of the
            Confidential Information to the authorized representatives and
            personnel of the Receiving Party who need to know such information.
            The Receiving Party agrees that the Confidential Information
            provided shall not be disclosed in violation of applicable law. The
            Receiving Party agrees to inform all individuals given access to the
            Confidential Information that such information is confidential and
            is subject to the terms and conditions of this Agreement.
          </Paragraph>
          <Header level={3}>5. Protection of Confidential Information:</Header>
          <Paragraph indent={1}>
            The Receiving Party agrees that it shall take reasonable security
            measures to hold the Confidential Information in strict confidence
            and safe custody and those measures shall be at least as great as
            the measures the Receiving Party uses to keep its own Confidential
            Information secure.
          </Paragraph>

          <Header level={3}>6. Survival:</Header>
          <Paragraph indent={1}>
            The obligations of Confidential Information under this Agreement
            shall survive the termination of services and shall continue
            indefinitely.
          </Paragraph>
          <Header level={3}>7. Right and Remedies:</Header>
          <Paragraph indent={1}>
            The Parties hereto specifically acknowledge that the proprietary
            nature of the Confidential Information and the monetary damages to
            Disclosing Party for breach of the terms of this Agreement may be
            difficult to determine and/or inadequate to compensate the
            Disclosing Party for breach hereof, and hereby agree that in the
            event of any breach by the Receiving Party of the provisions of this
            Agreement, the Disclosing Party, in addition to any other remedies
            it may have under the terms of this Agreement or at law, shall have
            the right to bring an action in equity for an injunction against the
            breach or threatened breach of the obligations under this Agreement
            or seeking specific performance of such obligations.
          </Paragraph>

          <Header level={3}>8. Entire Agreement:</Header>

          <Paragraph indent={1}>
            This Agreement contains the entire agreement between the Parties
            with respect to the Confidential Information and supersedes any
            prior written or oral negotiations, commitments, agreements and
            understandings. No letter, telegram, or other communication between
            the Parties hereto shall be deemed a part of this Agreement; nor
            shall a subsequent communication have the effect of modifying this
            Agreement unless it is specifically stated that such amendment,
            letter, telegram, or other communication shall constitute a part of
            this Agreement and such is signed by the Parties.
          </Paragraph>

          <Header level={3}>9. Indemnification:</Header>
          <Paragraph indent={1}>
            The Receiving Party agrees to indemnify, defend and hold the other
            harmless the Company from all claims, damages, demands, liabilities,
            costs and expenses, arising out of any of the breaches specified in
            the Agreement.
          </Paragraph>
          <Header level={3}>10. Return of Confidential Information:</Header>
          <Paragraph indent={1}>
            The Receiving Party agrees to return or destroy all Confidential
            Information immediately following the termination of services for
            any reason or upon request by the Company. To the extent the
            Receiving Party is in possession of any Confidential Information in
            electronic form on any computer(s) or in any other electronic
            devices, the Receiving Party agrees to irretrievably delete all such
            Confidential information following the termination of services for
            any reason or upon request by the Company.
          </Paragraph>
          <Header level={3}>11. Severability:</Header>
          <Paragraph indent={1}>
            In case any of the provisions (or portion thereof), contained in
            this Agreement will for any reason be held invalid, illegal or
            enforceable in any respect, such invalidity, illegality or
            enforceability will not affect the other provisions of this
            Agreement, and this Agreement will be construed as if such invalid,
            illegal or enforceable provision(s) had never been contained herein.
          </Paragraph>
          <Header level={3}>12. Governing Law & Jurisdiction:</Header>
          <Paragraph indent={1}>
            This Agreement and its terms and conditions shall be construed and
            governed in accordance with the laws of the Republic of India. Any
            dispute arising between the Parties in respect of this Agreement or
            in any manner touching upon it or connected to it in any manner
            shall be resolved amicably. If the dispute therein, is not resolved
            amicably within thirty (30) days from the date when such dispute has
            arisen or reported, the same will be resolved or settled by means of
            binding Arbitration under the Indian Arbitration and Conciliation
            Act, 1996 (as amended from time to time) by the sole Arbitrator to
            be appointed by the Company. The place of arbitration will be Pune
            and Courts at Pune alone shall have exclusive jurisdiction.
          </Paragraph>
          <Paragraph style={styles.paddingTop24}>
            I, the undersigned do hereby certify that I have received, read,
            understood and agree to abide by this Non-Disclosure Agreement.
          </Paragraph>
        </Scroller.Item>
      </Scroller>
    );
  }
}

const Paragraph = (props) => (
  <View
    style={[styles.paragraph, props.indent && styles[`indent${props.indent}`]]}
  >
    <Text style={styles.paragraphText}>{props.children}</Text>
  </View>
);
const ListItem = (props) => (
  <View style={[styles.listItem, styles[`indent${props.indent || 1}`]]}>
    <View style={styles.listItemNumber}>
      <Text style={styles.listItemNumberText}>{props.value}.</Text>
    </View>
    <Text style={styles.listItemText}>{props.children}</Text>
  </View>
);
const Header = (props) => (
  <SectionHeader
    style={[
      styles[`header${props.level || 1}`],
      props.indent && styles[`indent${props.indent}`],
    ]}
    level={props.level}
  >
    {props.children}
  </SectionHeader>
);

const styles = StyleSheet.create({
  scrollViewContent: {
    // padding: 16,
  },
  scroller: {
    width: 464,
    height: 344,
    ...StyleSheet.margin(0, -8, 0, -8),
    screen: ({ width, height }) => {
      if (width <= 640) {
        return {
          width: null,
        };
      }
    },
  },
  scrollerItemIndicator:{
    height: 0,
    width: 0
  },
  scrollerItem: {
    padding: 16,
  },
  paragraph: {
    paddingBottom: 16,
  },
  listItem: {
    flex: 1,
    flexDirection: "horizontal",
    paddingBottom: 16,
  },
  listItemNumber: {
    flex: 0,
    minWidth: 24,
    width: 24,
  },
  listItemText: {
    flex: 1,
  },
  indent1: {
    paddingLeft: 24,
  },
  indent2: {
    paddingLeft: 48,
  },
  indent3: {
    paddingLeft: 72,
  },
  header1: {
    fontSize: 20,
  },
  header2: {
    fontSize: 18,
  },
  header3: {
    fontSize: 18,
    fontWeight: 500,
  },
  header4: {
    fontSize: 16,
    fontWeight: 500,
  },
  bold: {
    fontWeight: 600,
  },
});
