import R14, { AsyncStorage } from "../core";

export default class VirtualWorkspaceDomain extends R14.Domain {
  constructor() {
    super();
    this.state = {};
  }
  async fetchSessionInfo(virtualWorkspaceConfigUid, virtualWorkspaceUid) {
    let ret = null;
    let res = await this.api.qry(
      `
        query VirtualWorkspaceSession(
          $virtualWorkspaceUid: ID!, 
          $virtualWorkspaceConfigUid: ID!, 
          ) {
          virtualWorkspaceSession(
            virtualWorkspaceUid: $virtualWorkspaceUid, 
            virtualWorkspaceConfigUid: $virtualWorkspaceConfigUid, 
          ) {
            url
          }
        }`,
      { virtualWorkspaceConfigUid, virtualWorkspaceUid }
    );
    if (res.errors && res.errors.length) 
      console.log("Error getting streaming url:", res.errors);
    else
      ret = {
        url:
          res.data.virtualWorkspaceSession &&
          res.data.virtualWorkspaceSession.url
            ? res.data.virtualWorkspaceSession.url
            : null,
      };

    return ret;
  }
  async updateUserSession(virtualWorkspaceConfigUid, virtualWorkspaceUid, virtualWorkspaceUserSessionUid = null){
    let ret = null;
    let res = await this.api.qry(
      `
        mutation SyncVirtualWorkspaceUserSession(
          $virtualWorkspaceUid: ID!, 
          $virtualWorkspaceConfigUid: ID!
          $virtualWorkspaceUserSessionUid: ID
        ) {
          syncVirtualWorkspaceUserSession(
            virtualWorkspaceUid: $virtualWorkspaceUid, 
            virtualWorkspaceConfigUid: $virtualWorkspaceConfigUid, 
            virtualWorkspaceUserSessionUid: $virtualWorkspaceUserSessionUid
          ) {
            uid
          }
        }`,
      { virtualWorkspaceConfigUid, virtualWorkspaceUid, virtualWorkspaceUserSessionUid }
    );

    if (res.errors && res.errors.length) 
      console.error("Error getting streaming url:", res.errors);
    else
      ret = res.data.syncVirtualWorkspaceUserSession;
   return ret;
  }
}
