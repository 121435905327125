import React from "react";
import StyleSheet from "../StyleSheet";

export default class ExternalLinkBase extends React.PureComponent {
  render() {
    let linkContent = this.props.url;
    if (React.Children.count(this.props.children))
      linkContent = this.props.children;
    else if (this.props.title) linkContent = this.props.title;
    return (
      <a
        href={this.props.url}
        className={StyleSheet.className([this.props.style])}
        target='_BLANK'
        rel='noopener noreferrer'
      >
        {linkContent}
      </a>
    );
  }
}
