import React from "react";
import R14, {
  Redirect,
  StyleSheet,
  Chip,
  Colors,
  PopUpMenu,
  PopUpMenuItem,
} from "../core";
import CountdownTimer from "../components/CountdownTimer";
import DocumentAnnotationEditForm from "../components/DocumentAnnotationEditForm";
export default R14.connect(
  class ManualEntryDocumentSetAnnotationEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleReject = this.handleReject.bind(this);
      this.handleAcceptRemainingPress =
        this.handleAcceptRemainingPress.bind(this);
      this.handleRejectRemainingPress =
        this.handleRejectRemainingPress.bind(this);
      this.handleDocumentZoomInPress =
        this.handleDocumentZoomInPress.bind(this);
      this.handleDocumentZoomOutPress =
        this.handleDocumentZoomOutPress.bind(this);
      this.handleDocumentZoomResetPress =
        this.handleDocumentZoomResetPress.bind(this);
      this.handleSelectionEditPress = this.handleSelectionEditPress.bind(this);

      this.handleDocumentScroll = this.handleDocumentScroll.bind(this);
      this.handleDocumentRotateLeftPress =
        this.handleDocumentRotateLeftPress.bind(this);
      this.handleDocumentRotateRightPress =
        this.handleDocumentRotateRightPress.bind(this);

      this.handleTimeout = this.handleTimeout.bind(this);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleOffsetChange = this.handleOffsetChange.bind(this);
      this.handleBeforeNavigation = this.handleBeforeNavigation.bind(this);
      this.startTime = Date.now();
      this.timeout = false;
      this.state = { submitting: false };
    }
    componentDidMount() {
      this.props.annotation.editForm.mount();
    }
    componentWillUnmount() {
      this.props.annotation.editForm.unmount();
      this.props.annotation.removeEditForm();
    }
    get documentSet() {
      return this.props.formData.documentSet;
    }
    get previewDocument() {
      return this.props.annotation.editForm.previewDocument;
    }
    get documentAnnotationForm() {
      return this.props.app.ui.form("documentAnnotationForm");
    }

    async handleReject() {
      if (this.documentSet.hasAppFeature("disableReject")) return false;
      this.documentAnnotationForm.submit({ reject: true });
    }
    async handleAcceptRemainingPress() {
      if (this.documentSet.hasAppFeature("disableAcceptRemaining"))
        return false;
      this.documentAnnotationForm.submit({ acceptRemaining: true });
    }
    async handleRejectRemainingPress() {
      if (this.documentSet.hasAppFeature("disableRejectRemaining"))
        return false;
      this.documentAnnotationForm.submit({ rejectRemaining: true });
    }
    async handleBeforeSubmit() {}
    async handleSubmit(form, options = {}) {
      if (this.props.documentSet.state.annotationsLoading) return false;
      let values = form.values;
      let ret = false;
      let success = true;
      let shouldUpdate =
        (options.acceptRemaining || options.rejectRemaining) &&
        !this.props.annotation.editForm.changed
          ? false
          : true;
      let nextAnnotation = null;
      if (shouldUpdate) {
        values.reject = this.documentSet.hasAppFeature("disableReject")
          ? false
          : options.reject || options.rejectRemaining || false;
        values.startTime = this.startTime;
        this.props.app.ui.progressIndicator.show();
        this.setState({ submitting: true });
        try {
          nextAnnotation = await this.props.annotation.update(values);
        } catch (err) {
          console.log("CHeck err?", err);
          this.props.app.ui.snackBar.show({
            variant: "error",
            message: `Error saving annotation: ${err.message}`,
          });
          success = false;
        }

        this.props.app.ui.progressIndicator.hide({ timeout: 500 });
        this.setState({ submitting: false });

        if (success) this.startTime = new Date();
      }

      if (!success) return false;

      this.props.annotation.editForm.setChanged(false);

      if (
        this.props.documentSet.metadata.state ===
        this.props.app.dm.manualEntry.STATE_REVIEW
      ) {
        ret = false;
        //this.setState({});
      } else {
        if (!nextAnnotation)
          nextAnnotation = this.props.documentSet.nextAnnotation;
        if (options.acceptRemaining || options.rejectRemaining) {
          options.startTime = this.startTime;
          this.props.documentSet.setDocumentCompleteDialogVisible(
            true,
            options
          );
        } else
          ret = (
            <Redirect
              to='manualEntryDocumentSetAnnotationEdit'
              params={{
                uid: this.props.documentSet.uid,
                annotationUuid: nextAnnotation.uuid,
              }}
            />
          );
      }
      return ret;
    }
    handleValueChange() {
      this.props.annotation.editForm.setChanged(true);
    }
    handleOffsetChange(offset) {
      // this.props.page.forceRefresh();
      this.props.annotation.editForm.setChanged(true);
    }
    handleBeforeNavigation() {
      return this.props.annotation.editForm.changed && !this.timeout
        ? window.confirm(
            "Are you sure? Changes you have made have not been accepted or rejected."
          )
        : true;
    }
    handleDocumentZoomInPress() {
      this.previewDocument.zoomIn();
    }
    handleDocumentZoomOutPress() {
      this.previewDocument.zoomOut();
    }
    handleDocumentZoomResetPress() {
      this.previewDocument.zoomReset();
    }
    handleDocumentRotateLeftPress() {
      this.previewDocument.rotateLeft();
    }
    handleDocumentRotateRightPress() {
      this.previewDocument.rotateRight();
    }
    handleDocumentScroll(event) {
      this.previewDocument.updateScroll(event.nativeEvent.contentOffset);
    }
    handleSelectionEditPress() {
      this.props.annotation.editForm.toggleOffsetSelectionMode();
    }
    async handleTimeout() {
      if (this.props.app.dm.manualEntry.disableTimeouts) return;
      this.timeout = true;
      this.props.documentSet.timeout();
      this.props.app.nav.to("manualEntryTimeout");
    }
    renderControlsRight() {
      let completedAt = new Date();
      completedAt.setSeconds(completedAt.getSeconds() + 60);
      let showMenu =
        !this.documentSet.hasAppFeature("disableRejectRemaining") ||
        !this.documentSet.hasAppFeature("disableAcceptRemaining");
      let ret = [];
      if (
        this.props.annotation.metadata.reject &&
        this.props.annotation.metadata.state ===
          this.props.app.dm.manualEntry.STATE_REVIEW
      )
        ret.push(
          <Chip
            key='rejected'
            label={"Rejected"}
            style={styles.rejectedIndicator}
          />
        );
      ret.push(
        <CountdownTimer
          key='timer'
          completeAt={this.props.app.dm.manualEntry.timeoutAt}
          onComplete={this.handleTimeout}
          style={[
            styles.countdownTimer,
            !showMenu && styles.countdownTimerNoMenu,
          ]}
        />
      );

      if (
        !this.documentSet.hasAppFeature("disableRejectRemaining") ||
        !this.documentSet.hasAppFeature("disableAcceptRemaining")
      )
        ret.push(
          <PopUpMenu controlIcon='dotsVertical' key='menu' direction='downLeft'>
            {!this.documentSet.hasAppFeature("disableAcceptRemaining") && (
              <PopUpMenuItem
                label='Accept Remaining'
                icon='checkCircle'
                key='accept'
                onPress={this.handleAcceptRemainingPress}
              />
            )}
            {!this.documentSet.hasAppFeature("disableRejectRemaining") && (
              <PopUpMenuItem
                label='Reject Remaining'
                icon='closeCircle'
                key='reject'
                onPress={this.handleRejectRemainingPress}
              />
            )}
          </PopUpMenu>
        );
      return ret;
    }
    render() {
      return (
        <DocumentAnnotationEditForm
          onSubmit={this.handleSubmit}
          onBeforeSubmit={this.handleBeforeSubmit}
          onReject={this.handleReject}
          onBeforeNavigation={this.handleBeforeNavigation}
          onValueChange={this.handleValueChange}
          onOffsetChange={this.handleOffsetChange}
          submitting={this.state.submitting}
          formData={this.props.formData}
          shouldUseEditButton={
            this.props.formData &&
            this.props.formData.annotation &&
            this.props.formData.annotation.metadata.state === "COMPLETE"
              ? true
              : false
          }
          disableDocumentPreview={this.documentSet.hasAppFeature(
            "disableDocumentPreview"
          )}
          enableDocumentFieldOffsetSelect={this.documentSet.hasAppFeature(
            "enableDocumentFieldOffsetSelect"
          )}
          disableReject={this.documentSet.hasAppFeature("disableReject")}
          disableSubmit={this.props.documentSet.documentCompleteDialogVisible}
          documentRef={this.previewDocument.documentRef}
          onDocumentZoomInPress={this.handleDocumentZoomInPress}
          onDocumentZoomOutPress={this.handleDocumentZoomOutPress}
          onDocumentZoomResetPress={this.handleDocumentZoomResetPress}
          onDocumentRotateLeftPress={this.handleDocumentRotateLeftPress}
          onDocumentRotateRightPress={this.handleDocumentRotateRightPress}
          onDocumentScroll={this.handleDocumentScroll}
          // onSelectionEditPress={this.handleSelectionEditPress}
          documentToolbarPosition='bottomCenter'
          documentToolbarStyle={styles.toolbar}
          offsetFieldRef={this.props.annotation.editForm.offsetFieldRef}
          // annotationSelectionModeEnabled={
          //   this.props.annotation.editForm.state.offsetSelectionModeEnabled
          // }
          // annotationSelectDisabled={
          //   this.props.annotation.editForm.disableOffsetSelect
          // }
          // onAnnotationSelectPress={
          //   this.props.annotation.editForm.onOffsetSelectPress
          // }
          // documentScale={this.previewDocument.state.zoomLevel}
          documentZoomLevel={this.previewDocument.state.zoomLevel}
          documentRotate={this.previewDocument.state.rotate}
          headerControlsRight={this.renderControlsRight()}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  countdownTimer: {
    // paddingRight: 8,
  },
  countdownTimerNoMenu: {
    paddingRight: 8,
  },
  toolbar: {
    bottom: -18,
  },
  rejectedIndicator: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
    ...StyleSheet.margin(0, 8, 0, 0),
  },
});
