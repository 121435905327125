import React from 'react';
import View from './View';
import ScrollView from './ScrollView';
import FloatingActionButton from './FloatingActionButton';
import StyleSheet from './StyleSheet';
import { Colors } from './Theme';

export default class FieldSet extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
    return (
      <View style={styles.fieldSet}>
        {this.props.children}
      </View>
    );
	}
}
const styles = StyleSheet.create({
  fieldSet: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
});