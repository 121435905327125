import React from "react";
import { StyleSheet, Colors, View, Text } from "../core";
import CharacterCompareView from "./CharacterCompareView";

export default class CharactersReviewView extends React.Component {
  constructor(props) {
    super(props);
  }
  parseValue(char) {
    return char.updatedValue !== null && char.updatedValue !== undefined
      ? char.updatedValue
      : char.value;
  }
  hasChanged(char) {
    return this.parseValue(char) !== char.value;
  }
  render() {
    return (
      <View style={styles.characterReviewView}>
        {this.props.characters.map((char) => (
          <CharacterCompareView
            key={`char${char.uid}`}
            character={char}
            imageSize={32}
            imagePadding={6}
            focused
            reject={char.reject}
            changed={this.hasChanged(char)}
          >
            <Text style={[styles.characterText]}>{this.parseValue(char)}</Text>
          </CharacterCompareView>
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  characterText: {
    fontSize: 26,
    lineHeight: 26,
    textAlign: "center",
    // height: 28,
    fontFamily: "monospace",
    color: Colors.onSurface,
    backgroundColor: Colors.surface,
  },
  characterReviewView: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginLeft: -8,
    marginBottom: 16,
  },
});
