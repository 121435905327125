import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import ReCaptchaBase from "./base/ReCaptchaBase";

/**
 * A component for displaying images.
 */
export default class ReCaptcha extends React.PureComponent {
  static propTypes = {
    /** If true the user won't be able to toggle the checkbox. Default value is false. */
    disabled: PropTypes.bool,
    /** The Google site key value. */
    siteKey: PropTypes.string,
    /** Invoked with the new value when the value changes. */
    onValueChange: PropTypes.func,
    /** The image source (either a remove url uri or a local file resource) */
    source: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the image */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };
  static defaultProps = {};
  constructor(props) {
    super(props);
  }
  render() {
    return <ReCaptchaBase {...this.props} />;
  }
}
const styles = StyleSheet.create({});
