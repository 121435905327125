import React from "react";
import R14, {
  StyleSheet,
  Colors,
  ScrollView,
  View,
  Text,
  SectionHeader,
  Dialog,
  Scroller,
} from "../core";
import NdaScroller from "./NdaScroller";

export default class NdaView extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Dialog
        name='ndaForm'
        title='I hereby agree to the following:'
        visible={this.props.visible}
        titleControlVariant='back'
        onClosePress={this.props.onClosePress}
        onBackPress={this.props.onClosePress}
        onCancelPress={this.props.onClosePress}
        onBackdropPress={this.props.onClosePress}
        onOkPress={this.props.onClosePress}
        okButtonTitle='Close'
        hideCancelButton={true}
      >
        <NdaScroller style={styles.scroller} />
      </Dialog>
    );
  }
}
const styles = StyleSheet.create({
  scroller: {
    ...StyleSheet.margin(-8, -8, 4, -8),
  },
});
