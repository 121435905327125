import React from "react";
import PropTypes from "prop-types";
import R14, {
  TextInput,
  StyleSheet,
  Colors,
  AnimatedView,
  View,
} from "../core";
import CharacterImage from "./CharacterImage";

export default class CharacterCompareField extends React.Component {
  static propTypes = {
    // /** Name for the character entry fields. */
    // name: PropTypes.string.isRequired,
    // /** Value of the character entry fields. */
    // value: PropTypes.any,
  };
  constructor(props) {
    super(props);
  }
  createDynamicStyles() {
    let dynamicStyles = {};
    if (this.props.borderColor) {
      dynamicStyles.characterField = { borderColor: this.props.borderColor };
    }
    if (this.props.imageSize) {
      dynamicStyles.content = {
        height: this.props.imageSize,
        width: this.props.imageSize,
      };
    }
    return StyleSheet.create(dynamicStyles);
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <AnimatedView
        key={`char${this.props.character.uid}`}
        in={this.props.focused || this.props.focus}
        enter={{
          opacity: 1.0,
        }}
        exit={{
          opacity: 0.9,
        }}
        duration={350}
        style={[styles.characterCompare]}
      >
        {this.props.controlsTop && (
          <View style={styles.controlsTop}>{this.props.controlsTop}</View>
        )}
        <View
          style={[
            styles.characterField,
            this.props.style,
            this.props.focused && styles.characterFieldFocused,
            this.props.focus && styles.characterFieldFocus,
            this.props.changed && styles.characterFieldChanged,
            this.props.reject && styles.characterFieldReject,
            this.props.edit && styles.characterFieldEdit,
            dynamicStyles.characterField,
          ]}
        >
          <View>
            <CharacterImage
              imageBase64={this.props.character.imageBase64}
              height={this.props.imageSize || 48}
              width={this.props.imageSize || 48}
              padding={this.props.imagePadding || 10}
            />
          </View>
          <View style={[styles.content, dynamicStyles.content]}>
            {this.props.children}
          </View>
        </View>
        {this.props.controlsBottom && (
          <View style={styles.controlsBottom}>{this.props.controlsBottom}</View>
        )}
      </AnimatedView>
    );
  }
}

const styles = StyleSheet.create({
  characterCompare: {
    ...StyleSheet.margin(8, 0, 0, 8),
  },
  characterField: {
    opacity: 0.9,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.6),
    borderWidth: 2,
    borderRadius: 4,
    overflow: "hidden",
  },
  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.surface,
    width: 48,
    height: 48,
    textAlign: "center",
  },
  characterFieldFocused: {
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  characterFieldChanged: {
    borderColor: StyleSheet.color("#4caf50").rgba(0.6),
  },
  characterFieldFocus: {
    borderColor: StyleSheet.color(Colors.activeQueueItem).rgba(0.8),
    elevation: 6,
  },
  characterFieldReject: {
    borderColor: StyleSheet.color(Colors.secondary).rgba(0.8),
  },
  characterFieldEdit: {
    borderColor: StyleSheet.color(Colors.primaryLight).rgba(0.8),
  },
  controlsBottom: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    ...StyleSheet.margin(4, 0, -16, 0),
  },
  controlsTop: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    ...StyleSheet.margin(0, 0, 4, 0),
  },
});
