
import React from 'react';
import PropTypes from 'prop-types';
import DownloadButtonBase from './base/DownloadButtonBase';
/**
 * A download button component. Extends the props of a Button
 */
export default class DownloadButton extends React.Component {
   static propTypes = {
    /** A simple array containing the list data.  */
    source: PropTypes.object,
    /** Callback called after download complete.  */
    onComplete: PropTypes.func
  };
  render(){
    return <DownloadButtonBase {...this.props} />
  }
}