
import PropTypes from 'prop-types'
import EventViewBase from './base/EventViewBase';

/** EventView is a component with custom advanced event handling */
export default class EventView extends EventViewBase {
  static propTypes = {
    /** The onPressOutside prop is called when something is pressed outside of the EventView . */
    onPressOutside: PropTypes.func,
  }
}