import React from 'react';
import R14 from '../R14';
import Calendar from './Calendar';
import AnimatedView from './AnimatedView';
import View from './View';
import IconButton from './IconButton';
import StyleSheet from './StyleSheet';

export default R14.connect(
  class DatePicker extends React.Component {
    constructor(props) {
      super(props);
      this.itemIndex = 0;
      // this.flatListRef = React.createRef();
      // this.renderItem = this.renderItem.bind(this);
      this.handleSelectDay = this.handleSelectDay.bind(this);
      // this.handleViewableItemsChanged = this.handleViewableItemsChanged.bind(this);
      this.handlePreviousMonthButtonPress = this.handlePreviousMonthButtonPress.bind(this);
      this.handleNextMonthButtonPress = this.handleNextMonthButtonPress.bind(this);

      this.state = {
        month: this.props.date ? new Date(this.props.date) : new Date()
      }
    }

    get hasNext() {
      if (!this.props.maxDate) return true;
      let nextDate = new Date(this.state.month);
      nextDate.setMonth(nextDate.getMonth() + 1);
      let maxDate = new Date(this.props.maxDate);
      maxDate.setMonth(maxDate.getMonth() + 1);
      return this.props.app.utils.date.isMonthLessThan(nextDate, maxDate);
    }
    get hasPrevious() {
      if (!this.props.minDate) return true;
      let prevDate = new Date(this.state.month);
      prevDate.setMonth(prevDate.getMonth() - 1);
      let minDate = new Date(this.props.minDate);
      minDate.setMonth(minDate.getMonth() - 1);
      return this.props.app.utils.date.isMonthLessThan(minDate, prevDate);
    }
    handleSelectDay(date) {
      if (this.props.onDateChange) this.props.onDateChange(date);
    }
    handlePreviousMonthButtonPress() {
      if(! this.hasPrevious) return false;
      let month = this.state.month;
      month.setMonth(month.getMonth() - 1);
      this.setState({
        month: month
      });
    }
    handleNextMonthButtonPress() {
      if(! this.hasNext) return false;
      let month = this.state.month;
      month.setMonth(month.getMonth() + 1);
      this.setState({
        month: month
      });
    }
    render() {
      /** @todo DatePicker disable days outside of range... */
      let nextMonthIcon = this.props.nextMonthIcon || 'keyboardArrowRight';
      let previousMonthIcon = this.props.previousMonthIcon || 'keyboardArrowLeft';
      let month = this.state.month;
      return (
        <View style={styles.datePicker}>
          <Calendar
            {...this.props}
            day={this.props.date}
            month={month}
            onSelectDay={this.handleSelectDay}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            headerControlsLeft={
              <AnimatedView
                style={styles.monthPreviousButton}
                in={this.hasPrevious}
                enter={{
                  opacity: 1
                }}
                exit={{
                  opacity: .2
                }}
                duration={100}
              >
                <IconButton
                  onPress={this.handlePreviousMonthButtonPress}
                  icon={previousMonthIcon}
                />
              </AnimatedView>
            }
            headerControlsRight={
              <AnimatedView
                style={styles.monthNextButton}
                in={this.hasNext}
                enter={{
                  opacity: 1
                }}
                exit={{
                  opacity: .2
                }}
                duration={100}
              >

                <IconButton
                  onPress={this.handleNextMonthButtonPress}
                  icon={nextMonthIcon}
                />
              </AnimatedView>
            }
          />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  datePicker: {
    position: 'relative',
    flex: 0
  },
  monthNextButton: {
    // position: 'absolute',
    // top: 0,
    // right: 0
  },
  monthPreviousButton: {
    // position: 'absolute',
    // top: 0,
    // left: 0
  },

});
