import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import PopUpMenu from "./PopUpMenu";
import PopUpMenuItem from "./PopUpMenuItem";
import Touchable from "./Touchable";
import View from "./View";
import AnimatedView from "./AnimatedView";
import Icon from "./Icon";
import IconButton from "./IconButton";
import Text from "./Text";
import Surface from "./Surface";
import Checkbox from "./Checkbox";
import Chip from "./Chip";
import ChipGroup from "./ChipGroup";
import TextInput from "./TextInput";
import ActivityIndicator from "./ActivityIndicator";
import { Colors } from "./Theme";

export default class SelectMenu extends React.PureComponent {
  static propTypes = {
    /** The items to be shown in the selection list */
    items: PropTypes.array,
    /** The selected value */
    value: PropTypes.any,
    /** Allow multiple selections using checkboxes  */
    multiple: PropTypes.bool,
    /** Allow for search selections  */
    searchable: PropTypes.bool,
    /** Opens the menu on focus  */
    openOnFocus: PropTypes.bool,
    /** Dynamically load selections, required for searchable  */
    itemLoader: PropTypes.func,
    /** Icon used for control */
    controlIcon: PropTypes.string,
    /** Disable the control animation when opened */
    disableControlAnimation: PropTypes.bool,
  };
  static defaultProps = {
    multiple: false,
    searchable: false,
    disableControlAnimation: false,
    items: [],
  };

  constructor(props) {
    super(props);
    this.MODE_LABEL_VALUE = "LABEL_VALUE";
    this.MODE_VALUE = "VALUE";
    this.ref = React.createRef();
    this.popupMenuRef = React.createRef();
    this.handleSelect = this.handleSelect.bind(this);
    this.itemRenderer = this.itemRenderer.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePressOutside = this.handlePressOutside.bind(this);
    this.handleSearchResetText = this.handleSearchResetText.bind(this);
    this.handleSearchChangeText = this.handleSearchChangeText.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.state = {
      isOpen: false,
      searchText: "",
      activeItemIdx: null,
    };
    this.lastActiveItemIdx = null;
    this.lastSelectedItemIdx = null;
    this.lastSearchText = null;
    this.lastSearchTimeoutText = null;
    this.searchTimeout = null;
    this.openTimeout = null;
    this.autoFocusTimeout = null;
    this.hasInitializedValueLabels = false;
    this.valueLabels = this.props.initialValueLabels || {};
    this.valueMap = {};
    this.loaded = false;
    //this._mode = this.props.useValuesOnly ? this.MODE_VALUE : this.MODE_LABEL_VALUE;
  }
  componentDidMount() {
    if (this.props.autoFocus) this.autoFocus();
    this.initValueLabels();
    this.initSelectedItem();
    // Set initial activeIdx
    if (this.props.items && this.props.value) {
      let value = this.getValue(this.props.value);
    }
  }
  componentDidUpdate(prevProps) {
    this.initSelectedItem();
  }
  componentWillUnmount() {
    this.searchTimeout && clearInterval(this.searchTimeout);
    this.openTimeout && clearInterval(this.openTimeout);
  }
  initSelectedItem() {
    if (this.props.value && this.lastActiveItemIdx === null) {
      let selectedItemIdx = this.getInitialSelectedIdx();
      if (selectedItemIdx !== null) {
        if (this.shouldShowDefaultBlankItem) selectedItemIdx++;
        this.lastActiveItemIdx = selectedItemIdx;
        this.lastSelectedItemIdx = selectedItemIdx;
        this.setState({ activeItemIdx: selectedItemIdx });
      }
    }
  }
  getInitialSelectedIdx() {
    let index = null;
    if (this.props.items && this.props.value) {
      let value = this.getValue(this.props.value);
      index = this.props.items.findIndex((item) => item.value === value);
    }
    return index === -1 ? null : index;
  }
  autoFocus() {
    this.autoFocusTimeout = setTimeout(() => {
      if (!this.ref.current) return;
      console.log(this.ref.current);
      this.ref.current.focus();
      this.autoFocusTimeout = null;
    }, 750);
  }
  focus() {
    !this.state.isOpen &&
      this.ref &&
      this.ref.current &&
      this.ref.current.focus &&
      this.ref.current.focus();
  }
  blur() {
    this.ref &&
      this.ref.current &&
      this.ref.current.blur &&
      this.ref.current.blur();
  }
  handleFocus() {
    if (this.props.openOnFocus && !this.state.isOpen)
      this.openTimeout = setTimeout(() => {
        this.open();
        this.openTimeout = null;
      }, 250);
    this.props.onFocus && this.props.onFocus();
  }
  handleSelect(value, label) {
    let val = {
      value: value,
      label: label,
    };
    // this.lastSelectedItemIdx
    // Pass through all data
    let activeItemIdx = null;
    if (this.props.items && Array.isArray(this.props.items))
      for (let idx in this.props.items) {
        const item = this.props.items[idx];
        if (
          typeof item === "object" &&
          "value" in item &&
          "label" in item &&
          item.value === value
        ) {
          activeItemIdx = parseInt(idx);
          val = item;
          break;
        }
      }

    if (activeItemIdx !== null && this.shouldShowDefaultBlankItem) {
      activeItemIdx++;
    }

    this.lastActiveItemIdx = activeItemIdx;
    this.lastSelectedItemIdx = activeItemIdx;
    if (this.state.activeItemIdx !== activeItemIdx)
      this.setState({ activeItemIdx: activeItemIdx });

    if (this.props.onValueChange)
      return this.props.onValueChange(val, this.mode);
  }
  handleRemove(value) {
    let val = {
      value: value,
      label: null,
    };
    if (this.props.onValueChange)
      return this.props.onValueChange(val, this.mode);
  }
  handleOpen() {
    if (this.props.onOpen) this.props.onOpen();
    this.setState({ isOpen: true });
  }
  handlePressOutside() {
    // Only keep active idx if no multiple
    let activeItemIdx = this.lastActiveItemIdx;

    let isDefaultBlankItem = false;
    if (this.shouldShowDefaultBlankItem) {
      if (activeItemIdx === 0) {
        isDefaultBlankItem = true;
        this.lastSelectedItemIdx = null;
        this.handleSelect(null, null);
      } else activeItemIdx--;
    }
    // In the case of arrowDown / arrowUp and a click out
    if (
      !isDefaultBlankItem &&
      activeItemIdx !== null &&
      activeItemIdx !== -1 &&
      this.lastSelectedItemIdx !== activeItemIdx
    ) {
      this.lastSelectedItemIdx = activeItemIdx;
      this.handleSelect(
        this.props.items[activeItemIdx].value,
        this.props.items[activeItemIdx].label
      );
    }
  }
  handleClose() {
    if (this.props.onClose) this.props.onClose();

    // Only keep active idx if no multiple
    let activeItemIdx = this.lastActiveItemIdx;
    this.setState({ isOpen: false, activeItemIdx: activeItemIdx });
  }
  handleKeyDown(e) {
    if (this.props.disabled) return false;
    if (this.props.handleKeyDown) this.props.handleKeyDown(e);
    if (!e.nativeEvent || !e.nativeEvent.key) return false;
    let activeItemIdx = this.state.activeItemIdx;
    let isOpen = this.state.isOpen;
    let totalItems = this.props.items ? this.props.items.length : 0;
    this.shouldShowDefaultBlankItem && totalItems++;

    let shouldPreventDefault = true;
    let shouldSelect = !this.props.multiple;
    switch (e.nativeEvent.key) {
      case "ArrowDown":
        if (totalItems > 0) {
          if (this.props.multiple) isOpen = true;
          if (activeItemIdx === null) activeItemIdx = 0;
          else if (activeItemIdx < totalItems - 1) activeItemIdx++;
          shouldSelect = !isOpen;
        }
        break;
      case "ArrowUp":
        if (totalItems > 0) {
          if (this.props.multiple) isOpen = true;
          if (activeItemIdx !== null && activeItemIdx > 0) activeItemIdx--;
          else if (activeItemIdx !== 0) activeItemIdx = null;
          shouldSelect = !isOpen;
        }
        break;
      case "Enter":
        if (this.props.multiple) {
          if (!this.state.isOpen) isOpen = true;
          else {
            shouldSelect = true;
          }
        } else isOpen = !this.state.isOpen;
        break;
      case "Tab":
        shouldPreventDefault = false;
        if (this.state.isOpen) isOpen = false;
        break;
      default:
        shouldPreventDefault = false;
        break;
    }

    if (activeItemIdx !== this.state.activeItemIdx) {
      this.setState({
        activeItemIdx,
      });
      this.lastActiveItemIdx = activeItemIdx;
    }

    if (shouldSelect && activeItemIdx !== null) {
      let isDefaultBlankItem = false;
      if (this.shouldShowDefaultBlankItem) {
        if (activeItemIdx === 0) {
          isDefaultBlankItem = true;
          this.lastSelectedItemIdx = null;
          this.handleSelect(null, null);
        } else activeItemIdx--;
      }
      if (!isDefaultBlankItem) {
        this.lastSelectedItemIdx = activeItemIdx;
        this.handleSelect(
          this.props.items[activeItemIdx].value,
          this.props.items[activeItemIdx].label
        );
      }
    }
    if (isOpen !== this.state.isOpen) isOpen ? this.open() : this.close();
    if (shouldPreventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  initValueLabels() {
    if (this.props.value && !this.hasInitializedValueLabels) {
      let valueLabels = {};
      let values = this.props.value || [];

      if (!Array.isArray(values)) values = [values];

      // Make sure the given values are correct
      if (values.length && typeof values[0] === "object") {
        if (this.mode === this.MODE_VALUE)
          throw new Error(
            "SelectMenu Value Error: Mode set to useValuesOnly, but given object."
          );
        if (!("label" in values[0]) || !("value" in values[0]))
          throw new Error(
            "SelectMenu Value Error: Value must contain value / label"
          );
      } else {
        switch (this.mode) {
          case this.MODE_LABEL_VALUE:
            if (values.length)
              throw new Error(
                "SelectMenu Value Error: Mode not set to useValuesOnly, but given simple values."
              );
            break;
          default:
            for (let i in this.props.items) {
              // if (values.indexOf(this.props.items[i].value) !== -1) {
              valueLabels[this.props.items[i].value] =
                this.props.items[i].label;
              //}
            }
            for (let i in values) {
              if (this.valueLabels[values[i]])
                valueLabels[values[i]] = this.valueLabels[values[i]];
            }
            this.valueLabels = valueLabels;
        }
      }

      this.hasInitializedValueLabels = true;
    }
  }
  getValueLabel(value) {
    let ret = null;
    if (this.mode === this.MODE_LABEL_VALUE) {
      ret = value.label;
    } else {
      for (let item of this.props.items) {
        if (item.value !== value) continue;
        ret = item.label;
        break;
      }
      if (!ret) ret = this.valueLabels[value] ? this.valueLabels[value] : value;
    }
    return ret;
  }
  getValue(value) {
    return this.mode === this.MODE_LABEL_VALUE ? value.value : value;
  }
  get hasSearchText() {
    return this.state.searchText && this.state.searchText !== "";
  }
  get hasItems() {
    return this.props.items && this.props.items.length ? true : false;
  }
  get mode() {
    return this.props.useValuesOnly ? this.MODE_VALUE : this.MODE_LABEL_VALUE;
  }
  // set mode(mode){
  //   this._mode = mode;
  // }
  handleSearchChangeText(text) {
    /** @todo Move to SearchTextInput */
    if (this.props.onSearchChangeText && text !== this.lastSearchTimeoutText) {
      // Throttle the change event for searching
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.lastSearchTimeoutText = text;
      this.searchTimeout = setTimeout(() => {
        if (this.props.onSearchChangeText && this.lastSearchText !== text) {
          this.lastSearchText = text;
          this.props.onSearchChangeText(text, (items) => {
            // console.log("CHECK ITEMS?", items);
          });
        }
        this.searchTimeout = null;
      }, 350);
    }
    this.setState({ searchText: text });
    //if(this.props.onSearchChangeText) this.props.onSearchChangeText();
  }
  // async callItemLoader(){
  //   if(this.props.itemLoader) await this.props.itemLoader({
  //     search: this.searchText
  //   });
  // }
  handleSearchResetText() {
    this.handleSearchChangeText("");
  }
  isSelectedValue(value, test = null) {
    if (this.props.multiple) {
      if (!this.props.value) return false;
      switch (this.mode) {
        case this.MODE_LABEL_VALUE:
          if (!this.props.value.length) return false;
          for (let val of this.props.value) {
            if (val.value === value) return true;
          }
          break;
        default:
          return this.props.value.indexOf(value) !== -1;
      }
    } else {
      switch (this.mode) {
        case this.MODE_LABEL_VALUE:
          return this.props.value && this.props.value.value === value;
          break;
        default:
          return this.props.value === value;
      }
    }
    return false;
  }
  open() {
    this.popupMenuRef.current && this.popupMenuRef.current.open();
  }
  close() {
    this.popupMenuRef.current && this.popupMenuRef.current.close();
  }
  reset(options = {}) {
    this.popupMenuRef.current &&
      this.popupMenuRef.current.reset &&
      this.popupMenuRef.current.reset(options);
  }

  itemRenderer(item, index) {
    let ret = null;
    switch (item.__selectMenuItemType) {
      case "noResults":
        ret = (
          <PopUpMenuItem
            labelTextStyle={styles.searchHelperLabelText}
            label='No Results Found'
            key={"noResults"}
          />
        );
        break;
      case "search":
        ret = (
          <PopUpMenuItem
            labelTextStyle={styles.searchHelperLabelText}
            label='Type in a search value...'
            key={"noResults"}
          />
        );
        break;
      case "noValue":
        ret = (
          <PopUpMenuItem
            labelTextStyle={styles.searchHelperLabelText}
            key='noValue'
            label={`No${this.props.label ? ` ${this.props.label}` : " Value"}`}
            value={null}
          />
        );
        break;
      default:
        const isSelectedValue = this.isSelectedValue(item.value);
        let style = null;
        const menuItemStyles = [styles.menuItem];
        let active = this.state.activeItemIdx === index;
        let selected = this.isSelectedValue(item.value);
        if (selected && active)
          menuItemStyles.push(styles.menuItemActiveSelected);
        else if (selected) menuItemStyles.push(styles.menuItemSelected);
        else if (active) menuItemStyles.push(styles.menuItemActive);
        ret = (
          <PopUpMenuItem
            icon={item.icon}
            iconSize='small'
            label={item.label}
            value={item.value}
            controlsLeft={
              this.props.multiple && (
                <Checkbox
                  value={isSelectedValue}
                  onPress={() => this.handleSelect(item.value, item.label)}
                />
              )
            }
            labelTextEllipsisMode='tail'
            labelTextNumberOfLines={1}
            key={item.value || "default"}
            style={menuItemStyles}
          />
        );
    }
    return ret;
  }
  createItems() {
    let menuItems = [];
    !this.props.loading &&
      !this.hasItems &&
      this.hasSearchText &&
      menuItems.push({
        __selectMenuItemType: "noResults",
      });
    this.props.searchable &&
      !this.hasItems &&
      !this.hasSearchText &&
      menuItems.push({
        __selectMenuItemType: "search",
      });
    let itemIdx = 0;
    this.hasItems &&
      !this.props.multiple &&
      this.props.showDefaultBlankItem &&
      menuItems.push({
        __selectMenuItemType: "noValue",
      });
    this.hasItems && menuItems.push(...this.props.items);
    return menuItems;
  }
  renderItems() {
    let menuItems = [];
    // false &&
    //   this.props.searchable &&
    //   menuItems.push(
    //     <Surface key='search' elevation='2' style={styles.searchTextInputField}>
    //       <AnimatedView
    //         enter={{
    //           opacity: 1,
    //         }}
    //         exit={{
    //           opacity: 0.5,
    //         }}
    //         in={this.hasSearchText}
    //         duration={100}
    //         style={styles.searchText}
    //       >
    //         <TextInput
    //           name='search'
    //           autoComplete={false}
    //           value={this.state.searchText}
    //           onChangeText={this.handleSearchChangeText}
    //           style={styles.searchTextInput}
    //           autoFocus
    //           placeholder={`Search${
    //             this.props.label ? ` ${this.props.label}` : ""
    //           }`}
    //           // onBlur={this.handleBlur}
    //         />
    //       </AnimatedView>
    //       {this.props.loading && (
    //         <ActivityIndicator
    //           size='small'
    //           containerStyle={styles.searchActivityIndicator}
    //         />
    //       )}
    //       <AnimatedView
    //         enter={{
    //           opacity: 0.5,
    //         }}
    //         exit={{
    //           opacity: 0,
    //         }}
    //         in={this.hasSearchText}
    //         duration={250}
    //         unmountOnExit
    //       >
    //         <IconButton icon='close' onPress={this.handleSearchResetText} />
    //       </AnimatedView>
    //     </Surface>
    //   );
    !this.props.loading &&
      !this.hasItems &&
      this.hasSearchText &&
      menuItems.push(
        <PopUpMenuItem
          labelTextStyle={styles.searchHelperLabelText}
          label='No Results Found'
          key={"noResults"}
        />
      );
    this.props.searchable &&
      !this.hasItems &&
      !this.hasSearchText &&
      menuItems.push(
        <PopUpMenuItem
          labelTextStyle={styles.searchHelperLabelText}
          label='Type in a search value...'
          key={"noResults"}
        />
      );
    let itemIdx = 0;
    if (this.shouldShowDefaultBlankItem) {
      menuItems.push(
        <PopUpMenuItem
          labelTextStyle={styles.searchHelperLabelText}
          key='noValue'
          label={`No${this.props.label ? ` ${this.props.label}` : " Value"}`}
          value={null}
        />
      );
      itemIdx++;
    }

    this.hasItems &&
      this.props.items.forEach((item, index) => {
        let menuItemStyles = [styles.menuItem];
        let active = this.state.activeItemIdx === itemIdx;
        let selected = this.isSelectedValue(item.value);
        if (selected && active)
          menuItemStyles.push(styles.menuItemActiveSelected);
        else if (selected) menuItemStyles.push(styles.menuItemSelected);
        else if (active) menuItemStyles.push(styles.menuItemActive);
        menuItems.push(
          <PopUpMenuItem
            icon={item.icon}
            iconSize='small'
            label={item.label}
            value={item.value}
            controlsLeft={
              this.props.multiple && (
                <Checkbox
                  value={this.isSelectedValue(item.value)}
                  onPress={() =>
                    this.handleSelect(item.value, item.label, parseInt(index))
                  }
                />
              )
            }
            key={item.value || "default"}
            labelTextEllipsisMode='tail'
            labelTextNumberOfLines={1}
            style={menuItemStyles}
          />
        );
        itemIdx++;
      });
    return menuItems;
  }
  renderChips() {
    this.initValueLabels();
    // if (!value) return null;
    let label = null;
    if (this.props.multiple) {
      let labels = [];
      let values = this.props.value || [];
      for (let i in values) {
        let label = this.getValueLabel(values[i]);
        let value = this.getValue(values[i]);
        if (!label) continue;
        labels.push(
          <Chip
            key={value}
            label={label}
            style={styles.selectValueChip}
            iconRight='close'
            onPress={() => {
              this.handleRemove(value);
            }}
          />
        );
      }
      if (labels.length)
        label = (
          <ChipGroup key='selections' style={styles.selectValueChipGroup}>
            {labels}
          </ChipGroup>
        );
    }
    return label;
  }
  renderSearchInput() {
    if (!this.props.searchable) return null;
    return (
      <Surface key='search' elevation={2} style={styles.searchTextInputField}>
        <AnimatedView
          enter={{
            opacity: 1,
          }}
          exit={{
            opacity: 0.5,
          }}
          in={this.hasSearchText}
          duration={100}
          style={styles.searchText}
        >
          <TextInput
            name='search'
            autoComplete={false}
            value={this.state.searchText}
            onChangeText={this.handleSearchChangeText}
            style={styles.searchTextInput}
            autoFocus
            // onKeyDown={this.handleKeyDown}
            placeholder={`Search${
              this.props.label ? ` ${this.props.label}` : ""
            }`}
            //onBlur={this.handleBlur}
          />
        </AnimatedView>
        {this.props.loading && (
          <ActivityIndicator
            size='small'
            containerStyle={styles.searchActivityIndicator}
          />
        )}
        <AnimatedView
          enter={{
            opacity: 0.5,
          }}
          exit={{
            opacity: 0,
          }}
          in={this.hasSearchText}
          duration={250}
          unmountOnExit
        >
          <IconButton icon='close' onPress={this.handleSearchResetText} />
        </AnimatedView>
      </Surface>
    );
  }
  get shouldShowDefaultBlankItem() {
    return (
      this.hasItems && !this.props.multiple && this.props.showDefaultBlankItem
    );
  }
  calculateScrollToItem() {
    if (!this.state.isOpen) return null;
    let value = null;
    let activeItemIdx = this.state.activeItemIdx || 0;

    let isDefaultBlankItem = false;
    if (this.shouldShowDefaultBlankItem) {
      if (activeItemIdx === 0) isDefaultBlankItem = true;
      else activeItemIdx--;
    }
    if (!isDefaultBlankItem && this.props.items[activeItemIdx])
      value = this.getValue(this.props.items[activeItemIdx].value);
    //else value = this.props.value ? this.getValue(this.props.value) : null;
    return value;
  }
  renderLabel() {
    this.initValueLabels();
    let ret = null;
    if (!this.props.multiple) {
      let value = this.props.value || "";
      let valueLabel = this.getValueLabel(value);
      let label = this.props.labelRenderer ? (
        this.props.labelRenderer(value)
      ) : (
        <Text style={this.props.valueLabelTextStyle}>{valueLabel}</Text>
      );
      ret = (
        <View style={styles.selectValueLabel} pointerEvents='none'>
          {/* {this.props.controlsLeft && 
            <View style={styles.controlsLeft}>{this.props.controlsLeft}</View>
          } */}
          {this.props.icon && <Icon name={this.props.icon} />}
          {label}
        </View>
      );
    }
    return ret;
  }
  render() {
    let menuItems = this.props.items;
    if (
      this.props.multiple &&
      this.props.value &&
      !Array.isArray(this.props.value)
    )
      throw new Error(
        "SelectMenu Error: SelectMenu with option multiple must have an array of values as it's value."
      );
    return (
      <View
        style={[styles.selectMenu, this.props.style]}
        tabIndex={this.props.tabIndex || 0}
        onFocus={this.handleFocus}
        onBlur={this.props.onBlur}
        onKeyDown={this.handleKeyDown}
        htmlRef={this.ref}
      >
        <PopUpMenu
          controlWidthCover
          disabled={this.props.disabled}
          style={[
            styles.popUpMenu,
            this.props.popupMenuStyle,
            this.props.popUpMenuStyle,
          ]}
          HeaderComponent={this.renderSearchInput()}
          FooterComponent={this.props.FooterComponent}
          ref={this.popupMenuRef}
          Control={
            <Touchable>
              <View style={[styles.selectInput, this.props.inputStyle]}>
                {this.renderLabel()}
                <View
                  key='control'
                  style={[styles.iconContainer, this.props.iconStyle]}
                >
                  {this.props.multiple ? (
                    <Icon
                      style={styles.addIcon}
                      name={this.props.controlIcon || "add"}
                      color={Colors.onSecondary}
                    />
                  ) : (
                    <AnimatedView
                      enter={{
                        transform: [{ rotate: "-180deg" }],
                      }}
                      exit={{
                        transform: [{ rotate: "0deg" }],
                      }}
                      in={
                        !this.props.disableControlAnimation && this.state.isOpen
                      }
                      duration={100}
                    >
                      <Icon
                        style={styles.icon}
                        name={this.props.controlIcon || "menuDown"}
                        color={
                          this.props.iconColor ||
                          StyleSheet.color(Colors.onBackground).rgba(0.6)
                        }
                      />
                    </AnimatedView>
                  )}
                </View>
              </View>
            </Touchable>
          }
          key='userMenu'
          direction='down'
          scrollToItem={this.calculateScrollToItem()}
          items={this.createItems()}
          itemRenderer={this.itemRenderer}
          loading={this.props.loading}
          onSelect={this.handleSelect}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          onPressOutside={this.handlePressOutside}
        >
          {/* {this.renderItems()} */}
        </PopUpMenu>
        {this.renderChips()}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  iconContainer: {
    ...StyleSheet.margin(0, 0, 0, "auto"),
    // alignSelf: 'flex-end',
  },
  addIcon: {
    backgroundColor: Colors.secondary,
    borderRadius: 18,
    elevation: 3,
    ...StyleSheet.padding(4),
    height: 32,
    width: 32,
    // marginRight: -4
  },
  popUpMenu: {
    ...StyleSheet.margin(2, -6, 0, -2),
  },
  selectValueLabel: {
    position: "relative",
    ...StyleSheet.margin(0, 8, 0, 0),
  },
  selectValueChipGroup: {
    // flex: 1,
    // flexShrink: 1,
    // flexGrow: 0
    // flex: 0,
    // flexGrow: 0,
    // flexWrap: 'nowrap'
    position: "relative",
    zIndex: 2,
    ...StyleSheet.margin(-42, 52, 2, 8),
  },
  selectValueChip: {
    // flexShrink: 1,
    // flexGrow: 0
    // flex: 0
    // ...StyleSheet.margin(0, 0, -4, -4)
  },
  selectMenu: {
    // position: "relative",
    // flex: 1,
    // flexDirection: "row",
    // justifyContent: "space-between",
    // alignItems: "center",
    // margin: 0,
    // ...StyleSheet.padding(14, 32, 14, 12),
    // width: "100%",
    // height: 48,
    // backgroundColor: "transparent",
    // color: Colors.onBackground,
    // lineHeight: 20,
    // fontSize: 16
  },
  selectInput: {
    position: "relative",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    ...StyleSheet.padding(10, 8, 10, 10),
    width: "100%",
    height: "auto",
    minHeight: 52,
    backgroundColor: "transparent",
    color: Colors.onBackground,
    lineHeight: 20,
    fontSize: 16,
  },
  searchTextInputField: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    ...StyleSheet.padding(4, 0, 4, 16),
    marginBottom: 8,
    height: 48,
    backgroundColor: Colors.background,
  },
  searchText: {
    width: "100%",
  },
  searchActivityIndicator: {
    ...StyleSheet.padding(8),
    ...StyleSheet.margin(0, 4, 0, 4),
  },
  searchTextInput: {
    backgroundColor: "transparent",
    color: Colors.onBackground,
    fontSize: 16,
    width: "100%",
  },
  searchHelperLabelText: {
    opacity: 0.5,
  },
  menuItem: {
    overflow: "hidden",
  },
  menuItemSelected: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.1),
  },
  menuItemActive: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.06),
  },
  menuItemActiveSelected: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.2),
  },
});
