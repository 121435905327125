import React from "react";
import R14, {
  Button,
  View,
  Text,
  Image,
  Colors,
  StyleSheet,
  IconButton,
  SectionHeader,
  ControlsView,
  Chip,
  CenteredForm,
} from "../core";
import CharactersReviewView from "../components/CharactersReviewView";
import FieldCharactersReviewView from "../components/FieldCharactersReviewView";
import CountdownTimer from "../components/CountdownTimer";
import FloatingLabel from "../components/FloatingLabel";
import ManualEntrySideSheet from "../components/ManualEntrySideSheet";

export default R14.connect(
  class DataEntryReviewScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleEditPress = this.handleEditPress.bind(this);
      this.handleSavePress = this.handleSavePress.bind(this);
      this.handleSaveLogoutPress = this.handleSaveLogoutPress.bind(this);
      this.handleKeyMapperAction = this.handleKeyMapperAction.bind(this);
      this.handleEditPress = this.handleEditPress.bind(this);
      this.handleTimeout = this.handleTimeout.bind(this);
      this.manualEntryDomain = this.props.app.dm.manualEntry;
      this.keyMapper = this.props.app.ui.keyMapper.create("manualEntryReview", {
        repeat: false,
      });
      this._saving = false;
      this.init();
    }
    componentWillUnmount() {
      this.keyMapper && this.keyMapper.disconnect();
    }
    init() {
      this.initKeyMapper();
    }
    initKeyMapper() {
      this.keyMapper
        // .onKeyDown(async ({ key }) =>
        //   this.manualEntryDomain.registerKeystroke(key)
        // )
        .addAction("save", ["Enter"], this.handleKeyMapperAction, {
          label: "Save",
        })
        .addAction("saveLogout", ["CtrlEnter"], this.handleKeyMapperAction, {
          label: "Save & Logout",
        });
    }
    async handleKeyMapperAction({ e, actionName }) {
      e.preventDefault();
      switch (actionName) {
        case "save":
          await this.handleSavePress();
          break;
        case "saveLogout":
          await this.handleSaveLogoutPress();
          break;
      }
    }
    async handleEditPress(idx) {
      this.props.app.nav.to("manualEntry", { queueItemNum: idx + 1 });
    }
    async handleSavePress() {
      await this.save();
    }
    async handleSaveLogoutPress() {
      await this.save({ logout: true });
    }
    async handleTimeout() {
      if (this.props.app.dm.manualEntry.disableTimeouts) return;
      this.props.app.dm.manualEntry.timeout();
      this.props.app.nav.to("manualEntryTimeout");
    }
    renderFieldContent(item) {
      let ret = null;
      switch (item.mode) {
        case this.manualEntryDomain.MODE_CHARACTERS:
          ret = <CharactersReviewView characters={item.annotations} />;
          break;
        case this.manualEntryDomain.MODE_FIELD_CHARACTERS:
          ret = <FieldCharactersReviewView item={item} />;
          break;
        case this.manualEntryDomain.MODE_FIELD:
          let field = this.props.app.dm.manualEntry
            .filterAnnotations(item.annotations, {
              type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD,
            })
            .at(0);
          let fieldValue = field
            ? field.updatedValue !== null
              ? field.updatedValue
              : field.value || null
            : null;
          ret = (
            <View style={styles.itemRow}>
              <View style={styles.itemImageContainer}>
                {field && field.imageBase64 && (
                  <Image
                    resizeMode='contain'
                    height={64}
                    width={"100%"}
                    style={styles.itemImage}
                    source={`data:image/png;base64,${field.imageBase64}`}
                  />
                )}
              </View>
              <View style={styles.itemValueContainer}>
                <Text style={styles.itemValueText}>
                  {fieldValue}
                </Text>
              </View>
            </View>
          );
          break;
        default:
          throw new Error("Unknown edit mode.");
      }
      return ret;
    }
    async save(options = {}) {
      if (this._saving) {
        return false;
      }
      this._saving = true;
      if (
        options.logout &&
        !window.confirm("Are you sure you want to save and logout?")
      )
        return false;
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.manualEntry.saveQueue();
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this.props.app.ui.snackBar.show({
        message: "Your updates have been submitted.",
        variant: "success",
      });
      this.props.app.nav.to(options.logout ? "logout" : "manualEntryLoad");
      this._saving = false;
    }
    renderItems() {
      let items = [];
      let queue = this.props.app.dm.manualEntry.queue;
      queue.forEach((item, idx) => {
        let itemStyles = [styles.item];
        let itemIdxIndicatorStyles = [styles.itemIdxIndicator];
        if (item.reject) {
          itemStyles.push(styles.itemRejected);
          itemIdxIndicatorStyles.push(styles.itemIdxIndicatorRejected);
        }
        items.push(
          <View style={itemStyles} key={`snippet-${idx + 1}`}>
            <View style={styles.itemTitle}>
              <View style={itemIdxIndicatorStyles}>
                <Text style={styles.itemIdxIndicatorText}>{idx + 1}</Text>
              </View>
              <SectionHeader
                style={styles.itemTitleText}
                level={3}
                noWrap={false}
              >
                {this.props.app.dm.manualEntry.getLabel(item, idx)}
              </SectionHeader>

              <ControlsView align='right'>
                {item.reject && (
                  <Chip
                    key='rejected'
                    label={"Rejected"}
                    style={styles.itemTitleChip}
                  />
                )}
                <IconButton
                  icon='edit'
                  key='edit'
                  size='small'
                  onPress={() => {
                    this.handleEditPress(idx);
                  }}
                />
              </ControlsView>
            </View>
            {this.renderFieldContent(item)}
          </View>
        );
      });
      return items;
    }
    render() {
      return (
        <CenteredForm
          name='manualEntryForm'
          title='Review &amp; Save'
          validateBeforeSubmit
          style={styles.centeredForm}
          onSubmit={this.handleSubmit}
          SideSheetComponent={
            <ManualEntrySideSheet keyMapper={this.keyMapper} />
          }
          titleControlsRight={
            this.props.app.dm.manualEntry.timeoutAt
              ? [
                  <CountdownTimer
                    key='timer'
                    completeAt={this.props.app.dm.manualEntry.timeoutAt}
                    onComplete={this.handleTimeout}
                    style={styles.countdownTimer}
                  />,
                ]
              : null
          }
          controlsBottomRight={[
            <FloatingLabel
              label={this.keyMapper.getActionKeyLabels("saveLogout")[0]}
              visible={this.props.app.dm.manualEntry.keyOverlayMode}
              position='bottom'
              key='saveLogout'
            >
              <Button
                title='Save &amp; Logout'
                // tooltip={`Save &amp; Exit [${this.getKeyByMapping("reject")}]`}

                variant='text'
                style={styles.buttonRowButton}
                // color={Colors.secondary}
                onPress={this.handleSaveLogoutPress}
              />
            </FloatingLabel>,
            <FloatingLabel
              label={this.keyMapper.getActionKeyLabels("save")[0]}
              visible={this.props.app.dm.manualEntry.keyOverlayMode}
              position='bottom'
              key='save'
            >
              <Button
                title='Save'
                // tooltip={`Save &amp; Exit [${this.getKeyByMapping("reject")}]`}
                key='Save'
                style={styles.buttonRowButton}
                onPress={this.handleSavePress}
              />
            </FloatingLabel>,
          ]}
          // FooterComponent={this.renderPageIndicators()}
        >
          <View style={styles.items}>{this.renderItems()}</View>
        </CenteredForm>
      );
      //return <View>{this.renderItems()}</View>;
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 640,
    ...StyleSheet.padding(0),
  },
  countdownTimer: {
    marginRight: 8,
  },
  items: {
    flex: 1,
    flexDirection: "column",
    paddingBottom: 20,
  },
  item: {
    borderRadius: 4,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.03),
    ...StyleSheet.padding(8),
    ...StyleSheet.margin(0, -8, 16, -8),
  },
  itemRejected: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.1),
  },
  itemIdxIndicator: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: 20,
    width: 20,
    borderRadius: 10,
    marginRight: 8,
    overflow: "hidden",
    backgroundColor: StyleSheet.color(Colors.primaryDark).rgba(0.2),
    //elevation: 1,
  },
  itemIdxIndicatorRejected: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
  itemIdxIndicatorText: {
    flex: 0,
    fontSize: 12,
    fontWeight: 500,
  },
  itemTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    // marginRight: -12,
    paddingBottom: 8,
  },
  itemTitleChip: {
    ...StyleSheet.margin(0),
  },
  itemTitleText: {
    fontSize: 16,
    fontWeight: 400,
    paddingBottom: 0,
    lineHeight: 36,
    marginRight: "auto",
  },
  itemRow: {
    flex: 1,
    flexDirection: "row",
  },
  itemImageContainer: {
    flex: 1,
    height: 64,
    width: "50%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overFlow: "hidden",
    marginRight: 4,
    // backgroundColor: Colors.surface,
  },
  itemImage: {
    backgroundPosition: "center",
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.7),
    //backgroundColor: StyleSheet.color("#FFFFFF").rgba(0.7),
  },
  itemValueContainer: {
    flex: 1,
    minHeight: 64,
    width: "50%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overFlow: "hidden",
    marginLeft: 4,
    ...StyleSheet.padding(8),
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.7),
    //backgroundColor: StyleSheet.color("#FFFFFF").rgba(0.9),
  },
  itemName: {
    paddingBottom: 4,
  },
  itemNameText: {
    fontSize: 12,
  },
  itemValueText: {
    fontFamily: "monospace",
    // color: "#010101",
  },
});
