import React from "react";
import PropTypes from "prop-types";
import R14, {
  TextInput,
  StyleSheet,
  Colors,
  AnimatedView,
  View,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Text,
} from "../core";
// import CharacterImage from "./CharacterImage";
// import CharacterCompareView from "./CharacterCompareView";
// import FloatingLabel from "../components/FloatingLabel";
import FieldCharactersEntryField from "./FieldCharactersEntryField";
import CharacterEntryField from "./CharacterEntryField";
import FloatingLabel from "./FloatingLabel";

export default R14.connectForm(
  /**
   * A hidden form field field component
   */
  class CharactersEntryField extends React.Component {
    static propTypes = {
      /** Name for the character entry fields. */
      name: PropTypes.string.isRequired,
      /** Value of the character entry fields. */
      value: PropTypes.any,
      /** KeyMapper instance from screen. */
      //keyMapper: PropTypes.instanceOf("KeyMapper").isRequired,
    };
    constructor(props) {
      super(props);
      this.handleKeyMapperAction = this.handleKeyMapperAction.bind(this);
      this.valueParser = this.valueParser.bind(this);
      let elmt = this.props.form.addElement(props);
      this.charactersEntryField = this.props.app.ui.charactersEntryField.create(
        this.props.item,
        this.props.form,
        elmt,
        {
          mode: this.props.mode,
        }
      );
      this.elmt = elmt;
      this.state = {
        focusedChars: [],
        editCharUid: null,
        //focusUid: this.elmt.value[0].uid,
      };
      //this.charRefs = {};
      // this.elmt.value.forEach(
      //   (char) => (this.charRefs[char.uid] = React.createRef())
      // );
      // this.firstUid = this.elmt.value.length ? this.elmt.value[0].uid : null;
      // this.currUid = this.elmt.value.length ? this.elmt.value[0].uid : null;
      this.keyMappings = {
        ArrowUp: "reject",
        ArrowDown: "edit",
      };
      this.freeEditEnabled = false;
      this.initKeyMapper();
    }
    initKeyMapper() {
      let keyMapper = this.props.keyMapper;
      keyMapper
        .onKeyDown(async ({ key, e }) => this.handleKeyDown(key, e))
        .addAction("rejectCharacter", ["ArrowUp"], this.handleKeyMapperAction, {
          label: "Reject Character",
          repeat: false,
        })
        .addAction(
          "previousCharacter",
          ["ArrowLeft", "ShiftTab"],
          this.handleKeyMapperAction,
          { label: "Previous Character", repeat: false }
        )
        .addAction(
          "nextCharacter",
          ["ArrowRight", "Tab"],
          this.handleKeyMapperAction,
          { label: "Next Character", repeat: false }
        );
      if (this.freeEditEnabled)
        keyMapper.addAction(
          "freeEditCharacter",
          ["ArrowDown"],
          this.handleKeyMapperAction,
          { label: "Free Edit Character" }
        );

      this.elmt.value.forEach((char, idx) => {
        let charNum = idx + 1;
        if (idx > 12) return;
        keyMapper.addAction(
          `gotoChar${char}`,
          [`F${charNum}`],
          this.handleKeyMapperAction,
          { label: `Character ${charNum}` }
        );
      });
    }
    get keyMapper() {
      return this.props.keyMapper;
    }
    async handleKeyMapperAction({ e, key, actionName }) {
      e.preventDefault();
      let uid = this.charactersEntryField.characters.focusUid;
      switch (actionName) {
        case "rejectCharacter":
          this.handleToggleRejectPress();
          break;
        case "freeEditCharacter":
          // throw new Error("DISABLED");
          // this.freeEditEnabled && uid && this.handleToggleEditPress(uid);
          break;
        case "nextCharacter":
          this.selectNext();
          break;
        case "previousCharacter":
          this.selectPrevious();
          break;
        default:
          if (key.startsWith("F") && [2, 3].includes(key.length)) {
            // Check if should skip to char
            let idx = 0;
            for (let char of this.elmt.value) {
              idx++;
              if (key === `F${idx}`) {
                this.select(char.uuid);
              }
            }
          }
      }
    }
    handleKeyDown(key, e) {
      if (this.props.keyMapper.isActionKey(key)) return;

      let character = this.charactersEntryField.characters.getCurrentInstance();
      if (!character) return;
      // let uid = this.state.focusUid;
      // if (!uid) return false;
      // if (this.focusedChars.includes(uid)) return false;
      // if (!this.charRefs[uid].current) return false;
      switch (key) {
        case character.value:
          e.preventDefault();
          this.selectNext();
          break;
        case "ArrowDown":
          e.preventDefault();
          break;
      }
    }
    get focusedChars() {
      return this.state.focusedChars;
    }
    handleChangeText(character, value) {
      if (this.props.upperCase) value = value.toUpperCase();
      character.updateValue(value);
      this.selectNext();
      // let characters = this.elmt.value.map((char) => {
      //   if (uid === char.uid) {
      //     let updatedValue = value;
      //     if (this.state.editCharUid !== uid) {
      //       if (this.props.upperCase) updatedValue = value.toUpperCase();
      //       if (updatedValue.length > 1) updatedValue = updatedValue[updatedValue.length - 1];
      //     }
      //     console.log("SETTING CHARACTER VALUE", updatedValue);
      //     char.updatedValue = updatedValue;
      //   }
      //   return char;
      // });
      // this.elmt.setValue(characters);
      // this.state.editCharUid !== uid && this.selectNext(uid);
    }
    handleToggleRejectPress() {
      this.charactersEntryField.characters.toggleRejectCurrent();
      this.selectNext();
    }
    handleToggleEditPress(uid) {
      let isCurrEditCharUid = this.state.editCharUid === uid;
      this.setState({
        editCharUid: isCurrEditCharUid ? null : uid,
      });
      !isCurrEditCharUid && this.focus(uid);
      isCurrEditCharUid && this.selectNext(uid);
    }
    handleFocus(uid) {
      // this.focus(uid);
    }
    handleBlur(uid) {
      // let nState = {};
      // if (!this.focusedChars.includes(uid)) {
      //   let focusedChars = this.focusedChars;
      //   focusedChars.push(uid);
      //   nState.focusedChars = focusedChars;
      //   if (this.props.app.dm.manualEntry.focusedCharacterUid === uid)
      //     nState.focusedCharacterUid = null;
      // }
      // if (this.state.editCharUid) nState.editCharUid = null;
      // this.props.app.dm.manualEntry.setState(nState);
    }
    focus(uid) {
      // if (this.state.focusUid === uid) return;
      // console.log("TODO, this is causing multiple renders multiple times.");
      // //this.setState({ focusedCharacterUid: uid });
      // this.props.app.dm.manualEntry.setState({ focusedCharacterUid: uid });
      // if (this.charRefs[uid].current) {
      //   this.charRefs[uid].current.focus();
      //   this.charRefs[uid].current.select();
      // }
    }
    getValue(uuid) {
      let value = null;
      for (let char of this.elmt.value) {
        if (uuid === char.uuid) {
          value = this.parseValue(char);
          break;
        }
      }
      return value;
    }
    parseValue(char) {
      return char.updatedValue !== null && char.updatedValue !== undefined
        ? char.updatedValue
        : char.value;
    }
    valueParser(char) {
      return this.parseValue(char);
    }
    hasChanged(char) {
      return this.parseValue(char) !== char.value;
    }
    hasFocused(char) {
      return this.focusedChars.includes(char.uuid);
    }
    hasFocus(char) {
      return this.state.focusUuid === char.uuid;
    }

    getCharIdx(uuid) {
      let idx = 0;
      for (let char of this.elmt.value) {
        if (char.uuid === uuid) return idx;
        idx++;
      }
      return null;
    }
    getChar(uuid) {
      for (let char of this.elmt.value) {
        if (char.uuid === uuid) return char;
      }
      return null;
    }

    select(uuid) {
      this.charactersEntryField.characters.select(uuid);
    }
    selectFirst() {
      this.charactersEntryField.characters.selectFirst();
    }
    selectNext() {
      let toChar = this.charactersEntryField.characters.selectNext();
      this.props.onSelectNext && this.props.onSelectNext(toChar);
    }
    selectPrevious() {
      let toChar = this.charactersEntryField.characters.selectPrevious();
      this.props.onSelectPrevious && this.props.onSelectPrevious(toChar);
    }
    // selectNextPrevious(uid, mode) {
    //   let toChar = this.charactersEntryField.characters.selectNext();
    //   this.props.onSelectPrevious(toChar)
    //   console.log("TO CHAR?", toChar);
    //   // let prevChar = null;
    //   // let isCurrUid = false;
    //   // let toChar = null;
    //   // let idx = 0;
    //   // for (let char of this.elmt.value) {
    //   //   if (!this.charRefs[char.uid].current) continue;
    //   //   if (char.uid === uid) {
    //   //     if (mode === "previous") {
    //   //       toChar = prevChar;
    //   //       break;
    //   //     } else isCurrUid = true;
    //   //   } else if (mode === "next" && isCurrUid) {
    //   //     toChar = char;
    //   //     break;
    //   //   }
    //   //   prevChar = char;
    //   //   idx++;
    //   // }
    //   // if (!toChar) idx = null;
    //   // if (mode === "next" && this.props.onSelectNext)
    //   //   this.props.onSelectNext(toChar, idx);
    //   // if (mode === "previous" && this.props.onSelectPrevious)
    //   //   this.props.onSelectPrevious(toChar, idx);
    //   // toChar && this.select(toChar.uid);
    // }
    componentDidMount() {
      this.selectFirst();
    }
    componentWillUnmount() {
      this.elmt.remove();
      this.props.app.ui.charactersEntryField.removeInstance(
        this.props.item.uid
      );
    }
    render() {
      let field = null;
      switch (this.props.mode) {
        case this.props.app.dm.manualEntry.MODE_FIELD_CHARACTERS:
          field = (
            <FieldCharactersEntryField
              {...this.props}
              //focusedCharacterUid={this.props.app.dm.manualEntry.focusedCharacterUid}
              elmt={this.elmt}
              annotationSetUid={this.props.item.uid}
              //characterRefs={this.charRefs}
              valueParser={this.parseValue}
              onFocus={(uuid) => this.handleFocus(uuid)}
              onBlur={(uuid) => this.handleBlur(uuid)}
              onChangeText={(uuid, value) => this.handleChangeText(uuid, value)}
              keyMapper={this.keyMapper}
            />
          );
          break;
        case this.props.app.dm.manualEntry.MODE_CHARACTERS:
          field = (
            <CharacterEntryField
              name='characters'
              {...this.props}
              elmt={this.elmt}
              annotationSetUid={this.props.item.uid}
              value={this.props.item.characters}
              onSelectNext={this.handleSelectNextCharacter}
              // onFocus={(uid) => this.handleFocus(uid)}
              // onBlur={(uid) => this.handleBlur(uid)}
              onChangeText={(uuid, value) => this.handleChangeText(uuid, value)}
              keyMapper={this.keyMapper}
              upperCase
            />
          );
          break;
      }
      if (!field) return null;
      return (
        <View>
          <View style={styles.prevNextCharLabels}>
            <FloatingLabel
              label={`Prev Char: ${
                this.keyMapper.getActionKeyLabels("previousCharacter")[0]
              }`}
              visible={this.props.app.dm.manualEntry.keyOverlayMode}
              key='previousCharacter'
              position='top'
              offset={-8}
            >
              <View style={styles.prevNextCharLabel} />
            </FloatingLabel>
            <FloatingLabel
              label={`Next Char: ${
                this.keyMapper.getActionKeyLabels("nextCharacter")[0]
              }`}
              visible={this.props.app.dm.manualEntry.keyOverlayMode}
              key='nextCharacter'
              position='top'
              offset={-8}
            >
              <View style={styles.prevNextCharLabel} />
            </FloatingLabel>
          </View>
          {field}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  // textInput: {
  //   fontSize: 36,
  //   //fontFamily: "monospace",
  //   fontFamily: "Arial, Helvetica, sans-serif",
  //   color: Colors.onSurface,
  //   backgroundColor: Colors.surface,
  //   width: 48,
  //   height: 48,
  //   textAlign: "center",
  //   borderBottomLeftRadius: 4,
  //   borderBottomRightRadius: 4,
  // },
  // characterEntryField: {
  //   flex: 1,
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   justifyContent: "center",
  //   marginLeft: -8,
  //   marginBottom: 32,
  // },
  // characterKeyIndicator: {
  //   // height: 36,
  //   // width: 36
  //   marginBottom: 8,
  // },
  // characterKeyIndicatorText: {
  //   fontSize: 12,
  //   fontWeight: 500,
  //   color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  // },
  prevNextCharLabels: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    height: 0,
  },
  prevNextCharLabel: {
    height: 0,
  },
});
