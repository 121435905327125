import React from "react";
import R14, {
  Button,
  View,
  Text,
  Colors,
  StyleSheet,
  CenteredForm,
} from "../core";
import FieldImage from "../components/FieldImage";

export default R14.connect(
  class ManualEntryScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRefreshPress = this.handleRefreshPress.bind(this);
      this.manualEntryDomain = this.props.app.dm.manualEntry;
      this.keyMapper = this.props.app.ui.keyMapper.create(
        "manualEntryRefresh",
        {
          repeat: false,
        }
      );
      this.init();
    }
    componentDidMount() {
      this.props.app.dm.userSession.unsubscribe();
    }
    componentWillUnmount() {
      this.keyMapper && this.keyMapper.disconnect();
    }
    init() {
      this.keyMapper.addAction(
        "refresh",
        ["Enter", "F6"],
        this.handleRefreshPress,
        {
          label: "Refresh",
        }
      );
    }
    async handleSubmit() {
      return this.handleRefreshPress;
    }
    async handleRefreshPress() {
      this.props.app.nav.to("manualEntryLoad");
      return false;
    }
    getKeyByMapping(mapping) {
      let ret = null;
      for (let i in this.keyMappings) {
        if (this.keyMappings[i] === mapping) {
          ret = i;
          break;
        }
      }
      return ret;
    }
    renderMessage() {
      return (
        this.props.message ||
        "No results at this time, please try to refresh in a few minutes."
      );
    }
    renderButtonTitle() {
      return this.props.buttonTitle || "Refresh";
    }
    renderControlsBottomRight() {
      let controls = [];
      controls.push([
        <Button
          title={this.renderButtonTitle()}
          tooltip={`${this.renderButtonTitle()} [Enter/F6]`}
          key='refresh'
          style={styles.buttonRowButton}
          color={Colors.secondary}
          onPress={this.handleRefreshPress}
        />,
      ]);
      return controls;
    }
    render() {
      return (
        <CenteredForm
          name='manualEntryRefreshForm'
          title={"Field Entry"}
          style={styles.centeredForm}
          onSubmit={this.handleSubmit}
          controlsBottomRight={this.renderControlsBottomRight()}
        >
          <View style={styles.noResults}>
            <Text>{this.renderMessage()}</Text>
          </View>
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  buttonRowButton: {
    // marginRight: 16,
  },
  centeredForm: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 480)
        return {
          width: "100%",
        };
    },
  },
  noResults: {
    marginBottom: 24,
  },
});
