import React from "react";
import SvgIcon from "./SvgIcon";
import { ReactComponent as ArrowUpwardIconSvg } from "../../assets/icons/material-icons/arrow-upward.svg";
import { ReactComponent as ArrowDownwardIconSvg } from "../../assets/icons/material-icons/arrow-downward.svg";
import { ReactComponent as ArrowBackIconSvg } from "../../assets/icons/material-icons/arrow-back.svg";
import { ReactComponent as ArrowForwardIconSvg } from "../../assets/icons/material-icons/arrow-forward.svg";

import { ReactComponent as KeyboardArrowUpIconSvg } from "../../assets/icons/material-icons/keyboard-arrow-up.svg";
import { ReactComponent as KeyboardArrowDownIconSvg } from "../../assets/icons/material-icons/keyboard-arrow-down.svg";
import { ReactComponent as KeyboardArrowLeftIconSvg } from "../../assets/icons/material-icons/keyboard-arrow-left.svg";
import { ReactComponent as KeyboardArrowRightIconSvg } from "../../assets/icons/material-icons/keyboard-arrow-right.svg";
import { ReactComponent as CloseIconSvg } from "../../assets/icons/material-icons/close.svg";
import { ReactComponent as LockIconSvg } from "../../assets/icons/material-icons/lock.svg";
import { ReactComponent as AddIconSvg } from "../../assets/icons/material-icons/add.svg";
import { ReactComponent as NotificationsIconSvg } from "../../assets/icons/material-icons/notifications.svg";
import { ReactComponent as NotificationsNoneIconSvg } from "../../assets/icons/material-icons/notifications-none.svg";
import { ReactComponent as NotificationsActiveIconSvg } from "../../assets/icons/material-icons/notifications-active.svg";
import { ReactComponent as RefreshIconSvg } from "../../assets/icons/material-icons/refresh.svg";
import { ReactComponent as SortIconSvg } from "../../assets/icons/material-icons/sort.svg";
import { ReactComponent as MessageIconSvg } from "../../assets/icons/material-icons/message.svg";
import { ReactComponent as DeleteSweepIconSvg } from "../../assets/icons/material-icons/delete-sweep.svg";
import { ReactComponent as DeleteIconSvg } from "../../assets/icons/material-icons/delete.svg";
import { ReactComponent as ErrorOutlineIconSvg } from "../../assets/icons/material-icons/error-outline.svg";
import { ReactComponent as OpenInNewIconSvg } from "../../assets/icons/material-icons/open-in-new.svg";
import { ReactComponent as ViewColumnIconSvg } from "../../assets/icons/material-icons/view-column.svg";
import { ReactComponent as DragHandle } from "../../assets/icons/material-icons/drag-handle.svg";
import { ReactComponent as ContentSaveSvg } from "../../assets/icons/material-icons/content-save.svg";
import { ReactComponent as RotateLeftIconSvg } from "../../assets/icons/material-icons/rotate-left.svg";
import { ReactComponent as RotateRightIconSvg } from "../../assets/icons/material-icons/rotate-right.svg";
import { ReactComponent as SendVariantSvg } from "../../assets/icons/material-icons/send-variant.svg";
import { ReactComponent as MicrophoneSvg } from "../../assets/icons/material-icons/microphone.svg";
import { ReactComponent as AttachmentSvg } from "../../assets/icons/material-icons/attachment.svg";
import { ReactComponent as FullscreenSvg } from "../../assets/icons/material-icons/fullscreen.svg";
import { ReactComponent as FullscreenExitSvg } from "../../assets/icons/material-icons/fullscreen-exit.svg";
import { ReactComponent as SelectSvg } from "../../assets/icons/material-icons/select.svg";
import { ReactComponent as AccountVoiceSvg } from "../../assets/icons/material-icons/account-voice.svg";
import { ReactComponent as CheckSvg } from "../../assets/icons/material-icons/check.svg";
//import Icon, { FontAwesome, Feather } from 'react-web-vector-icons';

export default class MaterialIcons extends React.PureComponent {
  get svgIcon() {
    let SvgIcon = null;
    switch (this.props.name) {
      case "content-save":
        SvgIcon = ContentSaveSvg;
        break;
      case "close":
        SvgIcon = CloseIconSvg;
        break;
        case "check":
        SvgIcon = CheckSvg;
        break;
      case "lock":
        SvgIcon = LockIconSvg;
        break;
      case "account-voice":
        SvgIcon = AccountVoiceSvg;
        break;
      case "add":
        SvgIcon = AddIconSvg;
        break;
      case "arrow-upward":
        SvgIcon = ArrowUpwardIconSvg;
        break;
      case "arrow-downward":
        SvgIcon = ArrowDownwardIconSvg;
        break;
      case "arrow-back":
        SvgIcon = ArrowBackIconSvg;
        break;
      case "arrow-forward":
        SvgIcon = ArrowForwardIconSvg;
        break;
      case "attachment":
        SvgIcon = AttachmentSvg;
        break;
      case "keyboard-arrow-right":
        SvgIcon = KeyboardArrowRightIconSvg;
        break;
      case "keyboard-arrow-left":
        SvgIcon = KeyboardArrowLeftIconSvg;
        break;
      case "keyboard-arrow-up":
        SvgIcon = KeyboardArrowUpIconSvg;
        break;
      case "keyboard-arrow-down":
        SvgIcon = KeyboardArrowDownIconSvg;
        break;
      case "notifications":
        SvgIcon = NotificationsIconSvg;
        break;
      case "notifications-none":
        SvgIcon = NotificationsNoneIconSvg;
        break;
      case "notifications-active":
        SvgIcon = NotificationsActiveIconSvg;
        break;
      case "message":
        SvgIcon = MessageIconSvg;
        break;
      case "microphone":
        SvgIcon = MicrophoneSvg;
        break;
      case "rotate-left":
        SvgIcon = RotateLeftIconSvg;
        break;
      case "rotate-right":
        SvgIcon = RotateRightIconSvg;
        break;
      case "refresh":
        SvgIcon = RefreshIconSvg;
        break;
      case "sort":
        SvgIcon = SortIconSvg;
        break;
      case "delete-sweep":
        SvgIcon = DeleteSweepIconSvg;
        break;
      case "delete":
        SvgIcon = DeleteIconSvg;
        break;
      case "error-outline":
        SvgIcon = ErrorOutlineIconSvg;
        break;
      case "fullscreen":
        SvgIcon = FullscreenSvg;
        break;
      case "fullscreen-exit":
        SvgIcon = FullscreenExitSvg;
        break;
      case "open-in-new":
        SvgIcon = OpenInNewIconSvg;
        break;
      case "view-column":
        SvgIcon = ViewColumnIconSvg;
        break;
      case "drag-handle":
        SvgIcon = DragHandle;
        break;
      case "send-variant":
        SvgIcon = SendVariantSvg;
        break;
      case "select":
        SvgIcon = SelectSvg;
        break;
      default:
        throw `Icon ${this.props.name} not found`;
    }
    return SvgIcon;
  }
  render() {
    return <SvgIcon {...this.props} svg={this.svgIcon} />;
  }
}
