import React from "react";
import AnimatedView from "./AnimatedView";

export default class FadeView extends React.PureComponent {
  render() {
    return (
      <AnimatedView
        {...this.props}
        in={this.props.visible}
        enter={{
          opacity: 1
        }}
        exit={{
          opacity: 0
        }}
        animateOnMount={this.props.animateOnMount}
        unmountOnExit={
          "unmountOnExit" in this.props ? this.props.unmountOnExit : true
        }
        timeout={this.props.timeout}
        duration={this.props.duration}
      >
        {this.props.children}
      </AnimatedView>
    );
  }
}
