import React from "react";
import StyleSheet from "./StyleSheet";
import View from "./View";
import IconButton from "./IconButton";
import Chip from "./Chip";
import ChipGroup from "./ChipGroup";
import PopUpMenu from "./PopUpMenu";
import PopUpMenuItem from "./PopUpMenuItem";
import TextInputBase from "./base/TextInputBase";
import {Colors} from "./Theme";

export default class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleContentSizeChange = this.handleContentSizeChange.bind(this);
    this.handleValueRemove = this.handleValueRemove.bind(this);
    this.handleValueAdd = this.handleValueAdd.bind(this);
    this.inputRef = React.createRef();
    this.state = {
      height: null,
    };
  }
  handleContentSizeChange(event) {
    if (this.props.onContentSizeChange) this.props.onContentSizeChange(event);
    if (this.props.autoSize)
      this.setState({
        height: event.nativeEvent.contentSize.height,
      });
  }
  handleValueAdd() {
    if (this.props.onValueAdd)
      return this.props.onValueAdd(this.props.textValue || "");
  }
  handleValueRemove(val) {
    if (this.props.onValueRemove) return this.props.onValueRemove(val);
  }
  renderChips() {
    let ret = null;
    if (this.props.multiple) {
      let labels = [];
      let values = this.props.value || [];
      for (let i in values) {
        let label = values[i];
        if (!label) continue;
        labels.push(
          <Chip
            key={`${label}${i}`}
            label={label}
            style={styles.valueChip}
            iconRight='close'
            onPress={() => {
              this.handleValueRemove(label);
            }}
          />
        );
      }
      if (labels.length)
        ret = (
          <ChipGroup key='selections' style={styles.valueChipGroup}>
            {labels}
          </ChipGroup>
        );
    }
    return ret;
  }
  select() {
    this.inputRef &&
      this.inputRef.current &&
      this.inputRef.current.select &&
      this.inputRef.current.select();
  }
  focus() {
    this.inputRef &&
      this.inputRef.current &&
      this.inputRef.current.focus &&
      this.inputRef.current.focus();
  }
  render() {
    let dynamicStyles = {};
    if (this.state.height) {
      dynamicStyles = StyleSheet.create({
        input: {
          height: this.state.height,
        },
      });
    }
    let input = (
      <TextInputBase
        {...this.props}
        placeholderTextColor={
          this.props.placeholderTextColor ||
          StyleSheet.color(Colors.onBackground).rgba(0.9)
        }
        value={this.props.multiple ? this.props.textValue : this.props.value}
        style={[this.props.style, dynamicStyles.input]}
        ref={this.inputRef}
        onContentSizeChange={
          this.props.onContentSizeChange || this.props.autoSize
            ? this.handleContentSizeChange
            : null
        }
      />
    );
    if (this.props.multiple) {
      input = (
        <View style={[styles.textInputMultiple, this.props.multipleStyle]}>
          <View style={[styles.multipleRow]}>
            {input}
            <IconButton
              icon='add'
              key='add'
              variant='circle'
              onPress={this.handleValueAdd}
              style={styles.addButton}
              disabled={this.props.textValue ? false : true}
            />
          </View>
          {this.renderChips()}
        </View>
      );
    }
    if (this.props.autoSuggest) {
      input = (
          <PopUpMenu
            Control={input}
            controlWidthCover
            // controlIcon='search'
            // controlStyle={styles.suggestionControl}
            disabled={true}
            // style={[styles.popUpMenu, this.props.popupMenuStyle]}
            // HeaderComponent={this.renderSearchInput()}
            // FooterComponent={this.props.FooterComponent}
            ref={this.suggestionPopupMenuRef}
          >
            <PopUpMenuItem
              icon='account'
              iconSize='small'
              label='Update Password'
              onPress={this.handleUpdatePassword}
              key='updatePassword'
            />
          </PopUpMenu>
      );
    }

    return input;
  }
}
const styles = StyleSheet.create({
  textInputMultiple: {
    flex: 1,
    flexDirection: "column",
  },
  multipleRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addButton: {
    borderRadius: 18,
    elevation: 3,
    ...StyleSheet.padding(4),
    height: 32,
    width: 32,
    ...StyleSheet.margin(10, 10, 10, 10),
  },
  valueChipGroup: {
    ...StyleSheet.margin(2, 2, 6, 10),
  },
});
