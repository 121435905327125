import React from "react";
import View from "./View";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

export default class Surface extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    let styles = this.styles();
    return (
      <View {...this.props} style={[styles.surface, this.props.style]}>
        {this.props.children}
      </View>
    );
  }
  styles() {
    return StyleSheet.create({
      surface: {
        backgroundColor: Colors.surface,
        color: Colors.onSurface,
        padding: 16,
        elevation: this.props.elevation || 2
      }
    });
  }
}
