import React from "react";
import StyleSheet from "../StyleSheet";
/**
 * A basic button Component
 */
export default class ButtonBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    this.props.focus && this.focus();
  }
  focus() {
    this.ref.current && this.ref.current.focus();
  }
  blur() {
    this.ref.current && this.ref.current.blur();
  }
  render() {
    let props = {};
    if (this.props.onPressIn) {
      props.onMouseDown = this.props.onPressIn;
      props.onTouchStart = this.props.onPressIn;
    }
    if (this.props.onPressOut) {
      props.onMouseUp = this.props.onPressOut;
      props.onTouchEnd = this.props.onPressOut;
    }
    return (
      <button
        type={this.props.type || "button"}
        onClick={this.props.onPress}
        disabled={this.props.disabled || null}
        className={StyleSheet.className([styles.button, this.props.style])}
        onFocus={this.props.onFocus || null}
        onBlur={this.props.onBlur || null}
        ref={this.ref}
        {...props}
      >
        {this.props.children}
      </button>
    );
  }
}
const styles = StyleSheet.create({
  button: {
    cursor: "pointer",
    outline: 0,
    border: 0,
    display: "block",
  },
});
