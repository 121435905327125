import React from 'react';
import R14 from '../R14';
import Modal from './Modal';
import Button from './Button';
import View from './View';
import Text from './Text';
import StyleSheet from './StyleSheet';
import DatePicker from './DatePicker';
import { Colors } from './Theme';

export default class DatePickerModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleCancelPress = this.handleCancelPress.bind(this);
    this.handleConfirmPress = this.handleConfirmPress.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
   
    this.state = {
      selectedDate: this.props.date || null
    }
  }
  handleCancelPress() {
    this.props.onCancelPress && this.props.onCancelPress();
  }
  handleConfirmPress() {
    this.props.onConfirmPress && this.props.onConfirmPress(this.state.selectedDate);
  }
  handleDateChange(date) {
    if(this.props.onDateChange){
      if(this.props.onDateChange(date) === false) return;
    }
    this.setState({
      selectedDate: date
    });
  }
  componentDidUpdate(prevProps){
    if(this.props.date !== prevProps.date){
      /** @todo this conversion code should be centralized somewhere. */
      let date = null;
      if(this.props.date) date = typeof this.props.date === 'string' ? new Date(this.props.date) : this.props.date;
      if(date !== this.state.selectedDate) this.setState({selectedDate: date});
    }
  }
  render() {
    // let dimensions = Dimensions.get('window');
    // let width = dimensions.width - 112;
    let date = this.state.selectedDate || new Date();
    let dayLabel = R14.utils.date.getDayLabel(date, R14.utils.date.LABEL_TYPE_SHORT);
    let monthLabel = R14.utils.date.getMonthLabel(date, R14.utils.date.LABEL_TYPE_SHORT);
    return (
      <Modal
        animationType="fade"
        backdropStyle={styles.backdrop}
        onBackdropPress={this.handleCancelPress}
        transparent={true}
        visible={this.props.visible}
        style={styles.datePickerModal}
        onRequestClose={() => { }}
        unmountOnExit
        backdrop
        scrollEnabled
      >
        <View
          style={styles.datePicker}
        >
          <View
            style={styles.header}
          >
            <View>
              <Text style={styles.headerTextYear}>{date.getFullYear()}</Text>
            </View>
            <View>
              <Text style={styles.headerTextDate}>{dayLabel}, {monthLabel} {date.getDate()}</Text>
            </View>
          </View>
          <DatePicker
            {...this.props}
            date = {this.state.selectedDate}
            onDateChange = {this.handleDateChange}
          />
          <View
            style={styles.buttons}
          >
            <Button title="cancel"
              variant="text"
              onPress={this.handleCancelPress}
            />
            <Button title="ok"
              variant="text"
              onPress={this.handleConfirmPress}
            />
          </View>
        </View>
      </Modal>
    );

  }
}

const styles = StyleSheet.create({
  datePicker: {
    position: 'relative',
    borderRadius: 4,
    backgroundColor: Colors.surface,
    paddingBottom: 16,
    elevation: 8,
    marginBottom: 80,
    marginTop: 80,
    width: 280,
    zIndex: 2
  },
  backdrop: {
    backgroundColor: StyleSheet.color(Colors.background).rgba(.5),
  },
  header: {
    backgroundColor: Colors.primary,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 16
  },
  headerTextYear: {
    color: Colors.onSurface,
    fontSize: 18,
    opacity: .6
  },
  headerTextDate: {
    color: Colors.onSurface,
    fontSize: 36,
    fontWeight: "300",
    lineHeight: 40
  },
  buttons: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 8
  }
});

// const defaultStyles = new StyleSheet.create({
//   datePickerOverlay:{
//     flex:1,
//     overflow:"hidden",
//     flexDirection: "column",
//     backgroundColor: "rgba(0,0,0,.25)",
//     justifyContent: "center",
//     paddingLeft: 32,
//     paddingRight: 32,

// 	},
// 	datePicker:{
//     borderRadius:4,
//     backgroundColor: "#000000",
//     paddingBottom:16
//   },
//   header:{
//     backgroundColor: "#1565c0",
//     borderTopLeftRadius: 4,
//     borderTopRightRadius: 4,
//     padding:16
//   },
//   headerTextYear:{
//     color:"#FFFFFF",
//     fontSize:18,
//     opacity:.6
//   },
//   headerTextDate:{
//     color:"#FFFFFF",
//     fontSize:36,
//     lineHeight:40
//   },
//   buttons:{
//     flex:0,
//     flexDirection:"row",
//     justifyContent:"flex-end",
//     paddingRight:24
//   }
// });

// const materialStyles = new StyleSheet.create({
// 	...defaultStyles
// });
// const materialDarkStyles = new StyleSheet.create({
// 	...defaultStyles
// });