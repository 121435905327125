import React from "react";
import R14 from "../R14";
import StyleSheet from "./StyleSheet";
import PropTypes from "prop-types";
import { Colors } from "./Theme";
import View from "./View";
import Link from "./Link";
import Touchable from "./Touchable";

export default R14.connectScroller(
  class ScrollerItem extends React.Component {
    static propTypes = {
      /** The unique name of the scroller item. */
      name: PropTypes.string.isRequired,
      /** The scroller object passed through hoc. */
      scroller: PropTypes.object.isRequired,
      /** The function to be called when the scroller item is pressed. */
      onPress: PropTypes.func,
      /** A StyleSheet object, or array of StyleSheet objects to apply to the scroller item */
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** A StyleSheet object, or array of StyleSheet objects to apply to the scroller item */
      activeStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** A StyleSheet object, or array of StyleSheet objects to apply to the scroller item indicator */
      indicatorStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.func,
      ]),
      /** A StyleSheet object, or array of StyleSheet objects to apply to the active scroller item indicator */
      indicatorActiveStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.func,
      ]),
      /** Whether the scroller item is selected */
      active: PropTypes.bool,
    };
    static defaultProps = {
      indicatorPosition: "bottom",
    };
    constructor(props) {
      super(props);
      this.handlePress = this.handlePress.bind(this);
      // this.handleActive = this.handleActive.bind(this);
      this.item = this.props.scroller.addItem(props);
      this.state = {};
    }
    componentDidMount() {
      this.item.active && this.item.updateActiveScroll();
    }
    componentDidUpdate() {
      this.item.active && this.item.updateActiveScroll();
    }
    componentWillUnmount() {
      this.item.remove();
    }
    handlePress() {
      this.props.onPress && this.props.onPress(this.props);
    }
    // handleActive() {
    //   console.log("ACTIVE ACTIVE ACTIVE!!!!!!");
    //   this.props.onActive && this.props.onActive(this.props);
    // }
    // getIndicatorStyles() {
    //   let indicatorStyles = { inactive: [], active: [styles.indicatorActive] };
    //   let indicatorPositionCaps = R14.utils.str.capitalize(
    //     this.props.indicatorPosition
    //   );
    //   indicatorStyles.inactive.push(styles[`indicator${indicatorPositionCaps}`]);
    //   indicatorStyles.active.push(
    //     styles[`indicatorActive${indicatorPositionCaps}`]
    //   );
    //   return indicatorStyles;
    // }
    renderIndicator() {
      if (this.props.indicator === false) return null;
      let indicatorStyles = [styles.indicator];
      let indicatorPositionCaps = R14.utils.str.capitalize(
        this.props.indicatorPosition
      );
      // console.log("CHECK ACTIVE ITEM", this.item.active, this.props._scroller.state.activeItemName);
      indicatorStyles.push(styles[`indicator${indicatorPositionCaps}`]);
      if (this.props.indicatorStyle)
        indicatorStyles.push(this.props.indicatorStyle);
      if (this.item.active) {
        indicatorStyles.push(styles.indicatorActive);
        if (this.props.indicatorActiveStyle) {
          let activeStyle =
            typeof this.props.indicatorActiveStyle === "function"
              ? this.props.indicatorActiveStyle(this.item.activeState)
              : this.props.indicatorActiveStyle;
          indicatorStyles.push(activeStyle);
        }
      }
      return <View style={indicatorStyles} />;
    }
    render() {
      // let dynamicStyles = this.createDynamicStyles();
      let touchable = null;
      // if (this.props.to)
      //   content = (
      //     <Link
      //       // style={tabNavItemStyles}
      //       // activeStyle={indicatorStyles.active}
      //       // style={indicatorStyles.inactive}
      //       to={this.props.to}
      //       onActive={this.handleActive}
      //       onPress={this.props.onPress && this.handlePress}
      //       ref={this.ref}
      //     >
      //       {this.props.children}
      //     </Link>
      //   );
      // else
      if (this.props.onPress)
        touchable = (
          <Touchable
            onPress={this.props.onPress && this.handlePress}
            style={[styles.touchable]}
            tooltip={this.props.tooltip || null}
          />
        );

      return (
        <View
          style={[
            styles.scrollerItem,
            this.props.style,
            this.item.active ? this.props.activeStyle : null,
          ]}
          ref={this.item.active && this.item.itemRef}
        >
          {!this.props.itemLoader && this.props.children}
          {touchable}
          {this.renderIndicator()}
        </View>
      );
    }
    createDynamicStyles() {
      // let dynamicStyles = {};
      // if (this.state.layout && this.state.layout.height) {
      //   dynamicStyles.scroller = {
      //     height: this.state.layout.height,
      //   };
      // }
      // return StyleSheet.create(dynamicStyles);
    }
  }
);
const styles = StyleSheet.create({
  scrollerItem: {
    flex: 1,
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "left",
    width: "100%",
  },
  indicator: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.2),
    position: "absolute",
  },
  indicatorActive: {
    backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.8),
  },
  indicatorTop: {
    top: 0,
    left: 0,
    right: 0,
    height: 4,
  },
  indicatorBottom: {
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
  },
  indicatorLeft: {
    top: 0,
    bottom: 0,
    left: 0,
    width: 4,
  },
  indicatorRight: {
    top: 0,
    bottom: 0,
    right: 0,
    width: 4,
  },
  touchable: {
    ...StyleSheet.absoluteFill,
    // display: "block"
    // flex: 1,
    // flexDirection: "row"
    // ...StyleSheet.absoluteFill
    // zIndex: 2,
    //flex: 1
  },
  // indicatorActive: {
  //   borderStyle: "solid",
  //   borderWidth: 2,
  //   borderColor: Colors.primaryLight,
  //   // backgroundColor: backgroundColor,
  //   opacity: 0.94,
  // },
});
