export default {
  account: {
    family: "MaterialCommunityIcons",
    name: "account",
  },
  accountCircle: {
    family: "MaterialCommunityIcons",
    name: "account-circle",
  },
  accountMultiple: {
    family: "MaterialCommunityIcons",
    name: "account-multiple",
  },
  accountSettings: {
    family: "MaterialCommunityIcons",
    name: "account-settings",
  },
  accountGroup: {
    family: "MaterialCommunityIcons",
    name: "account-group",
  },
  add: {
    family: "MaterialIcons",
    name: "add",
  },
  applicationExport: {
    family: "MaterialCommunityIcons",
    name: "application-export",
  },
  applicationImport: {
    family: "MaterialCommunityIcons",
    name: "application-import",
  },

  arrowBack: {
    family: "MaterialIcons",
    name: "arrow-back",
  },
  arrowForward: {
    family: "MaterialIcons",
    name: "arrow-forward",
  },
  arrowDown: {
    family: "MaterialIcons",
    name: "arrow-downward",
  },
  arrowUp: {
    family: "MaterialIcons",
    name: "arrow-upward",
  },
  arrowUpBold: {
    family: "MaterialCommunityIcons",
    name: "arrow-up-bold",
  },
  arrowUpBoldCircleOutline: {
    family: "MaterialCommunityIcons",
    name: "arrow-up-bold-circle-outline",
  },
  brain: {
    family: "MaterialCommunityIcons",
    name: "brain",
  },
  calendar: {
    family: "MaterialCommunityIcons",
    name: "calendar",
  },
  calendarClock: {
    family: "MaterialCommunityIcons",
    name: "calendar-clock",
  },
  chartBubble: {
    family: "MaterialCommunityIcons",
    name: "chart-bubble",
  },
  chartLine: {
    family: "MaterialCommunityIcons",
    name: "chart-line",
  },
  checkboxBlank: {
    family: "MaterialCommunityIcons",
    name: "checkbox-blank-outline",
  },
  checkboxMarked: {
    family: "MaterialCommunityIcons",
    name: "checkbox-marked",
  },
  checkBoxMarkedCircle: {
    family: "MaterialCommunityIcons",
    name: "checkbox-marked-circle",
  },
  checkboxMultipleBlank: {
    family: "MaterialCommunityIcons",
    name: "checkbox-multiple-blank-outline",
  },
  checkboxMultipleMarked: {
    family: "MaterialCommunityIcons",
    name: "checkbox-multiple-marked",
  },
  checkCircle: {
    family: "MaterialCommunityIcons",
    name: "check-circle-outline",
  },
  chevronDoubleDown: {
    family: "MaterialCommunityIcons",
    name: "chevron-double-down",
  },
  chevronDoubleUp: {
    family: "MaterialCommunityIcons",
    name: "chevron-double-up",
  },
  chevronUp: {
    family: "MaterialCommunityIcons",
    name: "chevron-up",
  },
  clock: {
    family: "MaterialCommunityIcons",
    name: "clock-outline",
  },
  cloud: {
    family: "MaterialCommunityIcons",
    name: "cloud",
  },
  cloudSync: {
    family: "MaterialCommunityIcons",
    name: "cloud-sync",
  },
  close: {
    family: "MaterialIcons",
    name: "close",
  },
  closeCircle: {
    family: "MaterialCommunityIcons",
    name: "close-circle",
  },
  codeBraces: {
    family: "MaterialCommunityIcons",
    name: "code-braces",
  },
  cogs: {
    family: "MaterialCommunityIcons",
    name: "cogs",
  },
  column: {
    family: "MaterialIcons",
    name: "view-column",
  },
  console: {
    family: "MaterialCommunityIcons",
    name: "console",
  },
  consoleNetwork: {
    family: "MaterialCommunityIcons",
    name: "console-network",
  },
  contentSave: {
    family: "MaterialIcons",
    name: "content-save",
  },
  cube: {
    family: "MaterialCommunityIcons",
    name: "cube-outline",
  },
  databaseCheck: {
    family: "MaterialCommunityIcons",
    name: "database-check",
  },
  database: {
    family: "MaterialCommunityIcons",
    name: "database",
  },
  databaseImport: {
    family: "MaterialCommunityIcons",
    name: "database-import",
  },
  databaseExport: {
    family: "MaterialCommunityIcons",
    name: "database-export",
  },
  delete: {
    family: "MaterialIcons",
    name: "delete",
  },
  deleteSweep: {
    family: "MaterialIcons",
    name: "delete-sweep",
  },
  developerBoard: {
    family: "MaterialCommunityIcons",
    name: "developer-board",
  },
  documents: {
    family: "MaterialCommunityIcons",
    name: "file-document-box-multiple-outline",
  },
  dotsVertical: {
    family: "MaterialCommunityIcons",
    name: "dots-vertical",
  },
  dotsHorizontal: {
    family: "MaterialCommunityIcons",
    name: "dots-horizontal",
  },
  edit: {
    family: "MaterialCommunityIcons",
    name: "pencil",
  },
  email: {
    family: "MaterialCommunityIcons",
    name: "email-outline",
  },
  error: {
    family: "MaterialIcons",
    name: "error-outline",
  },
  export: {
    family: "MaterialCommunityIcons",
    name: "export",
  },
  eye: {
    family: "MaterialCommunityIcons",
    name: "eye",
  },
  eyeCheckOutline: {
    family: "MaterialCommunityIcons",
    name: "eye-check-outline",
  },
  fitToPage: {
    family: "MaterialCommunityIcons",
    name: "fit-to-page-outline",
  },
  filter: {
    family: "MaterialCommunityIcons",
    name: "filter-variant",
  },
  file: {
    family: "MaterialCommunityIcons",
    name: "file-outline",
  },
  fileDocumentBoxRemove: {
    family: "MaterialCommunityIcons",
    name: "file-document-box-remove",
  },
  fileDocumentBoxCheck: {
    family: "MaterialCommunityIcons",
    name: "file-document-box-check",
  },
  fileHidden: {
    family: "MaterialCommunityIcons",
    name: "file-hidden",
  },
  folder: {
    family: "MaterialCommunityIcons",
    name: "folder-outline",
  },
  formatListCheckbox: {
    family: "MaterialCommunityIcons",
    name: "format-list-checkbox",
  },
  git: {
    family: "MaterialCommunityIcons",
    name: "git",
  },
  heartPulse: {
    family: "MaterialCommunityIcons",
    name: "heart-pulse",
  },
  help: {
    family: "MaterialCommunityIcons",
    name: "help-circle-outline",
  },
  import: {
    family: "MaterialCommunityIcons",
    name: "import",
  },
  keyboardArrowDown: {
    family: "MaterialIcons",
    name: "keyboard-arrow-down",
  },
  keyboardArrowLeft: {
    family: "MaterialIcons",
    name: "keyboard-arrow-left",
  },
  keyboardArrowRight: {
    family: "MaterialIcons",
    name: "keyboard-arrow-right",
  },
  keyboardArrowUp: {
    family: "MaterialIcons",
    name: "keyboard-arrow-up",
  },
  keyboardOutline: {
    family: "MaterialCommunityIcons",
    name: "keyboard-outline",
  },
  languagePython: {
    family: "MaterialCommunityIcons",
    name: "language-python",
  },
  lock: {
    family: "MaterialIcons",
    name: "lock",
  },
  messageTextOutline: {
    family: "MaterialCommunityIcons",
    name: "message-text-outline",
  },
  message: {
    family: "MaterialIcons",
    name: "message",
  },
  search: {
    family: "MaterialCommunityIcons",
    name: "magnify",
  },
  notifications: {
    family: "MaterialIcons",
    name: "notifications",
  },
  notificationsNone: {
    family: "MaterialIcons",
    name: "notifications-none",
  },
  notificationsActive: {
    family: "MaterialIcons",
    name: "notifications-active",
  },
  npm: {
    family: "MaterialCommunityIcons",
    name: "npm",
  },
  magnify: {
    family: "MaterialCommunityIcons",
    name: "magnify",
  },
  magnifyPlus: {
    family: "MaterialCommunityIcons",
    name: "magnify-plus",
  },
  magnifyMinus: {
    family: "MaterialCommunityIcons",
    name: "magnify-minus",
  },
  magnifyMinusOutline: {
    family: "MaterialCommunityIcons",
    name: "magnify-minus-outline",
  },
  magnifyPlusOutline: {
    family: "MaterialCommunityIcons",
    name: "magnify-plus-outline",
  },
  menu: {
    family: "MaterialCommunityIcons",
    name: "menu",
  },
  menuDown: {
    family: "MaterialCommunityIcons",
    name: "menu-down",
  },
  menuUp: {
    family: "MaterialCommunityIcons",
    name: "menu-up",
  },
  minus: {
    family: "MaterialCommunityIcons",
    name: "minus",
  },
  monitorDashboard: {
    family: "MaterialCommunityIcons",
    name: "monitor-dashboard",
  },
  move: {
    family: "MaterialCommunityIcons",
    name: "cursor-move",
  },
  openInNew: {
    family: "MaterialIcons",
    name: "open-in-new",
  },
  powerPlug: {
    family: "MaterialCommunityIcons",
    name: "power-plug",
  },
  radioboxBlank: {
    family: "MaterialCommunityIcons",
    name: "radiobox-blank",
  },
  radioboxMarked: {
    family: "MaterialCommunityIcons",
    name: "radiobox-marked",
  },
  rotateLeft: {
    family: "MaterialIcons",
    name: "rotate-left",
  },
  rotateRight: {
    family: "MaterialIcons",
    name: "rotate-right",
  },
  refresh: {
    family: "MaterialIcons",
    name: "refresh",
  },
  robot: {
    family: "MaterialCommunityIcons",
    name: "robot",
  },
  play: {
    family: "MaterialCommunityIcons",
    name: "play",
  },
  playCircle: {
    family: "MaterialCommunityIcons",
    name: "play-circle",
  },
  playCircleOutline: {
    family: "MaterialCommunityIcons",
    name: "play-circle-outline",
  },
  securityAccount: {
    family: "MaterialCommunityIcons",
    name: "security-account",
  },
  select: {
    family: "MaterialIcons",
    name: "select",
  },
  settings: {
    family: "MaterialCommunityIcons",
    name: "settings-outline",
  },
  server: {
    family: "MaterialCommunityIcons",
    name: "server",
  },
  sort: {
    family: "MaterialIcons",
    name: "sort",
  },
  sourceBranch: {
    family: "MaterialCommunityIcons",
    name: "source-branch",
  },
  stop: {
    family: "MaterialCommunityIcons",
    name: "stop",
  },
  stopCircle: {
    family: "MaterialCommunityIcons",
    name: "stop-circle",
  },
  stopCircleOutline: {
    family: "MaterialCommunityIcons",
    name: "stop-circle-outline",
  },
  restore: {
    family: "MaterialCommunityIcons",
    name: "restore",
  },
  viewDashboard: {
    family: "MaterialCommunityIcons",
    name: "view-dashboard",
  },
};
