import React from 'react';
import StyleSheet from './StyleSheet';
import PropTypes from "prop-types";
import ExternalLinkBase from './base/ExternalLinkBase';
import { Colors } from './Theme';

export default class ExternalLink extends React.PureComponent {
  static propTypes = {
    /** The external url to open */
    url: PropTypes.string.isRequired,
    /** Text to display inside the link */
    title: PropTypes.string,
    /** Content to be placed into the link, overrides title, iconLeft, iconRight */
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  };
  render() {
    return (
      <ExternalLinkBase
        {...this.props}
        style={[styles.text, this.props.style]}
      />
    );
  }
}
const styles = StyleSheet.create({
  text: {
    color: Colors.secondary
  }
});