import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import View from "./View";
import Text from "./Text";
import IconButton from "./IconButton";
import { Colors } from "./Theme";

export default class ShowHideView extends React.PureComponent {
  static propTypes = {
    /** The visible height when collapsed. */
    collapsedHeight: PropTypes.number
  };
  static defaultProps = {
    collapsedHeight: 64
  };
  constructor(props) {
    super(props);
    throw new Error("In Dev");
    this.handlePress = this.handlePress.bind(this);
    this.state = {
      isHidden: true
    };
  }
  handlePress() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  }
  render() {
    let styles = this.styles();
    let showButton = true;
    let collapsedHeight
    return (
      <View style={styles.showHideView}>
        <View style={styles.content}>{this.props.children}</View>
        {showButton && (
          <IconButton icon='keyboardArrowDown' style={styles.button} onPress={this.handlePress} />
        )}
      </View>
    );
  }
  styles() {
    return StyleSheet.create({
      showHideView: {
      },
      content: {
        maxHeight: this.props.collapsedHeight || null,
        overflow: "hidden",

      },
      button: {
        position: "absolute",
        bottom: 0
      }
    });
  }
}
