import React from "react";
import StyleSheet from "./StyleSheet";
import PropTypes from "prop-types";
import View from "./View";

export default class ChipGroup extends React.PureComponent {
  static propTypes = {
    /** Chips to be rendered inside the chip group */
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the chip group */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };
  render() {
    return (
      <View
        style={[styles.chipGroup, this.props.style]}
      >
        {this.props.children}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  chipGroup: {
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: "flex-start",
    flexDirection: "row",
    marginBottom: -8,
    marginRight: -8
  }
});
