import React from "react";
import R14 from "../R14";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import View from "./View";
import IconButton from "./IconButton";
import ScrollView from "./ScrollView";
import FadeView from "./FadeView";

export default R14.connect(
  class TabNavigator extends React.Component {
    constructor(props) {
      super(props);
      this.tabNavRef = React.createRef();
      this.tabNavCntrRef = React.createRef();
      this.activeItemRef = React.createRef();
      this.handleActiveItem = this.handleActiveItem.bind(this);
      this.updateLayoutStyles();
    }
    updateLayoutStyles() {
      if (this.props.onLayoutStylesUpdate) {
        this.props.onLayoutStylesUpdate(this.layoutStyles);
      }
    }
    componentDidMount() {
      if (this.props.scrollEnabled) {
        // this.tabNavCntrRef.current.measureInWindow((px, py, width, height) => {
        //   console.log('measureInWindow',px, py, width, height);
        //   // this.setState({
        //   //   controlLayout: {
        //   //     top: py,
        //   //     left: px,
        //   //     width: width,
        //   //     height: height
        //   //   }
        //   // });
        //   // this.open();
        // });
      }
    }
    handleActiveItem(item) {
      item.measure((offsetLeft) => {
        console.log("item.measureOffset", offsetLeft);
      });
    }
    renderItems() {
      if (this.props.routes) {
        // icon, title,
        throw "Prop routes not available.";
      } else
        return this.props.children.map((element) => {
          let ref = null;
          if (element.props.to && element.props.to.route) {
            if (
              this.props.app.r14Instance.navigation.isActiveRoute(
                element.props.to.route
              )
            )
              ref = this.activeItemRef;
          }
          return React.cloneElement(element, {
            ref: ref,
            position: this.props.position,
            scrollEnabled: this.props.scrollEnabled,
            // onActive: this.handleActiveItem
          });
        });
    }
    componentWillUnmount() {
      this.props.onWillBlur &&
        this.props.onWillBlur(this.props, this.props.app);
    }
    render() {
      let tabNavCntrStyles = [styles.tabNavCntr];
      let tabNavStyles = [styles.tabNav];
      let position = this.props.position || "top";
      switch (position) {
        case "top":
          tabNavCntrStyles.push(styles.tabNavCntrTop);
          tabNavStyles.push(styles.tabNavTop);
          break;
        case "bottom":
          tabNavCntrStyles.push(styles.tabNavCntrBottom);
          tabNavStyles.push(styles.tabNavBottom);
          break;
        case "left":
          tabNavCntrStyles.push(styles.tabNavCntrLeft);
          tabNavStyles.push(styles.tabNavLeft);
          break;
        case "responsive-left-bottom":
          tabNavCntrStyles.push(styles.tabNavCntrResponsiveLeftBottom);
          tabNavStyles.push(styles.tabNavResponsiveLeftBottom);
          break;
      }
      let TabNavCntr = View;
      let tabNavCntrProps = {};
      if (this.props.scrollEnabled) {
        switch (position) {
          case "top":
          case "bottom":
            TabNavCntr = TabNavigatorScrollView;
            tabNavCntrProps.horizontal = true;
            tabNavCntrProps.showsHorizontalScrollIndicator = false;
            // tabNavCntrProps.onContentSizeChange = this.handleTabNav
            tabNavCntrStyles.push(styles.tabNavCntrScrollX);
            tabNavStyles.push(styles.tabNavScrollX);
            break;
        }
      }
      if (this.props.style) tabNavStyles.push(this.props.style);
      tabNavCntrProps.style = tabNavCntrStyles;
      return (
        <TabNavCntr {...tabNavCntrProps} tabNavigator={this}>
          <View ref={this.tabNavRef} style={tabNavStyles}>
            {this.renderItems()}
          </View>
        </TabNavCntr>
      );
    }
    get layoutStyles() {
      let position = this.props.position || "top";
      let layoutStyles = null;
      switch (position) {
        case "top":
          layoutStyles = StyleSheet.create({
            offset: {
              marginTop: 48,
              // screen: ({ width }) => {
              //   if (width <= 640)
              //     return {
              //       marginTop: 48,
              //     };
              // },
            },
          });
          break;
        case "bottom":
          layoutStyles = StyleSheet.create({
            offset: {
              marginBottom: 48,
            },
          });
          break;
        case "left":
          layoutStyles = StyleSheet.create({
            offset: {
              marginLeft: 56,
            },
          });
          break;
        case "responsive-left-bottom":
          layoutStyles = StyleSheet.create({
            offset: {
              marginLeft: 56,
              screen: ({ width }) => {
                if (width <= 640)
                  return {
                    marginLeft: 0,
                    marginBottom: 56,
                  };
              },
            },
          });
          break;
      }
      return layoutStyles;
    }
  }
);

const styles = StyleSheet.create({
  tabNavCntr: {
    position: "absolute",
    backgroundColor: Colors.primaryDark,
    elevation: 2,
  },
  tabNavCntrTop: {
    height: 48,
    top: 0,
    right: 0,
    left: 0,
    bottom: null,
    backgroundColor: Colors.background,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 48,
          elevation: 4,
        };
    },
  },
  tabNavCntrLeft: {
    top: 0,
    right: null,
    left: 0,
    bottom: 0,
    width: 56,
  },
  tabNavCntrResponsiveLeftBottom: {
    top: 0,
    right: null,
    left: 0,
    bottom: 0,
    width: 56,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 56,
          width: "auto",
          elevation: 12,
          top: null,
          right: 0,
          left: 0,
          bottom: 0,
        };
    },
  },
  tabNavCntrBottom: {
    top: null,
    right: 0,
    left: 0,
    bottom: 0,
  },
  tabNav: {
    flex: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  tabNavResponsiveLeftBottom: {
    screen: ({ width }) => {
      if (width <= 640)
        return {
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        };
    },
  },
  tabNavTop: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 48,
          // elevation: 4,
        };
    },
  },
  // tabNavScrollX: {
  //   height: 72,
  //   overflowX: 'auto',
  //   overflowY: 'hidden',
  //   '::-webkit-scrollbar': {
  //     width: 0
  //   }
  // }
});

const TabNavigatorScrollView = R14.connect(
  class TabNavigatorScrollView extends React.Component {
    constructor(props) {
      super(props);
      this.scrollRef = React.createRef();
      this.handleScroll = this.handleScroll.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.handleControlLeftPress = this.handleControlLeftPress.bind(this);
      this.handleControlRightPress = this.handleControlRightPress.bind(this);
      this.isInitialized = false;
      this.offset = null;
      this.contentSize = null;
      this.size = null;
      this.lastScrollEvent = null;
      this.state = {
        controlLeftVisible: false,
        controlRightVisible: false,
      };
    }
    componentDidMount() {
      /** @todo This is a hack to get the initial position, check with react native and make it work the same */
      //setTimeout(() => this.scrollRef.current.handleScroll(), 1);
      // this.scrollRef.current.scrollTo({x: 10})
      //setTimeout(() => this.updateScrollEvent(), 1);
      // throw('FINISH ADDING ACTIVE SCROLLTO');
      if (
        this.props.tabNavigator &&
        this.props.tabNavigator.activeItemRef.current
      ) {
        /** @todo Check to make sure that it is scrolling */
        setTimeout(() => {
          if (
            this.props.tabNavigator &&
            this.props.tabNavigator.activeItemRef &&
            this.props.tabNavigator.activeItemRef.current
          )
            this.props.tabNavigator.activeItemRef.current.measure(
              (offsetLeft, b, c, d) => {
                if (offsetLeft >= 32)
                  this.scrollRef.current.scrollTo({ x: offsetLeft - 32 });
              }
            );
        }, 100);
      }
    }
    updateScrollEvent() {
      this.scrollRef.current.triggerOnScroll();
    }
    handleLayout() {
      this.updateScrollEvent();
    }
    handleScroll(ev) {
      this.lastScrollEvent = ev.nativeEvent;
      let width = ev.nativeEvent.layout.width;
      let contentWidth = ev.nativeEvent.contentSize.width;
      let offset = ev.nativeEvent.contentOffset;
      let controlLeftVisible = false;
      let controlRightVisible = false;
      if (contentWidth > width) {
        if (offset.x > 16) controlLeftVisible = true;
        if (offset.x + width < contentWidth) controlRightVisible = true;
      }
      if (
        this.state.controlLeftVisible !== controlLeftVisible ||
        this.state.controlRightVisible !== controlRightVisible
      )
        this.setState({
          controlLeftVisible: controlLeftVisible,
          controlRightVisible: controlRightVisible,
        });
    }
    handleControlLeftPress() {
      let { contentOffset, contentSize, layout } = this.lastScrollEvent;
      let scrollTo = contentOffset.x - layout.width * 0.8;
      if (scrollTo < 0) scrollTo = 0;
      this.scrollRef.current.scrollTo({ x: scrollTo });
    }
    handleControlRightPress() {
      let { contentOffset, contentSize, layout } = this.lastScrollEvent;
      let scrollTo = contentOffset.x + layout.width * 0.8;
      //if(scrollTo )
      if (scrollTo > contentSize.width) scrollTo = contentSize.width;
      this.scrollRef.current.scrollTo({ x: scrollTo });
    }
    render() {
      let styles = TabNavigatorScrollViewStyles;
      return (
        <View style={[styles.tabNavigatorScrollViewWrapper, this.props.style]}>
          <ScrollView
            onScroll={this.handleScroll}
            ref={this.scrollRef}
            style={styles.tabNavigatorScrollView}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            onLayout={this.handleLayout}
          >
            {this.props.children}
          </ScrollView>
          <FadeView
            visible={this.state.controlLeftVisible}
            style={[styles.controls, styles.controlsLeft]}
          >
            <IconButton
              style={styles.controlIcon}
              onPress={this.handleControlLeftPress}
              icon='keyboardArrowLeft'
            />
          </FadeView>
          <FadeView
            visible={this.state.controlRightVisible}
            style={[styles.controls, styles.controlsRight]}
          >
            <IconButton
              style={styles.controlIcon}
              onPress={this.handleControlRightPress}
              icon='keyboardArrowRight'
            />
          </FadeView>
        </View>
      );
    }
  }
);
const TabNavigatorScrollViewStyles = StyleSheet.create({
  tabNavigatorScrollViewWrapper: {
    flex: 1,
    flexDirection: "row",
    overflow: "hidden",
    height: 48,
    // screen: ({ width, height }) => {
    //   if (width <= 640) {
    //     return { height: 48 };
    //   }
    // },
  },
  tabNavigatorScrollView: {
    paddingBottom: 128,
  },
  controls: {
    position: "absolute",
    flex: 1,
    height: 48,
    justifyContent: "space-around",
    screen: ({ width, height }) => {
      if (width <= 640) {
        return { height: 48 };
      }
    },
  },
  controlIcon: {
    ...StyleSheet.margin(0),
  },
  controlsLeft: {
    left: 0,
    screen: ({ width }) =>
      width <= 640
        ? {
            backgroundImage: `linear-gradient(to right, ${Colors.surface}, ${Colors.surface}, transparent)`,
          }
        : {
            backgroundImage: `linear-gradient(to right, ${Colors.background}, ${Colors.background}, transparent)`,
          },
  },
  controlsRight: {
    right: 0,
    screen: ({ width }) =>
      width <= 640
        ? {
            backgroundImage: `linear-gradient(to left, ${Colors.surface}, ${Colors.surface}, transparent)`,
          }
        : {
            backgroundImage: `linear-gradient(to left, ${Colors.background}, ${Colors.background}, transparent)`,
          },
  },
});
