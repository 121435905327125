import React from 'react';
import StyleSheet from '../StyleSheet';

export default class SvgIcon extends React.PureComponent {
  render() {
    let styles = this.styles();
    let SvgIcon = this.props.svg;
    return (
      <SvgIcon
        className={StyleSheet.className([styles.svgIcon, this.props.style])}
        height={`${this.props.size}px`}
        width={`${this.props.size}px`}
        // width="100%" height="100%" 
        preserveAspectRatio="xMinYMin slice"
        viewBox={[0,0,24,24]}
      />
    );
  }
  styles(){
    return StyleSheet.create({
      svgIcon: {
        fill: this.props.color || "#FFFFFF",
        height: this.props.size,
        width: this.props.size
      }
    });
  }
}