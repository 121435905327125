import React from 'react';
import R14 from '../R14';
import LinkBase from './base/LinkBase';

const Link = R14.connect(
	class Link extends React.Component {
		constructor(props) {
			super(props);
			this.handlePress = this.handlePress.bind(this);
			this.routeProps = null;
      this.initialize();
      this.ref = React.createRef();
			this.state = {
				isActive: this.isActive
      };
		}
		// componentWillReact() {
		// 	this.initialize();
		// }
		componentDidUpdate() {
      this.updateActive();
		}
		componentDidMount(){
      if(this.state.isActive && this.isActive && this.props.onActive) this.props.onActive(this);
      else this.updateActive();
    }
    updateActive(){
      if(this.isActive !== this.state.isActive){
        if(this.isActive && this.props.onActive) this.props.onActive(this);
        this.setState({
          isActive: this.isActive
        });
      }
    }
		handlePress(e) {
      if(this.props.onPress) this.props.onPress(e);
			this.props.app.navigation.to(this.routeProps);
    }
    handleActive(){
      if(this.props.onActive) this.props.onActive(this);
    }
    parseLinkProps(props) {
      let routeProps = {
        route: props.route || null,
        //portal: props.portal || "default",
        delay: props.delay || 0
      };
      if (!props.to) throw ("Route Link Error: No to.");
      if (typeof props.to === 'object') {
  
        if (props.to.pathname) routeProps.route = props.to.pathname;
        else if (props.to.route) routeProps.route = props.to.route;

        if (props.to.params) routeProps.params = props.to.params;
  
        if (props.to.portal) routeProps.portal = props.to.portal;
        //if(props.to.delay) routeProps.delay = props.to.delay;
        if(props.to.state) routeProps.state = props.to.state;
      }
      else routeProps.route = props.to;
      if (!routeProps.route) throw ("Route Link Error: No pathname.");
      return routeProps;
    }
		initialize() {
			this.routeProps = this.parseLinkProps(this.props);
		}
		get isActive() {
      /** @todo Link Component: figure out how to deal with isactive and params */
      let isActive = this.props.app.r14Instance.navigation.isActiveRoute(this.routeProps);
      return isActive;
		}
		get className() {

    }
    measureInWindow(callback){
      this.ref.current.measureInWindow(callback);
    }
    measure(callback){
      this.ref.current.measure(callback);
    }
		render() {

      //console.log('GOT PORTAL ROUTE renderLink',this.routeProps, this.isActive, this.props.activeStyle);

      let linkStyles = [];
      if(this.props.style) linkStyles.push(this.props.style);
      if(this.props.activeStyle && this.isActive) linkStyles.push(this.props.activeStyle);

			return (
        <LinkBase
          routeProps={this.routeProps}
          style={linkStyles}
          onPress={this.handlePress}
          ref={this.ref}
        >
          {this.props.children}
        </LinkBase>
			);
    }
	}
);
export default Link;