import React from "react";
import View from "./View";
import ScrollView from "./ScrollView";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

/** A responsive scroll view container for displaying cards. */
export default class CardsView extends React.PureComponent {
  render() {
    let ContentWrapper = this.props.scrollEnabled ? ScrollView : View;
    return (
      <ContentWrapper>
        <View style={[styles.cardsView, this.props.style]}>
          {this.props.children}
        </View>
      </ContentWrapper>
    );
  }
}
const styles = StyleSheet.create({
  cardsView: {
    // flex: 1,
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexBasis: "100%",
    ...StyleSheet.padding(16, 0, 16, 16),
    marginRight: 0,
    backgroundColor: Colors.background,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.padding(0, 0, 16, 0),
          marginRight: 0,
        };
    },
  },
});
