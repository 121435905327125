import React from "react";
import R14, {
  WebView,
  StyleSheet,
  Button,
  ScreenLayout,
  ActivityIndicator,
  View,
  Text,
  IconButton,
  ControlsView,
  PopUpMenu,
  PopUpMenuItem,
  AnimatedView,
  FadeView,
  Colors,
} from "../core";
import AmazonAppStreamView from "../components/AmazonAppStreamView";
export default R14.connect(
  class VirtualWorkspaceScreen extends React.Component {
    constructor(props) {
      super(props);
      this._userSessionUpdateInterval = null;
      this._virtualWorkspaceUserSessionUid = null;
      this.updateUserSession = this.updateUserSession.bind(this);
      this.handleSessionStateChange = this.handleSessionStateChange.bind(this);
    }
    async updateUserSession(appStreamView) {
      let sessionState = appStreamView.getSessionState();
      switch (sessionState) {
        case appStreamView.SESSION_STATE_STARTED:
          let virtualWorkspaceUserSession =
            await this.props.app.dm.virtualWorkspace.updateUserSession(
              this.props.virtualWorkspaceConfigUid,
              this.props.uid,
              this._virtualWorkspaceUserSessionUid
            );
          if (virtualWorkspaceUserSession && virtualWorkspaceUserSession.uid) {
            this._virtualWorkspaceUserSessionUid =
              virtualWorkspaceUserSession.uid;
          }
          // Start the interval to update last active
          this.createUserSessionUpdateInterval(appStreamView);
          break;
        default:
          this.clearUserSessionUpdateInterval();
      }
    }
    async handleSessionStateChange(sessionState, appStreamView) {
      await this.updateUserSession(appStreamView);
    }
    createUserSessionUpdateInterval(appStreamView) {
      if (!this._userSessionUpdateInterval)
        this._userSessionUpdateInterval = setInterval(
          () => {
            this.updateUserSession(appStreamView);
          },
          process.env.NODE_ENV === "development" ? 6000 : 180000
        );
      return true;
    }
    clearUserSessionUpdateInterval() {
      this._userSessionUpdateInterval &&
        clearInterval(this._userSessionUpdateInterval);
      this._userSessionUpdateInterval = null;
    }
    componentWillUnmount() {
      this.clearUserSessionUpdateInterval();
    }
    render() {
      if (!this.props.url) return null;
      return (
        <ScreenLayout>
          {this.props.url && (
            <AmazonAppStreamView
              sessionUrl={this.props.url}
              style={styles.webView}
              onSessionStateChange={this.handleSessionStateChange}
            />
          )}
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  webView: {
    flex: 1,
  },
});
