import R14 from "../R14";

export default class SnackBarUiDomain extends R14.Domain {
  constructor() {
    super();
    this.handleRequestDismiss = this.handleRequestDismiss.bind(this);
    this.state = {
      visible: false,
    };
    this._props = {
      name: "navigator",
      message: "",
    };
  }
  show(message, props = {}) {
    if (typeof message === "object") {
      props = message;
      message = props.message;
    }
    if (!message) throw new Error("SnackBack Error: Message Required");
    props.message = message;
    this._props = props;
    if (!props.name) props.name = "default";

    if (this.state.visible) {
      console.error(
        "SnackBar Error: SnackBar already visible. TODO: hide then show."
      );
      this.setState({
        visible: false,
      });
    }

    this.setState({
      visible: true,
    });
    return this;
  }
  get props() {
    return this._props;
  }
  handleRequestDismiss() {
    this.props.onRequestDismiss ? this.props.onRequestDismiss() : this.hide();
  }
  dismiss() {
    return this.hide();
  }
  hide() {
    this.setState({
      visible: false,
    });
    return this;
  }
}
