import React from "react";
import R14, { AsyncStorage } from "../core";
const constants = {
  ANNOTATION_TYPE_FIELD: "FIELD",
  ANNOTATION_TYPE_CHARACTER: "CHARACTER",
  ANNOTATION_TYPE_FIELD_GROUP: "FIELD_GROUP",
  SORT_BY_LAYOUT: "SORT_BY_LAYOUT",
};

export default class CharactersEntryFieldUiDomain extends R14.DomainInstances {
  constructor() {
    super();
  }

  create(annotationSet, form, elmt, options = {}) {
    if (this.exists(annotationSet.uid))
      return this.getInstance(annotationSet.uid);
    let characterEntryField = new CharactersEntryFieldInstanceUiDomain(
      annotationSet,
      form,
      elmt,
      options
    );
    // await annotationSetInstance.init();
    this.addInstance(annotationSet.uid, characterEntryField);
    return characterEntryField;
  }
  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
}

class CharactersEntryFieldInstanceUiDomain extends R14.Domain {
  constructor(annotationSet, form, elmt, options) {
    super();
    this._form = form;
    this._elmt = elmt;
    this._annotationSet = annotationSet;
    this._fontRatio = 1.15;
    this._rowPadding = 4;
    this._field = this.initField();
    this.characters = new CharactersUiInstanceDomain(this, options);
  }
  initField() {
    let ret = this.filterAnnotations({
      type: this.dm.manualEntry.ANNOTATION_TYPE_FIELD,
    }).at(0);
    // console.log("initCharacterGrid field", ret);
    // if (!ret)
    //   throw new Error(
    //     "Unable to initialize field. Field annotation not found."
    //   );
    return ret;
  }
  filterAnnotations(filter = {}) {
    return this.dm.manualEntry.filterAnnotations(this._elmt.value, filter);
  }
  filterAnnotations(filter = {}) {
    if (!this._elmt.value) return [];
    return this._elmt.value.filter((annotation) => {
      if (filter.type && annotation.type !== filter.type) return false;
      return true;
    });
  }
  get imageBase64() {
    console.log("initChara image", this._field);
    return this._field && this._field.imageBase64
      ? this._field.imageBase64
      : null;
  }
  get characterGrid() {
    return this._characterGrid;
  }
  get annotationSet() {
    return this._annotationSet;
  }
}

class CharactersUiInstanceDomain extends R14.DomainInstances {
  constructor(charactersEntryField, options = {}) {
    super();
    this._charactersEntryField = charactersEntryField;
    this._uuidMap = {};
    this._grid = {};
    this._fontSize = null;
    this._focusUuid = null;
    this._maxPerCell = options.maxCharactersPerCell || 1;
    this._uppercase = options.upperCaseCharacters ? true : false;
    this._mode = options.mode;
    switch (options.mode) {
      case this.dm.manualEntry.MODE_FIELD_CHARACTERS:
        this._grid = this.initCharacterGrid();
        this._fontSize = this._grid.fontSize;
        break;
      case this.dm.manualEntry.MODE_CHARACTERS:
        // this._grid = this.initCharacterGrid();
        // this._fontSize = this._grid.fontSize;
        break;
      default:
        throw new Error("Unknown mode.");
    }
    this.filterAnnotations({
      type: this.dm.manualEntry.ANNOTATION_TYPE_CHARACTER,
    }).forEach((annotation, idx) => {
      this._uuidMap[annotation.uuid] = parseInt(idx);
      this.create(annotation);
    });
  }
  getCurrentInstance() {
    if (!this.focusUuid) return;
    return this.getInstance(this.focusUuid);
  }
  get focusUuid() {
    return this._focusUuid;
  }
  setFocusUuid(uuid) {
    this._focusUuid = uuid;
  }
  get grid() {
    return this._grid;
  }
  get maxPerCell() {
    return this._grid;
  }
  get upperCase() {
    return this._uppercase;
  }
  getIdx(uuid) {
    return this._uuidMap[uuid];
  }
  getUpdateValues() {
    let updateValues = [];
    this._charactersEntryField._annotationSet.annotations.forEach(
      (char, idx) => {
        let character = this.getInstance(char.uuid);
        if (!character) return;
        let valueUpdate = character.value !== char.value;
        let rejectUpdate = character.reject !== char.reject;
        if (valueUpdate || rejectUpdate) {
          let updateValue = { uuid: char.uuid, idx: parseInt(idx) };
          if (valueUpdate) updateValue.updatedValue = character.value;
          if (rejectUpdate) updateValue.reject = character.reject;
          updateValues.push(updateValue);
        }
      }
    );
    return updateValues;
  }
  toggleRejectCurrent() {
    if (!this.focusUuid) return false;
    let char = this.getInstance(this.focusUuid);
    char.toggleReject();
    return char.reject;
  }
  selectFirst() {
    this.selectNext(0);
  }
  select(uuid) {
    let char = this.getInstance(uuid);
    if (char) char.select();
  }
  selectNext(idx = null) {
    let nextChar = null;
    if (!idx && this.focusUuid) {
      idx = this.getIdx(this.focusUuid);
      if (idx === null) throw new Error("Character not found 1");
      idx++;
    }
    if (idx === null) throw new Error("Character not found 2");
    let annotations = this.filterAnnotations({
      type: this.dm.manualEntry.ANNOTATION_TYPE_CHARACTER,
    });
    for (let currIdx = idx; currIdx < annotations.length; currIdx++) {
      nextChar = this.getInstance(annotations[currIdx].uuid);
      if (nextChar && nextChar.hasFieldOffset) break;
    }
    nextChar && nextChar.select();
    return nextChar;
  }
  selectPrevious() {
    let prevChar = null;
    if (!this.focusUuid) {
      return null;
    } else {
      let annotations = this.filterAnnotations({
        type: this.dm.manualEntry.ANNOTATION_TYPE_CHARACTER,
      });
      let idx = this.getIdx(this.focusUuid);
      if (idx === null) throw new Error("Character not found");
      for (let currIdx = idx - 1; currIdx >= 0; currIdx--) {
        prevChar = this.getInstance(annotations[currIdx].uuid);
        if (prevChar && prevChar.hasFieldOffset) break;
      }
      prevChar && prevChar.select();
    }
    return prevChar;
  }
  create(character, options = {}) {
    if (this.exists(character.uuid)) return this.getInstance(character.uuid);
    // await annotationSetInstance.init();
    this.addInstance(
      character.uuid,
      new CharacterUiDomain(
        this._charactersEntryField,
        this,
        character.uuid,
        character,
        options
      )
    );
    return character;
  }
  hasCharacterFieldOffset(character) {
    if (this._mode === this.dm.manualEntry.MODE_CHARACTERS) return true;
    return (
      character.offset &&
      !isNaN(character.offset.top) &&
      !isNaN(character.offset.left) &&
      !isNaN(character.offset.width) &&
      !isNaN(character.offset.height) &&
      character.offset.top >= 0 &&
      character.offset.left >= 0 &&
      character.offset.width >= 0 &&
      character.offset.height >= 0
    );
  }
  filterAnnotations(filter = {}) {
    return this._charactersEntryField.filterAnnotations(filter);
  }
  initCharacterGrid() {
    let annotations = this.filterAnnotations({
      type: this.dm.manualEntry.ANNOTATION_TYPE_CHARACTER,
    });
    let characterGrid = { fontSize: 0, rows: [] };
    let currRow = 0;
    let lastChar = null;
    for (let idx in annotations) {
      let annotation = annotations[idx];
      // check if new rows.
      let char = annotation;
      let hasFieldOffset = this.hasCharacterFieldOffset(char);
      if (hasFieldOffset) {
        if (lastChar) {
          // TODO: There are a lot of exceptions for this.
          if (
            char.offset.left < lastChar.offset.left + lastChar.offset.width &&
            char.offset.top > lastChar.offset.top + lastChar.offset.height
          ) {
            currRow++;
          }
        }
      }
      if (characterGrid.rows.length === currRow) {
        characterGrid.rows.push({
          offset: {},
          cols: [],
        });
      }
      characterGrid.rows[currRow].cols.push({
        hasFieldOffset,
        idx,
        ...char,
      });
      if (hasFieldOffset) lastChar = char;
    }
    characterGrid.rows = characterGrid.rows.map((row, rowIdx) => {
      let offset = null;
      // Calculate offset of row in field
      console.log("initCharacterGrid row", row);
      row.cols = row.cols.map((col, colIdx) => {
        if (offset === null)
          offset = { top: col.offset.top, height: col.offset.height };
        else {
          if (col.offset.top < offset.top) offset.top = col.offset.top;
          if (col.offset.height > offset.height)
            offset.height = col.offset.height;
        }

        // TODO find a better way to calculate font / size
        if (offset.height > characterGrid.fontSize)
          characterGrid.fontSize = offset.height;

        return col;
      });
      row.offset = offset;

      // Expand the rows to all touch like col cells.
      let colOffsetLeftMap = {};
      row.cols.forEach((col, colIdx) => {
        colIdx = parseInt(colIdx);
        if (colIdx === 0) {
          colOffsetLeftMap[col.uuid] = 0;
          return;
        }
        let lastCol = colIdx > 0 ? row.cols[colIdx - 1] : null;
        if (lastCol && !lastCol.hasFieldOffset) lastCol = null;

        let nextCol =
          row.cols.length > colIdx + 1 ? row.cols[colIdx + 1] : null;
        if (nextCol && !nextCol.hasFieldOffset) nextCol = null;

        let lastColDiffLeft = lastCol
          ? col.offset.left - (lastCol.offset.left + lastCol.offset.width)
          : null;

        let nextColDiffLeft = nextCol
          ? nextCol.offset.left - (col.offset.left + col.offset.width)
          : null;

        // Check for whitespace
        let whitespace =
          col.value === null ||
          col.value === undefined ||
          col.value.trim() === ""
            ? true
            : false;
        let whitespaceLast =
          lastCol &&
          (lastCol.value === null ||
            lastCol.value === undefined ||
            lastCol.value.trim() === "")
            ? true
            : false;
        // let whitespaceNext =
        //   nextCol && nextCol.value.trim() === "" ? true : false;
        // if (whitespaceLast) {
        //   console.log(
        //     "WHITE SPACE LAST",
        //     col.value,
        //     lastColDiffLeft,
        //     nextColDiffLeft
        //   );
        // }
        if (whitespaceLast && nextColDiffLeft !== null) {
          colOffsetLeftMap[col.uuid] = colOffsetLeftMap[col.uuid] =
            col.offset.left - nextColDiffLeft / 2;
        } else if (whitespace) {
          if (lastCol && lastCol.uuid in colOffsetLeftMap) {
            // console.log('CHECK CHECK CHECK', lastCol.value,lastCol.offset.left - colOffsetLeftMap[lastCol.uuid]);
            colOffsetLeftMap[col.uuid] =
              lastCol.offset.left +
              lastCol.offset.width +
              (lastCol.offset.left - colOffsetLeftMap[lastCol.uuid]) * 2;
          }
        } else {
          colOffsetLeftMap[col.uuid] = col.offset.left - lastColDiffLeft / 2;
        }
        if (colOffsetLeftMap[col.uuid] > 1) colOffsetLeftMap[col.uuid] = 1;
      });

      row.cols = row.cols.map((col, colIdx) => {
        if (!col.hasFieldOffset) return col;
        colIdx = parseInt(colIdx);

        let lastCol = colIdx > 0 ? row.cols[colIdx - 1] : null;
        if (lastCol && !lastCol.hasFieldOffset) lastCol = null;

        let nextCol =
          row.cols.length > colIdx + 1 ? row.cols[colIdx + 1] : null;
        if (nextCol && !nextCol.hasFieldOffset) nextCol = null;

        let nCollOffset = {
          width: col.offset.width,
          left: col.offset.left,
        };

        if (col.uuid in colOffsetLeftMap) {
          nCollOffset.left = colOffsetLeftMap[col.uuid];
          if (nextCol && nextCol.uuid in colOffsetLeftMap) {
            nCollOffset.width =
              colOffsetLeftMap[nextCol.uuid] - colOffsetLeftMap[col.uuid];
          } else {
            nCollOffset.width =
              col.offset.width + (col.offset.left - nCollOffset.left) * 2;
            if (nCollOffset.left + nCollOffset.width > 1) {
              nCollOffset.width -= nCollOffset.left + nCollOffset.width - 1;
            }
          }
        }
        col.offset = nCollOffset;
        return col;
      });

      return row;
    });
    return characterGrid;
  }
}
class CharacterUiDomain extends R14.Domain {
  constructor(charactersEntryField, characters, uuid, character) {
    super();
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this._charactersEntryField = charactersEntryField;
    this._characters = characters;
    this._character = character;
    this._hasFieldOffset = this._characters.hasCharacterFieldOffset(character);
    this._ref = React.createRef();
    let value = this.parseValue();
    this.state = {
      reject: character.reject || false,
      value: value,
      changed: value === character.updatedValue,
      focus: false,
    };
  }
  get ref() {
    return this._ref;
  }
  get uuid() {
    return this._character.uuid;
  }
  get uid() {
    return this._character.uid;
  }
  get hasFieldOffset() {
    return this._hasFieldOffset;
  }
  get reject() {
    return this.state.reject;
  }
  get value() {
    return this.state.value;
  }
  get focus() {
    return this.state.focus;
  }
  get changed() {
    return this.state.changed;
  }
  get score() {
    return this._character.score;
  }
  get lowScore() {
    return this._character.lowScore || false;
  }
  get fontSize() {
    return this._characters.grid.fontSize;
  }
  get fontRatio() {
    return this._charactersEntryField._fontRatio;
  }
  toggleReject() {
    let reject = !this.state.reject;
    this.setState({
      reject,
    });
    return reject;
  }
  parseValue() {
    let char = this._character;
    return char.updatedValue !== null && char.updatedValue !== undefined
      ? char.updatedValue
      : char.value;
  }
  select() {
    this.onFocus();
  }
  onFocus(e) {
    if (!this.state.focus) this.setState({ focus: true });
    this.ref.current && this.ref.current.focus();
    this.ref.current && this.ref.current.select();
    this._characters.setFocusUuid(this.uuid);
  }
  onBlur() {
    if (this.state.focus) this.setState({ focus: false });
  }
  updateValue(value) {
    if (this._characters.upperCase) value = value.toUpperCase();
    if (this._characters._maxPerCell < value.length)
      value = value.substring(value.length - this._characters._maxPerCell);

    if (this.state.value !== value) {
      this.setState({ value: value, changed: true });
      // Update the element value
      //this._characters.updateValue(this, value);
    }
  }
}
