import React from 'react';
import StyleSheet from './StyleSheet';
import WebViewBase from './base/WebViewBase';
import { Colors } from './Theme';

export default class WebView extends React.PureComponent {
  render() {
    return (
      <WebViewBase
        {...this.props}
        style={[styles.webView, this.props.style]}
      />
    );
  }
}
const styles = StyleSheet.create({
  webView: {
   
  }
});