import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import InputField from "./InputField";
import TextInput from "./TextInput";
import StyleSheet from "./StyleSheet";
import View from "./View";
import { Colors } from "./Theme";

const ColorInputField = R14.connectForm(
  /**
   * A color input field component
   */
  class ColorInputField extends React.Component {
    static propTypes = {
      /** Label for the color input. */
      label: PropTypes.string,
      /** Hex color value of the color input. */
      value: PropTypes.string,
      /** The color input varient to use */
      variant: PropTypes.oneOf(["outlined", "filled"]),
      /** If true, disable the color input */
      disabled: PropTypes.bool,
      /** If true, displays a secure color input for things like passwords */
      secure: PropTypes.bool,
      /** Handler to be called when the color inputs value is changed  */
      onValueChange: PropTypes.func,
      /** Handler to be called when the user taps the color input  */
      onPress: PropTypes.func,
      /** Handler to be called when the color input gains focus  */
      onFocus: PropTypes.func,
      /** Handler to be called when the color input loses focus  */
      onBlur: PropTypes.func,
      // /** The name of the icon to be displayed to the left of the color input  */
      // icon: PropTypes.string,
      /** The name of the icon to be displayed to the right of the color input  */
      iconRight: PropTypes.string
      /** If true, text content can be multiple lines */
    };
    static defaultProps = {
      variant: "outlined",
      autoSize: false
    };
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.state = {
        color: null
      };
    }
    handleValueChange(value) {
      this.elmt.setValue(value);
      if (this.props.onValueChange) this.props.onValueChange(value, this.elmt);
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setBlur(true);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    calculateControlColor(){
      let value = this.elmt.value;
      return (value && this.isHexColor(value)) ? value : 'transparent';
    }
    isHexColor(hex){
      return /^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/.test(hex) ? true : false;
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    renderControls() {
      let style = StyleSheet.create({
        colorControl: {
          backgroundColor: this.calculateControlColor(),
          height: 24,
          width: 24,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: StyleSheet.color(Colors.onBackground).rgba(.1),
          borderRadius: 2
        }
      });

      return <View style={style.colorControl}></View>;
    }

    render() {
      let styles = this.styles();
      let variantStyles = this.variantStyles();
      let type = "text";
      if (this.props.secure) type = "password";
      return (
        <InputField
          {...this.props}
          element={this.elmt}
          controlsLeft={this.renderControls()}
          InputComponent={
            <TextInput
              type={type}
              name={this.elmt.name}
              ref={this.elmt.ref}
              placeholder={
                this.elmt.isActive && this.props.placeholder
                  ? this.props.placeholder
                  : null
              }
              value={this.elmt.value !== null ? `${this.elmt.value}` : ""}
              onChangeText={this.handleValueChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              autoFocus={this.props.autoFocus || null}
              style={[
                styles.textInput,
                variantStyles.textInput,
                this.props.style
              ]}
              secureTextEntry={this.props.secure || false}
              autoComplete={this.props.autoComplete}
              multiline={this.props.multiline || false}
              autoSize={this.props.autoSize || false}
              onAutoFill={this.handleAutoFill}
            />
          }
        />
      );
    }
    variantStyles() {
      switch (this.props.variant) {
        case "outlined":
          return StyleSheet.create({
            textInput: {
              paddingTop: 16,
              paddingBottom: 16,
              // height: this.elmt.isFocused ? 52 : 54,
              paddingLeft:
                (44) - (this.elmt.isFocused ? 1 : 0),
              paddingRight:
                (this.props.iconRight ? 44 : 12) -
                (this.elmt.isFocused ? 1 : 0),
              lineHeight: 22,
              height: this.elmt.isFocused ? 52 : 54
              // minHeight: 54
            }
          });
          break;
        default:
          return StyleSheet.create({
            textInput: {
              paddingTop: 20,
              paddingBottom: 5,
              minHeight: 55,
              lineHeight: 30
            }
          });
      }
    }
    styles() {
      return StyleSheet.create({
        textInput: {
          margin: 0,
          paddingLeft: 44,
          paddingRight: this.props.iconRight ? 44 : 12,
          width: "100%",
          backgroundColor: "transparent",
          color: Colors.onBackground,
          fontSize: 16
        }
      });
    }
  }
);
export default ColorInputField;
