import PropTypes from 'prop-types'
import FileInputBase from './base/FileInputBase';
// import StyleSheet from './StyleSheet';

/** A file input component. */
export default class FileInput extends FileInputBase {
    static propTypes = {

       
    }
    static defaultProps = {
        
    }
}