import React from "react";
import PropTypes from "prop-types";
import R14, {
  TextInput,
  StyleSheet,
  Colors,
  AnimatedView,
  View,
  Text,
} from "../core";
import TimeDurationLabel from "./TimeDurationLabel";

export default class CharacterCompareField extends React.Component {
  static propTypes = {
    /** Date / Time Timer is complete. */
    completeAt: PropTypes.instanceOf(Date).isRequired,
    // /** Value of the character entry fields. */
    // value: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this._countdownTimerInterval = null;
    this.state = {
      diffTime: null,
      startAt: new Date()
    };
    this.mounted = false;
  }
  componentDidMount() {
    this.mounted = true;
    this.startCountdownTimer();
  }
  componentWillUnmount() {
    this.mounted = false;
    this.clearCountdownTimer();
  }
  updateTime() {
    let currTime = new Date();
    let diffTime = this.props.completeAt.getTime() - new Date();
    if (diffTime <= 0) {
      diffTime = 0;
      this.clearCountdownTimer();
      this.props.onComplete && this.props.onComplete();
    }
    if (!this._countdownTimerInterval) return false;
    this.setState({
      diffTime: diffTime,
      startAt: currTime
    });
  }
  startCountdownTimer() {
    this._countdownTimerInterval = setInterval(() => {
      this.updateTime();
    }, 500);
    this.updateTime();
  }
  clearCountdownTimer() {
    if (this._countdownTimerInterval) {
      clearInterval(this._countdownTimerInterval);
      this._countdownTimerInterval = null;
    }
  }
  renderTimer() {
    if (this.state.diffTime === null) return null;
    return (
      <TimeDurationLabel
        startAt={this.state.startAt}
        endAt={this.props.completeAt}
        style={styles.timer}
        numberValueTextStyle={styles.numberValue}
        numberLabelTextStyle={styles.numberLabel}
      />
    );
  }
  render() {
    return (
      <AnimatedView
        key={"countdownTimer"}
        in={this.state.diffTime !== null}
        enter={{
          opacity: 1.0,
        }}
        exit={{
          opacity: 0.0,
        }}
        duration={300}
        style={this.props.style}
      >
        <View>
          <View>
            <Text>{this.props.label}</Text>
          </View>
          <View>{this.renderTimer()}</View>
        </View>
      </AnimatedView>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    color: Colors.onSurface,
  },
  timer: {},
  numberValue: {
    fontSize: 18,
    color: Colors.onSurface,
    paddingRight: 1,
  },
  numberLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  number: {
    paddingLeft: 4,
  },
  timer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
