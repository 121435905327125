import React from "react";
import R14, {
  TextInputField,
  SubmitButton,
  SwitchField,
  Button,
  StyleSheet,
  CenteredCard,
  CenteredForm,
  View,
  FieldGroup,
  PopUpMenu,
  PopUpMenuItem,
  Text,
  LabelView,
  StateIndicatorText
} from "../../core";

export default R14.connect(
  class R14LicenseAdminConfigEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleAuthSubmit = this.handleAuthSubmit.bind(this);
      this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
      this.handleCreatePress = this.handleCreatePress.bind(this);
      this.handleDeletePress = this.handleDeletePress.bind(this);
      this.handleReplacePress = this.handleReplacePress.bind(this);
      this.handleCreateCancelPress = this.handleCreateCancelPress.bind(this);
      this.state = {
        key: null,
        accessToken: null,
        adminConfig: null,
        create: false,
      };
      
    }
    get dm() {
      return this.props.app.dm;
    }
    async handleAuthSubmit(form) {
      let res = null;
      let { key, adminSecretKey } = form.values;
      this.props.app.ui.progressIndicator.show();

      try {
        res = await this.dm.r14License.authAdminConfig({ key, adminSecretKey });
        if (!res.accessToken) throw new Error("No access token.");
        this.setState({
          key,
          accessToken: res.accessToken,
          adminConfig: res.adminConfig || null,
          create: res.adminConfig ? false : true,
        });
      } catch (err) {
        console.error(err)
        this.props.app.ui.snackBar.show({
          message: "Authentication Failed.",
          variant: "error",
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });

      return false;
    }
    async handleCreateSubmit(form) {
      this.props.app.ui.progressIndicator.show();
      try {
        if (!this.state.accessToken) throw new Error("No access token.");
        let adminConfig = await this.dm.r14License.createAdminConfig(
          this.state.key,
          this.state.accessToken,
          form.values
        );
        if(! adminConfig) throw new Error("Admin config not found");
        this.setState({
          create: false,
          adminConfig
        });
        this.props.app.ui.snackBar.show({
          message: "Admin config has been created.",
          variant: "success",
        });
      } catch (err) {
        this.props.app.ui.snackBar.show({
          message: "An error occurred while creating the admin config.",
          variant: "error",
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return false;
    }
    async handleDeletePress() {
      if(! window.confirm("Are you sure?")) return false;
      this.props.app.ui.progressIndicator.show();
      try {
        if (!this.state.accessToken) throw new Error("No access token.");
        let success = await this.dm.r14License.deleteAdminConfig(
          this.state.key,
          this.state.accessToken,
        );
        if(! success) throw new Error("Error deleting token");
        this.setState({
          create: true,
          adminConfig : null
        });
        this.props.app.ui.snackBar.show({
          message: "Admin config has been deleted.",
          variant: "success",
        });
      } catch (err) {
        this.props.app.ui.snackBar.show({
          message: "An error occurred while deleting the admin config.",
          variant: "error",
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return false;
    }
    handleCreatePress() {
      this.setState({ create: true });
    }
    handleReplacePress() {
      this.setState({ create: true });
    }
    handleCreateCancelPress() {
      this.setState({ create: false });
    }
    renderAdminConfigCard() {
      let content = <Button title='Create' onPress={this.handleCreatePress} />;
      let titleControlsRight = [];
      if (this.state.adminConfig) {
        const adminConfig = this.state.adminConfig;
        let labelViews = [];
        let expiresIn = adminConfig.expiresIn
          ? parseInt(adminConfig.expiresIn)
          : null;
        let createdAt = adminConfig.createdAt
          ? new Date(adminConfig.createdAt)
          : null;
        let dateUtils = this.props.app.utils.date;
        labelViews.push(
          <LabelView style={styles.labelView} key='state' label='state'>
            <StateIndicatorText
              label={
                adminConfig.active ? "License Active" : "License Deactivated"
              }
              color={adminConfig.active ? "green" : "red"}
            />
          </LabelView>
        );
        labelViews.push(
          <LabelView
            style={styles.labelView}
            key='description'
            label='Description'
          >
            <Text>{adminConfig.description}</Text>
          </LabelView>
        );
        if (createdAt) {
          labelViews.push(
            <LabelView style={styles.labelView} key='createdAt' label='Created'>
              <Text>
                {dateUtils.toShortDate(createdAt)} {dateUtils.toTime(createdAt)}
              </Text>
            </LabelView>
          );
        }
        if (createdAt && expiresIn !== null) {
          let expiresAt = new Date(createdAt);
          expiresAt.setMilliseconds(expiresAt.getMilliseconds() + expiresIn);
          labelViews.push(
            <LabelView style={styles.labelView} key='expiresAt' label='Expires'>
              <Text>
                {dateUtils.toShortDate(expiresAt)} {dateUtils.toTime(expiresAt)}
              </Text>
            </LabelView>
          );
        }
        content = <View style={styles.labelViews}>{labelViews}</View>;

        // Create the popup menu
        titleControlsRight.push(
          <PopUpMenu
            controlIcon='dotsVertical'
            // controlIconSize='small'
            key='menu'
            direction='downLeft'
          >
            <PopUpMenuItem
              icon='refresh'
              label='Replace'
              key='replace'
              onPress={this.handleReplacePress}
            />
            <PopUpMenuItem
              icon='delete'
              label='Delete'
              key='delete'
              onPress={this.handleDeletePress}
            />
          </PopUpMenu>
        );
      }

      return (
        <CenteredCard
          title='Admin Config'
          titleControlsRight={titleControlsRight}
        >
          {content}
        </CenteredCard>
      );
    }
    renderAuthForm() {
      let initialValues = {};
      return (
        <CenteredForm
          name='authForm'
          title='Admin Config'
          onSubmit={this.handleAuthSubmit}
          style={styles.form}
          validateBeforeSubmit
          initialValues={initialValues}
          controlsBottomRight={[
            <SubmitButton title='Continue' key='continue' />,
          ]}
        >
          <TextInputField
            name='key'
            label='Key'
            required='Please input a key.'
          />
          <TextInputField
            name='adminSecretKey'
            label='Admin Secret Key'
            required='Please input an admin secret key.'
            secure
          />
        </CenteredForm>
      );
    }
    renderCreateForm() {
      let initialValues = null;
      return (
        <CenteredForm
          name='createForm'
          title='Create Admin Config'
          onSubmit={this.handleCreateSubmit}
          style={styles.form}
          validateBeforeSubmit
          initialValues={initialValues}
          controlsBottomRight={[<Button title='Cancel' variant="text" key='cancel' onPress={this.handleCreateCancelPress} />, <SubmitButton title='Save' key='save' />]}
        >
          <SwitchField name='active' label='Is Active' />
          <TextInputField
            name='expiresIn'
            label='Expires'
            required='Please enter a value for expires.'
            validator='positiveInt'
            helper='Milliseconds'
          />
          <TextInputField
            name='description'
            label='Description'
            required='Please enter a short description.'
            multiline
            autoSize
          />
          {/* <TextInputField
            name='verifyUrl'
            label='Verification URL'
            helper='Optional.'
          /> */}
          {/* <FieldGroup>
            <TextInputField
              name='syncTimeInterval'
              label='Sync Interval'
              required='Please enter a sync interval.'
              validator='positiveInt'
              helper='Milliseconds'
            />
            <TextInputField
              name='syncFailureTimeInterval'
              label='Sync Failure Interval'
              validator='positiveInt'
              required='Please enter a sync interval.'
              helper='Milliseconds'
            />
          </FieldGroup> */}
        </CenteredForm>
      );
    }
    render() {
      return (
        <View style={styles.layout}>
          {!this.state.accessToken && this.renderAuthForm()}
          {this.state.accessToken &&
            !this.state.create &&
            this.renderAdminConfigCard()}
          {this.state.accessToken &&
            this.state.create &&
            this.renderCreateForm()}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  layout: {
    flex: 1,
  },
  labelView: {
    paddingBottom: 16,
  },
  labelViews: {
    marginBottom: -16,
  },
});
