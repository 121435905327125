import R14, { AsyncStorage } from "../core";

export default class userDomain extends R14.EntityDomain {
  constructor() {
    super({ name: "manualEntryUser" });
    this.SERVICE_TYPE_MANUAL_ENTRY = "MANUAL_ENTRY";
    this.SERVICE_TYPE_VIRTUAL_WORKSPACE = "VIRTUAL_WORKSPACE";
  }
  async fetchServiceInfo(uid) {
    let manualEntryUser = await super.get(
      uid,
      `manualEntryConfig {
      name
      uid
    }
    virtualWorkspaceConfig {
      name
      uid
      virtualWorkspaces {
        nodes {
          name
          uid
        }
      }
    }`
    );
    if (!manualEntryUser) {
      throw new Error("Unknown error");
    }
    let services = {
      [this.SERVICE_TYPE_MANUAL_ENTRY]: [],
      [this.SERVICE_TYPE_VIRTUAL_WORKSPACE]: [],
    };
    if (
      manualEntryUser.manualEntryConfig &&
      manualEntryUser.manualEntryConfig.uid
    )
      services[this.SERVICE_TYPE_MANUAL_ENTRY].push({
        uid: manualEntryUser.manualEntryConfig.uid,
        name: manualEntryUser.manualEntryConfig.name,
      });
    if (
      manualEntryUser.virtualWorkspaceConfig &&
      manualEntryUser.virtualWorkspaceConfig.virtualWorkspaces &&
      manualEntryUser.virtualWorkspaceConfig.virtualWorkspaces.nodes &&
      manualEntryUser.virtualWorkspaceConfig.virtualWorkspaces.nodes.length
    )
      services[this.SERVICE_TYPE_VIRTUAL_WORKSPACE] =
        manualEntryUser.virtualWorkspaceConfig.virtualWorkspaces.nodes.map(
          (virtualWorkspace) => ({
            uid: virtualWorkspace.uid,
            name: virtualWorkspace.name,
            virtualWorkspaceConfigUid:
              manualEntryUser.virtualWorkspaceConfig.uid,
          })
        );

    return services;
  }
  async fetchAccountSettingsFormData(uid) {
    return {
      values: {
        password: null,
        newPassword: null,
        confirmNewPassword: null,
      },
    };
  }

  async updateAccountSettings({ password, newPassword }) {
    let res = await this.api.mutate(
      `
        mutation UpdateAccountSettings($input: UpdateAccountSettingsInput!) {
          updateAccountSettings(input: $input){
            manualEntryUser {
              uid
              name
            }
            success
            error
          }
       }`,
      {
        input: {
          password,
          newPassword,
        },
      }
    );
    if (
      res.data &&
      res.data.updateAccountSettings &&
      res.data.updateAccountSettings.error
    )
      throw new Error(res.data.updateAccountSettings.error);
    else if (res.errors && res.errors.length) {
      throw new Error(res.errors[0].message);
    } else if (!res.data.updateAccountSettings) {
      throw new Error("Unknown error");
    }
    return true;
  }
}
