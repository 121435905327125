import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Navigation from "../Navigation";
import RootComponents from "../RootComponents";

function AppBase({ progressIndicatorVariant = null }) {
  return (
    <BrowserRouter>
      <Route component={Navigation} />
      <RootComponents progressIndicatorVariant={progressIndicatorVariant} />
    </BrowserRouter>
  );
}
export default AppBase;
