import React from "react";
import R14, {
  View,
  StyleSheet,
  ScrollView,
  Image,
  Colors,
  IconButton,
  ControlsView,
} from "../core";
export default R14.connect(
  class FieldImage extends React.Component {
    constructor(props) {
      super(props);
      this.handleLayout = this.handleLayout.bind(this);
      this.handleZoomIn = this.handleZoomIn.bind(this);
      this.handleZoomOut = this.handleZoomOut.bind(this);
      this.state = {
        imageHeight: null,
        imageWidth: null,
        height: null,
        width: null,
        zoom: 0,
        scale: 1,
      };
      this._initImage = true;
      this._zoomInterval = 0.3;
    }
    componentDidMount() {
      this.initImage();
    }
    componentDidUpdate(prevProps) {
      if (prevProps.idx !== this.props.idx) this.initImage();
    }
    handleZoomIn() {
      this.setState({
        zoom: Math.round((this.state.zoom + this._zoomInterval) * 10) / 10,
      });
    }
    handleZoomOut() {
      this.setState({
        zoom: Math.round((this.state.zoom - this._zoomInterval) * 10) / 10,
      });
    }
    get field(){
      return this.props.field;
    }
    get characters(){
      return this.props.characters
    }
    async initImage() {
      let imageSize = await Image.getSize(
        `data:image/png;base64,${this.field.imageBase64}`
      );
      this.setState({
        imageHeight: imageSize.height,
        imageWidth: imageSize.width,
        zoom: 0,
      });
    }
    calculateScale() {
      let scale = 1;
      if (
        this.state.height &&
        this.state.width &&
        this.state.imageWidth &&
        this.state.imageHeight
      ) {
        if (this.state.imageHeight > this.state.imageWidth) {
          scale = this.state.height / this.state.imageHeight;
          if (this.state.imageWidth * scale > this.state.width)
            scale = this.state.width / this.state.imageWidth;
        } else {
          scale = this.state.width / this.state.imageWidth;
          if (this.state.imageHeight * scale > this.state.height)
            scale = this.state.height / this.state.imageHeight;
        }
      }
      return scale;
    }
    async handleLayout({ nativeEvent }) {
      let newState = {
        height: nativeEvent.layout.height,
        width: nativeEvent.layout.width,
      };
      this.setState(newState);
    }
    createDynamicStyles(scale) {
      let zScale = scale + this.state.zoom;
      let characterStyles = {};
      if (this.characters) {
        this.characters.forEach((char) => {
          let updatedValue =
            char.updatedValue !== undefined ? char.updatedValue : null;
          if (!char.reject && !updatedValue) return;
          characterStyles[`character${char.uuid}`] = {
            height: char.offset.height * this.state.imageHeight * zScale,
            width: char.offset.width * this.state.imageWidth * zScale,
            left: char.offset.left * this.state.imageWidth * zScale,
            top: char.offset.top * this.state.imageHeight * zScale,
          };
          if (char.reject) {
            characterStyles[`character${char.uuid}`].backgroundColor =
              Colors.secondary;
          }
          if (updatedValue) {
            characterStyles[`character${char.uuid}`].backgroundColor = "#4caf50";
          }
        });
      }
      return StyleSheet.create({
        image: {
          transform: [
            {
              scale: zScale,
              translateX:
                (this.state.imageWidth * zScale - this.state.imageWidth) /
                (zScale * 2),
              translateY:
                (this.state.imageHeight * zScale - this.state.imageHeight) /
                (zScale * 2),
            },
          ],
        },
        imageWrapper: {
          height: this.state.imageHeight * zScale,
          width: this.state.imageWidth * zScale,
          marginTop:
            this.state.imageHeight * zScale > this.state.height
              ? 0
              : (this.state.height - this.state.imageHeight * zScale) / 2,
          marginLeft:
            this.state.imageWidth * zScale > this.state.width
              ? 0
              : (this.state.width - this.state.imageWidth * zScale) / 2,
        },
        ...characterStyles,
      });
    }
    renderCharacterOverlay() {
      return;
    }
    render() {
      let scale = this.calculateScale();
      let dynamicStyles = this.createDynamicStyles(scale);
      return (
        <View style={styles.imageFieldContainer}>
          <View style={styles.imageField}>
            <ScrollView
              onLayout={this.handleLayout}
              style={styles.imageFieldScrollView}
              height={98}
              overflow='auto'
            >
              {this.field && (
                <View style={[styles.imageWrapper, dynamicStyles.imageWrapper]}>
                  <Image
                    // resizeMode="cover"
                    width={this.state.imageWidth}
                    height={this.state.imageHeight}
                    style={[styles.image, dynamicStyles.image]}
                    source={`data:image/png;base64,${this.field.imageBase64}`}
                  />
                  <View style={styles.characterOverlay}>
                    {this.characters &&
                      this.characters.map((char) => {
                        let updatedValue =
                          char.updatedValue !== undefined
                            ? char.updatedValue
                            : null;
                        if (!char.reject && !updatedValue) return null;
                        return (
                          <View
                            key={`character${char.uuid}`}
                            style={[
                              styles.character,
                              dynamicStyles[`character${char.uuid}`],
                            ]}
                          />
                        );
                      })}
                  </View>
                </View>
              )}
            </ScrollView>
          </View>
          <ControlsView align='right' style={styles.zoomControls}>
            <IconButton
              tooltip={`Zoom Out`}
              icon='magnifyMinus'
              size='small'
              onPress={this.handleZoomOut}
              color={this.state.zoom < 0 ? Colors.primary : Colors.onSurface}
              disabled={this.state.zoom <= 0}
              key='zoomOut'
              style={styles.zoomIcon}
            />
            <IconButton
              tooltip={`Zoom In`}
              icon='magnifyPlus'
              size='small'
              onPress={this.handleZoomIn}
              color={this.state.zoom > 0 ? Colors.primary : Colors.onSurface}
              // disabled={!this.manualEntryDomain.hasPreviousQueueItem}
              key='zoomIn'
              style={styles.zoomIcon}
            />
          </ControlsView>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  imageField: {
    height: 100,
    // marginBottom: 24,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overFlow: "hidden",
  },
  imageFieldContainer: {
    marginBottom: 8,
  },
  characterOverlay: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
  },
  character: {
    position: "absolute",
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.8),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.onSurface,
    opacity: 0.3,
  },
  zoomIcon: {
    ...StyleSheet.margin(0),
  },
  zoomControls: {
    marginRight: 0,
  },
  image: {
    flex: 1,
    backgroundPosition: "center",
  },
  imageWrapper: {
    // flex: 0,
    position: "relative",
    overflow: "hidden",
  },
});
