import React from "react";
import { Provider } from "mobx-react";
import PropTypes from "prop-types";
// import RouteBase from './base/RouteBase';
import R14 from "../R14";
import StyleSheet from "./StyleSheet";
import ActivityIndicator from "./ActivityIndicator";
import AnimatedView from "./AnimatedView";
import View from "./View";
import Text from "./Text";
import Theme, { Colors } from "./Theme";

/** Native base for route navigation. */
const Route = R14.connect(
  class Route extends React.Component {
    static propTypes = {
      /** The name of the route. */
      name: PropTypes.string.isRequired,
      /** Route config as defined in given routes config. */
      config: PropTypes.object.isRequired,
    };
    constructor(props) {
      super(props);
      this.handleLayout = this.handleLayout.bind(this);
      this.name = null;
      this.path = null;
      this.hasFocused = false;
      this.hasRendered = false;
      this.isReloading = false;
      this._hasSubmittedForm = false;
      this._currLayout = null;
      this._portal = this.props.r14.navigation.getPortalByRouteName(
        this.props.name
      );
      this._r14AppInstance = this._portal.createAppInstance();
      this.state = {
        component: null,
        isLoading: false,
      };
    }
    forward() {
      let ret = false;
      if (this.props.config.to) {
        let route = null;
        // let params = this._portal && this._portal.data ? this._portal.data : {};
        let params = {};
        if (typeof this.props.config.to === "string") {
          route = this.props.config.to;
        } else {
          route = this.props.config.to.route;
          if (this.props.config.to.params) {
            params = { ...params, ...this.props.config.to.params };
            //params = this.props.config.to.params;
          }
        }
        this.props.app.nav.to(route, params);
        return true;
      }
      return ret;
    }

    componentDidUpdate(prevProps) {
      let shouldInit = this.name === this.props.name && this.hasSubmittedForm;
      /** @todo Route componenetWillReact, figure out why below statement doesn't work */
      //console.log("UPDATE UPDATE BELOW CODE MAY NEED TO BE UN COMMENTED!!!!!!!!!!!!!!!!");
      // this.props.r14.navigation.initializeContainerPortalsByRoute(
      //   this.props.name
      // );
      if (this.path === this.props.path && this.hasSubmittedForm) {
        if (this.forward()) return false;
        this.props.r14.navigation.initActiveRoute(this.props.name, this.props);
        this.isReloading = true;
        this.initializeActionResult();
      }
    }
    componentWillFocus() {
      if (!this.props.path || this.path !== this.props.path) {
        if (this.forward()) return false;
        /** @todo separate out native and web, this is confusing... */

        /** @todo Make sure this is truly an unchanged route. */
        let isActiveRoute = false;
        if (
          this._portal &&
          this._portal.route &&
          this._portal.route.path &&
          this._portal.route.path === this.props.path
        ) {
          isActiveRoute = true;
          // Route already initialized. Is this a good enough way to do it?
        }
        if (!isActiveRoute)
          this.props.r14.navigation.initActiveRoute(
            this.props.name,
            this.props
          );
        this.isReloading = false;
        this.name = this.props.name;
        this.path = this.props.path;
        //if(! this.state.component || ! isActiveRoute)
        this.initializeActionResult();
      }
    }
    // componentDidFocus() {
    //   /** @todo check params for update? */
    // }
    setLoading(isLoading) {
      this.setState({
        isLoading: isLoading,
      });
    }
    get isLoading() {
      return this.state.isLoading;
    }
    get currLayout() {
      return this._currLayout;
    }
    handleLayout(event) {
      this._currLayout =
        event && event.nativeEvent && event.nativeEvent.layout
          ? event.nativeEvent.layout
          : null;
    }
    async load() {}
    async initializeActionResult() {
      if (!this.props.name) throw "Route name prop is required.";
      //if (!this.props.path) throw ('Route path prop is required.');
      if (!this.props.config)
        throw `Route config prop is required for route '${this.props.name}'.`;
      //if (!this.props.config.actions) throw (`Route config actions is required for route '${this.props.name}'.`);

      // Initialize the app
      await this.props.app.initialize();

      this.props.app.ui.form.clearSubmittedForm();
      // Set state to loading
      /** @todo Route loading, consider putting in timeout for fast loads */
      // If it is reloading, just show a progressIndicator

      if (this.isReloading) {
        this.props.app.ui.progressIndicator.show();
      } else {
        this.setState({
          component: null,
          isLoading: true,
        });
      }
      // Check for onBeforeLoad?
      let beforeLoadRes = await this.props.r14.navigation.handleBeforeLoad(
        this.props.app
      );
      // if onBeforeLoad returns an element, set it and return
      if (React.isValidElement(beforeLoadRes)) {
        this.setState({
          component: beforeLoadRes,
          isLoading: false,
        });
        return;
      } else if (beforeLoadRes !== true) {
        // Don't do anything, just return
        // It will continue to wait until something is done.
        return;
      }

      let action = this.props.config.action || "default";

      let actionName = null;
      let Actions = null;
      /** @todo Depreciate Action from method name. */
      if (this.props.config.actions) {
        Actions = new this.props.config.actions();
        if (!Actions[`${action}Action`])
          throw `Unable to find action '${action}Action' for route '${this.props.name}'`;
        actionName = `${action}Action`;
      } else {
        Actions = this.props.app.action;
        actionName = this.props.config.action || this.props.name;
      }

      if (!Actions || !actionName || !Actions[actionName])
        throw new Error(
          `Unable to find action method for route '${this.props.name}'.`
        );

      let Component = await Actions[actionName]({
        ...this._portal.initialParams,
        ...this._portal.params,
        ...this._portal.query,
      });

      if (this.isReloading) {
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.isReloading = false;
      }
      // Create app context with the route
      this.setState({
        component: Component,
        isLoading: false,
      });
    }
    get hasSubmittedForm() {
      // if (this._hasSubmittedForm) return false;
      if (this.props.app.ui.form.activeRouteName === this.props.name) {
        this._hasSubmittedForm = true;
        return true;
      } else return false;
    }
    render() {
      if (this.state.isLoading)
        return (
          <ActivityIndicator
            size='large'
            containerStyle={styles.activityIndicator}
            height={this.currLayout ? this.currLayout.height : null}
            width={this.currLayout ? this.currLayout.width : null}
          />
        );

      if (this.hasSubmittedForm) {
        /** @todo: Route.js: find a better way of making the form react, totally hacky */
      }
      return (
        <Provider app={this._r14AppInstance}>
          <View
            key={Theme.key}
            style={styles.routeContainer}
            onLayout={this.handleLayout}
          >
            <AnimatedView
              animation={{
                from: {
                  opacity: 0,
                },
                to: {
                  opacity: 1,
                },
              }}
              timingFunction='ease-out'
              duration={250}
              style={styles.route}
            >
              {this.state.component}
            </AnimatedView>
          </View>
        </Provider>
      );
    }
  }
);
const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1,
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: Colors.background,
  },
  routeContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  route: {
    flex: 1,
  },
  text: {
    color: "#000000",
  },
});
export default Route;
// import React, {
//   Suspense,
//   useState,
//   useMemo,
//   useEffect,
//   useTransition,
//   useRef,
// } from "react";
// import { Provider } from "mobx-react";
// import PropTypes from "prop-types";
// // import RouteBase from './base/RouteBase';
// import R14, { withAppObserver, useApp, useUi, useNav, useR14 } from "../R14";
// import StyleSheet from "./StyleSheet";
// import ActivityIndicator from "./ActivityIndicator";
// import AnimatedView from "./AnimatedView";
// import View from "./View";
// import Text from "./Text";
// import Theme, { Colors } from "./Theme";

// const Route = withAppObserver((props) => {
//   const nav = useNav();
//   const app = useApp();
//   const r14 = useR14();
//   const formUi = useUi("form");
//   const progressIndicatorUi = useUi("progressIndicator");
//   const portal = useMemo(() => r14.navigation.getPortalByRouteName(props.name));
//   const [path, setPath] = useState(null);
//   const [name, setName] = useState(null);
//   const [reloading, setReloading] = useState(false);
//   //const [isPending, startTransition] = useTransition();
//   const [loading, setLoading] = useState(false);
//   // const [layout, setLayout] = useState(null);
//   const layoutRef = useRef(null);
//   const [component, setComponent] = useState(null);
//   const [r14Instance, setR14Instance] = useState(portal.createAppInstance());
//   const hasSubmittedForm = () => formUi.activeRouteName === props.name;
//   const forward = () => {
//     if (props.config.to) {
//       let route = null;
//       let params = {};
//       if (typeof props.config.to === "string") route = props.config.to;
//       else {
//         route = props.config.to.route;
//         if (props.config.to.params)
//           params = { ...params, ...props.config.to.params };
//       }
//       nav.to(route, params);
//       return true;
//     }
//     return false;
//   };
//   const updateLoading = (loading) => {
//     //startTransition(() => {
//     setLoading(loading);
//     //});
//   };
//   const initializeActionResult = async () => {
//     if (!props.name) throw new Error("Route name prop is required.");
//     if (!props.config)
//       throw new Error(
//         `Route config prop is required for route '${props.name}'.`
//       );

//     // Initialize the app
//     await app.initialize();

//     formUi.clearSubmittedForm();

//     if (reloading) progressIndicatorUi.show();
//     else {
//       setComponent(null);
//       updateLoading(true);
//     }

//     // Check for onBeforeLoad?
//     let beforeLoadRes = await r14.navigation.handleBeforeLoad(app);

//     // if onBeforeLoad returns an element, set it and return
//     if (React.isValidElement(beforeLoadRes)) {
//       this.setState({
//         component: beforeLoadRes,
//         isLoading: false,
//       });
//       setComponent(beforeLoadRes);
//       updateLoading(false);
//       return;
//     } else if (beforeLoadRes !== true)
//       // Don't do anything, just return
//       // It will continue to wait until something is done.
//       return;

//     let action = props.config.action || "default";

//     let actionName = null;
//     let Actions = null;
//     /** @todo Depreciate Action from method name. */
//     if (props.config.actions) {
//       Actions = new props.config.actions();
//       if (!Actions[`${action}Action`])
//         throw `Unable to find action '${action}Action' for route '${props.name}'`;
//       actionName = `${action}Action`;
//     } else {
//       Actions = app.action;
//       actionName = props.config.action || props.name;
//     }

//     if (!Actions || !actionName || !Actions[actionName])
//       throw new Error(
//         `Unable to find action method for route '${props.name}'.`
//       );

//     let Component = await Actions[actionName]({
//       ...portal.initialParams,
//       ...portal.params,
//       ...portal.query,
//     });

//     if (reloading) {
//       progressIndicatorUi.hide({ timeout: 750 });
//       setReloading(false);
//     }
//     // Create app context with the route
//     setComponent(Component);
//     updateLoading(false);
//     return Component;
//   };
//   const handleLayout = (event) => {
//     let newLayout =
//       event && event.nativeEvent && event.nativeEvent.layout
//         ? event.nativeEvent.layout
//         : null;
//     layoutRef.current = newLayout;
//   };
//   useEffect(() => {
//     let shouldInitialize = false;
//     // Handle a submitted form
//     if (path === props.path && formUi.activeRouteName === props.name) {
//       if (forward()) return false;
//       r14.navigation.initActiveRoute(props.name, props);
//       setReloading(true);
//       shouldInitialize = true;
//     }
//     if (!props.path || path !== props.path) {
//       if (forward()) return false;
//       /** @todo separate out native and web, this is confusing... */
//       /** @todo Make sure this is truly an unchanged route. */
//       if (
//         portal &&
//         portal.route &&
//         portal.route.path &&
//         portal.route.path === props.path
//       )
//         r14.navigation.initActiveRoute(props.name, props);

//       setReloading(false);
//       setPath(props.path);
//       setName(props.name);
//       shouldInitialize = true;
//     }
//     shouldInitialize && initializeActionResult();
//   }, [props.path, formUi.activeRouteName]);
//   let RouteComponent = () => {
//     return (
//       <Provider app={r14Instance}>
//         <View
//           key={Theme.key}
//           style={styles.routeContainer}
//           onLayout={handleLayout}
//         >
//           <AnimatedView
//             animation={{
//               from: {
//                 opacity: 0,
//               },
//               to: {
//                 opacity: 1,
//               },
//             }}
//             timingFunction='ease-out'
//             duration={250}
//             style={styles.route}
//           >
//             {component}
//           </AnimatedView>
//         </View>
//       </Provider>
//     );
//   };
//   return loading ? (
//     <ActivityIndicator
//       size='large'
//       containerStyle={styles.activityIndicator}
//       height={layoutRef.current ? layoutRef.current.height : null}
//       width={layoutRef.current ? layoutRef.current.width : null}
//     />
//   ) : (
//     <RouteComponent />
//   );
// });
// Route.propTypes = {
//   /** The name of the route. */
//   name: PropTypes.string.isRequired,
//   /** Route config as defined in given routes config. */
//   config: PropTypes.object.isRequired,
// };

// // /** Native base for route navigation. */
// // const Route_legacy = R14.connect(
// //   class Route extends React.Component {
// //     static propTypes = {
// //       /** The name of the route. */
// //       name: PropTypes.string.isRequired,
// //       /** Route config as defined in given routes config. */
// //       config: PropTypes.object.isRequired,
// //     };
// //     constructor(props) {
// //       super(props);
// //       this.handleLayout = this.handleLayout.bind(this);
// //       this.name = null;
// //       this.path = null;
// //       //this.hasFocused = false;
// //       //this.hasRendered = false;
// //       this.isReloading = false;
// //       this._hasSubmittedForm = false;
// //       this._currLayout = null;
// //       this._portal = this.props.r14.navigation.getPortalByRouteName(
// //         this.props.name
// //       );
// //       this._r14AppInstance = this._portal.createAppInstance();
// //       this.state = {
// //         component: null,
// //         isLoading: false,
// //       };
// //     }
// //     forward() {
// //       let ret = false;
// //       if (this.props.config.to) {
// //         let route = null;
// //         // let params = this._portal && this._portal.data ? this._portal.data : {};
// //         let params = {};
// //         if (typeof this.props.config.to === "string") {
// //           route = this.props.config.to;
// //         } else {
// //           route = this.props.config.to.route;
// //           if (this.props.config.to.params) {
// //             params = { ...params, ...this.props.config.to.params };
// //             //params = this.props.config.to.params;
// //           }
// //         }
// //         this.props.app.nav.to(route, params);
// //         return true;
// //       }
// //       return ret;
// //     }
// //     componentDidUpdate(prevProps) {
// //       let shouldInit = this.name === this.props.name && this.hasSubmittedForm;
// //       /** @todo Route componenetWillReact, figure out why below statement doesn't work */
// //       //console.log("UPDATE UPDATE BELOW CODE MAY NEED TO BE UN COMMENTED!!!!!!!!!!!!!!!!");
// //       // this.props.r14.navigation.initializeContainerPortalsByRoute(
// //       //   this.props.name
// //       // );

// //       if (this.path === this.props.path && this.hasSubmittedForm) {
// //         if (this.forward()) return false;
// //         this.props.r14.navigation.initActiveRoute(this.props.name, this.props);
// //         this.isReloading = true;
// //         this.initializeActionResult();
// //       }
// //     }
// //     componentWillFocus() {
// //       if (!this.props.path || this.path !== this.props.path) {
// //         if (this.forward()) return false;
// //         /** @todo separate out native and web, this is confusing... */

// //         /** @todo Make sure this is truly an unchanged route. */
// //         let isActiveRoute = false;
// //         if (
// //           this._portal &&
// //           this._portal.route &&
// //           this._portal.route.path &&
// //           this._portal.route.path === this.props.path
// //         ) {
// //           isActiveRoute = true;
// //           // Route already initialized. Is this a good enough way to do it?
// //         }
// //         if (!isActiveRoute)
// //           this.props.r14.navigation.initActiveRoute(
// //             this.props.name,
// //             this.props
// //           );
// //         this.isReloading = false;
// //         this.name = this.props.name;
// //         this.path = this.props.path;
// //         //if(! this.state.component || ! isActiveRoute)
// //         this.initializeActionResult();
// //       }
// //     }
// //     // componentDidFocus() {
// //     //   /** @todo check params for update? */
// //     // }
// //     setLoading(isLoading) {
// //       this.setState({
// //         isLoading: isLoading,
// //       });
// //     }
// //     get isLoading() {
// //       return this.state.isLoading;
// //     }
// //     get currLayout() {
// //       return this._currLayout;
// //     }
// //     handleLayout(event) {
// //       this._currLayout =
// //         event && event.nativeEvent && event.nativeEvent.layout
// //           ? event.nativeEvent.layout
// //           : null;
// //     }
// //     async initializeActionResult() {
// //       if (!this.props.name) throw "Route name prop is required.";
// //       //if (!this.props.path) throw ('Route path prop is required.');
// //       if (!this.props.config)
// //         throw `Route config prop is required for route '${this.props.name}'.`;
// //       //if (!this.props.config.actions) throw (`Route config actions is required for route '${this.props.name}'.`);

// //       // Initialize the app
// //       await this.props.app.initialize();

// //       this.props.app.ui.form.clearSubmittedForm();
// //       // Set state to loading
// //       /** @todo Route loading, consider putting in timeout for fast loads */
// //       // If it is reloading, just show a progressIndicator

// //       if (this.isReloading) {
// //         this.props.app.ui.progressIndicator.show();
// //       } else {
// //         this.setState({
// //           component: null,
// //           isLoading: true,
// //         });
// //       }
// //       // Check for onBeforeLoad?
// //       let beforeLoadRes = await this.props.r14.navigation.handleBeforeLoad(
// //         this.props.app
// //       );
// //       // if onBeforeLoad returns an element, set it and return
// //       if (React.isValidElement(beforeLoadRes)) {
// //         this.setState({
// //           component: beforeLoadRes,
// //           isLoading: false,
// //         });
// //         return;
// //       } else if (beforeLoadRes !== true) {
// //         // Don't do anything, just return
// //         // It will continue to wait until something is done.
// //         return;
// //       }

// //       let action = this.props.config.action || "default";

// //       let actionName = null;
// //       let Actions = null;
// //       /** @todo Depreciate Action from method name. */
// //       if (this.props.config.actions) {
// //         Actions = new this.props.config.actions();
// //         if (!Actions[`${action}Action`])
// //           throw `Unable to find action '${action}Action' for route '${this.props.name}'`;
// //         actionName = `${action}Action`;
// //       } else {
// //         Actions = this.props.app.action;
// //         actionName = this.props.config.action || this.props.name;
// //       }

// //       if (!Actions || !actionName || !Actions[actionName])
// //         throw new Error(
// //           `Unable to find action method for route '${this.props.name}'.`
// //         );

// //       let Component = await Actions[actionName]({
// //         ...this._portal.initialParams,
// //         ...this._portal.params,
// //         ...this._portal.query,
// //       });

// //       if (this.isReloading) {
// //         this.props.app.ui.progressIndicator.hide({ timeout: 750 });
// //         this.isReloading = false;
// //       }
// //       // Create app context with the route
// //       this.setState({
// //         component: Component,
// //         isLoading: false,
// //       });
// //     }
// //     get hasSubmittedForm() {
// //       // if (this._hasSubmittedForm) return false;
// //       if (this.props.app.ui.form.activeRouteName === this.props.name) {
// //         this._hasSubmittedForm = true;
// //         return true;
// //       } else return false;
// //     }
// //     render() {
// //       if (this.hasSubmittedForm) {
// //         /** @todo: Route.js: find a better way of making the form react, totally hacky */
// //       }
// //       console.log("CHECK LOADING", this.state.isLoading);
// //       return (
// //         <Suspense
// //           fallback={
// //             <ActivityIndicator
// //               size='large'
// //               containerStyle={styles.activityIndicator}
// //               height={this.currLayout ? this.currLayout.height : null}
// //               width={this.currLayout ? this.currLayout.width : null}
// //             />
// //           }
// //         >
// //           {this.state.isLoading ? null : (
// //             <Provider app={this._r14AppInstance}>
// //               <View
// //                 key={Theme.key}
// //                 style={styles.routeContainer}
// //                 onLayout={this.handleLayout}
// //               >
// //                 <AnimatedView
// //                   animation={{
// //                     from: {
// //                       opacity: 0,
// //                     },
// //                     to: {
// //                       opacity: 1,
// //                     },
// //                   }}
// //                   timingFunction='ease-out'
// //                   duration={250}
// //                   style={styles.route}
// //                 >
// //                   {this.state.component}
// //                 </AnimatedView>
// //               </View>
// //             </Provider>
// //           )}
// //         </Suspense>
// //       );
// //     }
// //   }
// // );
// const styles = StyleSheet.create({
//   activityIndicator: {
//     flex: 1,
//     justifyContent: "space-around",
//     alignItems: "center",
//     backgroundColor: Colors.background,
//   },
//   routeContainer: {
//     flex: 1,
//     backgroundColor: Colors.background,
//   },
//   route: {
//     flex: 1,
//   },
//   text: {
//     color: "#000000",
//   },
// });
// export default Route;
