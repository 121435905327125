import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import Touchable from "./Touchable";
import View from "./View";
import DraggableView from "./DraggableView";
import DraggableContainer from "./DraggableContainer";

export default function AreaSelectionView({
  onSelect,
  onCancel,
  minHeight = 2,
  minWidth = 2,
  resizeTargetSize = 4,
  backgroundColor = null,
  style = null,
  overlayStyle = null,
  selectionStyle = null,
  offsetTop = null,
  offsetLeft = null,
  width = null,
  height = null,
  onLayout = null,
  onCtrlPress = null,
}) {
  const [select, setSelect] = useState(false);
  const [selection, setSelection] = useState(
    offsetTop !== null &&
      offsetLeft !== null &&
      width !== null &&
      height !== null
      ? { x: offsetLeft, y: offsetTop, width, height }
      : null
  );
  const currSelectionRef = useRef(selection);
  const layoutRef = useRef(null);
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

  // Check if selection props are updated with useEffect
  useEffect(() => {
    // if (
    //   offsetTop !== null &&
    //   offsetLeft !== null &&
    //   width !== null &&
    //   height !== null
    // ) {
    updateSelection({ x: offsetLeft, y: offsetTop, width, height });
    // }
  }, [offsetTop, offsetLeft, width, height]);

  // Add a keydown and keyup event for the control key
  useEffect(() => {
    if (!document) return;
    const isControlKey = (e) => {
      let key = e.key || (e.nativeEvent && e.nativeEvent.key) || null;
      return key === "Control";
    };
    const handleKeyDown = (e) => isControlKey(e) && setCtrlKeyPressed(true);
    const handleKeyUp = (e) => isControlKey(e) && setCtrlKeyPressed(false);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // // credate a use effect to check the selection change
  // // if selection changes, call the onSelect function
  // useEffect(() => {
  //   console.log("CHECK SELECTION", JSON.stringify(selection));
  // }, [selection]);

  const normalizeFinalSelection = (values) => {
    let ret = values ? { ...values } : null;
    if (!values) return null;

    if (values.x < 0) {
      ret.x = 0;
      ret.width = values.width + values.x;
    }
    if (values.y < 0) {
      ret.y = 0;
      ret.height = values.height + values.y;
    }
    if (layoutRef.current) {
      if (values.x + values.width > layoutRef.current.width) {
        ret.width = layoutRef.current.width - values.x;
      }
      if (values.y + values.height > layoutRef.current.height) {
        ret.height = layoutRef.current.height - values.y;
      }
    }

    if (
      ret.width <= 0 ||
      ret.height <= 0 ||
      (minWidth && ret.width < minWidth) ||
      (minHeight && ret.height < minHeight)
    )
      return null;

    return ret;
  };

  const updateSelection = (values, callback = null) => {
    if (callback) {
      const parsedValues = values
        ? {
            x: values.x,
            y: values.y,
            offsetTop: values.y,
            offsetLeft: values.x,
            width: values.width,
            height: values.height,
          }
        : null;
      callback(parsedValues);
    }
    currSelectionRef.current = values;
    setSelection(values);
  };
  const calculateSelection = (event, prevSelection) => {
    if (!event || !event.nativeEvent) return null;
    const { locationX, locationY } = event.nativeEvent;
    const { startX, startY } = prevSelection;
    const diffX = locationX - startX;
    const diffY = locationY - startY;
    return {
      ...prevSelection,
      height: Math.abs(diffY),
      width: Math.abs(diffX),
      x: diffX > 0 ? startX : locationX,
      y: diffY > 0 ? startY : locationY,
    };
  };

  const handleLayout = (event) => {
    layoutRef.current = event.nativeEvent.layout;
    if (onLayout) onLayout(event);
  };

  const handleSelectionEnd = (event) => {
    const prevSelection = currSelectionRef.current;
    let updatedSelection = calculateSelection(event, prevSelection);
    updatedSelection = normalizeFinalSelection(updatedSelection);
    updateSelection(updatedSelection, onSelect);
    setSelect(false);
  };

  const handlePressIn = (event) => {
    const { locationX, locationY } = event.nativeEvent;
    if (onCtrlPress && event.nativeEvent.ctrlKey) {
      onCtrlPress({
        height: 0,
        width: 0,
        offsetTop: locationY,
        offsetLeft: locationX,
      });
      return false;
    }
    const prevSelection = currSelectionRef.current;
    if (prevSelection) {
      if (
        locationX >= prevSelection.x &&
        locationX <= prevSelection.x + prevSelection.width &&
        locationY >= prevSelection.y &&
        locationY <= prevSelection.y + prevSelection.height
      ) {
        return prevSelection;
      }
    }
    setSelect(true);
    updateSelection({
      startX: locationX,
      startY: locationY,
      x: locationX,
      y: locationY,
      width: 0,
      height: 0,
    });
  };
  const handlePressMove = (event) => {
    if (!select) return false;
    const prevSelection = currSelectionRef.current;
    const updatedSelection = calculateSelection(event, prevSelection);
    updateSelection(updatedSelection);
  };
  const handlePressOut = (event) => handleSelectionEnd(event);
  const handlePressCancel = (event) => handleSelectionEnd(event);
  const handleDragEnd = (event) => {
    const prevSelection = currSelectionRef.current;
    const updatedSelection = normalizeFinalSelection({
      ...prevSelection,
      x: prevSelection.x + event.nativeEvent.translationX,
      y: prevSelection.y + event.nativeEvent.translationY,
      height: event.nativeEvent.height,
      width: event.nativeEvent.width,
    });

    updateSelection(updatedSelection, onSelect);
  };
  const createDynamicStyles = () => {
    let dynamicStyles = {};
    if (select) {
      dynamicStyles.selection = {
        // top: selection.y,
        // left: selection.x,
        // height: selection.height,
        // width: selection.width,
      };
      if (backgroundColor) dynamicStyles.backgroundColor = backgroundColor;
    }
    return StyleSheet.create(dynamicStyles);
  };
  let dynamicStyles = createDynamicStyles();
  return (
    <DraggableContainer
      name='areaSelection'
      defaultCursor={ctrlKeyPressed ? "pointer" : "crosshair"}
      style={[styles.areaSelection, style]}
      onPressIn={handlePressIn}
      onPressOut={select ? handlePressOut : null}
      onPressMove={select ? handlePressMove : null}
      onPressCancel={select ? handlePressCancel : null}
      onLayout={handleLayout}
    >
      <View style={[styles.areaSelectionOverlay, overlayStyle]}>
        {selection && (
          <DraggableView
            name='areaSelection'
            style={[styles.selection, dynamicStyles.selection, selectionStyle]}
            offsetTop={selection.y}
            offsetLeft={selection.x}
            height={selection.height}
            width={selection.width}
            disabled={select || ctrlKeyPressed}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragEnd}
            resizable={!(select || ctrlKeyPressed)}
            resizeTargetSize={resizeTargetSize}
          />
        )}
      </View>
    </DraggableContainer>
  );
}

const styles = StyleSheet.create({
  areaSelection: {
    position: "relative",
    // ...StyleSheet.absoluteFill,
    // cursor: "crosshair",
    //  backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.1),
  },
  areaSelectionOverlay: {
    flex: 1,
  },
  selection: {
    position: "absolute",
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2),
  },
});
