import React from "react";
import R14, {
  Redirect,
  TextInputField,
  SubmitButton,
  StyleSheet,
  FadeView,
  Button,
  View,
  Text,
  Colors,
  CenteredForm
} from "../core";
export default R14.connect(
  class LoginVerifyScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleResendPress = this.handleResendPress.bind(this);
    }
    async handleResendPress() {
      this.props.app.ui.progressIndicator.show();
      try {
        let success = await this.props.app.dm.userSession.resendMfaCode();
        this.props.app.ui.snackBar.show({
          message: "A new authentication code has been sent.",
          variant: "success",
        });
      } catch (err) {
        console.log(err);
        this.props.app.ui.snackBar.show({
          message: "Unable to resend authentication code.",
          variant: "error",
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    async handleSubmit(loginVerifyForm) {
      try {
        this.props.app.ui.progressIndicator.show();
        let authState = await this.props.app.dm.userSession.verifyMfaCode(
          loginVerifyForm.values
        );
        let redirectRoute = null;
        switch (authState) {
          case this.props.app.dm.userSession.AUTH_STATE_LOGGED_IN:
            redirectRoute = "landing";
            break;
        }
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        let params = this.props.f ? { f: this.props.f } : null;

        return redirectRoute ? (
          <Redirect to={redirectRoute} params={params} />
        ) : (
          false
        );
      } catch (err) {
        console.log(err);
        this.props.app.ui.snackBar.show({
          message: "Code is invalid or expired.",
          variant: "error",
        });
        // this.props.app.ui
        //   .form("loginVerityForm")
        //   .addError("Code is invalid or expired.");
      }
    }
    render() {
      return (
        <CenteredForm
          name='loginVerityForm'
          title='Verify Account'
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          style={styles.centeredForm}
          controlsBottomRight={[
            <Button
              key='resend'
              title='Resend'
              variant='text'
              style={styles.resendButton}
              onPress={this.handleResendPress}
            />,
            <SubmitButton title='Verify' key='verify' />,
          ]}
        >
          <View style={styles.info}>
            <Text style={styles.infoText}>
              We just sent you a message via SMS with your authentication code.
              Please enter the code below to verify your account.
            </Text>
          </View>
          <TextInputField
            name='mfaCode'
            label='Authentication Code'
            // icon='lock'
            required='Enter authentication code.'
            autoFocus
            autoComplete={false}
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  info: {
    ...StyleSheet.padding(0, 0, 24, 0),
  },
  infoText: {
    fontSize: 14,
    // fontWeight: "300",
    color: StyleSheet.color(Colors.onSurface).rgba(0.9),
  },
  centeredForm: {
    width: 320,
  },
  resendButton: {
    ...StyleSheet.margin(0, 8, 0, 0),
  },
});
