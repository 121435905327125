import React from "react";
import PropTypes from "prop-types";
import View from "./View";
import Surface from "./Surface";
import SectionHeader from "./SectionHeader";
import ControlsView from "./ControlsView";
import StyleSheet from "./StyleSheet";
import R14 from "../R14";
// import { Colors } from '../styles/Theme';

/** A surface based card component */
export default R14.connect(
  class Card extends React.Component {
    static propTypes = {
      /** Optional title for the card. */
      title: PropTypes.string,
      /** Controls such as buttons, icon buttons, etc... Placed at the right of the title.*/
      titleControlsRight: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      /** The elevation (z index shadow effect) of the surface. */
      elevation: PropTypes.number,
      /** Component(s) placed into the main content area of the card. Over-rides props.children if both given. */
      content: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      /** The total width of the component */
      width: PropTypes.number,
      /** The minimum width of the component. */
      minWidth: PropTypes.number,
    };
    renderTitle() {
      if (!this.props.title && !this.props.titleControlsRight) return null;
      return (
        <View style={[styles.title, this.props.titleStyle]}>
          {this.props.titleControlsLeft && (
            <ControlsView align='left'>
              {this.props.titleControlsLeft}
            </ControlsView>
          )}
          <SectionHeader
            style={[styles.titleText, this.props.titleTextStyle]}
            level={2}
            noWrap={this.props.titleNoWrap || false}
            numberOfLines={this.props.titleNumberOfLines || null}
            ellipsisMode={this.props.titleEllipsisMode || null}
            tooltip={this.props.titleTooltip || null}
          >
            {this.props.title}
          </SectionHeader>

          {this.props.titleControlsRight && (
            <ControlsView align='right'>
              {this.props.titleControlsRight}
            </ControlsView>
          )}
        </View>
      );
    }
    render() {
      let widthStyles = StyleSheet.create({
        card: {
          minWidth: this.props.minWidth || 280,
          width: this.props.width || null,
        },
      });

      return (
        <View style={[styles.card, widthStyles.card, this.props.style]}>
          <Surface
            style={[styles.surface, this.props.surfaceStyle]}
            elevation={this.props.elevation || 2}
          >
            {this.renderTitle()}
            <View style={[styles.content, this.props.contentStyle]}>
              {this.props.content || this.props.children}
            </View>
          </Surface>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  card: {
    flexGrow: 1,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: "100%",
        };
    },
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    // marginRight: -12,
    paddingBottom: 8,
  },
  titleText: {
    fontSize: 20,
    paddingBottom: 0,
    lineHeight: 36,
    marginRight: 'auto'
  },
  surface: {
    flex: 1,
    paddingTop: 12,
    marginRight: 16,
    marginBottom: 16,
    borderRadius: 4,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          marginRight: 0,
          borderRadius: 0,
        };
    },
  },
});
