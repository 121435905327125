import React from "react";
import R14, {
  Redirect,
  Button,
  Touchable,
  StyleSheet,
  Text,
  View,
  SectionHeader,
  Colors,
  Surface,
  CenteredCard
} from "../core";
export default R14.connect(
  class ServicesScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    renderServiceSection(type) {
      let services = this.props.services;
      if (!services[type] || !services[type].length) return null;
      let title = null;
      let navTo = null;
      switch (type) {
        case this.props.app.dm.user.SERVICE_TYPE_MANUAL_ENTRY:
          title = "Manual Entry Queue";
          navTo = ({ uid }) => this.props.app.nav.to("manualEntryLoad");
          break;
        case this.props.app.dm.user.SERVICE_TYPE_VIRTUAL_WORKSPACE:
          title = "Virtual Workspace(s)";
          navTo = ({ uid, virtualWorkspaceConfigUid }) =>
            this.props.app.nav.to("virtualWorkspace", {
              uid,
              virtualWorkspaceConfigUid,
            });
          break;
        default:
          // Do nothing
          return null;
      }
      return (

        <View style={styles.serviceSection}>
          <SectionHeader style={styles.serviceSectionHeader} level={3}>
            {title}
          </SectionHeader>
          {services[type]
            .map((service) => {
              return (
                <Touchable
                  style={styles.serviceTouchable}
                  key={service.uid}
                  onPress={() => navTo(service)}
                >
                  <Surface elevation={2} style={styles.serviceSurface}>
                    <Text>{service.name}</Text>
                  </Surface>
                </Touchable>
              );
            })
            .filter((service) => (service ? true : false))}
        </View>
      );
    }
    render() {
      return (
        <CenteredCard title='Select a service...' style={styles.centeredCard}>
          <View style={styles.wrapper}>
            {this.renderServiceSection(
              this.props.app.dm.user.SERVICE_TYPE_MANUAL_ENTRY
            )}
            {this.renderServiceSection(
              this.props.app.dm.user.SERVICE_TYPE_VIRTUAL_WORKSPACE
            )}
          </View>
        </CenteredCard>
      );
    }
  }
);

const styles = StyleSheet.create({
  centeredCard: {
    maxWidth: 448,
  },
  wrapper: {
    marginBottom: -16,
  },
  serviceTouchable: {
    marginBottom: 12,
  },
  sectionHeader: {
    fontSize: 14,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
    paddingBottom: 16,
  },
  serviceSection: {
    paddingBottom: 12,
  },
  serviceSurface: {
    // borderWidth: 1,
    // borderStyle: "solid",
    borderRadius: 4,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  serviceSectionHeader: {
    fontSize: 14,
    ...StyleSheet.padding(0, 0, 16, 0),
  },
});
