import React from "react";
import PropTypes from "prop-types";
import R14, {
  StyleSheet,
  View,
  ScrollView,
  TextInput,
  Image,
  Colors,
  ControlsView,
  IconButton
} from "../core";
// import CharacterImage from "./CharacterImage";
// import FieldImage from "./FieldImageNew";
import FloatingLabel from "./FloatingLabel";
import FieldCharactersRow from "./FieldCharactersRow";


export default R14.connectForm(
  class FieldCharactersEntryField extends React.Component {
    static propTypes = {
      // /** Name for the character entry fields. */
      // name: PropTypes.string.isRequired,
      // /** Value of the character entry fields. */
      // value: PropTypes.any,
    };

    constructor(props) {
      super(props);
      this.handleLayout = this.handleLayout.bind(this);
      this.handleZoomIn = this.handleZoomIn.bind(this);
      this.handleZoomOut = this.handleZoomOut.bind(this);
      //this.columnRenderer = this.columnRenderer.bind(this);
      this.desiredRowHeight = 100;
      this.state = {
        imageHeight: null,
        imageWidth: null,
        //height: this.desiredRowHeight,
        width: null,
        zoom: 0,
        // scale: 1,
      };
      this.zoomInterval = 0.3;
      this.scale = 1;
      this.fontRatio = 1.15;
      this.rowPadding = 4;
      // this.characterGrid = this.props.app.dm.manualEntry.createCharacterGrid(
      //   this.props.item.characters
      // );
      this.field = null;
      this.charactersEntryField = this.props.app.ui.charactersEntryField.getInstance(
        this.props.annotationSetUid
      );
      this.characterGrid = this.charactersEntryField.characters.grid;
    }
    componentDidMount() {
      this.init();
    }
    componentDidUpdate() {}
    get keyMapper() {
      return this.props.keyMapper;
    }
    async init() {
      let imageSize = await Image.getSize(
        `data:image/png;base64,${this.charactersEntryField.imageBase64}`
      );
      this.setState({
        imageHeight: imageSize.height,
        imageWidth: imageSize.width,
        zoom: 0,
      });
      // this.characterGrid = this.props.app.dm.manualEntry.createCharacterGrid(
      //   this.props.item.characters
      // );
    }
    initScale() {
      this.scale = this.calculateScale();
    }
    getCharacter(uuid) {
      let ret = null;
      for (let char of this.props.elmt.value) {
        if (uuid === char.uuid) {
          ret = char;
        }
      }
      return ret;
    }
    getCharacterValue(uuid) {
      let char = this.getCharacter(uuid);
      return char ? this.props.valueParser(char) : null;
    }
    calculateScale() {
      let scale = 1;
      // Get the tallest row and calculate scale
      let rowHeight = this.characterGrid.fontSize;
      if (
        this.state.height &&
        this.state.width &&
        this.state.imageWidth &&
        this.state.imageHeight
      ) {
        if (this.state.imageHeight > this.state.imageWidth) {
          scale = this.state.height / (this.state.imageHeight * rowHeight);
          if (this.state.imageWidth * scale > this.state.width)
            scale = this.state.width / this.state.imageWidth;

          let actualHeight = this.state.imageHeight * scale;
          let maxCharacterHeight = 128;
          if (actualHeight > maxCharacterHeight) {
            scale *= maxCharacterHeight / actualHeight;
          }
        } else {
          scale = this.state.width / this.state.imageWidth;
          if (this.state.imageHeight * rowHeight * scale > this.state.height)
            scale = this.state.height / (this.state.imageHeight * rowHeight);
        }
      }
      return (scale += this.state.zoom);
    }
    async handleLayout({ nativeEvent }) {
      let newState = {
        width: nativeEvent.layout.width,
      };
      if (this.state.zoom === 0) {
        newState.height = nativeEvent.layout.height;
      }
      this.setState(newState);
    }
    handleZoomIn() {
      this.setState({
        zoom: Math.round((this.state.zoom + this.zoomInterval) * 10) / 10,
      });
    }
    handleZoomOut() {
      this.setState({
        zoom: Math.round((this.state.zoom - this.zoomInterval) * 10) / 10,
      });
    }
    initStyles() {}
    createDynamicStyles() {
      let scale = this.scale;
      return StyleSheet.create({
        //...dynamicStyleMap,
        characterTextInputRows: {
          width: this.state.imageWidth * scale,
          //height: this.state.imageHeight * scale,
        },
        // rowFieldImageWrapper: {
        //   width: this.state.imageWidth * scale,
        //   height: this.state.imageHeight * scale,
        // },
      });
    }

    renderRows() {
      return (
        <View
          style={[
            styles.characterTextInputRows,
            this.dynamicStyles.characterTextInputRows,
          ]}
        >
          {this.characterGrid.rows.map((row, idx) => (
            <FieldCharactersRow
              key={`fieldRow${idx}`}
              rowIdx={parseInt(idx)}
              //focusCharacterUid={this.state.focusedCharacterUid}
              columns={row.cols}
              annotationSetUid={this.props.annotationSetUid}
              characters={this.props.elmt.value}
              valueParser={this.props.valueParser}
              // characterRefs={this.characterRefs}
              // columnRenderer={this.columnRenderer}
              scale={this.scale}
              height={row.offset.height}
              // fontRatio={this.fontRatio}
              // fontSize={this.characterGrid.fontSize}
              top={row.offset.top}
              rowPadding={this.rowPadding}
              imageHeight={this.state.imageHeight}
              imageWidth={this.state.imageWidth}
              fieldImageBase64={this.charactersEntryField.imageBase64}
              onCellFocus={this.props.onFocus}
              onCellBlur={this.props.onBlur}
              onCellChangeText={this.props.onChangeText}
            />
          ))}
        </View>
      );
    }
    render() {
      if (!this.state.imageHeight || !this.state.imageWidth) return null;
      this.initScale();
      this.dynamicStyles = this.createDynamicStyles();

      let content = (
        <View style={styles.fieldCharacters} onLayout={this.handleLayout}>
          {this.renderRows()}
        </View>
      );
      if (this.state.zoom) {
        content = (
          <ScrollView
            style={styles.rowFieldImageScrollView}
            height={this.state.height ? this.state.height + 16 : 16}
            overflow='auto'
            //onLayout={this.handleLayout}
          >
            {content}
          </ScrollView>
        );
      }

      return (
        <View>
          {content}
          <View style={styles.shortcutCharLabels}>
            <FloatingLabel
              label={`Reject Char: ${
                this.keyMapper.getActionKeyLabels("rejectCharacter")[0]
              }`}
              visible={this.props.app.dm.manualEntry.keyOverlayMode}
              key='rejectCharacter'
              position='top'
              offset={-4}
            >
              <View style={styles.shortcutCharLabel} />
            </FloatingLabel>
          </View>
          <ControlsView align='right' style={styles.zoomControls}>
            <IconButton
              tooltip={`Zoom Out`}
              icon='magnifyMinus'
              size='small'
              onPress={this.handleZoomOut}
              color={this.state.zoom < 0 ? Colors.primary : Colors.onSurface}
              disabled={this.state.zoom <= 0}
              key='zoomOut'
              style={styles.zoomIcon}
            />
            <IconButton
              tooltip={`Zoom In`}
              icon='magnifyPlus'
              size='small'
              onPress={this.handleZoomIn}
              color={this.state.zoom > 0 ? Colors.primary : Colors.onSurface}
              // disabled={!this.manualEntryDomain.hasPreviousQueueItem}
              key='zoomIn'
              style={styles.zoomIcon}
            />
          </ControlsView>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  fieldRow: {
    marginBottom: 16,
    //backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.8),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overflow: "hidden",
    position: "relative",
  },
  rowFieldImage: {
    flex: 1,
    backgroundPosition: "center",
  },
  rowFieldImageScrollView: {
    minHeight: 96,
    // flex: 0,
    // flexDirection: "column",
    // justifyContent: "space-around",
    // alignItems: "center"
  },
  rowFieldImageWrapper: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
  },
  textInputField: {
    // minHeight: 22,
    fontFamily: "'Courier Prime', 'Courier New', monospace",
    //fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: 24,
  },
  characterTextInput: {
    fontFamily: "'Courier Prime', 'Courier New', monospace",
    fontWeight: 400,
    backgroundColor: Colors.surface,
    //backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.3),
    position: "absolute",
    overflow: "visible",
    textAlign: "center",
    ...StyleSheet.padding(0),
  },
  characterCompareOverlay: {
    //backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.2),
    position: "absolute",
    borderRightWidth: 1,
    borderStyle: "dashed",
    borderColor: StyleSheet.color("#999999").rgba(0.9),
    // borderRadius: 4,
  },
  fieldCharacterOverlay: {
    position: "absolute",
  },
  // character: {
  //   backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2),
  // },
  fieldCharacters: {
    // flex: 1,
    // flexDirection: "rows",
    // justifyContent: "center",
    paddingTop: 8,
    // paddingBottom: 12,
  },
  characterTextInputRows: {
    position: "relative",
  },
  rowFieldCharacters: {
    position: "relative",
  },
  shortcutCharLabels: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    height: 0,
  },
  shortcutCharLabel: {
    height: 0,
  },
  zoomControls: {
    marginTop: -4,
    marginBottom: 12
  }
});
