import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import InputField from "./InputField";
import TextInputField from "./TextInputField";
import DatePickerModal from "./DatePickerModal";
import SelectMenu from "./SelectMenu";
import View from "./View";
import PopUpMenu from "./PopUpMenu";
import PopUpMenuItem from "./PopUpMenuItem";
import Touchable from "./Touchable";
import Icon from "./Icon";
import AnimatedView from "./AnimatedView";
import AnimatedText from "./AnimatedText";

import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

export default R14.connectForm(
  /**
   * A text input field component
   */
  class DatePickerField extends React.Component {
    static propTypes = {
      /** Name for the date picker input. */
      name: PropTypes.string.isRequired,
      /** Label for the date picker input. */
      label: PropTypes.string,
      /** Value of the date picker input. */
      value: PropTypes.string,
      /** The date picker field varient to use */
      variant: PropTypes.oneOf(["outlined", "filled"]),
      /** The mode to open the date picker calendar */
      mode: PropTypes.oneOf(["dropdown", "modal"]),
      /** Whether or not the text input can be edited. If false, the date picker opens on focus. */
      textInputEditable: PropTypes.bool,
      /** If true, disable the date picker input */
      disabled: PropTypes.bool,
      /** Handler to be called when value of the date picker is changed  */
      onValueChange: PropTypes.func,
      /** Handler to be called when the user taps the date picker input  */
      onPress: PropTypes.func,
      /** Handler to be called when the date picker input gains focus  */
      onFocus: PropTypes.func,
      /** Handler to be called when the date picker input loses focus  */
      onBlur: PropTypes.func,
      /** The name of the icon to be displayed to the left of the date picker input  */
      icon: PropTypes.string,
      /** The name of the icon to be displayed to the right of the date picker input  */
      iconRight: PropTypes.string,
      /** The minimum selectable date */
      minDate: PropTypes.instanceOf(Date),
      /** The maximum selectable date  */
      maxDate: PropTypes.instanceOf(Date),
      /** Style to be applied to the date picker input. */
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Helper text style to be applied to the date picker input helper. */
      helperTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Label text style to be applied to the date picker input helper label. */
      labelTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    };
    static defaultProps = {
      variant: "outlined",
      mode: "modal",
      textInputEditable: false,
      icon: "calendar",
    };
    constructor(props) {
      super(props);
      this.handleFocus = this.handleFocus.bind(this);
      //this.handleBlur = this.handleBlur.bind(this);
      this.handleChangeText = this.handleChangeText.bind(this);
      this.handleCancelPress = this.handleCancelPress.bind(this);
      this.handleConfirmPress = this.handleConfirmPress.bind(this);
      this.handleIconButtonPress = this.handleIconButtonPress.bind(this);
      this.handleClearPress = this.handleClearPress.bind(this);
      this.valueParser = this.valueParser.bind(this);
      this.ref = React.createRef();
      this.state = {
        datePickerVisible: false,
        date: null,
        showClear: false,
      };
      // console.log("Should value be a Date? Should calendar be clickable");
    }
    handleFocus() {
      this.show();
    }
    // handleBlur() {
    //   console.log("Blur make sure it is a valid date");
    // }
    handleChangeText(text) {
      // Block changes if not editable
      let showClear = this.state.showClear;
      let date = this.state.date;
      if (!this.props.textInputEditable) {
        this.props.form.elmts[this.props.name].value = date
          ? this.props.app.utils.date.toShortDate(date)
          : "";
      } else {
        let newDate = this.props.app.utils.date.isShortDate(text) ? text : null;
        if (date !== newDate) date = newDate;
      }
      if (text) showClear = true;
      if (this.state.date !== date || this.state.showClear !== showClear)
        this.setState({
          date: date,
          showClear: showClear,
        });
    }
    handleIconButtonPress() {
      this.show();
    }
    handleClearPress() {
      this.props.form.elmts[this.props.name].value = null;
      if (this.props.onValueChange) this.props.onValueChange(null, this.elmt);
      this.props.form.triggerValueChange(
        null,
        this.props.form.elmts[this.props.name]
      );
      this.setState({
        date: null,
        showClear: false,
      });
    }
    show() {
      let newState = { datePickerVisible: true };
      if (!this.state.date && this.props.form.elmts[this.props.name].value) {
        newState.date = this.props.form.elmts[this.props.name].value;
      }
      if (this.ref.current) this.props.form.elmts[this.props.name].blur();
      this.setState(newState);
    }
    handleCancelPress() {
      this.setState({ datePickerVisible: false });
    }
    handleConfirmPress(date) {
      let currDate = this.props.form.elmts[this.props.name].value;
      if (date)
        this.props.form.elmts[
          this.props.name
        ].value = this.props.app.utils.date.toShortDate(date);
      if (currDate !== this.props.form.elmts[this.props.name].value) {
        if (this.props.onValueChange) this.props.onValueChange(date, this.elmt);
        this.props.form.triggerValueChange(
          date,
          this.props.form.elmts[this.props.name]
        );
      }
      this.setState({ datePickerVisible: false, date: date, showClear: true });
    }
    valueParser(value) {
      return value && this.props.app.utils.date.isShortDate(value)
        ? new Date(value)
        : null;
    }
    // componentDidMount(){
    //   console.log("mount check val",this.props.form.elmts[this.props.name]);
    //   //this.props.form.elmts[this.props.name].value && this.setState({date: this.props.form.elmts[this.props.name].value});
    // }
    // componentDidUpdate(){
    //   console.log("mount check val",this.props.form.elmts[this.props.name].state.value);
    //   //this.props.form.elmts[this.props.name].value && this.setState({date: this.props.form.elmts[this.props.name].value});
    // }
    render() {
      let textInputFieldProps = {
        //onBlur: this.handleBlur,
        onChangeText: this.handleChangeText,
      };
      if (this.props.textInputEditable && !this.props.icon)
        throw new Error(
          "Must provide an icon if using model open mode iconButtonPress"
        );
      if (this.props.icon)
        textInputFieldProps.onIconPress = this.handleIconButtonPress;
      if (!this.props.textInputEditable) {
        textInputFieldProps.onFocus = this.handleFocus;
      }
      if (this.state.showClear) {
        textInputFieldProps.iconRight = "close";
        textInputFieldProps.onIconRightPress = this.handleClearPress;
      }
      return (
        <React.Fragment>
          <TextInputField
            {...this.props}
            {...textInputFieldProps}
            autoComplete={false}
            ref={this.ref}
            validator='shortDate'
            // valueParser={this.valueParser}
          />
          <DatePickerModal
            visible={this.state.datePickerVisible}
            date={this.state.date}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            onCancelPress={this.handleCancelPress}
            onConfirmPress={this.handleConfirmPress}
          />
        </React.Fragment>
      );
    }
  }
);
