import React from 'react';
import PropTypes from 'prop-types';
import AnimatedElementBase from './base/AnimatedElementBase';
/**
 * 
 * Text component that animates. See animated view for props and methods
 * 
 * 
 */
export default class AnimatedText extends React.Component {
  render(){
    return <AnimatedElementBase type='text' {...this.props} />
  }
}