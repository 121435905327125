import React from "react";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import Touchable from "./Touchable";
import Icon from "./Icon";
import Text from "./Text";
import Theme from "./Theme";
import DrawerNavigatorItem from "./DrawerNavigatorItem";
export default class DrawerItems extends React.Component {
  constructor(props){
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }
  handlePress() {
    if (this.props.type === "modal") this.props.navigator.hide();
    this.props.onPress && this.props.onPress();
  }
  render() {
    // icon, title,
    let drawerItems = [];
    for (let routeName in this.props.routes) {
      let route = this.props.routes[routeName];
      let to = route.to || { route: routeName };
      if (this.props.itemRenderer) {
        let ret = this.props.itemRenderer({
          route: route,
          app: this.props.app,
        });
        if (!ret) continue;
        else if (React.isValidElement(ret)) {
          drawerItems.push(ret);
          continue;
        }
      }
      let label = route.label;
      let toRoute = routeName;
      if (route.to) toRoute = route.to;
      let isActive = this.props.app.r14Instance.navigation.isActiveRoute(
        toRoute
      );
      // if (label && this.props.upperCaseLabel) label = label.toUpperCase();
      if (route.icon || label) {
        drawerItems.push(
          <DrawerNavigatorItem
            key={routeName}
            icon={route.icon}
            type={this.props.type}
            label={label}
            to={to}
            style={this.props.itemStyle}
            iconColor={this.props.itemIconColor}
            labelTextStyle={this.props.itemLabelTextStyle}
            activeStyle={this.props.itemActiveStyle}
            activeLabelTextStyle={this.props.itemActiveLabelTextStyle}
            activeIconColor={this.props.itemActiveIconColor}
            onPress={this.handlePress}
            position={this.props.position}
            scrollEnabled={this.props.scrollEnabled}
            active={isActive}
            upperCaseLabel={this.props.upperCaseLabel}
          />
        );
      }
    }
    return drawerItems;
  }
}
