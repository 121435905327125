import React from 'react';
import R14, { Redirect, TextInputField, SubmitButton, StyleSheet } from '../core';
import ManualEntryUserSessionMetricDataTable from "../components/ManualEntryUserSessionMetricDataTable";
export default R14.connect(
  class LoginScreen extends React.Component {
    constructor(props){
      super(props);
    }
    render() {
      return <ManualEntryUserSessionMetricDataTable />;
    }
  }
);