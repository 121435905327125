import React from "react";
import R14 from "../R14";
import StyleSheet from "./StyleSheet";
import Surface from "./Surface";
import TextInput from "./TextInput";
import { Colors } from "./Theme";
import View from "./View";
import ScrollView from "./ScrollView";
import Text from "./Text";
import IconButton from "./IconButton";
import AnimatedView from "./AnimatedView";
export default R14.connect(
  class SearchableText extends React.Component {
    constructor(props) {
      super(props);
      this.scrollRef = this.props.scrollRef || React.createRef();
      this.textMatches = [];
      this.textMatchIdx = 0;
      this.handleSearchChangeText = this.handleSearchChangeText.bind(this);
      this.handleNextMatchPress = this.handleNextMatchPress.bind(this);
      this.handlePreviousMatchPress = this.handlePreviousMatchPress.bind(this);
      this.handleSearchBarShowPress = this.handleSearchBarShowPress.bind(this);
      this.handleSearchBarClosePress = this.handleSearchBarClosePress.bind(
        this
      );
      this.state = {
        search: "",
        matchIndex: null,
        textComponents: null,
        searchBarVisible: false
      };
    }
    // componentDidMount() {
    //   // this.initText();
    // }
    componentDidUpdate(prevProps, prevState) {
      // if (
      //   this.props.text !== prevProps.text ||
      //   this.state.search !== prevState.search
      // )
      //   this.initText();
      // console.log(
      //   "component did update",
      //   prevState.matchIndex,
      //   this.state.matchIndex
      // );
      if (this.textMatches.length && this.state.matchIndex === null) {
        this.setState({
          matchIndex: 0
        });
      } else if (!this.textMatches.length && this.state.matchIndex !== null) {
        this.setState({
          matchIndex: null
        });
      } else if (prevState.matchIndex !== this.state.matchIndex) {
        this.scrollToMatch();
      }
    }
    handleSearchChangeText(value) {
      if (value !== this.state.search) {
        this.setState({ search: value, matchIndex: null });
      }
    }
    handleNextMatchPress() {
      let matchIndex = this.state.matchIndex;
      if (!this.textMatches.length) return;
      if (this.textMatches.length <= (matchIndex + 1)) matchIndex = 0;

      this.setState({ matchIndex: matchIndex + 1 });
    }
    handlePreviousMatchPress() {
      let matchIndex = this.state.matchIndex;
      if (!this.textMatches.length) return;

      if (matchIndex < 1) matchIndex = this.textMatches.length;

      // let matchScrollY = this.textMatches[matchIndex].y;

      // this.scrollRef.current.scrollTo({
      //   y: matchScrollY - 16,
      //   animated: true
      // });
      this.setState({ matchIndex: matchIndex - 1 });
    }
    scrollToMatch() {
      if (
        this.state.matchIndex !== null &&
        this.textMatches[this.state.matchIndex]
      ) {
        this.scrollRef.current.scrollTo({
          y: this.textMatches[this.state.matchIndex].y - 16,
          animated: true
        });
      }
    }
    handleSearchBarShowPress() {
      this.setState({
        searchBarVisible: true
      });
    }
    handleSearchBarClosePress() {
      this.setState({
        search: "",
        totalMatches: 0,
        searchBarVisible: false
      });
    }
    scrollToEnd(options = { animated: false }) {
      if (this.scrollRef.current) this.scrollRef.current.scrollToEnd(options);
    }
    renderText() {
      let text = this.props.text || "";
      let textComponents = [];
      let search = this.state.search;
      this.textMatches = [];
      if (search.length && text.length) {
        let textIdx = 0;
        let textMatchIdx = 0;
        let sIdx = 0;
        let idx = null;
        let indices = [];
        let currDate = new Date();
        while (
          (idx = text.toLowerCase().indexOf(search.toLowerCase(), sIdx)) > -1
        ) {
          indices.push(idx);
          let preText = text.substring(sIdx, idx);
          let searchText = text.substring(idx, idx + search.length);
          textComponents.push(
            <Text
              style={this.props.textStyle}
              key={`txt${currDate.getTime()}${textIdx}`}
            >
              {preText}
            </Text>
          );
          textComponents.push(
            <SearchableTextMatch
              idx={textMatchIdx}
              key={`searchTxt${currDate.getTime()}${textMatchIdx}`}
              highlight={this.state.matchIndex === textMatchIdx}
              onLayout={(e, idx) => {
                this.textMatches[idx] = e.nativeEvent.layout;
              }}
              textStyle={this.props.textStyle}
            >
              {searchText}
            </SearchableTextMatch>
          );
          this.textMatches[textMatchIdx] = null;
          sIdx = idx + search.length;
          textIdx++;
          textMatchIdx++;
        }
        if (sIdx < text.length) {
          textComponents.push(
            <Text
              style={this.props.textStyle}
              key={`txt${currDate.getTime()}${textIdx}`}
            >
              {text.substring(sIdx, text.length)}
            </Text>
          );
        }
      }
      return textComponents.length ? (
        <View
          onLayout={e => {
            console.log(this.textMatches);
          }}
          style={styles.searchableTextMatch}
        >
          {textComponents}
        </View>
      ) : (
        <Text style={this.props.textStyle}>{this.props.text}</Text>
      );
    }
    render() {
      if(! this.props.text) return null;
      let textComponents = this.renderText();
      return (
        <View style={styles.searchableText} onLayout={this.props.onLayout || null}>
          <IconButton
            icon='search'
            style={styles.searchIconButton}
            size='small'
            key='close'
            variant='circle'
            onPress={this.handleSearchBarShowPress}
            //backgroundColor={StyleSheet.color(Colors.onBackground).rgba(.5)}
          />
          <TextSearchBar
            onChangeText={this.handleSearchChangeText}
            searchValue={this.state.search}
            matchIndex={this.state.matchIndex}
            totalMatches={this.textMatches.length}
            onNextMatchPress={this.handleNextMatchPress}
            onPreviousMatchPress={this.handlePreviousMatchPress}
            onClosePress={this.handleSearchBarClosePress}
            visible={this.state.searchBarVisible}
          />

          <ScrollView
            style={styles.scrollView}
            height={this.props.height || 300}
            ref={this.scrollRef}
          >
            <View style={styles.textComponents}>{textComponents}</View>
          </ScrollView>
        </View>
      );
    }
  }
);
class TextSearchBar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let matchNum =
      this.props.matchIndex !== null ? this.props.matchIndex + 1 : 0;
    return (
      <AnimatedView
        in={this.props.visible}
        enter={{
          transform: [{ translateY: 0 }, { scale: 1 }],
          opacity: 1
        }}
        exit={{
          transform: [{ translateY: 48 }, { scale: 0.95 }],
          opacity: 0
        }}
        duration={150}
        unmountOnExit
        style={styles.textSearchBar}
      >
        <Surface style={styles.textSearchBarSurface} elevation={6}>
          <TextInput
            name='search'
            value={this.props.searchValue}
            onChangeText={this.props.onChangeText}
            style={styles.textSearchBarTextInput}
            autoFocus
            placeholder='Search'
            onBlur={this.handleBlur}
          />
          <View style={styles.textSearchBarControlsRight}>
            <Text style={styles.textSearchBarInfoText}>
              {matchNum}/{this.props.totalMatches || 0}
            </Text>
            <IconButton
              icon='keyboardArrowDown'
              key='nextMatch'
              size='small'
              style={styles.textSearchBarIcon}
              // disabled={this.props.totalMatches > 0}
              onPress={this.props.onNextMatchPress}
            />
            <IconButton
              icon='keyboardArrowUp'
              style={styles.textSearchBarIcon}
              size='small'
              key='prevMatch'
              // disabled={this.props.totalMatches > 0}
              onPress={this.props.onPreviousMatchPress}
            />
            <IconButton
              icon='close'
              style={styles.textSearchBarIcon}
              size='small'
              key='close'
              onPress={this.props.onClosePress}
            />
          </View>
        </Surface>
      </AnimatedView>
    );
  }
}
class SearchableTextMatch extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Text
        style={[
          styles.searchableTextMatch,
          this.props.textStyle,
          this.props.highlight && styles.searchableTextMatchHighlight
        ]}
        onLayout={e => {
          this.props.onLayout(e, this.props.idx);
        }}
      >
        {this.props.children}
      </Text>
    );
  }
}
const styles = StyleSheet.create({
  searchableText: {
    flex: 1,
    borderWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.05)
    // paddingBottom: 16
  },
  searchableTextMatch: {
    color: Colors.primary,
    fontWeight: "500"
  },
  searchableTextMatchHighlight: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2)
    // color: Colors.secondary
  },
  scrollView: {
    ...StyleSheet.margin(0),
    ...StyleSheet.padding(16, 16, 64, 16),
    backgroundColor: Colors.background
  },
  textComponents: {
    width: "100%"
  },
  searchIconButton: {
    position: "absolute",
    bottom: 6,
    right: 6,
    margin: 0
  },
  textSearchBar: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0
  },
  textSearchBarSurface: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    // borderBottomRightRadius: 4,
    // borderBottomLeftRadius: 4,
    ...StyleSheet.padding(6, 6, 6, 12)
    // backgroundColor: Colors.surface
  },
  textSearchBarControlsRight: {
    flex: 0,
    flexDirection: "row",
    alignItems: "center"
  },
  textSearchBarIcon: {
    ...StyleSheet.margin(0)
  },
  textSearchBarTextInput: {
    backgroundColor: "transparent",
    color: Colors.onSurface,
    fontSize: 16,
    width: 144
  },
  textSearchBarControlRight: {},
  textSearchBarInfoText: {
    fontSize: 14,
    fontWeight: "300",
    minWidth: 100,
    // borderStyle: "solid",
    // borderRightWidth: 1,
    // borderColor: StyleSheet.color(Colors.onSurface).rgba(.5),
    ...StyleSheet.padding(4, 8, 4, 8)
    // marginRight: 4
  }
});
