import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import InputField from "./InputField";
import FileInput from "./FileInput";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

const FileInputField = R14.connectForm(
  /**
   * A file input field component
   */
  class FileInputField extends React.Component {
    static propTypes = {
      /** Label for the file input. */
      label: PropTypes.string,
      /** Value of the file input. */
      value: PropTypes.string,
      /** The file input varient to use */
      variant: PropTypes.oneOf(["outlined", "filled"]),
      /** If true, disable the file input */
      disabled: PropTypes.bool,
      /** Handler to be called when the file inputs value is channed  */
      onValueChange: PropTypes.func,
      /** Handler to be called when the user taps the file input  */
      onPress: PropTypes.func,
      /** Handler to be called when the file input gains focus  */
      onFocus: PropTypes.func,
      /** Handler to be called when the file input loses focus  */
      onBlur: PropTypes.func,
      /** The name of the icon to be displayed to the left of the input field  */
      icon: PropTypes.string,
      /** The function to be called when the icon is pressed  */
      onIconPress: PropTypes.func,
      /** The name of the icon to be displayed to the right of the input field  */
      iconRight: PropTypes.string,
      /** The function to be called when the iconRight is pressed  */
      onIconRightPress: PropTypes.func,
      /** Helper text displayed below the input. */
      helper: PropTypes.string,
      /** If true, text content can be multiple lines */
      multiline: PropTypes.bool,
      /** If true, multiline file input will grow or shrink with content */
      autoSize: PropTypes.bool,
      /** If false, auto complete is disabled */
      autoComplete: PropTypes.bool,
      /** Style to be applied to the input field. */
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Helper text style to be applied to the input field helper. */
      helperTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Label text style to be applied to the input field helper label. */
      labelTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** The function to be called when getting all form values (for example, on submit), or if using elmt.parseValue()  */
      valueParser: PropTypes.func,
    };
    static defaultProps = {
      variant: "outlined",
      autoSize: false,
    };
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleIconRightPress = this.handleIconRightPress.bind(this);
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setBlur(true);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    handleValueChange(file) {
      this.elmt.setValue(file);
      if (this.props.onValueChange)
        this.props.onValueChange(file, this.elmt, this.elmt.form);
      this.elmt.form.triggerValueChange(file, this.elmt);
    }
    handleIconRightPress() {
      this.elmt.setValue(null);
      if (this.props.onIconRightPress) this.props.onIconRightPress(this.elmt);
    }
    componentWillUnmount() {
      this.elmt.remove();
    }

    getValue(){
      let file = this.elmt.value;
      let value = null;
      if(file instanceof File) value = null
      else if(file && file.value) value = file.value;
      return value;
    }
    getLabel(){
      let file = this.elmt.value;
      let label = null;
      if(file instanceof File) label = file.name;
      else if(file && file.label) label = file.label;
      return label;
    }
    render() {
      let styles = this.createDynamicStyles();
      let variantStyles = this.variantStyles();
      return (
        <InputField
          {...this.props}
          icon={this.props.icon || 'folder'}
          iconRight={this.elmt.value && 'close'}
          onIconRightPress={this.handleIconRightPress}
          element={this.elmt}
          InputComponent={
            <FileInput
              name={this.elmt.name}
              ref={this.elmt.ref}
              placeholder={
                this.elmt.isActive && this.props.placeholder
                  ? this.props.placeholder
                  : null
              }
              label={this.getLabel()}
              value={this.getValue()}
              onValueChange={this.handleValueChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              autoFocus={this.props.autoFocus || null}
              style={[
                styles.fileInput,
                variantStyles.fileInput,
                this.props.style,
              ]}
            />
          }
        />
      );
    }
    variantStyles() {
      switch (this.props.variant) {
        case "outlined":
          return StyleSheet.create({
            fileInput: {
              // paddingTop: 16,
              // paddingBottom: 16,
              // // height: this.elmt.isFocused ? 52 : 54,
              // paddingLeft: this.props.icon !== false ? 44 : 12,
              // paddingRight: this.props.iconRight ? 44 : 12,
              // lineHeight: 22,
              // height: this.elmt.isFocused ? 52 : 54,
              // // minHeight: 54
              overFlow: 'hidden',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: this.props.icon !==false ? 43 : 11,
              paddingRight: this.props.iconRight ? 43 : 11,
              lineHeight: 22,
              height: 52,
            },
          });
          break;
        default:
          return StyleSheet.create({
            fileInput: {
              paddingTop: 20,
              paddingBottom: 5,
              minHeight: 55,
              lineHeight: 30,
            },
          });
      }
    }
    createDynamicStyles() {
      return StyleSheet.create({
        fileInput: {
          margin: 0,
          paddingLeft: this.props.icon ? 44 : 12,
          paddingRight: this.props.iconRight ? 44 : 12,
          width: "100%",
          backgroundColor: "transparent",
          color: Colors.onBackground,
          fontSize: 16,
        },
      });
    }
  }
);
export default FileInputField;
