import React from "react";
import R14 from "../../R14";

import Route from "../Route";
import StyleSheet from "../StyleSheet";
import View from "../View";
import Modal from "../Modal";
import Surface from "../Surface";
import TabNavigator from "../TabNavigator";
import TabNavigatorItem from "../TabNavigatorItem";
import DrawerNavigator from "../DrawerNavigator";
import SnackBar from "../SnackBar";
import Header from "../Header";
import ModalHeader from "../ModalHeader";
import Dimensions from "../Dimensions";
import { Colors } from "../Theme";
import PortalContext from "../navigation/PortalContext";
const NavigationBase = R14.connect(
  class NavigationBase extends React.Component {
    constructor(props) {
      super(props);
    }
    // static get Navigator() {
    //   return Navigator;
    // }
    // static get ContainerPortal() {
    //   return ContainerPortal;
    // }
    componentDidMount() {
      this.props.app.r14Instance.navigation.initializeRouterProps(this.props);
    }
    componentDidUpdate() {
      this.props.app.r14Instance.navigation.initializeRouterProps(this.props);
    }
    // componentWillReact() {
    //   this.props.app.r14Instance.navigation.initializeRouterProps(this.props);
    // }
    parseNavigator(navigator) {
      if (!navigator.routes) return null;
    }
    render() {
      /** @todo Call below is hacky, but observes for portal state reaction do not remove. */
      let activeRoute = this.props.app.r14Instance.navigation.portals;

      /** @todo move this code into a method */
      /** Check if the active route is the same as the form submitted */
      // let submittedForm = null;
      // if (this.props.app.ui.form.submittedForm) {
      //   let routeName = this.props.app.ui.form.submittedForm.routeName;
      //   if (routeName === activeRoute) {
      //     submittedForm = (routeName === activeRoute) ? this.props.app.ui.form.submittedForm : null;
      //   }
      // }

      return (
        <React.Fragment>
          <RootPortal />
          <ModalPortal />
        </React.Fragment>
        // <View>
        //   <Navigator root name="r14NavRoot" navigation={this.props.app.r14Instance.navigation} screen={activeRoute} submittedForm={submittedForm} />
        //   <ModalNavigator navigation={this.props.app.r14Instance.navigation} screen={activeRoute} submittedForm={submittedForm} />
        // </View>
      );
    }
  }
);

const RootPortal = R14.connect(
  class RootPortal extends React.Component {
    static contextType = PortalContext;
    constructor(props) {
      super(props);
      this._portal = this.props.app.r14Instance.navigation.addPortal(
        "root",
        this.props.app.r14Instance.navigation.PORTAL_TYPE_ROOT
      );
    }
    // componentWillUnmount() {
      
    //   //this.props.app.r14Instance.navigation.removePortal("root");
    // }
    render() {
      // Get the active portal route
      let activeRouteName = this._portal.activeRouteName;
      let activeRoutePath = this._portal.activeRoutePath;
      if (!activeRouteName) return null;
      return (
        <PortalContext.Provider value='root'>
          <Navigator
            root
            name='r14NavRoot'
            navigation={this.props.app.r14Instance.navigation}
            screen={activeRouteName}
            path={activeRoutePath || null}
          />
        </PortalContext.Provider>
      );
    }
  }
);

const Navigator = R14.connect(
  class Navigator extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      // Check if screen is in the navigator
      let nav = this.props.navigation;

      let navigator = nav.navigators(this.props.name);
      // let submittedForm = this.props.submittedForm || null;
      // if (this.props.submittedForm) {
      //submittedForm = (this.props.submittedForm.routeName === this.props.screen) ? this.props.app.ui.form.submittedFormRouteName : null;
      // }
      /** @todo Navigator: Should the last screen always show until removed? */
      if (!this.props.screen) return null;
      let components = [];
      if (nav.isScreenInNavigator(this.props.screen, this.props.name)) {
        // Render the route / screen
        components.push(
          <Route
            // {...props}
            key={this.props.screen}
            name={this.props.screen}
            path={this.props.path}
            config={nav.screens(this.props.screen)}
            // submittedForm={submittedForm}
          />
        );
      } else {
        let navigator = nav.getNextNavigatorName(
          this.props.screen,
          this.props.name
        );
        if (!navigator) throw "Navigator not found.";
        components.push(
          <Navigator
            key='navigator'
            name={navigator}
            path={this.props.path}
            navigation={this.props.navigation}
            screen={this.props.screen}
          />
        );
      }

      // Figure out he next route
      return (
        <NavigatorLayout
          name={this.props.name}
          path={this.props.path}
          root={this.props.root}
          screen={this.props.screen}
          navigator={navigator}
          style={this.props.style}
        >
          {components}
        </NavigatorLayout>
      );
    }
  }
);

const NavigatorLayout = R14.connect(
  class NavigatorLayout extends React.Component {
    constructor(props) {
      super(props);
      this._navRef = React.createRef();
      this.state = {
        initialized: false,
      };
      this.handleLayoutStylesUpdate = this.handleLayoutStylesUpdate.bind(this);
      this.handleToggle = this.handleToggle.bind(this);
      this.navigator = this.props.app.ui.navigator.create(this.props);
      this.layoutStyles = null;
      this.shouldResetLayout = false;
      this.initNavigator();
    }
    renderControls(Controls = null) {
      return Controls ? <Controls /> : null;
    }
    // componentDidMount() {
    //   // this.initNavigator();
    // }
    initNavigator() {
      /** @todo should this be moved to the ui navigator domain?  */
      switch (this.props.navigator.type) {
        case "drawer":
          let drawerType =
            this.props.navigator.drawerNavigator.type || "standard";
          let updateByDimensions = (dimensions) => {
            if (dimensions.width <= 800) {
              this.navigator.hide();
              if (dimensions.width <= 640 && drawerType !== "modal")
                this.navigator.setOption("type", "modal");
              else {
                this.navigator.setOption("type", drawerType);
              }
            } else {
              this.navigator.setOption("type", drawerType);
              this.navigator.show();
            }
          };
          let windowDimensions = Dimensions.get("window");
          updateByDimensions(windowDimensions);
          Dimensions.addEventListener("change", ({ window }) => {
            updateByDimensions(window);
          });
          break;
      }
    }
    componentWillUnmount() {
      this.navigator.remove();
    }
    handleLayoutStylesUpdate(layoutStyles) {
      this.shouldResetLayout = false;
      this.navigator.setLayoutStyles(layoutStyles);
    }
    handleToggle(visible) {
      console.log("handle toggle!", visible);
    }
    componentDidUpdate() {
      if (this.shouldResetLayout && this.navigator.state.layoutStyles) {
        this.shouldResetLayout = false;
        this.navigator.setLayoutStyles(null);
      }
    }
    getRouteParams(routeName) {
      let portal = this.props.r14.navigation.getPortalByRouteName(routeName);
      //console.log(routeName, portal, this.props.navigator.routes);
      let route = this.props.navigator.routes[routeName];
      if (!route.path && route.initialRoute && route.routes) {
        // Find the navigator and get the initial route
        let navigator = this.props.r14.navigation.navigators(routeName);
        let initialRoute = null;
        if (typeof navigator.initialRoute === "function") {
          initialRoute = navigator.initialRoute({
            navigator: navigator,
            app: R14.getInstance().app,
          });
        } else initialRoute = navigator.initialRoute;
        if (!route.routes[initialRoute])
          throw new Error(
            `Could not find initialRoute ${initialRoute} for route ${routeName}.`
          );
        route = route.routes[initialRoute];
      }
      let routeParams = route.initialParams || {};

      /** @todo make sure to match by whole word, and that on routes with shared navigator get the params */
      if (portal.data) {
        let paramKeys = Object.keys(portal.data).sort(
          (a, b) => b.length - a.length
        );
        for (let k of paramKeys) {
          if (route.path && route.path.indexOf(`:${k}`) !== -1) {
            routeParams[k] = portal.data[k];
          }
        }
      }

      return routeParams;
    }
    getRoute(routeName) {
      /** @todo This should create a r14 route */
      return this.getRouteConfig(routeName);
    }

    getRouteConfig(routeName) {
      let routeParams = this.getRouteParams(routeName);
      let initialConfig = this.props.navigator.routes[routeName];
      let config = { ...initialConfig };
      config.params = routeParams || {};
      if (
        initialConfig.metadata &&
        typeof initialConfig.metadata === "function"
      ) {
        config.metadata = initialConfig.metadata({
          route: {
            name: routeName,
            params: routeParams,
          },
          app: this.props.app,
        });
      }
      if (initialConfig.options) {
        config.options =
          typeof initialConfig.options === "function"
            ? initialConfig.options({
                route: {
                  name: routeName,
                  params: routeParams,
                },
                app: this.props.app,
              })
            : config.options;
        config = { ...config, ...config.options };
        delete config.options;
      }
      return config;
    }
    render() {
      let navComponent = null;
      if (this.props.navigator.type) {
        switch (this.props.navigator.type) {
          case "tab":
            // Create the items
            // icon, title, name, etc...
            let tabNavItems = [];
            for (let routeName in this.props.navigator.routes) {
              let route = this.getRoute(routeName);

              if (this.props.navigator.tabNavigator.itemRenderer) {
                let ret = this.props.navigator.tabNavigator.itemRenderer({
                  route: route,
                  app: this.props.app,
                });
                if (!ret) continue;
                else if (React.isValidElement(ret)) {
                  tabNavItems.push(ret);
                  continue;
                }
              }

              // let portal = this.props.r14.navigation.getPortalByRouteName(
              //   routeName
              // );
              // let routeParams = {};

              // /** @todo make sure to match by whole word, and that on routes with shared navigator get the params */
              // if (portal.data) {
              //   let paramKeys = Object.keys(portal.data).sort(
              //     (a, b) => b.length - a.length
              //   );
              //   for (let k of paramKeys) {
              //     if (route.path.indexOf(`:${k}`) !== -1) {
              //       routeParams[k] = portal.data[k];
              //     }
              //   }
              // }

              let label = route.label;
              if (
                label &&
                this.props.navigator.tabNavigator.showLabel !== false &&
                this.props.navigator.tabNavigator.upperCaseLabel
              )
                label = label.toUpperCase();

              if (route.icon || label) {
                tabNavItems.push(
                  <TabNavigatorItem
                    key={routeName}
                    icon={
                      this.props.navigator.tabNavigator.showIcon && route.icon
                    }
                    label={label}
                    to={{
                      route: routeName,
                      params: route.params,
                    }}
                    style={this.props.navigator.tabNavigator.itemStyle || null}
                    activeStyle={
                      this.props.navigator.tabNavigator.activeItemStyle || null
                    }
                  />
                );
              }
            }
            navComponent = (
              <TabNavigator
                ref={this._navRef}
                key={this.props.name}
                onLayoutStylesUpdate={this.handleLayoutStylesUpdate}
                {...this.props.navigator.tabNavigator}
              >
                {tabNavItems}
              </TabNavigator>
            );
            break;
          case "drawer":
            // Create the items
            // icon, title, name, etc...
            navComponent = (
              <DrawerNavigator
                key={this.props.name}
                name={this.props.name}
                path={this.props.path}
                routes={this.props.navigator.routes}
                navigator={this.navigator}
                onLayoutStylesUpdate={this.handleLayoutStylesUpdate}
                onToggle={this.handleToggle}
                visible={this.navigator.state.visible}
                ref={this._navRef}
                {...this.props.navigator.drawerNavigator}
                type={this.navigator.state.options.type || "standard"}
              />
            );
            break;
          case "stack":
            // console.log(this.props.navigator);
            if (this.props.navigator.header) {
              let headerProps = { ...this.props.navigator.header };
              // headerProps.controlsLeft = null;
              // headerProps.controlsRight = null;
              // console.log(React.isValidElement(headerProps));
              // console.log(headerProps);
              // if(React.isValidElement(headerProps) || headerProps.wrappedComponent){
              //   let component =
              //   navComponent = headerProps;
              // }
              // else{
              if (headerProps.controlsLeft)
                headerProps.controlsLeft = this.renderControls(
                  headerProps.controlsLeft
                );
              if (headerProps.controlsRight)
                headerProps.controlsRight = this.renderControls(
                  headerProps.controlsRight
                );

              navComponent = (
                <Header
                  // navigator={this.navigator}
                  onLayoutStylesUpdate={this.handleLayoutStylesUpdate}
                  ref={this._navRef}
                  {...headerProps}
                />
              );
              //}
            } else {
              this.shouldResetLayout = true;
            }

            break;
          case "modal":
            if (this.props.navigator.header) {
              let headerProps = { ...this.props.navigator.header };
              if (this.props.screen) {
                let route = null;
                route =
                  this.props.app.r14Instance.navigation.isScreenInNavigator(
                    this.props.screen,
                    this.props.name
                  )
                    ? this.getRoute(this.props.screen)
                    : this.props.app.r14Instance.navigation.getRouteByRouteName(
                        this.props.screen
                      );
                if (route && route.title) headerProps.title = route.title;
              }
              navComponent = (
                <ModalHeader
                  navigator={this.navigator}
                  ref={this._navRef}
                  {...headerProps}
                />
              );
            }
            break;
        }
      }
      let navigatorStyles = [styles.navigator];
      let navigatorLayoutStyle = [styles.navigator];
      if (this.props.root) navigatorStyles.push(styles.rootNavigator);
      if (this.navigator.state.layoutStyles) {
        navigatorLayoutStyle.push(this.navigator.state.layoutStyles.offset);
      }
      if (this.props.style) navigatorStyles.push(this.props.style);

      return (
        <View style={navigatorStyles}>
          {this.props.root && (
            <div key='modal' id='r14_navigator_root_modal_lay'></div>
          )}
          {this.props.root && (
            <SnackBar
              key='snackBar'
              {...this.props.app.ui.snackBar.props}
              visible={this.props.app.ui.snackBar.state.visible}
              onRequestDismiss={this.props.app.ui.snackBar.handleRequestDismiss}
              onDismiss={this.props.app.ui.snackBar.handleDismiss}
            />
          )}

          <View key='children' style={navigatorLayoutStyle}>
            {this.props.children}
          </View>
          {navComponent}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  navigator: {
    flex: 1,
    flexDirection: "column",
    position: "relative",
    backgroundColor: Colors.backgroundColor,
    overflow: "hidden",
  },
  rootNavigator: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
    backgroundColor: Colors.background,
  },
});

const ContainerPortal = R14.connect(
  class ContainerPortal extends React.Component {
    static contextType = PortalContext;
    constructor(props) {
      super(props);
      this._portal = this.props.app.r14Instance.navigation.addPortal(
        this.props.name,
        this.props.app.r14Instance.navigation.PORTAL_TYPE_CONTAINER,
        {
          parentPortalName: this.props.app.nav.route
            ? this.props.app.nav.route.portal
            : null,
          initialParams: this.props.initialParams || null,
        }
      );
    }
    componentDidMount() {
      this.props.app.r14Instance.navigation.initializeContainerPortalsByRoute(
        this.props.app.nav.route.name
      );
      // Make sure this navigation container was initialized
    }
    // componentDidUpdate(){
    //   console.log("PORTAL UPDATE, CHECK IF INITIALIZED", this._portal.initialized);
    //   // Make sure this navigation container was initialized
    // }
    componentWillUnmount() {
      this.props.app.r14Instance.navigation.removePortal(this.props.name);
    }
    render() {
      // Get the active portal route
      let activeRouteName = this._portal.activeRouteName;
      let activeRoutePath = this._portal.activeRoutePath;
      if (!activeRouteName) return null;
      return (
        <PortalContext.Provider value={this.props.name}>
          <Navigator
            name={this.props.name}
            style={this.props.style}
            navigation={this.props.app.r14Instance.navigation}
            screen={activeRouteName}
            path={activeRoutePath || null}
          />
        </PortalContext.Provider>
      );
    }
  }
);

const ModalPortal = R14.connect(
  class ModalPortal extends React.Component {
    static contextType = PortalContext;
    constructor(props) {
      super(props);
      this._portal = this.props.app.r14Instance.navigation.addPortal(
        "modal",
        this.props.app.r14Instance.navigation.PORTAL_TYPE_MODAL
      );
      this.handleBackdropPress = this.handleBackdropPress.bind(this);
    }
    componentWillUnmount() {
      this.props.app.r14Instance.navigation.removePortal("modal");
    }
    handleBackdropPress() {
      let rootPortal = this.props.app.r14Instance.navigation.portals("root");
      this.props.app.nav.to(rootPortal.route.name, {
        ...rootPortal.route.params,
        ...rootPortal.route.query,
      });
    }
    render() {
      // Get the active portal route
      let activeRouteName =
        this.props.app.r14Instance.navigation.portals("modal").activeRouteName;
      let activeRoutePath =
        this.props.app.r14Instance.navigation.portals("modal").activeRoutePath;
      return (
        <PortalContext.Provider value='modal'>
          <Modal
            visible={activeRouteName ? true : false}
            transparent
            animationType='fade'
            style={[
              ModalPortalStyles.modal,
              this.props.app.nav.route && this.props.app.nav.route.modalStyle,
            ]}
            backdrop
            backdropStyle={ModalPortalStyles.backdrop}
            onBackdropPress={this.handleBackdropPress}
            scrollEnabled
          >
            {/* <ModalNavigator> */}
            <Surface
              style={[
                ModalPortalStyles.dialog,
                this.props.app.nav.route &&
                  this.props.app.nav.route.dialogStyle,
              ]}
              elevation={12}
            >
              <Navigator
                name='modal'
                navigation={this.props.app.r14Instance.navigation}
                screen={activeRouteName}
                path={activeRoutePath}
              />
            </Surface>
            {/* </ModalNavigator> */}
          </Modal>
        </PortalContext.Provider>
      );
    }
  }
);
const ModalPortalStyles = StyleSheet.create({
  backdrop: {
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.8),
  },
  modal: {
    // flex: 1,
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "space-around",
    // position: "absolute",
    // ...StyleSheet.absoluteFill,
  },
  dialog: {
    // flex: 0,
    // position: "relative",
    borderRadius: 4,
    minWidth: 300,

    maxWidth: 640,
    // minHeight: 400,
    ...StyleSheet.padding(0),
    marginTop: 80,
    marginBottom: 80,
    zIndex: 2,
    backgroundColor: Colors.background,
    overflow: "hidden",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          maxWidth: null,
          width: "100%",
          minHeight: "100%",
          // position: "absolute",
          // ...StyleSheet.absoluteFill,
          borderRadius: 0,
          ...StyleSheet.margin(0),
        };
    },
  },
});

NavigationBase.Navigator = Navigator;
NavigationBase.ContainerPortal = ContainerPortal;
export default NavigationBase;
