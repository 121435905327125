import React from "react";
import R14 from "../R14";

export default class DraggableUiDomain extends R14.DomainInstances {
  create(props) {
    if (!props.name) throw "Draggable Error: Must have a name.";
    let draggableInstance = new DraggableUiInstance(props, this);
    this.addInstance(props.name, draggableInstance);
    return draggableInstance;
  }
  remove(name) {
    this.removeInstance(name);
  }
}
class DraggableUiInstance extends R14.DomainInstances {
  constructor(props, containers) {
    super();
    this._containers = containers;
    this._props = props;
    this._activeView = null;
    this._measure = null;
    this._layout = null;
    this.state = {
      activeViewName: null,
      x: null,
      y: null,
      height: null,
      width: null,
      layout: null,
    };
  }
  remove() {
    this._containers.removeInstance(this._props.name);
  }
  setActiveView(view = null) {
    this.setState({
      activeViewName: view ? view.name : null,
    });
    this._activeView = view || null;
  }
  get active() {
    return this.state.activeViewName ? true : false;
  }
  get activeView() {
    return this._activeView || null;
  }
  get cursor() {
    return this.activeView ? this.activeView.cursor : null;
  }
  handleMeasure(measure) {
    this.setState({
      ...measure,
    });
  }
  addView(props) {
    if (!props.name) throw "Draggable View Error: Must have a name.";
    let draggableInstance = new DraggableViewUiInstance(props, this);
    this.addInstance(props.name, draggableInstance);
    return draggableInstance;
  }
  removeView(name) {
    this.removeInstance(name);
    return this;
  }
  handlePressOut(event) {
    this.active && this.activeView.handleContainerPressOut(event);
  }
  handlePressCancel(event) {
    if (this.active) {
      this.activeView.handleContainerPressCancel(event);
      this.setActiveView(null);
    }
  }
  handlePressMove(event) {
    if (this.active) {
      //this.detectCollisions(event);
      this.activeView.handleContainerPressMove(event);
    }
  }
  // detectCollisions() {
  //   if (! this.activeView.props.onDragCollision) return false;
  //   let collisions = [];
  //   if (this.activeView.state.layout) {
  //     let activeViewPos = {
  //       top: this.activeView.props.offsetTop+this.activeView.state.dragY,
  //       left: this.activeView.props.offsetLeft+this.activeView.state.dragX,
  //       bottom: this.activeView.props.offsetTop+this.activeView.state.dragY+this.activeView.state.layout.height,
  //       right: this.activeView.props.offsetLeft+this.activeView.state.dragX+this.activeView.state.layout.width,
  //     }
  //     this.forEach((view) => {
  //       if (this.activeView.name === view.name) return false;
  //       let viewPos = {
  //         top: view.props.offsetTop,
  //         left: view.props.offsetLeft,
  //         bottom: view.props.offsetTop + view.state.layout.height,
  //         right: view.props.offsetLeft + view.state.layout.width,
  //       }

  //       if(activeViewPos.left > viewPos.left && activeViewPos.right < viewPos.right){
  //         console.log('CHECKING X', view.name);
  //       }

  //     });
  //   }
  // }
}
// class DraggableViewUiDomain extends R14.DomainInstances {
//   add(props) {
//     if (!props.name) throw "Draggable View Error: Must have a name.";
//     let draggableInstance = new DraggableViewUiInstance(props.name, this);
//     this.addInstance(props.name, draggableInstance);
//     return draggableInstance;
//   }
//   remove(name) {
//     this.removeInstance(name);
//     return this;
//   }
// }
class DraggableViewUiInstance extends R14.Domain {
  constructor(props, container) {
    super();
    this.DRAG_TYPE_MOVE = "MOVE";
    this.DRAG_TYPE_RESIZE_RIGHT = "RESIZE_RIGHT";
    this.DRAG_TYPE_RESIZE_LEFT = "RESIZE_LEFT";
    this.DRAG_TYPE_RESIZE_TOP = "RESIZE_TOP";
    this.DRAG_TYPE_RESIZE_BOTTOM = "RESIZE_BOTTOM";
    this.DRAG_TYPE_RESIZE_TOP_RIGHT = "RESIZE_TOP_RIGHT";
    this.DRAG_TYPE_RESIZE_TOP_LEFT = "RESIZE_TOP_LEFT";
    this.DRAG_TYPE_RESIZE_BOTTOM_RIGHT = "RESIZE_BOTTOM_RIGHT";
    this.DRAG_TYPE_RESIZE_BOTTOM_LEFT = "RESIZE_BOTTOM_LEFT";
    this.RESIZE_TARGET_SIZE = 4;
    this._props = props;
    this._container = container;
    this._pressInEvent = null;
    this._dragType = null;
    this.state = {
      dragX: 0,
      dragY: 0,
      resizeHeight: null,
      resizeWidth: null,
      layout: null,
    };
    this._styleOnDrag = null;
  }
  remove() {
    this._container.removeInstance(this.name);
  }
  get name() {
    return this.props.name;
  }
  get props() {
    return this._props;
  }
  get resizeTargetSize() {
    return this._props.resizeTargetSize || this.RESIZE_TARGET_SIZE;
  }
  get active() {
    return this._container.state.activeViewName === this.name;
  }
  get styleOnDrag() {
    return this._styleOnDrag;
  }
  get container() {
    return this._container;
  }
  get pressInEvent() {
    return this._pressInEvent;
  }
  get dragType() {
    return this._dragType;
  }
  get props() {
    return this._props;
  }
  get dragX() {
    return this.state.dragX;
  }
  get dragY() {
    return this.state.dragY;
  }
  get resizeWidth() {
    return this.state.resizeWidth;
  }
  get resizeHeight() {
    return this.state.resizeHeight;
  }
  get layout() {
    return this.state.layout;
  }
  get cursor() {
    let cursor = "move";
    if (!this.active) return cursor;
    switch (this.dragType) {
      case this.DRAG_TYPE_RESIZE_LEFT:
      case this.DRAG_TYPE_RESIZE_RIGHT:
        cursor = "ew-resize";
        break;
      case this.DRAG_TYPE_RESIZE_TOP:
      case this.DRAG_TYPE_RESIZE_BOTTOM:
        cursor = "ns-resize";
        break;
      case this.DRAG_TYPE_RESIZE_TOP_LEFT:
      case this.DRAG_TYPE_RESIZE_BOTTOM_RIGHT:
        cursor = "nwse-resize";
        break;
      case this.DRAG_TYPE_RESIZE_TOP_RIGHT:
      case this.DRAG_TYPE_RESIZE_BOTTOM_LEFT:
        cursor = "nesw-resize";
        break;
      default:
        cursor = "move";
    }
    return cursor;
  }
  setPressInEvent(event) {
    this._pressInEvent = event;
  }
  setDragType(dragType) {
    this._dragType = dragType;
  }
  setLayout(layout) {
    let currLayout = this.state.layout || {};
    this.setState({ layout: { ...currLayout, ...layout } });
  }
  normalizeEvent(event) {
    let nativeEvent = event.nativeEvent || {};
    nativeEvent.translationX = this.state.dragX;
    nativeEvent.translationY = this.state.dragY;
    nativeEvent.height =
      this.state.resizeHeight ||
      this.props.height ||
      this.state.layout.height ||
      null;
    nativeEvent.width =
      this.state.resizeWidth ||
      this.props.width ||
      this.state.layout.width ||
      null;
    return { nativeEvent };
  }
  handlePressIn(event) {
    event = this.normalizeEvent(event);
    let dragType = this.calculateDragType(event);
    this.setDragType(dragType);
    this.setPressInEvent(event);
    this.container.setActiveView(this);

    if (this.props.onDragStart) {
      this.props.onDragStart(event, this);
    }
  }
  calculateDragType(event) {
    let dragType = null;
    let pos = event.nativeEvent || null;
    let locationX = pos.locationX;
    let locationY = pos.locationY;
    if (
      locationX <= this.resizeTargetSize &&
      locationY >= this.layout.height - this.resizeTargetSize
    ) {
      dragType = this.DRAG_TYPE_RESIZE_BOTTOM_LEFT;
    } else if (
      locationX <= this.resizeTargetSize &&
      locationY <= this.resizeTargetSize
    ) {
      dragType = this.DRAG_TYPE_RESIZE_TOP_LEFT;
    } else if (locationX <= this.resizeTargetSize) {
      dragType = this.DRAG_TYPE_RESIZE_LEFT;
    } else if (
      locationX >= this.layout.width - this.resizeTargetSize &&
      locationY >= this.layout.height - this.resizeTargetSize
    ) {
      dragType = this.DRAG_TYPE_RESIZE_BOTTOM_RIGHT;
    } else if (
      locationX >= this.layout.width - this.resizeTargetSize &&
      locationY <= this.resizeTargetSize
    ) {
      dragType = this.DRAG_TYPE_RESIZE_TOP_RIGHT;
    } else if (locationX >= this.layout.width - this.resizeTargetSize) {
      dragType = this.DRAG_TYPE_RESIZE_RIGHT;
    } else if (locationY <= this.resizeTargetSize) {
      dragType = this.DRAG_TYPE_RESIZE_TOP;
    } else if (locationY >= this.layout.height - this.resizeTargetSize) {
      dragType = this.DRAG_TYPE_RESIZE_BOTTOM;
    } else dragType = this.DRAG_TYPE_MOVE;

    return dragType;
  }
  calculateDragLayout(event) {
    let ret = {
      dragX: 0,
      dragY: 0,
      resizeHeight: null,
      resizeWidth: null,
    };

    let dragX = event.nativeEvent.pageX - this.pressInEvent.nativeEvent.pageX;
    let dragY = event.nativeEvent.pageY - this.pressInEvent.nativeEvent.pageY;

    // Resize should continue if it gets to zero
    switch (this.dragType) {
      case this.DRAG_TYPE_RESIZE_LEFT:
        ret.resizeWidth = this.layout.width + dragX * -1;
        ret.dragX = dragX;
        console.log(
          "CHECK RESIZE LEFT",
          ret.resizeWidth,
          ret.dragX,
          this.layout.width
        );
        break;
      case this.DRAG_TYPE_RESIZE_RIGHT:
        ret.resizeWidth = this.layout.width + dragX;
        break;
      case this.DRAG_TYPE_RESIZE_TOP:
        ret.resizeHeight = this.layout.height + dragY * -1;
        ret.dragY = dragY;
        break;
      case this.DRAG_TYPE_RESIZE_BOTTOM:
        ret.resizeHeight = this.layout.height + dragY;
        break;
      case this.DRAG_TYPE_RESIZE_TOP_LEFT:
        ret.resizeWidth = this.layout.width + dragX * -1;
        ret.resizeHeight = this.layout.height + dragY * -1;
        ret.dragY = dragY;
        ret.dragX = dragX;
        break;
      case this.DRAG_TYPE_RESIZE_TOP_RIGHT:
        ret.resizeWidth = this.layout.width + dragX;
        ret.resizeHeight = this.layout.height + dragY * -1;
        ret.dragY = dragY;
        break;
      case this.DRAG_TYPE_RESIZE_BOTTOM_RIGHT:
        ret.resizeWidth = this.layout.width + dragX;
        ret.resizeHeight = this.layout.height + dragY;
        break;
      case this.DRAG_TYPE_RESIZE_BOTTOM_LEFT:
        ret.resizeWidth = this.layout.width + dragX * -1;
        ret.resizeHeight = this.layout.height + dragY;
        ret.dragX = dragX;
        break;
      default:
        ret.dragX = dragX;
        ret.dragY = dragY;
    }
    if (ret.resizeWidth !== null && ret.resizeWidth < 0) {
      ret.resizeWidth = ret.resizeWidth * -1;
      switch (this.dragType) {
        case this.DRAG_TYPE_RESIZE_LEFT:
        case this.DRAG_TYPE_RESIZE_TOP_LEFT:
        case this.DRAG_TYPE_RESIZE_BOTTOM_LEFT:
          ret.dragX = this.layout.width;
          console.log("CHECK RESIZE LEFT 2", ret.resizeWidth, ret.dragX);
          break;
        case this.DRAG_TYPE_RESIZE_RIGHT:
        case this.DRAG_TYPE_RESIZE_TOP_RIGHT:
        case this.DRAG_TYPE_RESIZE_BOTTOM_RIGHT:
          ret.dragX = dragX + this.layout.width;
          break;
      }
    }
    if (ret.resizeHeight !== null && ret.resizeHeight < 0) {
      ret.resizeHeight = ret.resizeHeight * -1;
      switch (this.dragType) {
        case this.DRAG_TYPE_RESIZE_TOP:
        case this.DRAG_TYPE_RESIZE_TOP_LEFT:
        case this.DRAG_TYPE_RESIZE_TOP_RIGHT:
          ret.dragY = this.layout.height;
          break;
        case this.DRAG_TYPE_RESIZE_BOTTOM:
        case this.DRAG_TYPE_RESIZE_BOTTOM_RIGHT:
        case this.DRAG_TYPE_RESIZE_BOTTOM_LEFT:
          ret.dragY = dragY + this.layout.height;
          break;
      }
    }

    const posX = this.layout.x + ret.dragX;
    const posY = this.layout.y + ret.dragY;
    const width =
      this.resizeWidth !== null ? this.resizeWidth : this.layout.width;
    const height =
      this.resizeHeight !== null ? this.resizeHeight : this.layout.height;

    // TODO: "DEV DEV DEV THIS WAS ADDED BECAUSE THE PROPS ARE USED BUT CAN'T CHANGE SO IT IS NOT CONTROLLED"

    ret.resizeWidth = ret.resizeWidth || width;
    ret.resizeHeight = ret.resizeHeight || height;

    if (posX < 0) ret.dragX = this.layout.x * -1;
    else if (posX + width > this.container.state.width)
      ret.dragX = this.container.state.width - width - this.layout.x;
    if (posY < 0) ret.dragY = this.layout.y * -1;
    else if (posY + height > this.container.state.height)
      ret.dragY = this.container.state.height - height - this.layout.y;
    return ret;
  }

  // handleDragEnd(event) {
  //   console.log("HANDLE DRAG END!!!", event.nativeEvent);
  //   // event = this.normalizeEvent(event);
  //   // this.setPressInEvent(null);
  //   // this.setDragType(null);
  //   // this.reset();
  //   // console.log("CHECK ON DRAG END 1");
  //   this.props.onDragEnd && this.props.onDragEnd(event, this);
  // }
  _handleContainerPressEnd(event, callback = null) {
    event = this.normalizeEvent(event);
    this.setPressInEvent(null);
    this.setDragType(null);
    // Update layout based on drag
    this.updateLayout();
    // this.reset();
    callback && callback(event, this);
  }

  handleContainerPressCancel(event) {
    this._handleContainerPressEnd(event, this.props.onDragCancel);
  }
  handleContainerPressOut(event) {
    this._handleContainerPressEnd(event, this.props.onDragEnd);
  }
  // handlePressOut(event) {
  //   console.log("DEV DEV DEV REMOVE AND CLEANUP, USE CONTAINER");
  //   return;
  //   event = this.normalizeEvent(event);
  //   console.log("handlePressOut", event.nativeEvent);
  //   this.setPressInEvent(null);
  //   this.setDragType(null);
  //   // Update layout based on drag
  //   this.updateLayout();
  //   // this.reset();
  //   if (this.props.onDragCancel) {
  //     this.props.onDragCancel(event, this);
  //   }
  // }
  handleContainerPressMove(event) {
    event = this.normalizeEvent(event);
    if (this.active) {
      let dragLayout = this.calculateDragLayout(event);
      if (this.props.styleOnDrag) {
        this._styleOnDrag = this.props.styleOnDrag(event, this) || null;
      }
      this.setState(dragLayout);
      if (this.props.onDrag) {
        this.props.onDrag(event, this);
        // throw new Error('TEST ON DRAG');
      }
    }
  }
  handleLayout(event) {
    if (this.state.layout && this.active) return;
    // console.log("UPDATING LAYOUT");
    // console.log(
    //   "HANDLE LAYOUT HANDLE LAYOUT",
    //   this.active,
    //   event.nativeEvent.layout
    // );
    // //this._layout = event && event.nativeEvent ? event.nativeEvent.layout : null;
    this.setState({
      layout: event && event.nativeEvent ? event.nativeEvent.layout : null,
    });
  }
  handleMeasure(x, y, width, height, pageX, pageY) {
    this._measure = { x, y, width, height, pageX, pageY };
    // this.setState({
    //   measure: { x, y, width, height, pageX, pageY },
    // });
  }
  updateLayout() {
    let layout = { ...this.state.layout };
    if (this.dragX) layout.x = layout.x + this.dragX;
    if (this.dragY) layout.y = layout.y + this.dragY;
    if (this.resizeHeight !== null) layout.height = this.resizeHeight;
    if (this.resizeWidth !== null) layout.width = this.resizeWidth;

    this.setState({
      layout,
      dragX: 0,
      dragY: 0,
      resizeHeight: null,
      resizeWidth: null,
    });
    this._styleOnDrag = null;
    this.container.setActiveView(null);
  }
  reset() {
    this.setState({
      dragX: 0,
      dragY: 0,
      resizeHeight: null,
      resizeWidth: null,
    });
    this._styleOnDrag = null;
    this.container.setActiveView(null);
  }
}
