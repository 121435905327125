import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import R14, {
  useForm,
  useScroller,
  StyleSheet,
  Colors,
  Card,
  Text,
  IconButton,
  Scroller,
  HiddenInputField,
} from "../core";

import AnnotationQuestionField from "./AnnotationQuestionField";

export default forwardRef(function AnnotationQuestionFieldGroup(
  {
    initialValueVisible,
    questionFields,
    values = [],
    submitButtonRef,
    disableSubmit,
    disabled = false,
    autoFocus = true,
  },
  ref
) {
  const [selectionKeys, setSelectionKeys] = useState(
    values
      .filter((answer) => (answer.selectionKey ? true : false))
      .map((answer) => answer.selectionKey)
  );
  const lastSelectionKey = selectionKeys.length
    ? selectionKeys[selectionKeys.length - 1]
    : null;
  const form = useForm("documentAnnotationForm");
  const scroller = useScroller("answerFieldScroller");
  const autoFocusFieldKeyRef = useRef(null);
  const answersRef = useRef(values);
  const forEachFields = (fields, callback, parentField = null) => {
    let ret = [];
    for (let field of fields) {
      let res = callback(field, parentField);
      if (res === false) return;
      field.questionFields &&
        forEachFields(field.questionFields, callback, field);
    }
    return;
  };
  const mapFields = (fields, callback, parentField = null) => {
    let ret = [];
    forEachFields(fields, (field, parentField) => {
      let res = callback(field, parentField);
      if (res === false) return false;
      ret.push(res);
    });
    return ret;
  };
  const findField = (fields, callback) => {
    let ret = null;
    forEachFields(fields, (field, parentField) => {
      if (callback(field, parentField)) {
        ret = field;
        return false;
      }
    });
    return ret;
  };
  const compareArrays = (a1, a2) =>
    a1.length === a2.length && a1.every((elmt, idx) => elmt === a2[idx]);
  const answerKeys = answersRef.current.map((answer) => answer.key);

  useEffect(() => {
    // Update the authFocusFieldKey
    let timeoutHandler = null;
    if (!disabled) {
      if (autoFocus && autoFocusFieldKeyRef.current) {
        timeoutHandler = setTimeout(() => {
          form.elmts[autoFocusFieldKeyRef.current].setFocus(true);
          scroller && scroller.setActiveItemName(autoFocusFieldKeyRef.current);
        }, 100);
      } else if (!disableSubmit && submitButtonRef.current)
        submitButtonRef.current.focus();
    }

    // Update any form elmts in case it has an answer
    answersRef.current.forEach((answer) => {
      form.elmts[answer.key] &&
        form.elmts[answer.key].setValue(answer.selectionKey || answer.value);
    });
    return () => {
      timeoutHandler && clearTimeout(timeoutHandler);
    };
  }, [selectionKeys]);

  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          if (autoFocusFieldKeyRef.current) {
            form.elmts[autoFocusFieldKeyRef.current].setFocus(true);
            scroller &&
              scroller.setActiveItemName(autoFocusFieldKeyRef.current);
          }
        },
        blur() {
          for (let key in form.elmts) {
            if (form.elmts[key].isFocused) form.elmts[key].blur();
          }
        },
      };
    },
    []
  );

  const updateSelections = (answers) => {
    // Update the selectionKeys
    let newSelectionKeys = answers
      .filter((answer) => (answer.selectionKey ? true : false))
      .map((answer) => answer.selectionKey);
    !compareArrays(selectionKeys, newSelectionKeys) &&
      setSelectionKeys(newSelectionKeys);
  };

  const removeAnswerByKey = (key) => {
    let newAnswers = answersRef.current.filter((answer) => answer.key !== key);
    answersRef.current = newAnswers;
    form.elmts.values.value = newAnswers.map((answer) => ({
      questionAnnotationUuidPath: answer.questionAnnotationUuidPath,
      selectionUuid: answer.selectionUuid || null,
      value: answer.value,
    }));
    updateSelections(newAnswers);
  };

  const updateAnswers = (answers, answer) => {
    // Update the answers
    let newAnswers = [...answers];
    if (answer) {
      // Move answer to the front
      newAnswers = newAnswers.filter((a) => a.key !== answer.key);
      newAnswers.push(answer);
    }
    answersRef.current = newAnswers;
    form.elmts.values.value = newAnswers.map((answer) => ({
      questionAnnotationUuidPath: answer.questionAnnotationUuidPath,
      selectionUuid: answer.selectionUuid || null,
      value: answer.value,
    }));
    updateSelections(newAnswers);
  };

  const handleFieldValueChange = (field, value) => {
    // Cleanup the answers
    let answers = answersRef.current.filter(
      ({ key }) => key !== field.key && !field.childKeys.includes(key)
    );
    // Add the selected answer
    let answer = {
      key: field.key,
      questionAnnotationUuidPath: field.path,
    };
    let shouldUpdateAnswers = true;
    if (field.fieldType === "INPUT") answer.value = value;
    else {
      let selection = field.selections.find(({ key }) => key === value);
      answer.selectionKey = value;
      if (selection) {
        answer.selectionUuid = selection.uuid;
        answer.value = selection.value || selection.label;
      } else {
        console.log(
          "Warning: handleFieldValueChange could not find selection for key:",
          field.key,
          value
        );
        shouldUpdateAnswers = false;
        removeAnswerByKey(field.key);
      }
    }
    // Update the selectionKeys
    shouldUpdateAnswers && updateAnswers(answers, answer);
  };

  const handleFieldValueEditPress = (field) => {
    let answer = null;
    // if the field has a parent
    if (field.parentKey) {
      // Add the selected answer
      answer = {
        key: field.parentKey,
        selectionKey: field.parentSelectionKey,
      };
    }
    // Remove any answers that are children of field
    let answers = answersRef.current.filter(({ key }) => {
      return (
        !field.childKeys.includes(key) &&
        field.key !== key &&
        field.parentKey !== key
      );
    });
    autoFocusFieldKeyRef.current = field.key;
    updateAnswers(answers, answer);
  };

  const getFieldValueLabel = (field) => {
    let value = getFieldValue(field);
    return value ? (field.fieldType === "INPUT" ? value : value.label) : null;
  };

  const getFieldValue = (field) => {
    let ret = null;
    // if (!field.selections) return field.value || null;
    for (const answer of answersRef.current) {
      if (answer.key === field.key) {
        if (field.fieldType === "INPUT") ret = answer.value;
        else if (field.selections)
          for (const selection of field.selections) {
            if (selection.key === answer.selectionKey) {
              ret = selection;
              break;
            }
          }
        break;
      }
    }
    return ret;
  };

  const renderFields = (fields) => {
    let foundAutoFocus = false;
    return mapFields(fields, (field, parentField) => {
      if (!parentField) autoFocusFieldKeyRef.current = null;
      let shouldRenderField = false;
      let shouldRenderFieldBreadcrumb = false;
      if (!answersRef.current.length && !parentField) {
        if (!autoFocusFieldKeyRef.current)
          autoFocusFieldKeyRef.current = field.key;
        shouldRenderField = true;
      } else {
        // Check answers to decide what to render
        // If the field is already answered must be a form field or breadcrumb
        if (answerKeys.includes(field.key)) {
          // Check if the answer has any children
          //let childField = field.selectionQuestionAnnotations && field.selectionQuestionAnnotations.some();
          // If the field has childKeys then show the breadcrumb
          if (field.fieldType === "INPUT") shouldRenderField = true;
          else {
            let value = getFieldValue(field);
            shouldRenderFieldBreadcrumb = true;
            if (value) {
              let childField =
                field.selectionQuestionAnnotations &&
                field.selectionQuestionAnnotations.find(
                  ({ selectionUuid }) => selectionUuid === value.uuid
                );
              if (!childField) {
                shouldRenderField = true;
                shouldRenderFieldBreadcrumb = false;
              }
            } else {
              shouldRenderField = true;
              shouldRenderFieldBreadcrumb = false;
            }
          }
          // else shouldRenderFieldBreadcrumb = true;
          // if (field.childKeys.length) shouldRenderFieldBreadcrumb = true;
          // else shouldRenderField = true;
        } else if (
          // If the parent has an answer show the field
          field.parentKey &&
          answerKeys.includes(field.parentKey) &&
          selectionKeys.includes(field.parentSelectionKey)
        ) {
          shouldRenderField = true;
        }

        // Calculate the autofocus
        if (
          shouldRenderField &&
          !foundAutoFocus &&
          !answerKeys.includes(field.key)
        ) {
          if (
            lastSelectionKey &&
            lastSelectionKey === field.parentSelectionKey
          ) {
            foundAutoFocus = true;
            autoFocusFieldKeyRef.current = field.key;
          } else if (!autoFocusFieldKeyRef.current)
            autoFocusFieldKeyRef.current = field.key;
        }
      }
      let component = null;

      if (shouldRenderField)
        component = (
          <AnnotationQuestionField
            key={field.key}
            name={field.key}
            label={field.label}
            items={
              field.selections
                ? field.selections.map((selection) => ({
                    label: selection.label,
                    value: selection.key,
                  }))
                : null
            }
            searchable={field.searchable || false}
            required={field.required || false}
            editable={field.editable || false}
            disabled={disabled}
            initialValueVisible={initialValueVisible}
            fieldType={field.fieldType}
            onValueChange={(value) => handleFieldValueChange(field, value)}
          />
        );
      else if (shouldRenderFieldBreadcrumb)
        component = (
          <Card
            key={field.key}
            surfaceStyle={styles.fieldBreadCrumbCardSurface}
            title={field.label}
            titleTextStyle={[styles.fieldBreadCrumbCardTitleText]}
            titleControlsRight={[
              <IconButton
                key='edit'
                size='small'
                style={styles.fieldBreadCrumbCardEditIconButton}
                icon='edit'
                onPress={() => handleFieldValueEditPress(field)}
                tooltip='Edit'
                disabled={disabled}
              />,
            ]}
          >
            <Text>{getFieldValueLabel(field)}</Text>
          </Card>
        );
      return component ? (
        <Scroller.Item
          key={field.key}
          name={field.key}
          style={styles.scrollerItem}
          indicator={false}
        >
          {component}
        </Scroller.Item>
      ) : null;
    }).filter((field) => (field ? true : false));
  };
  return (
    <Scroller
      name='answerFieldScroller'
      style={[styles.scroller]}
      direction='vertical'
      maxHeight={400}
    >
      <HiddenInputField name='values' />
      {renderFields(questionFields)}
    </Scroller>
  );
});
const styles = StyleSheet.create({
  valueTextInput: {
    height: 80,
  },
  fieldBreadCrumbCardTitleText: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 24,
  },
  fieldBreadCrumbCardEditIconButton: {
    ...StyleSheet.margin(0),
  },
  fieldBreadCrumbCardSurface: {
    ...StyleSheet.margin(0, 0, 24, 0),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.02),
  },
  scroller: {
    ...StyleSheet.margin(0, -16, 0, -16),
    ...StyleSheet.padding(0, 0, 0, 0),
  },
  scrollerItem: {
    ...StyleSheet.padding(0, 16, 0, 16),
  },
});
