import React from "react";
import R14, { View, Text, StyleSheet, CenteredForm } from "../core";
export default R14.connect(
  class ManualEntryDocumentSetCompleteScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleCompletePress = this.handleCompletePress.bind(this);
    }

    async handleSubmit() {
      return false;
    }
    renderMessage() {
      return (
        this.props.message || "Please use Google Chrome browser at this time."
      );
    }
    renderButtonTitle() {
      return this.props.buttonTitle || "Refresh";
    }
    render() {
      return (
        <View>
          <Text>
            All fields have been completed. Press complete to save your changes
            and go to the next item in your queue.
          </Text>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 480)
        return {
          width: "100%",
        };
    },
  },
  noResults: {
    marginBottom: 24,
  },
});
