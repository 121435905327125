import React from "react";
import StyleSheet from "./StyleSheet";
import Form from "./Form";
import SectionHeader from "./SectionHeader";

import { Colors } from "./Theme";
import CenteredCard from "./CenteredCard";
export default class CenteredForm extends React.Component {
  componentDidMount() {
    this.props.onKeyDown &&
      document.addEventListener("keydown", this.props.onKeyDown);
  }
  componentWillUnmount() {
    this.props.onKeyDown &&
      document.removeEventListener("keydown", this.props.onKeyDown);
  }
  render() {
    return (
      <CenteredCard {...this.props}>
        <Form
          backgroundColor={Colors.surface}
          {...this.props}
          style={[styles.centeredForm]}
        >
          {this.props.headerLabel && (
            <SectionHeader style={styles.formHeader} level={2}>
              {this.props.headerLabel}
            </SectionHeader>
          )}
          {this.props.children}
        </Form>
      </CenteredCard>
    );
  }
}
const styles = StyleSheet.create({
  centeredForm: {
    width: null,
    ...StyleSheet.padding(8, 0, 0, 0),
    screen: ({ width }) => {
      if (width <= 640) {
        return {
          width: null,
        };
      }
    },
  },
});
