import React from "react";
import Surface from "./Surface";
import IconButton from "./IconButton";
import StyleSheet from "./StyleSheet";
import FadeView from "./FadeView";
import { Colors } from "./Theme";

export default class FloatingActionButton extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    let styles = this.styles();
    let Component = this.props.animate ? FadeView : Surface;
    let props = this.props.animate
      ? {
          visible: this.props.visible === true,
          animateOnMount: true,
          duration: 200,
        }
      : { elevation: 8 };

    return (
      <Component
        style={[styles.floatingActionButton, this.props.style]}
        {...props}
        // {...this.props}
      >
        <IconButton
          icon={this.props.icon}
          to={this.props.to}
          onPress={this.props.onPress}
          style={styles.iconButton}
          color={this.props.iconColor || Colors.onSecondary}
        />
      </Component>
    );
  }
  styles() {
    return StyleSheet.create({
      floatingActionButton: {
        position: "absolute",
        bottom: 16,
        right: 16,
        zIndex: 4,
        height: 56,
        width: 56,
        padding: 0,
        borderRadius: 28,
        backgroundColor: this.props.color || Colors.secondary,
        elevation: 8,
      },
      iconButton: {
        height: 56,
        width: 56,
        padding: 16,
        borderRadius: 28,
        overflow: 'hidden',
        ...StyleSheet.margin(0),
      },
    });
  }
}
