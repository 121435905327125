import React from "react";
import Text from "./Text";
import View from "./View";
import Icon from "./Icon";
import Touchable from "./Touchable";
import StyleSheet from "./StyleSheet";
import ControlsView from "./ControlsView";
import { Colors } from "./Theme";

export default class PopUpMenuItem extends React.PureComponent {
  constructor(props) {
    super(props);
    if (!this.props.popUpMenu)
      throw new Error("PopUpMenuItem Error: No popupmenu found");
    this.handlePress = this.handlePress.bind(this);
    this.handleLayout = this.handleLayout.bind(this);
    this.popUpMenu = this.props.popUpMenu;
  }
  handleLayout(event) {
    if (this.props.onLayout) this.props.onLayout(event);
    if (this.scrollToItem) this.popUpMenu.handleScrollItemLayout(event);
  }
  handlePress() {
    this.popUpMenu.handleItemPress(this.props.value, this.props.label);
    if (this.props.onPress)
      this.props.onPress(this.props.value, this.props.label);
  }
  get scrollToItem() {
    if (
      this.popUpMenu.scrollToItem !== null &&
      "value" in this.props &&
      this.popUpMenu.scrollToItem === this.props.value
    ) {
      return true;
    }
    return false;
  }
  render() {
    return (
      <View style={[styles.popUpMenuItem, this.props.style]}>
        {this.props.controlsLeft && (
          <ControlsView style={styles.controlsLeft} align='left'>
            {this.props.controlsLeft}
          </ControlsView>
        )}
        <Touchable style={styles.touchable} onPress={this.handlePress}>
          <View
            onLayout={this.handleLayout}
            style={[
              styles.container,
              this.props.controlsLeft && styles.containerControlsLeft,
            ]}
          >
            {this.props.icon && (
              <Icon
                color={
                  this.props.iconColor ||
                  StyleSheet.color(Colors.onSurface).rgba(0.75)
                }
                name={this.props.icon}
                size={this.props.iconSize || "small"}
                style={[styles.icon, this.props.iconStyle]}
              />
            )}
            <Text
              style={[styles.labelText, this.props.labelTextStyle]}
              ellipsisMode={this.props.labelTextEllipsisMode}
              numberOfLines={this.props.labelTextNumberOfLines}
            >
              {this.props.label}
            </Text>
          </View>
        </Touchable>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  popUpMenuItem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    hover: {
      backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
    },
  },
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    paddingRight: 16,
    paddingLeft: 16,
    minHeight: 48,
  },
  containerControlsLeft: {
    paddingLeft: 0,
  },
  touchable: {
    flex: 1,
    overflow: "hidden",
  },
  labelText: {
    lineHeight: 22,
    paddingTop: 13,
    paddingBottom: 13,
    color: Colors.onSurface,
  },
  icon: {
    marginRight: 8,
    marginTop: 14,
    marginLeft: -6,
  },
  controlsLeft: {
    flex: 0,
    marginLeft: 8,
    marginRight: 0,
    marginTop: 6,
  },
});
