const FormValidators = {
  email: (value) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(value)) {
      return {
        success: true,
        value: value,
      };
    } else return { error: "Please enter a valid email." };
  },
  key: (value) => {
    if (/^\w+([\.-]?\w+)+$/.test(value)) {
      return {
        success: true,
        value: value,
      };
    } else return { error: "Please enter a valid key." };
  },
  username: (value) => {
    let isValid = value.match(/^\w+$/);
    value = value.trim();
    if (isValid && (value.length < 3 || value.length > 20)) isValid = false;
    if (!isValid)
      return {
        //error: "Username must be between 3 and 15 letters and can only contain letters, numbers, and userscores.",
        error: "Please enter a valid username.",
        value: value,
      };
    else
      return {
        success: true,
        value: value,
      };
  },
  password: (value) => {
    if (!value || value.length < 6)
      return { error: "Password must be at least 6 characters." };
    else return true;
  },
  passwordConfirm: (value, options, element, form) => {
    if (!form.elmts.password)
      return {
        error: "Password element not found.",
      };
    if (form.elmts.password.value !== value)
      return {
        error: "Password Mismatch",
      };
    else return true;
  },
  positiveInt: (value) => {
    value = `${value}`;
    if (/^\d+$/.test(value)) return true;
    else return { error: "Must be a positive whole number." };
  },
  int: (value) => {
    value = `${value}`;
    if (value.match(/^-{0,1}\d+$/)) return true;
    else return { error: "Must be a whole number." };
  },
  number: (number) => {
    if (isNaN(number)) return { error: "Must be a whole number." };
    else return true;
  },
  shortDate: (value) => {
    let t = value.split("/");
    let d = new Date(t[2] + "/" + t[0] + "/" + t[1]);
    return d &&
      d.getMonth() + 1 == t[0] &&
      d.getDate() == Number(t[1]) &&
      d.getFullYear() == Number(t[2])
      ? true
      : { error: "Date format MM/DD/YYYY." };
  },
  hexColor: (hex) => {
    return /^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/.test(hex)
      ? true
      : { error: "Invalid hex color." };
  },
  time: (value) => {
    value = `${value}`;
    if (value.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/)) return true;
    else return { error: "Must be a valid time." };
  },
  url: (url) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(url);
  },
};
export default FormValidators;
