import React from "react";
import PropTypes from "prop-types";
import R14, {
  StyleSheet,
  View,
  ScrollView,
  TextInput,
  Image,
  Colors,
} from "../core";
import CharacterImage from "./CharacterImage";
import FieldImage from "./FieldImageNew";
import FieldCharacterTextInput from "./FieldCharacterTextInput";

export default class FieldCharactersRow extends React.PureComponent {
  static propTypes = {
    // /** Name for the character entry fields. */
    // name: PropTypes.string.isRequired,
    // /** Value of the character entry fields. */
    // value: PropTypes.any,
  };

  constructor(props) {
    super(props);
  }

  createDynamicStyles() {
    let dynamicStyles = {};
    let {
      rowPadding,
      scale,
      height,
      top,
      imageHeight,
      imageWidth,
    } = this.props;
    let rowHeight = height * imageHeight * scale + 2 * rowPadding;
    // let rowHeight =
    // row.offset.height * this.state.imageHeight * scale + 2 * rowPadding;
    // dynamicStyleMap[`characterTextInputRow${rowIdx}`] = {
    //   // top: row.offset.top * this.state.imageHeight * scale,
    //   height: rowHeight,
    //   width: this.state.imageWidth * scale,
    // };
    // dynamicStyleMap[`rowFieldCharacters${rowIdx}`] = {
    //   height: rowHeight,
    //   // marginTop: row.offset.height * this.state.imageHeight * scale * .25,
    //   width: this.state.imageWidth * scale,
    // };
    // dynamicStyleMap[`rowFieldImageWrapper${rowIdx}`] = {
    //   height: rowHeight,
    //   paddingTop: rowPadding,
    //   width: this.state.imageWidth * scale,
    // };

    dynamicStyles.rowFieldCharacters = {
      height: rowHeight,
      // marginTop: row.offset.height * this.state.imageHeight * scale * .25,
      width: imageWidth * scale,
    };
    dynamicStyles.rowFieldImageWrapper = {
      height: rowHeight,
      paddingTop: rowPadding,
      width: imageWidth * scale,
    };
    dynamicStyles.rowFieldImage = {
      transform: [
        {
          scale: scale,
          translateX: (imageWidth * scale - imageWidth) / (scale * 2),
          translateY: (imageHeight * scale - imageHeight) / (scale * 2),
          //   -
        },
      ],
      marginTop: -1 * top * scale * imageHeight,
    };
    return StyleSheet.create(dynamicStyles);
  }
  getCharacter(uuid) {
    let ret = null;
    for (let char of this.props.characters) {
      if (uuid === char.uuid) {
        ret = char;
      }
    }
    return ret;
  }
  getCharacterValue(uuid) {
    let char = this.getCharacter(uuid);
    return char ? this.props.valueParser(char) : null;
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <View style={styles.fieldRow} key={`fieldRow${this.props.rowIdx}`}>
        <View
          style={[
            styles.rowFieldImageWrapper,
            dynamicStyles.rowFieldImageWrapper,
          ]}
        >
          <Image
            // resizeMode="cover"
            width={this.props.imageWidth}
            height={this.props.imageHeight}
            style={[styles.rowFieldImage, dynamicStyles.rowFieldImage]}
            source={`data:image/png;base64,${this.props.fieldImageBase64}`}
          />
        </View>
        <View
          style={[styles.rowFieldCharacters, dynamicStyles.rowFieldCharacters]}
        ></View>
        {this.props.columns.map((col, colIdx) => {
          // return this.props.columnRenderer(this.props.rowIdx, colIdx);
          //console.log('CHECK CHECK CHECK', col);
          if(! col.hasFieldOffset) return null;
          let character = this.getCharacter(col.uuid);
          let firstChar = this.props.rowIdx === 0 && colIdx === 0;
          return (
            <FieldCharacterTextInput
              key={`char${col.uuid}`}
              name={`char${col.uuid}`}
              characterUid={col.uid}
              characterUuid={col.uuid}
              annotationSetUid={this.props.annotationSetUid}
              //characterRef={this.props.characterRefs[col.uid] || null}
              width={col.offset.width}
              left={col.offset.left}
              imageHeight={this.props.imageHeight}
              imageWidth={this.props.imageWidth}
              rowHeight={this.props.height}
              rowPadding={this.props.rowPadding}
              fontSize={this.props.fontSize}
              fontRatio={this.props.fontRatio}
              // changed={character.value !== this.props.valueParser(character)}
              // reject={character.reject}
              lowScore={character.lowScore || false}
              // focus={
              //   character.uid ===
              //   this.props.app.dm.manualEntry.focusedCharacterUid
              // }
              scale={this.props.scale}
              value={this.getCharacterValue(col.uuid)}
              onChangeText={this.props.onCellChangeText}
              autoSelect={firstChar}
              autoFocus={firstChar}
              // onFocus={() => this.props.onCellFocus(col.uid)}
              // onBlur={() => this.props.onCellBlur(col.uid)}
              autoComplete={false}
            />
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  fieldRow: {
    marginBottom: 16,
    //backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.8),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overflow: "hidden",
    position: "relative",
  },
  rowFieldImage: {
    flex: 1,
    backgroundPosition: "center",
  },
  rowFieldImageWrapper: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
  },
  // textInputField: {
  //   // minHeight: 22,
  //   fontFamily: "'Courier Prime', 'Courier New', monospace",
  //   //fontFamily: "Arial, Helvetica, sans-serif",
  //   fontSize: 24,
  // },
  // characterTextInput: {
  //   fontFamily: "'Courier Prime', 'Courier New', monospace",
  //   fontWeight: 400,
  //   backgroundColor: Colors.surface,
  //   //backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.3),
  //   position: "absolute",
  //   overflow: "visible",
  //   textAlign: "center",
  //   ...StyleSheet.padding(0),
  // },
  // characterCompareOverlay: {
  //   //backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.2),
  //   position: "absolute",
  //   borderRightWidth: 1,
  //   borderStyle: "dashed",
  //   borderColor: StyleSheet.color("#999999").rgba(0.9),
  //   // borderRadius: 4,
  // },
  // fieldCharacterOverlay: {
  //   position: "absolute",
  // },
  // character: {
  //   backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2),
  // },
  // fieldCharacters: {
  //   flex: 1,
  //   flexDirection: "rows",
  //   justifyContent: "center",
  // },
  // characterTextInputRows: {
  //   position: "relative",
  // },
  rowFieldCharacters: {
    position: "relative",
  },
});
