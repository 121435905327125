
import React from 'react';
import PropTypes from 'prop-types';
import R14 from '../R14';
import Button from './Button';
/**
 * 
 * A button that navigates back if possible. See button for inherited props.
 * 
 */
export default class BackButton extends React.PureComponent {
  static propTypes = {
    /** Callback for when the button is pressed. If the callback returns false, the app will not navigate back. */
    onPress: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }
  handlePress() {
    if(this.props.onPress) if(this.props.onPress() === false) return false;
    R14.instance.app.nav.back();
  }
  render() {
    return (
      <Button
        {...this.props}
        onPress={this.handlePress}
      />
    );
  }
}