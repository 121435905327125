import React, { useMemo } from "react";
import PropTypes from "prop-types";
import R14, {
  StyleSheet,
  Colors,
  View,
  Text,
  Scroller,
  Chip,
  ActivityIndicator,
  withAppObserver,
  useDomain,
  useUi,
} from "../core";

function DocumentSetFieldScroller({ documentSet }) {
  const manualEntryDomain = useDomain("manualEntry");
  const uiConfig = useUi("config");

  const pageInfo = documentSet.fieldAnnotationsPageInfo;

  const getIndicatorStyle = (annotation, active = false) => {
    return getUiSettingStyle("fieldIndicator", annotation, active);
  };
  const getItemStyle = (annotation, active = false) => {
    return getUiSettingStyle("field", annotation, active);
  };
  const getItemValueTextStyle = (annotation, active = false) => {
    return getUiSettingStyle("fieldValueText", annotation, active);
  };
  const getItemLabelTextStyle = (annotation, active = false) => {
    return getUiSettingStyle("fieldLabelText", annotation, active);
  };

  const getUiSettingStyle = (prefix, annotation, active = false) => {
    return uiConfig.getStylesByAnnotation(prefix, annotation, active);
    // let activeStr = active ? "Active" : "";
    // let stateStr = "";
    // switch (annotation.metadata.state) {
    //   case manualEntryDomain.STATE_REVIEW:
    //     stateStr = "Review";
    //     break;
    //   case manualEntryDomain.STATE_COMPLETE:
    //     stateStr = "Complete";
    //     break;
    //   case manualEntryDomain.STATE_QUEUED:
    //     stateStr = "Queued";
    //     break;
    //   default:
    //   // Do nothing
    // }
    // let statusStr = null;

    // if (annotation.metadata.reject) statusStr = "Reject";
    // else if (annotation.metadata.changed) statusStr = "Changed";
    // else if (annotation.metadata.state !== manualEntryDomain.STATE_QUEUED)
    //   statusStr = "Accept";
    // const statusArr = statusStr ? ["", statusStr] : [""];

    // const configStyles = [];
    // const activeArr = active ? ["", "Active"] : [""];
    // activeArr.forEach((activeStr) =>
    //   ["", stateStr].forEach((state) =>
    //     statusArr.forEach((status) => {
    //       let styleName = `${prefix}${status}${state}${activeStr}`;
    //       if (uiConfig.styles[styleName])
    //         configStyles.push(uiConfig.styles[styleName]);
    //     })
    //   )
    // );
    // return configStyles;
  };

  // const createBackgroundColor = (color, alpha = null) => {
  //   return alpha != null && alpha !== undefined && alpha > 0
  //     ? StyleSheet.color(color).rgba(alpha)
  //     : color;
  // };
  // const renderItems=() =>{
  //   return documentSet
  //     .findAnnotations({
  //       sortBy: ["page", "layout"],
  //       type: this.props.manualEntryDomain.ANNOTATION_TYPE_FIELD,
  //     })
  //     .map((annotation) => renderItem(annotation));
  // }
  const renderItem = (annotation) => {
    const active =
      documentSet.currentAnnotation &&
      documentSet.currentAnnotation.uuid === annotation.uuid;
    const valueLabel = annotation.valueLabel;

    return (
      // console.log(
      //   "RENDER ITEM!",
      //   annotation.name,
      //   annotation.value,
      //   annotation.values,
      //   annotation.valueExists,
      //   annotation
      // ),
      <Scroller.Item
        key={annotation.uuid}
        name={annotation.uuid}
        style={[styles.scrollerItem, getItemStyle(annotation)]}
        activeStyle={getItemStyle(annotation, true)}
        onPress={() => documentSet.navToAnnotation(annotation.uuid)}
        annotationUuid={annotation.uuid}
        indicatorPosition='left'
        indicatorStyle={getIndicatorStyle(annotation)}
        indicatorActiveStyle={getIndicatorStyle(annotation, true)}
        tooltip={valueLabel}
        // active={
        //   this.props.documentSet.currentAnnotation &&
        //   this.props.documentSet.currentAnnotation.type ===
        //     annotation.type &&
        //   this.props.documentSet.currentAnnotation.key === annotation.key
        // }
      >
        {documentSet.totalPages > 1 && (
          <Chip
            size='small'
            key='pageNumber'
            label={`${annotation.pageNumber}`}
            labelTextStyle={[
              styles.chipLabelText,
              getItemLabelTextStyle(annotation, active),
            ]}
            style={styles.pageNumberChip}
          />
        )}
        <Text
          ellipsisMode='tail'
          numberOfLines={1}
          style={getItemLabelTextStyle(annotation, active)}
        >
          {annotation.label || annotation.name}
        </Text>
        {(!annotation.valueExists ||
          !annotation.valuesExist ||
          uiConfig.showFieldValues) && (
          <Chip
            size='small'
            key='value'
            // noWrap={false}
            ellipsisMode='tail'
            numberOfLines={1}
            label={valueLabel}
            labelTextStyle={[
              styles.chipLabelText,
              getItemValueTextStyle(annotation, active),
            ]}
            style={styles.chip}
          />
        )}
      </Scroller.Item>
    );
  };
  const itemLoadingRenderer = (itemIdx) => {
    return (
      <View key={`fieldScrollerItem${itemIdx}`} style={styles.scrollerItem}>
        <Chip
          size='small'
          key='pageNumber'
          label={` `}
          style={[styles.pageNumberChip, styles.pageNumberChipLoading]}
        />
        <View style={styles.fieldTextLoading}></View>
      </View>
    );
  };

  return (
    <View style={styles.documentSetFieldScroller}>
      <Scroller
        name='annotationScroller'
        dataLoader={async (page) => {
          // const documents =
          const annotations = await documentSet.loadAnnotations({
            page,
            sortBy: ["page", "layout"],
            type: manualEntryDomain.ANNOTATION_TYPE_FIELD,
          });
          return annotations;
        }}
        keyExtractor={(item) => item.uuid}
        // onRemoveItem={(page) =>
        //   this.props.documentSet.removePageInstance(page)
        // }
        itemRenderer={(item) => renderItem(item)}
        itemLoadingRenderer={(itemIdx) => itemLoadingRenderer(itemIdx)}
        itemsPerPage={pageInfo.resultsPerPage}
        totalItems={documentSet.totalFieldAnnotations}
        initialPage={pageInfo.page}
        initialData={documentSet.findAnnotations({
          sortBy: ["page", "layout"],
          type: manualEntryDomain.ANNOTATION_TYPE_FIELD,
        })}
        onRemoveItem={(annotation) =>
          documentSet.removeAnnotationInstance(annotation)
        }
        itemSize={52}
        activeItemName={
          documentSet.currentAnnotation
            ? documentSet.currentAnnotation.uuid
            : null
        }
        style={styles.scroller}
        direction='vertical'
      >
        {/* {renderItems()} */}
      </Scroller>
      {false && documentSet.state.annotationsLoading && (
        <ActivityIndicator
          size='large'
          containerStyle={styles.activityIndicator}
        />
      )}
    </View>
  );
}
export default withAppObserver(DocumentSetFieldScroller);
// export default R14.connect(
//   class DocumentSetFieldScroller extends React.Component {
//     static propTypes = {};
//     constructor(props) {
//       super(props);
//     }
//     getIndicatorStyle(annotation, active = false) {
//       let activeStr = active ? "Active" : "";
//       let styleName =
//         annotation.metadata.state === this.props.app.dm.manualEntry.STATE_REVIEW
//           ? annotation.metadata.reject
//             ? `scrollerItemIndicator${activeStr}Reject`
//             : annotation.metadata.changed
//             ? `scrollerItemIndicator${activeStr}Changed`
//             : `scrollerItemIndicator${activeStr}Accept`
//           : `scrollerItemIndicator${activeStr}`;
//       return styles[styleName] || null;
//     }
//     getTextStyle(annotation, active = false) {
//       return annotation.metadata.state ===
//         this.props.app.dm.manualEntry.STATE_COMPLETE
//         ? styles.scrollerItemTextComplete
//         : null;
//     }
//     renderItems() {
//       return this.props.documentSet
//         .findAnnotations({
//           sortBy: ["page", "layout"],
//           type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD,
//         })
//         .map((annotation) => this.renderItem(annotation));
//     }

//     renderItem(annotation) {
//       console.log(
//         "RENDER ITEM",
//         annotation.pageNumber,
//         annotation.label,
//         annotation.uuid
//       );
//       return (
//         <Scroller.Item
//           key={annotation.uuid}
//           name={annotation.uuid}
//           style={styles.scrollerItem}
//           activeStyle={styles.scrollerItemActive}
//           onPress={() => this.props.documentSet.navToAnnotation(annotationUuid)}
//           annotationUuid={annotation.uuid}
//           indicatorPosition='left'
//           indicatorStyle={this.getIndicatorStyle(annotation)}
//           indicatorActiveStyle={this.getIndicatorStyle(annotation, true)}
//           // active={
//           //   this.props.documentSet.currentAnnotation &&
//           //   this.props.documentSet.currentAnnotation.type ===
//           //     annotation.type &&
//           //   this.props.documentSet.currentAnnotation.key === annotation.key
//           // }
//         >
//           <Chip
//             size='small'
//             key='pageNumber'
//             label={`${annotation.pageNumber}`}
//             labelTextStyle={styles.chipLabelText}
//             style={styles.pageNumberChip}
//           />
//           <Text
//             ellipsisMode='tail'
//             numberOfLines={1}
//             style={this.getTextStyle(annotation)}
//           >
//             {annotation.label || annotation.name}
//           </Text>
//           {!annotation.valueExists && (
//             <Chip
//               size='small'
//               key='blank'
//               label='blank'
//               labelTextStyle={styles.chipLabelText}
//               style={styles.chip}
//             />
//           )}
//         </Scroller.Item>
//       );
//     }
//     itemLoadingRenderer(itemIdx) {
//       return (
//         <View key={`fieldScrollerItem${itemIdx}`} style={styles.scrollerItem}>
//           <Chip
//             size='small'
//             key='pageNumber'
//             label={` `}
//             style={[styles.pageNumberChip, this.pageNumberChipLoading]}
//           />
//           <View style={styles.fieldTextLoading}></View>
//         </View>
//       );
//     }
//     render() {
//       return (
//         <View style={styles.documentSetFieldScroller}>
//           <Scroller
//             name='annotationScroller'
//             dataLoader={async (page) => {
//               // const documents =
//               const annotations = await this.props.documentSet.loadAnnotations({
//                 page,
//                 sortBy: ["page", "layout"],
//                 type: this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD,
//               });
//               console.log("CHECK LOADED ANNOATIOTIONS", page, annotations);
//               return annotations;
//             }}
//             keyExtractor={(item) => item.uuid}
//             // onRemoveItem={(page) =>
//             //   this.props.documentSet.removePageInstance(page)
//             // }
//             itemRenderer={(item) => this.renderItem(item)}
//             itemLoadingRenderer={(itemIdx) => this.itemLoadingRenderer(itemIdx)}
//             itemsPerPage={20}
//             totalItems={300}
//             itemSize={48}
//             activeItemName={
//               this.props.documentSet.currentAnnotation
//                 ? this.props.documentSet.currentAnnotation.uuid
//                 : null
//             }
//             style={styles.scroller}
//             direction='vertical'
//           >
//             {this.renderItems()}
//           </Scroller>
//           {this.props.documentSet.state.annotationsLoading && (
//             <ActivityIndicator
//               size='large'
//               containerStyle={styles.activityIndicator}
//             />
//           )}
//         </View>
//       );
//     }
//     createuiConfig.styles() {
//       let uiConfig.styles = {};
//       this.props.documentSet.pages.forEach((page) => {
//         let { width } = this.calculateThumbnailSize(page.image);
//         uiConfig.styles[`${this.createPageImageKey(page)}ScrollerItem`] = {
//           height: this.thumbnailHeight,
//           width,
//         };
//       });
//       return StyleSheet.create(uiConfig.styles);
//     }
//   }
// );

const styles = StyleSheet.create({
  documentSetFieldScroller: {
    position: "relative",
    flex: 1,
    flexDirection: "column",
  },
  scroller: {
    width: "100%",
  },
  activityIndicator: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
  },
  chip: {
    ...StyleSheet.margin(0, 0, 0, "auto"),
    height: 24,

    //marginLeft: "auto"
  },
  chipLabelText: {
    fontSize: 12,
    // fontWeight: "500"
  },
  pageNumberChip: {
    height: 24,
    ...StyleSheet.margin(0, 8, 0, 0),
    ...StyleSheet.padding(0),
  },
  pageNumberChipLoading: {
    width: 24,
  },
  fieldTextLoading: {
    height: 16,
    flex: 1,
    borderRadius: 4,
    // backgroundColor: StyleSheet.color(Colors.onSurface).rgba(1),
    backgroundImage: `linear-gradient(to bottom right, ${StyleSheet.color(
      Colors.onSurface
    ).rgba(0.1)}, ${StyleSheet.color(Colors.onSurface).rgba(0.02)})`,
  },
  scrollerItem: {
    width: "100%",
    height: 48,
    lineHeight: 48,
    // width: "auto",
    flex: 0,
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 0,
    ...StyleSheet.padding(0, 16, 0, 16),
    ...StyleSheet.margin(0, 0, 4, 0),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.01),
  },
  scrollerItemComplete: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0),
  },
  scrollerItemTextComplete: {
    color: StyleSheet.color(Colors.onSurface).rgba(0.6),
  },
  scrollerItemActive: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.04),
  },
  scrollerItemIndicatorActive: {
    backgroundColor: StyleSheet.color(Colors.activeQueueItem).rgba(0.8),
  },
  scrollerItemIndicatorReject: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
  scrollerItemIndicatorRejectActive: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.8),
  },
  scrollerItemIndicatorAccept: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.4),
  },
  scrollerItemIndicatorAcceptActive: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.8),
  },
  scrollerItemIndicatorChanged: {
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.4),
  },
  scrollerItemIndicatorChangedActive: {
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.8),
  },
  // characterFieldFocused: {
  //   borderColor: StyleSheet.color(Colors.onSurface).rgba(0.8),
  // },
  // characterFieldChanged: {
  //   borderColor: StyleSheet.color("#4caf50").rgba(0.6),
  // },
  // characterFieldFocus: {
  //   borderColor: StyleSheet.color(Colors.primary).rgba(0.8),
  //   elevation: 6,
  // },
  // characterFieldReject: {
  //   borderColor: StyleSheet.color(Colors.secondary).rgba(0.8),
  // },
  // characterFieldEdit: {
  //   borderColor: StyleSheet.color(Colors.primaryLight).rgba(0.8),
  // },
});
