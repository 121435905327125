import React from "react";
import R14 from "../../R14";
import StyleSheet from "../StyleSheet";
import { Colors } from "../Theme";

const Chart = R14.connect(
  class Chart extends React.Component {
    constructor(props) {
      super(props);
      this.chart = this.props.app.ui.chart.create(this.props);
      // this.chart.background.fill = this.props.backgroundColor || Colors.primary
    }
    componentDidMount() {
      this.initChart();
    }
    componentDidUpdate(prevProps) {
      this.initChart();
    }
    componentWillUnmount() {
      this.chart.remove();
    }
    async initChart() {
      this.chart.initialize(this.props.data);
    }
    render() {
      return (
        <div
          id={this.chart.domElementId}
          style={{ width: "100%", height: "300px" }}
        ></div>
      );
    }
  }
);
export default Chart;
