import React from "react";
import R14 from "../R14";
import Button from "./Button";

const SubmitButton = R14.connectForm(
  class SubmitButton extends React.Component {
    constructor(props) {
      super(props);
      this.handlePress = this.handlePress.bind(this);
      this.backgroundColor =
        this.props.backgroundColor || this.props.form.activeColor;
      this.ref = React.createRef();
    }
    handlePress(e) {
      e && e.preventDefault();
      this.props.form.submit();
    }
    focus() {
      this.ref.current && this.ref.current.focus();
    }
    blur() {
      this.ref.current && this.ref.current.blur();
    }
    render() {
      return (
        <Button
          tabIndex={0}
          backgroundColor={this.backgroundColor}
          {...this.props}
          onPress={this.handlePress}
          type='submit'
          ref={this.ref}
        />
      );
    }
  }
);
export default SubmitButton;
