import React from "react";
import R14, { View, text, StyleSheet, Text, FadeView, Colors } from "../core";
import PropTypes from "prop-types";
const AppStream = window.AppStream;
// import "../lib/amazon/appstream_embed";

export default class AmazonAppStreamView extends React.Component {
  static propTypes = {
    /** offset from edge of children */
    sessionUrl: PropTypes.string,
  };
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.appstreamEmbed = null;
    this.SESSION_STATE_UNKNOWN = "UNKNOWN";
    this.SESSION_STATE_RESERVED = "RESERVED";
    this.SESSION_STATE_STARTED = "STARTED";
    this.SESSION_STATE_DISCONNECTED = "DISCONNECTED";
    this.SESSION_STATE_ENDED = "ENDED";
  }
  componentDidMount() {
    // let AppStream = window.AppStream;
    let appstreamOptions = {
      sessionURL: this.props.sessionUrl,
      userInterfaceConfig: {
        [AppStream.Embed.Options.HIDDEN_ELEMENTS]: [],
      },
    };
    this.appstreamEmbed = new window.AppStream.Embed(
      "appstream-container",
      appstreamOptions
    );
    this.props.onSessionStateChange &&
      this.appstreamEmbed.addEventListener(
        AppStream.Embed.Events.SESSION_STATE_CHANGE,
        ({ sessionStatus }) => {
          let sessionState = this.getSessionStateByKey(sessionStatus);
          this.props.onSessionStateChange(sessionState, this);
        }
      );

      console.log("bridtge?", this.appstreamEmbed._bridge);
    setTimeout((t) => {
      // if (this.ref.current) {
      //   let htmlColl = this.ref.current.getElementsByTagName("iframe");
      //   let iframeRef = (htmlColl && htmlColl.length) ? htmlColl[0] : null;
      //   if(iframeRef){
      //     //console.log("Add event listener", iframeRef.contentWindow.document.getElementById("dcv-display"));
      //     iframeRef.contentWindow.document.addEventListener("keydown", (event) => {
      //       console.log(
      //         `Key: ${event.key} with keycode ${event.keyCode} has been pressed`
      //       );
      //     });
      //   }
      //   console.log("check ref", iframeRef);
      // }
      // document.domain = 'stream.dev.teletype.team';
      this.appstreamEmbed._bridge._iframe.contentWindow.document.addEventListener("keydown", (e) =>
        console.log(e)
      );
    }, 15000);
  }
  getSessionState() {
    let { sessionStatus } = this.appstreamEmbed.getSessionState();
    return this.getSessionStateByCode(sessionStatus);
  }
  getSessionStateByCode(code) {
    let ret = this.SESSION_STATE_UNKNOWN;
    switch (code) {
      case AppStream.Embed.SessionStatus.Unknown:
        ret = this.SESSION_STATE_UNKNOWN;
        break;
      case AppStream.Embed.SessionStatus.Reserved:
        ret = this.SESSION_STATE_RESERVED;
        break;
      case AppStream.Embed.SessionStatus.Started:
        ret = this.SESSION_STATE_STARTED;
        break;
      case AppStream.Embed.SessionStatus.Disconnected:
        ret = this.SESSION_STATE_DISCONNECTED;
        break;
      case AppStream.Embed.SessionStatus.Ended:
        ret = this.SESSION_STATE_ENDED;
        break;
      default:
        console.error("Unknown virtual workspace session state.");
    }
    return ret;
  }
  getSessionStateByKey(key) {
    let code = AppStream.Embed.SessionStatus[key];
    return this.getSessionStateByCode(code);
  }
  render() {
    return (
      <View
        style={styles.appStreamView}
        htmlRef={this.ref}
        // onKeyDown={e=>console.log(e)}
        // onPointerDown={e=>console.log(e)}
        // tabIndex={1}
        id='appstream-container'
      ></View>
    );
  }
}
const styles = StyleSheet.create({
  appStreamView: {
    flex: 1,
  },
});
