import React from "react";
import NdaScroller from "../components/NdaScroller";
import R14, {
  Redirect,
  TextInputField,
  SubmitButton,
  StyleSheet,
  FadeView,
  CenteredForm,
  View,
  Text,
  Colors,
} from "../core";
export default R14.connect(
  class NdaScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleScrollEnd = this.handleScrollEnd.bind(this);
      this.state = { enableFormSubmit: false };
    }
    async handleSubmit(ndaForm) {
      let ret = false;
      if (!this.state.enableFormSubmit) {
        this.props.app.ui.snackBar.show({
          message: "Please scroll to the bottom to continue.",
          variant: "error",
        });
        return false;
      }
      this.props.app.ui.progressIndicator.show();
      try {
        let values = ndaForm.values;
        values.uid = this.props.app.dm.userSession.uid;
        values.ndaAccepted = true;
        let res = await this.props.app.dm.user.update(values, [
          "uid",
          "ndaAccepted",
          "ndaSignature",
          "ndaAcceptedAt",
        ]);
        this.props.app.ui.snackBar.show({
          variant: "success",
          message: "Your account has been updated.",
        });
        ret = <Redirect to='manualEntryLoad' />;
      } catch (err) {
        this.props.app.ui.snackBar.show({
          message: err.message,
          variant: "error",
        });
        ret = false;
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
      // try {
      //   this.props.app.ui.progressIndicator.show();
      //   let authState = await this.props.app.dm.userSession.auth(
      //     loginForm.values
      //   );
      //   let redirectRoute = null;
      //   switch (authState) {
      //     case this.props.app.dm.userSession.AUTH_STATE_LOGGED_IN:
      //       redirectRoute = "landing";
      //       break;
      //     case this.props.app.dm.userSession.AUTH_STATE_VERIFY_MFA_CODE:
      //       redirectRoute = "loginVerify";
      //       break;
      //   }
      //   this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      //   let params = this.props.f ? { f: this.props.f } : null;
      //   return redirectRoute ? (
      //     <Redirect to={redirectRoute} params={params} />
      //   ) : (
      //     false
      //   );
      // } catch (err) {
      //   this.props.app.ui
      //     .form("loginForm")
      //     .addError("Error logging in. Please check username / password.");
      // }
      return false;
    }
    handleScrollEnd() {
      this.setState({ enableFormSubmit: true });
    }
    render() {
      return (
        <CenteredForm
          name='ndaForm'
          title='I hereby agree to the following:'
          style={styles.centeredForm}
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          controlsBottomRight={[
            <SubmitButton
              title='Accept'
              key='submit'
              disabled={!this.state.enableFormSubmit}
            />,
          ]}
        >
          <View>
            <Text>
              Please read the following agreement. You must read and scroll to
              the bottom before completing this form.
            </Text>
          </View>
          <NdaScroller
            onScrollEnd={this.handleScrollEnd}
            style={styles.scroller}
          />
          <TextInputField
            name='ndaSignature'
            label='Digital Signature'
            required='Enter Digital Signature'
            helper='Type your full name'
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 480,
  },
  scroller: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    ...StyleSheet.margin(16, -8, 24, -8),
  },
});
