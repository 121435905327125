import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import Touchable from "./Touchable";
import View from "./View";
import StyleSheet from "./StyleSheet";
export default R14.connectDraggableContainer(
  class DraggableView extends React.Component {
    static propTypes = {
      /** The name for the view */
      name: PropTypes.string.isRequired,
      /** The draggable ui domain instance */
      draggableContainer: PropTypes.func.isRequired,
      /** The top offset in pixels from the container */
      offsetTop: PropTypes.number,
      /** The left offset in pixels from the container */
      offsetLeft: PropTypes.number,
      /** Disables draggable when given true */
      disabled: PropTypes.bool,
    };
    static defaultProps = {
      offsetTop: 0,
      offsetLeft: 0,
      disabled: false,
      resizeTargetSize: 4,
    };
    constructor(props) {
      super(props);
      this.draggableView = this.props.draggableContainer.addView(this.props);
      this.handlePressMove = this.handlePressMove.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.handlePressIn = this.handlePressIn.bind(this);
      // this.handlePressOut = this.handlePressOut.bind(this);
      this.handlePressCancel = this.handlePressCancel.bind(this);
      this.ref = React.createRef();
      this.state = {
        dragging: false,
      };
    }
    componentWillUnmount() {
      this.draggableView.remove();
    }
    handlePressMove(event) {
      this.draggableView.handlePressMove(event);
    }
    // handlePressOut(event) {
    //   console.log("PRESS OUT: VIEW", event.nativeEvent);
    //   this.draggableView.handlePressOut(event);
    // }
    handlePressIn(event) {
      let disabled = this.props.disabled;
      if (this.props.onPressIn) {
        let pressInDisabled = this.props.onPressIn(event) === false;
        if (!disabled && pressInDisabled) disabled = true;
      }
      event.preventDefault();
      !disabled && this.draggableView.handlePressIn(event);
    }
    handlePressCancel(event) {
      this.draggableView.handlePressCancel(event);
    }
    handleLayout(event) {
      // this.measure((x, y, width, height, pageX, pageY) =>
      //   this.draggableView.handleMeasure(x, y, width, height, pageX, pageY)
      // );
      this.draggableView.handleLayout(event);
    }
    // measure(callback) {
    //   this.ref.current.measure(callback);
    // }

    getDelta(event) {
      let left = event.pageX;
      let top = event.pageY;
      let delta = {
        left: left - this.previousLeft,
        top: top - this.previousTop,
      };
      this.previousLeft = left;
      this.previousTop = top;
      return delta;
    }
    renderResizableTargets(dynamicStyles) {
      return [
        <View key='resizeLeftTarget' style={dynamicStyles.resizeLeftTarget} />,
        <View key='resizeTopTarget' style={dynamicStyles.resizeTopTarget} />,
        <View
          key='resizeBottomTarget'
          style={dynamicStyles.resizeBottomTarget}
        />,
        <View
          key='resizeRightTarget'
          style={dynamicStyles.resizeRightTarget}
        />,
        <View
          key='resizeTopLeftTarget'
          style={dynamicStyles.resizeTopLeftTarget}
        />,
        <View
          key='resizeTopRightTarget'
          style={dynamicStyles.resizeTopRightTarget}
        />,
        <View
          key='resizeBottomLeftTarget'
          style={dynamicStyles.resizeBottomLeftTarget}
        />,
        <View
          key='resizeBottomRightTarget'
          style={dynamicStyles.resizeBottomRightTarget}
        />,
      ];
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      return (
        <Touchable
          ref={this.ref}
          onLayout={this.handleLayout}
          // onPressOut={this.props.disabled ? null : this.handlePressOut}
          onPressIn={this.handlePressIn}
          onPress={this.props.onPress || null}
          // onPressMove={this.handlePressMove}
          // onPressCancel={this.handlePressCancel}
          feedback={false}
          style={[
            dynamicStyles.draggable,
            this.props.style,
            this.draggableView.active && this.props.activeStyle,
            this.draggableView.active && dynamicStyles.styleOnDrag,
          ]}
        >
          {this.props.children}
          {/* {this.draggableView.active && <View style={styles.moveTarget} />} */}
          {!this.draggableView.active &&
            this.props.resizable &&
            this.renderResizableTargets(dynamicStyles)}
        </Touchable>
      );
    }
    createDynamicStyles() {
      let dynamicStyles = {
        draggable: {
          position: "absolute",
          top: this.props.offsetTop,
          left: this.props.offsetLeft,
          height:
            this.draggableView.state.resizeHeight !== null
              ? this.draggableView.state.resizeHeight
              : this.props.height || null,
          width:
            this.draggableView.state.resizeWidth !== null
              ? this.draggableView.state.resizeWidth
              : this.props.width || null,
          zIndex: this.draggableView.active ? 1 : 0,
          opacity: this.draggableView.active ? 0.8 : 1,
          transform: [
            { translateX: this.draggableView.state.dragX },
            { translateY: this.draggableView.state.dragY },
          ],
          // cursor: this.props.disabled
          //   ? null
          //   : this.draggableView.active
          //   ? "grabbing"
          //   : "grab",
          cursor: this.props.disabled ? null : this.draggableView.cursor,
        },
        resizeLeftTarget: {
          position: "absolute",
          cursor: "ew-resize",
          left: 0,
          top: this.props.resizeTargetSize,
          bottom: this.props.resizeTargetSize,
          width: this.props.resizeTargetSize,
        },
        resizeRightTarget: {
          position: "absolute",
          cursor: "ew-resize",
          right: 0,
          top: this.props.resizeTargetSize,
          bottom: this.props.resizeTargetSize,
          width: this.props.resizeTargetSize,
        },
        resizeTopTarget: {
          position: "absolute",
          cursor: "ns-resize",
          right: this.props.resizeTargetSize,
          left: this.props.resizeTargetSize,
          top: 0,
          height: this.props.resizeTargetSize,
        },
        resizeBottomTarget: {
          position: "absolute",
          cursor: "ns-resize",
          right: this.props.resizeTargetSize,
          left: this.props.resizeTargetSize,
          bottom: 0,
          height: this.props.resizeTargetSize,
        },
        resizeTopLeftTarget: {
          position: "absolute",
          cursor: "nwse-resize",
          left: 0,
          top: 0,
          width: this.props.resizeTargetSize,
          height: this.props.resizeTargetSize,
        },
        resizeTopRightTarget: {
          position: "absolute",
          cursor: "nesw-resize",
          right: 0,
          top: 0,
          width: this.props.resizeTargetSize,
          height: this.props.resizeTargetSize,
        },
        resizeBottomLeftTarget: {
          position: "absolute",
          cursor: "nesw-resize",
          left: 0,
          bottom: 0,
          width: this.props.resizeTargetSize,
          height: this.props.resizeTargetSize,
        },
        resizeBottomRightTarget: {
          position: "absolute",
          cursor: "nwse-resize",
          right: 0,
          bottom: 0,
          width: this.props.resizeTargetSize,
          height: this.props.resizeTargetSize,
        },
      };
      if (this.draggableView.active && this.draggableView.styleOnDrag) {
        dynamicStyles.styleOnDrag = this.draggableView.styleOnDrag;
      }
      return StyleSheet.create(dynamicStyles);
    }
  }
);
const styles = StyleSheet.create({});
