import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import Modal from "./Modal";
import Button from "./Button";
import View from "./View";
import Text from "./Text";
import Touchable from "./Touchable";
import StyleSheet from "./StyleSheet";
import TimePicker from "./TimePicker";
import { Colors } from "./Theme";

export default class TimePickerModal extends React.PureComponent {
  static propTypes = {
    /** The time in format 12:00 AM. */
    time: PropTypes.string,
    /** Whether of not to show the modal. */
    visible: PropTypes.bool,
    /** Callback when confirm is pressed. */
    onTimeChange: PropTypes.func,
    /** Callback when confirm is pressed. */
    onConfirmPress: PropTypes.func,
    /** Callback when cancel is pressed. */
    onCancelPress: PropTypes.func,

  };
  static defaultProps = {
    time: "12:00 AM"
  };
  constructor(props) {
    super(props);
    this.handleCancelPress = this.handleCancelPress.bind(this);
    this.handleConfirmPress = this.handleConfirmPress.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleTimePress = this.handleTimePress.bind(this);
    this.handlePeriodPress = this.handlePeriodPress.bind(this);
    this.dateUtils = R14.utils.date;
    this.state = {
      selectedTime: this.props.time || "12:00 AM",
      pickerMode: "hour"
    };
  }
  get time() {
    return this.state.selectedTime || "12:00 AM";
  }
  get timeInfo() {
    return this.dateUtils.parseTime(this.time);
  }
  handleCancelPress() {
    this.props.onCancelPress && this.props.onCancelPress();
  }
  handleConfirmPress() {
    this.props.onConfirmPress &&
      this.props.onConfirmPress(this.state.selectedTime);
  }
  handleTimeChange(time, options = {}) {
    if (this.props.onTimeChange) {
      // if onTimeChange callback false, don't set time.
      if (this.props.onTimeChange(time) === false) return;
    }
    let nState = {selectedTime: time};
    if(this.state.pickerMode === 'hour' && ! options.disablePickerModeChange) nState.pickerMode = 'minute';
    this.setState(nState);
  }
  handleTimePress(pickerMode) {
    this.setState({
      pickerMode: pickerMode
    });
  }
  handlePeriodPress(period) {
    let timeInfo = this.timeInfo;
    timeInfo.period = period;
    this.handleTimeChange(this.dateUtils.toTime(timeInfo), {disablePickerModeChange: true});
  }
  componentDidUpdate(prevProps) {
    // If it is hidden, reset the selected time
    let nState = {};
    if(this.props.visible && ! prevProps.visible){
      nState.pickerMode = 'hour';
    }
    if (!prevProps.time && this.props.time) {
      let time = this.props.time;
      nState.selectedTime = time;
    }
    if(Object.keys(nState).length) this.setState(nState);
  }
  render() {
    let timeInfo = this.timeInfo;
    return (
      <Modal
        animationType='fade'
        backdropStyle={styles.backdrop}
        onBackdropPress={this.handleCancelPress}
        transparent={true}
        visible={this.props.visible}
        style={styles.timePickerModal}
        onRequestClose={() => {}}
        unmountOnExit
        backdrop
        scrollEnabled
      >
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerTime}>
              <Touchable
                onPress={() => this.handleTimePress("hour")}
                style={styles.headerMinuteTouchable}
              >
                <Text
                  style={[
                    styles.headerMinuteHourText,
                    this.state.pickerMode === "hour" && styles.headerActiveText
                  ]}
                >
                  {timeInfo.hour}
                </Text>
              </Touchable>

              <Text style={styles.headerMinuteHourText}>:</Text>

              <Touchable
                onPress={() => this.handleTimePress("minute")}
                style={styles.headerHourTouchable}
              >
                <Text
                  style={[
                    styles.headerMinuteHourText,
                    this.state.pickerMode === "minute" &&
                      styles.headerActiveText
                  ]}
                >
                  {timeInfo.minute}
                </Text>
              </Touchable>
              <View style={styles.headerPeriod}>
                <Touchable
                  onPress={() => this.handlePeriodPress("AM")}
                  style={styles.headerPeriodTouchable}
                >
                  <Text
                    style={[
                      styles.headerPeriodText,
                      timeInfo.period === "AM" && styles.headerActiveText
                    ]}
                  >
                    AM
                  </Text>
                </Touchable>
                <Touchable
                  onPress={() => this.handlePeriodPress("PM")}
                  style={styles.headerPeriodTouchable}
                >
                  <Text
                    style={[
                      styles.headerPeriodText,
                      timeInfo.period === "PM" && styles.headerActiveText
                    ]}
                  >
                    PM
                  </Text>
                </Touchable>
              </View>
            </View>
          </View>
          <View style={styles.timePicker}>
            <TimePicker
              {...this.props}
              time={this.state.selectedTime}
              onTimeChange={this.handleTimeChange}
              mode={this.state.pickerMode}
              size={248}
            />
          </View>

          <View style={styles.buttons}>
            <Button
              title='cancel'
              variant='text'
              onPress={this.handleCancelPress}
            />
            <Button
              title='ok'
              variant='text'
              onPress={this.handleConfirmPress}
            />
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    borderRadius: 4,
    backgroundColor: Colors.surface,
    paddingBottom: 16,
    elevation: 8,
    marginBottom: 80,
    marginTop: 80,
    width: 280,
    zIndex: 2
  },
  backdrop: {
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.5)
  },
  header: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: Colors.primary,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 16
  },
  headerTime: {
    flex: 1,
    flexDirection: "row",
    marginLeft: "auto",
    alignItems: "center"
  },
  headerMinuteHourText: {
    fontSize: 52,
    color: StyleSheet.color(Colors.onPrimary).rgba(0.5)
    // fontFamily: "monospace"
  },
  headerHourTouchable: {
    marginRight: 4
  },
  headerPeriod: {
    marginRight: 8
  },
  headerPeriodTouchable: {
    height: 24,
    ...StyleSheet.padding(4),
    flex: 0,
    flexDirection: "column",
    alignItems: "center"
  },
  headerPeriodText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 16,
    color: StyleSheet.color(Colors.onPrimary).rgba(0.5)
  },
  headerActiveText: {
    color: Colors.onPrimary
  },
  timePicker: {
    ...StyleSheet.padding(16)
  },
  buttons: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 8
  }
});
