import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import ImageBase from "./base/ImageBase";

/**
 * A component for displaying images.
 */
export default class Image extends React.PureComponent {
  static propTypes = {
    /** The image source (either a remove url uri or a local file resource) */
    source: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /** The mode that the image will use to resize when the frame doesn't match the image dimensions */
    resizeMode: PropTypes.oneOf([
      "cover",
      "contain",
      "stretch",
      "repeat",
      "center"
    ]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the image */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };
  static defaultProps = {};
  constructor(props) {
    super(props);
  }
  static async getSize(uri){
    return await ImageBase.getSize(uri);
  }
  render() {
    return (
      <ImageBase {...this.props} />
    );
  }
}
const styles = StyleSheet.create({
  
});
