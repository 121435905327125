import React from "react";
import R14, {
  View,
  StyleSheet,
  ScrollView,
  Image,
  Colors,
  IconButton,
  ControlsView,
  TextInput,
} from "../core";

export default class FieldImage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLayout = this.handleLayout.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
    this.state = {
      imageHeight: null,
      imageWidth: null,
      height: null,
      width: null,
      zoom: 0,
      scale: 1,
    };
    this._initImage = true;
    this._zoomInterval = 0.3;
  }
  componentDidMount() {
    this.init();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.idx !== this.props.idx) this.init();
  }
  handleZoomIn() {
    this.setState({
      zoom: Math.round((this.state.zoom + this._zoomInterval) * 10) / 10,
    });
  }
  handleZoomOut() {
    this.setState({
      zoom: Math.round((this.state.zoom - this._zoomInterval) * 10) / 10,
    });
  }
  async init() {
    let imageSize = await Image.getSize(
      `data:image/png;base64,${this.props.item.fieldImageBase64}`
    );
    this.setState({
      imageHeight: imageSize.height,
      imageWidth: imageSize.width,
      zoom: 0,
    });
    // this.characterGrid = this.props.app.dm.manualEntry.createCharacterGrid(
    //   this.props.item.characters
    // );
  }
  calculateScale() {
    let scale = 1;
    if (
      this.state.height &&
      this.state.width &&
      this.state.imageWidth &&
      this.state.imageHeight
    ) {
      if (this.state.imageHeight > this.state.imageWidth) {
        scale = this.state.height / this.state.imageHeight;
        if (this.state.imageWidth * scale > this.state.width)
          scale = this.state.width / this.state.imageWidth;
      } else {
        scale = this.state.width / this.state.imageWidth;
        if (this.state.imageHeight * scale > this.state.height)
          scale = this.state.height / this.state.imageHeight;
      }
    }
    return scale;
  }
  async handleLayout({ nativeEvent }) {
    let newState = {
      height: nativeEvent.layout.height,
      width: nativeEvent.layout.width,
    };
    this.setState(newState);
  }
  getCharacterStyleMap(scale) {
    let characterStyleMap = null;
    let zScale = scale + this.state.zoom;
    if (this.props.item.characters) {
      characterStyleMap = {};
      this.props.item.characters.forEach((char) => {
        characterStyleMap[char.uid] = {
          height: char.fieldOffset.height * this.state.imageHeight * zScale,
          width: char.fieldOffset.width * this.state.imageWidth * zScale,
          left: char.fieldOffset.left * this.state.imageWidth * zScale,
          top: char.fieldOffset.top * this.state.imageHeight * zScale,
        };
      });
    }
    return characterStyleMap;
  }
  calculateCharacterFontSize(scale) {
    let fontSize = null;
    let zScale = scale + this.state.zoom;
    let characterStyleMap = null;
    if (this.props.item.characters) {
      characterStyleMap = {};
      this.props.item.characters.forEach((char) => {
        let height = char.fieldOffset.height * this.state.imageHeight * zScale;
        if (fontSize === null || fontSize < height) fontSize = height;
      });
    }
    return fontSize;
  }
  createDynamicStyles(scale) {
    let zScale = scale + this.state.zoom;
    let characterStyleMap = this.getCharacterStyleMap(scale);
    let characterStyles = {};
    if (this.props.item.characters) {
      let characterFontSize = this.calculateCharacterFontSize(scale);

      this.props.item.characters.forEach((char) => {
        if (!characterStyleMap[char.uid]) return;

        characterStyles[`characterTextInput${char.uid}`] =
          characterStyleMap[char.uid];

        if (characterFontSize)
          characterStyles[
            `characterTextInput${char.uid}`
          ].fontSize = characterFontSize;

        characterStyles[`character${char.uid}`] = {
          height: characterStyleMap[char.uid].height,
          width: characterStyleMap[char.uid].width,
          left: characterStyleMap[char.uid].left,
          top: characterStyleMap[char.uid].top,
        };

        // if (char.reject) {
        //   characterStyles[`character${char.uid}`].backgroundColor =
        //     Colors.secondary;
        // }
        // if (
        //   char.updatedValue !== null &&
        //   char.updatedValue !== undefined &&
        //   char.updatedValue != char.value
        // ) {
        //   characterStyles[`character${char.uid}`].backgroundColor = "#4caf50";
        // }
      });
    }
    return StyleSheet.create({
      image: {
        transform: [
          {
            scale: zScale,
            translateX:
              (this.state.imageWidth * zScale - this.state.imageWidth) /
              (zScale * 2),
            translateY:
              (this.state.imageHeight * zScale - this.state.imageHeight) /
              (zScale * 2),
          },
        ],
      },
      imageWrapper: {
        height: this.state.imageHeight * zScale,
        width: this.state.imageWidth * zScale,
        marginTop:
          this.state.imageHeight * zScale > this.state.height
            ? 0
            : (this.state.height - this.state.imageHeight * zScale) / 2,
        marginLeft:
          this.state.imageWidth * zScale > this.state.width
            ? 0
            : (this.state.width - this.state.imageWidth * zScale) / 2,
      },
      characterFields: {
        // height: this.state.imageHeight * zScale,
        // width: this.state.imageWidth * zScale,
        paddingTop:
          this.state.imageHeight * zScale > this.state.height
            ? 0
            : (this.state.height - this.state.imageHeight * zScale) / 2,
        paddingLeft:
          this.state.imageWidth * zScale > this.state.width
            ? 0
            : (this.state.width - this.state.imageWidth * zScale) / 2,
      },
      characterFieldsLayout: {
        height: this.state.imageHeight * zScale,
        width: this.state.imageWidth * zScale,
      },
      ...characterStyles,
    });
  }
  renderCharacterOverlay() {
    return;
  }
  render() {
    let scale = this.calculateScale();
    let dynamicStyles = this.createDynamicStyles(scale);
    return (
      <View style={styles.imageFieldContainer}>
        <View style={styles.imageField}>
          <ScrollView
            onLayout={this.handleLayout}
            style={styles.imageFieldScrollView}
            height={98}
            overflow='auto'
          >
            {this.props.item && (
              <View style={[styles.imageWrapper, dynamicStyles.imageWrapper]}>
                <Image
                  // resizeMode="cover"
                  width={this.state.imageWidth}
                  height={this.state.imageHeight}
                  style={[styles.image, dynamicStyles.image]}
                  source={`data:image/png;base64,${this.props.item.fieldImageBase64}`}
                />
                <View style={styles.characterOverlay}>
                  {this.props.item.characters &&
                    this.props.item.characters.map((char) => {
                      return (
                        <View
                          key={`character${char.uid}`}
                          style={[
                            styles.character,
                            dynamicStyles[`character${char.uid}`],
                          ]}
                        />
                      );
                    })}
                </View>
              </View>
            )}
          </ScrollView>
        </View>
        <ControlsView align='right' style={styles.zoomControls}>
          <IconButton
            tooltip={`Zoom Out`}
            icon='magnifyMinus'
            size='small'
            onPress={this.handleZoomOut}
            color={this.state.zoom < 0 ? Colors.primary : Colors.onSurface}
            disabled={this.state.zoom <= 0}
            key='zoomOut'
            style={styles.zoomIcon}
          />
          <IconButton
            tooltip={`Zoom In`}
            icon='magnifyPlus'
            size='small'
            onPress={this.handleZoomIn}
            color={this.state.zoom > 0 ? Colors.primary : Colors.onSurface}
            // disabled={!this.manualEntryDomain.hasPreviousQueueItem}
            key='zoomIn'
            style={styles.zoomIcon}
          />
        </ControlsView>
        <View style={[styles.characterFields, dynamicStyles.characterFields]}>
          <View
            style={[
              styles.characterFieldsLayout,
              ,
              dynamicStyles.characterFieldsLayout,
            ]}
          >
            {this.props.item.characters.map((char) => {
              return (
                <TextInput
                  key={`char${char.uid}`}
                  name={`char${char.uid}`}
                  value={char.value}
                  onChangeText={(val) => console.log(val)}
                  style={[
                    styles.characterTextInput,
                    dynamicStyles[`characterTextInput${char.uid}`],
                  ]}
                  //ref={this.charRefs[`char${char.uid}`]}
                  //onChangeText={(value) => this.handleChangeText(char.uid, value)}
                  // onPress={()=>console.log('pres press press')}
                  // onKeyDown={(e) => this.handleKeyDown(char.uid, e)}
                  // style={[
                  //   styles.textInput,
                  //   // this.focusedChars.includes(char.uid) &&
                  //   //   styles.textInputFocused,
                  // ]}
                  //value={this.parseValue(char)}
                  // autoSelect={char.uid === this.firstUid}
                  // autoFocus={char.uid === this.firstUid}
                  // onFocus={() => this.handleFocus(char.uid)}
                  // onBlur={() => this.handleBlur(char.uid)}
                />
              );
            })}
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  imageField: {
    height: 100,
    // marginBottom: 24,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overFlow: "hidden",
  },
  imageFieldContainer: {
    marginBottom: 8,
  },
  characterOverlay: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
  },
  character: {
    position: "absolute",
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.8),
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: Colors.onSurface,
    opacity: 0.3,
  },
  zoomIcon: {
    ...StyleSheet.margin(0),
  },
  zoomControls: {
    marginRight: 0,
  },
  image: {
    flex: 1,
    backgroundPosition: "center",
  },
  imageWrapper: {
    // flex: 0,
    position: "relative",
    overflow: "hidden",
  },
  characterFieldsLayout: {
    position: "relative",
  },
  characterFields: {
    height: 100,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    overflow: "hidden",
  },
  characterTextInput: {
    fontFamily: "'Courier Prime', 'Courier New', monospace",
    fontWeight: 400,
    backgroundColor: Colors.surface,
    position: "absolute",
    overflow: "visible",
    textAlign: "center",
    ...StyleSheet.padding(0),
  },
});
