import HeaderControlsLeft from "../components/HeaderControlsLeft";
import HeaderControlsRight from "../components/HeaderControlsRight";
import { Colors, StyleSheet } from "../core";

const checkRoutePermissions = ({ route, app }) => {
  return true;
  // if (!route.metadata && route.routes) {
  //   for (let routeName in route.routes) {
  //     let ret = checkRoutePermissions({
  //       route: route.routes[routeName],
  //       app,
  //     });
  //     if (ret) return true;
  //   }
  //   return false;
  // } else if (route.metadata && route.metadata.permissions) {
  //   let perms = route.metadata.permissions;
  //   return app.dm.user.checkPermissions(
  //     perms.type,
  //     perms.subtype,
  //     perms.access || app.dm.user.PERMISSION_ACCESS_READ
  //   );
  // } else return true;
};
const styles = StyleSheet.create({
  manualEntryPricingConfigEditDialog: {
    maxWidth: 800,
  },
  childTabNavigator: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.06),
  },
  childTabNavigatorItem: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.02),
  },
  childTabNavigatorActiveItem: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.04),
    borderColor: Colors.secondaryLight,
  },
});

export default () => ({
  initialRoute: "login",
  routes: {
    main: {
      initialRoute: "manualEntryLoad",
      type: "stack",
      header: {
        //title: 'Field Entry',
        controlsLeft: HeaderControlsLeft,
        controlsRight: HeaderControlsRight,
      },
      routes: {
        landing: {
          path: "/",
        },
        accountSettings: {
          path: `/account/settings`,
        },
        services: {
          path: "/services",
        },
        incompatible: {
          path: "/incompatible",
          metadata: {
            public: true,
          },
        },
        manualEntryLoad: {
          path: "/item/load",
        },
        manualEntryTimeout: {
          path: "/item/timeout",
        },
        manualEntryTimeoutRefresh: {
          path: "/item/refresh/timeout",
        },
        manualEntryRefresh: {
          path: "/item/refresh",
        },
        manualEntry: {
          path: "/item/:queueItemNum",
        },
        manualEntryDocumentSet: {
          path: "/documents/:uid",
        },
        manualEntryReview: {
          path: "/items/review",
        },
        history: {
          path: "/history",
        },
        userSessionConfirm: {
          path: "/session/confirm",
        },
        nda: {
          path: "/nda",
        },
        virtualWorkspace: {
          path: "/virtualWorkspace/:virtualWorkspaceConfigUid/:uid",
        },
        auth: {
          path: "/auth",
          metadata: {
            public: true,
          },
        },
        r14LicenseAdminConfigEdit: {
          path: `/r14/license/admin/config`,
          label: "R14 License Config",
          metadata: {
            public: true,
          },
        },
        login: {
          path: "/login",
          metadata: {
            public: true,
          },
        },
        loginVerify: {
          path: "/login/verify",
          metadata: {
            public: true,
          },
        },
        logout: {
          path: "/logout",
          metadata: {
            public: true,
          },
        },
        // adminNavigator: {
        //   type: "tab",
        //   path: `/admin`,
        //   initialRoute: ({ navigator, app }) => {
        //     let ret = "users";
        //     // for (let routeName in navigator.routes) {
        //     //   if (
        //     //     checkRoutePermissions({
        //     //       route: navigator.routes[routeName],
        //     //       app,
        //     //     })
        //     //   ) {
        //     //     ret = routeName;
        //     //     break;
        //     //   }
        //     // }
        //     return ret;
        //   },
        //   label: "Admin",
        //   icon: "securityAccount",
        //   metadata: {
        //     permissions: {
        //       type: "admin",
        //     },
        //   },
        //   tabNavigator: {
        //     position: "top",
        //     showIcon: false,
        //     showLabel: true,
        //     upperCaseLabel: true,
        //     scrollEnabled: true,
        //     // itemRenderer: checkRoutePermissions,
        //   },
        //   routes: {
        //     adminClients: {
        //       path: `/admin/clients`,
        //       label: "Clients",
        //       metadata: {
        //         permissions: {
        //           type: "admin",
        //           subtype: "client",
        //         },
        //       },
        //     },
        //     adminUsers: {
        //       path: `/admin/users`,
        //       label: "Users",
        //       icon: "accountMultiple",
        //       metadata: {
        //         permissions: {
        //           type: "admin",
        //           subtype: "user",
        //         },
        //       },
        //     },
        //     adminManualEntryNavigator: {
        //       type: "tab",
        //       // initialRoute: ({ navigator, app }) => {
        //       //   let ret = "manualEntryConfigs";
        //       //   return ret;
        //       //   for (let routeName in navigator.routes) {
        //       //     if (
        //       //       checkRoutePermissions({
        //       //         route: navigator.routes[routeName],
        //       //         app,
        //       //       })
        //       //     ) {
        //       //       ret = routeName;
        //       //       break;
        //       //     }
        //       //   }
        //       //   return ret;
        //       // },
        //       label: "Manual Entry",
        //       icon: "securityAccount",
        //       initialRoute: "adminManualEntryConfigs",
        //       metadata: {
        //         permissions: {
        //           type: "admin",
        //         },
        //       },
        //       tabNavigator: {
        //         position: "top",
        //         showIcon: false,
        //         showLabel: true,
        //         upperCaseLabel: true,
        //         scrollEnabled: true,
        //         itemRenderer: checkRoutePermissions,
        //         style: styles.childTabNavigator,
        //         itemStyle: styles.childTabNavigatorItem,
        //         activeItemStyle: styles.childTabNavigatorActiveItem,
        //       },
        //       routes: {
        //         adminManualEntryConfigs: {
        //           path: `/admin/manualEntry`,
        //           label: "Configs",
        //           // icon: "accountMultiple",
        //           metadata: {
        //             permissions: {
        //               type: "admin",
        //               subtype: "manualEntryConfig",
        //             },
        //           },
        //         },
        //         adminManualEntryRoutes: {
        //           path: `/admin/manualEntry/routes`,
        //           label: "Routes",
        //           // icon: "accountMultiple",
        //           metadata: {
        //             permissions: {
        //               type: "admin",
        //               subtype: "manualEntryRoute",
        //             },
        //           },
        //         },
        //         adminManualEntryPricingConfigs: {
        //           path: `/admin/manualEntry/pricing`,
        //           label: "Pricing",
        //           // icon: "accountMultiple",
        //           metadata: {
        //             permissions: {
        //               type: "admin",
        //               subtype: "manualEntryPricingConfig",
        //             },
        //           },
        //         },
        //         adminMmanualEntryErrors: {
        //           path: `/admin/manualEntry/errors`,
        //           label: "Errors",
        //           // icon: "accountMultiple",
        //           metadata: {
        //             permissions: {
        //               type: "admin",
        //               subtype: "manualEntryPricingErrors",
        //             },
        //           },
        //         },
        //         // adminManualEntryUserPaymentBatchNavigator: {
        //         //   label: "Payment Batches",
        //         //   initialRoute: "manualEntryUserPaymentBatches",
        //         //   type: "stack",
        //         //   header: null,
        //         //   routes: {
        //         //     adminManualEntryUserPaymentBatches: {
        //         //       path: `/admin/manualEntry/payment/batches`,
        //         //       label: "Payment Batches",
        //         //       metadata: {
        //         //         permissions: {
        //         //           type: "admin",
        //         //           subtype: "manualEntryUserPaymentBatch",
        //         //         },
        //         //       },
        //         //     },
        //         //     adminManualEntryUserPaymentBatch: {
        //         //       path: `/admin/manualEntry/payment/batches/:uid`,
        //         //       // label: "Payment Batch",
        //         //       metadata: {
        //         //         permissions: {
        //         //           type: "admin",
        //         //           subtype: "manualEntryUserPaymentBatch",
        //         //         },
        //         //       },
        //         //     },
        //         //   },
        //         // },

        //         adminManualEntryAccuracyTests: {
        //           path: `/admin/manualEntry/AccuracyTests`,
        //           label: "Accuracy Tests",
        //           // icon: "accountMultiple",
        //           metadata: {
        //             permissions: {
        //               type: "admin",
        //               subtype: "manualEntryAccuracyTest",
        //             },
        //           },
        //         },
        //       },
        //     },
        //     adminVirtualWorkspaceNavigator: {
        //       type: "tab",
        //       // initialRoute: ({ navigator, app }) => {
        //       //   let ret = "manualEntryConfigs";
        //       //   return ret;
        //       //   for (let routeName in navigator.routes) {
        //       //     if (
        //       //       checkRoutePermissions({
        //       //         route: navigator.routes[routeName],
        //       //         app,
        //       //       })
        //       //     ) {
        //       //       ret = routeName;
        //       //       break;
        //       //     }
        //       //   }
        //       //   return ret;
        //       // },
        //       label: "Virtual Workspace",
        //       icon: "securityAccount",
        //       initialRoute: "adminVirtualWorkspaces",
        //       metadata: {
        //         permissions: {
        //           type: "admin",
        //         },
        //       },
        //       tabNavigator: {
        //         position: "top",
        //         showIcon: false,
        //         showLabel: true,
        //         upperCaseLabel: true,
        //         scrollEnabled: true,
        //         itemRenderer: checkRoutePermissions,
        //         style: styles.childTabNavigator,
        //         itemStyle: styles.childTabNavigatorItem,
        //         activeItemStyle: styles.childTabNavigatorActiveItem,
        //       },
        //       routes: {
        //         adminVirtualWorkspaces: {
        //           path: `/admin/virtualWorkspaces`,
        //           label: "Configs",
        //           // icon: "accountMultiple",
        //           metadata: {
        //             permissions: {
        //               type: "admin",
        //               subtype: "virtualWorkspace",
        //             },
        //           },
        //         },
        //         adminVirtualWorkspaceImages: {
        //           path: `/admin/virtualWorkspace/Images`,
        //           label: "Images",
        //           // icon: "accountMultiple",
        //           metadata: {
        //             permissions: {
        //               type: "admin",
        //               subtype: "virtualWorkspaceImage",
        //             },
        //           },
        //         },
        //       },
        //     },
        //   },
        // },
      },
    },
    modal: {
      type: "modal",
      initialRoute: "manualEntryDocumentSetComplete",
      routes: {
        manualEntryDocumentSetComplete: {
          title: "Document(s) Complete",
          path: "/documents/complete/:uid",
          initialParentRoute: "manualEntryDocumentSet",
        },
      },
    },
    manualEntryDocumentSetContainer: {
      type: "container",
      initialRoute: "manualEntryDocumentSetDocument",
      routes: {
        manualEntryDocumentSetDocument: {
          title: "Document",
          path: "/documents/:uid/:pageNumber/",
          initialParams: { pageNumber: 1 },
          // metadata: {
          //   permissions: {
          //     type: "document",
          //     access: "edit",
          //   },
          // },
        },
        manualEntryDocumentSetDocumentReload: {
          title: "Document",
          path: "/documents/reload/:uid/:pageNumber",
          initialParams: { pageNumber: 1 },
        },
      },
    },
    manualEntryDocumentSetAnnotationContainer: {
      type: "container",
      initialRoute: "manualEntryDocumentSetAnnotationEdit",
      routes: {
        manualEntryDocumentSetAnnotationEdit: {
          title: "Annotation",
          path: "/documents/annotation/edit/:uid/:annotationUuid",
          // metadata: {
          //   permissions: {
          //     type: "document",
          //     access: "edit",
          //   },
          // },
        },
      },
    },
  },
});
