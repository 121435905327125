import React from 'react';
import StyleSheet from './StyleSheet';
import TextBase from './base/TextBase';
import { Colors } from './Theme';

export default class TextInput extends React.PureComponent {
  render() {
    return (
      <TextBase
        {...this.props}
        style={[styles.text, this.props.style]}
      />
    );
  }
}
const styles = StyleSheet.create({
  text: {
    color: Colors.onBackground
  }
});