import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import View from "./View";
import AnimatedView from "./AnimatedView";
import IconButton from "./IconButton";
import { Colors } from "./Theme";

export default class RadioButton extends React.PureComponent {
  static propTypes = {
    /** If true the user won't be able to toggle the radioButton. Default value is false. */
    disabled: PropTypes.bool,
    /** The value of the radioButton. If true the radioButton will be turned on. Default value is false. */
    value: PropTypes.bool,
    /** Invoked with the new value when the value changes. */
    onValueChange: PropTypes.func,
    /** The radioButton icon to be displayed when the radioButton value is false. */
    iconBlank: PropTypes.string,
    /** The radioButton icon to be displayed when the radioButton value is true. */
    iconMarked: PropTypes.string,
    /** The radioButton color to be displayed when the radioButton value is false. */
    colorBlank: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The radioButton color to be displayed when the radioButton value is true. */
    colorMarked: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };
  static defaultProps = {
    value: false,
    iconBlank: "radioboxBlank",
    iconMarked: "radioboxMarked",
    colorBlank: Colors.onBackground,
    colorMarked: Colors.primary,
  };
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleValueChange(value) {
    if (this.props.onValueChange) this.props.onValueChange(value);
  }
  handleChange() {
    if (this.props.onPress) this.props.onPress();
    this.handleValueChange(this.props.value ? false : true);
  }
  handleKeyDown(e) {
    // alert("LSDKJF")
    // console.log("CHECK EVENT", e.nativeEvent);
    if (this.props.onKeyDown) this.props.onKeyDown(e);
    // if (!e.nativeEvent || !e.nativeEvent.key) return false;
    // switch (e.nativeEvent.key) {
    //   case "ArrowDown":
    //     break;
    //   case "ArrowUp":
    //     break;
    //   case "Enter":
    //     // this.handleChange(e);
    //     // e.preventDefault();
    //     break;
    // }
  }
  render() {
    let styles = this.createDynamicStyles();
    return (
      <View style={[styles.radioButton, this.props.style]}>
        <AnimatedView
          style={[
            styles.handle,
            this.props.value && styles.handleOn,
            this.props.style,
          ]}
          in={this.props.value ? true : false}
          enter={{
            // transform: [{ scale: 1 }],
            opacity: 1,
          }}
          exit={{
            // transform: [{ scale: .95 }],
            opacity: 0.6,
          }}
          duration={100}
        >
          <IconButton
            icon={
              this.props.value ? this.props.iconMarked : this.props.iconBlank
            }
            style={styles.iconButton}
            onPress={this.handleChange}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            autoFocus={this.props.autoFocus}
            tabIndex={this.props.tabIndex}
            color={
              this.props.value ? this.props.colorMarked : this.props.colorBlank
            }
            ref={this.ref}
            elementRef={this.props.elementRef || null}
            size='small'
            disabled={this.props.disabled}
            onKeyDown={this.handleKeyDown}
          />
        </AnimatedView>
      </View>
    );
  }
  createDynamicStyles() {
    return StyleSheet.create({
      radioButton: {
        // marginLeft: -6
        //borderRadius: "100%"
      },
      iconButton: {
        ...StyleSheet.margin(0),
      },
      // handleOn: {
      //   backgroundColor: StyleSheet.color(this.props.colorMarked).rgba(0.05),
      //   borderRadius: "100%",
      // },
    });
  }
}
