import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import View from "./View";
import Text from "./Text";
import Surface from "./Surface";
import Modal from "./Modal";
import ScrollView from "./ScrollView";

class SideSheetSection extends React.Component {
  render() {
    return (
      <View style={styles.sideSheetSection}>
        {this.props.title && (
          <View style={styles.sideSheetSectionTitle}>
            <Text style={styles.sideSheetSectionTitleText}>
              {this.props.title}
            </Text>
          </View>
        )}
        <View style={styles.sideSheetSectionContent}>
          {this.props.children}
        </View>
      </View>
    );
  }
}

class SideSheetDivider extends React.Component {
  render() {
    return <View style={styles.sideSheetDivider} />;
  }
}

export default class SideSheet extends React.Component {
  static propTypes = {
    /** Date / Time Timer is complete. */
    // completeAt: PropTypes.instanceOf(Date).isRequired,
    // /** Value of the character entry fields. */
    // value: PropTypes.any,
  };
  constructor(props) {
    super(props);
  }
  static Section = SideSheetSection;
  static Divider = SideSheetDivider;

  render() {
    let sideSheet = (
      <View style={styles.sideSheet}>
        <ScrollView>
          <Surface style={styles.surface}>{this.props.children}</Surface>
        </ScrollView>
      </View>
    );

    if (this.props.modal) {
      sideSheet = (
        <Modal
          visible={this.props.visible}
          transparent
          animationType='fade'
          backdrop
          backdropStyle={styles.sideSheetModalBackdrop}
          contentStyle={styles.sideSheetModalContent}
          onBackdropPress={this.props.onBackdropPress}
          // scrollEnabled
        >
          {sideSheet}
        </Modal>
      );
    }

    return sideSheet;
  }
}

const styles = StyleSheet.create({
  sideSheet: {
    position: "relative",
    width: 320,
    // width: "auto",
  },
  surface: {
    // position: "absolute",
    // top:0,
    // right: 0,
    // bottom: 0,
    // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.08),
    // width: 320,
    elevation: 8,
    // marginBottom: 12,
    ...StyleSheet.padding(0),
    flex: 1,
    overFlow: "hidden",
  },
  sideSheetSection: {
    ...StyleSheet.padding(16, 16, 0, 16),
  },
  sideSheetSectionTitle: {
    paddingBottom: 12,
  },
  sideSheetSectionTitleText: {
    fontSize: 16,
    fontWeight: 400,
    paddingBottom: 4,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  metric: {},
  sideSheetSectionItem: {},
  // sideSheetSectionItemTitle: {
  //   paddingBottom: 2,
  // },
  // sideSheetSectionItemTitleText: {
  //   fontSize: 16,
  //   fontWeight: 500,

  // },
  sideSheetDivider: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.08),
    height: 1,
    width: "100%",
    // marginBottom: 16
  },
});
