import React from "react";
import R14, { View, StyleSheet, Text, FadeView, Colors } from "../core";
import PropTypes from "prop-types";

export default class FloatingLabel extends React.Component {
  static propTypes = {
    /** offset from edge of children */
    offset: PropTypes.number,
  };
  static defaultProps = {
    offset: 2,
  };
  constructor(props) {
    super(props);
  }
  createDynamicStyles() {
    let dynamicStyles = {};
    switch (this.props.position) {
      case "bottom":
        dynamicStyles.label = {
          bottom: -24 - this.props.offset,
        };
        break;
      default:
        dynamicStyles.label = {
          top: -24 - this.props.offset,
        };
        break;
    }
    return StyleSheet.create(dynamicStyles);
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <View style={[styles.floatingLabel, this.props.style]}>
        <FadeView style={[styles.label, dynamicStyles.label, this.props.labelStyle]} visible={this.props.visible}>
          <Text noWrap style={styles.labelText}>{this.props.label}</Text>
        </FadeView>
        {this.props.children}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  floatingLabel: {
    position: "relative",
    flex: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  labelTop: {
    position: "absolute",
    top: -28,
  },
  labelBottom: {
    position: "absolute",
    bottom: -28,
  },
  label: {
    position: "absolute",
    // top: -28,
    // left: 0,
    // right: 0,
    // flex: 1,
    // borderWidth: 1,
    // borderColor: StyleSheet.color(Colors.onSurface).rgba(0.2),
    // borderStyle: 'solid',
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    borderRadius: 4,
    height: 20,
    padding: 4,
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    //elevation: 2
  },
  // labelTop:
  labelText: {
    fontSize: 12,
    fontWeight: 400,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
});
