import React, {
  useState,
  useMemo,
  useEffect,
  useTransition,
  useRef,
} from "react";

import R14, {
  SelectMenuField,
  FadeView,
  TextInputField,
  StyleSheet,
  RadioButtonGroupField,
  SectionHeader,
  Colors,
} from "../core";

export default function AnnotationQuestionField({
  required,
  searchable,
  // initialValueVisible,
  label,
  items,
  fieldType,
  onValueChange,
  autoFocus,
  name,
  disabled = false,
}) {
  // const [valueVisible, setValueVisible] = useState(
  //   initialValueVisible || fieldType === "INPUT" || false
  // );
  const [focus, setFocus] = useState(false);
  const clearTimeoutRef = useRef(null);
  const handleValueChange = (value) => {
    if (!onValueChange) return false;
    onValueChange(value);
    // switch (fieldType) {
    //   case "INPUT":
    //     console.log("FIX SO WORKS WITH CUSTOM");
    //     clearTimeoutRef.current && clearTimeout(clearTimeoutRef.current);
    //     clearTimeoutRef.current = setTimeout(() => onValueChange(value), 500);
    //     break;
    //   default:
    //     // const newValueVisible = value === "__r14_valueSelectionEditable";
    //     // valueVisible !== newValueVisible && setValueVisible(newValueVisible);
    //     onValueChange(value);
    // }
  };
  useEffect(() => {
    return () =>
      clearTimeoutRef.current && clearTimeout(clearTimeoutRef.current);
  }, []);

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const itemLoader = ({ search }) =>
    items.filter(({ value, label }) =>
      search
        ? (label || value).toLowerCase().indexOf(search.toLowerCase()) !== -1
        : true
    );
  searchable = true;
  let field = null;
  switch (fieldType) {
    case "SELECT":
      field = (
        <SelectMenuField
          key={name}
          name={name}
          //label={label}
          useValuesOnly
          openOnFocus
          searchable={searchable}
          popUpMenuStyle={styles.popUpMenu}
          //autoLoad
          onValueChange={(value) => handleValueChange(value)}
          //autoFocus={autoFocus}
          onFocus={handleFocus}
          onBlur={handleBlur}
          itemLoader={itemLoader}
          items={items}
          required={required}
          helper={required ? "Required" : null}
          disabled={disabled}
        />
      );
      break;
    case "RADIO":
      field = (
        <RadioButtonGroupField
          key={name}
          name={name}
          //label={label}
          items={items}
          onValueChange={(value) => handleValueChange(value)}
          autoFocus={!disabled && autoFocus}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={styles.valueSelectionsFieldGroup}
          required={required}
          helper={required ? "Required" : null}
          maxContentHeight={items.length > 6 ? 248 : null}
          disabled={disabled}
        />
      );
      break;
    case "INPUT":
      field = (
        <TextInputField
          key={name}
          name={name}
          // label={items ? "Other" : label}
          multiline
          required={required}
          helper={required ? "Required" : null}
          style={styles.valueTextInput}
          // autoFocus={autoFocus}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoSelect={!disabled && autoFocus}
          onChangeText={(value) => handleValueChange(value)}
          disabled={disabled}
        />
      );
      break;
    default:
      throw new Error(`Unknown field type: ${fieldType}`);
  }
  return (
    <FadeView animateOnMount key='annotationQuestionField' visible={true}>
      <SectionHeader
        level={2}
        style={[
          styles.labelSectionHeader,
          focus && styles.labelSectionHeaderFocus,
        ]}
      >
        {label}
      </SectionHeader>
      {field}
    </FadeView>
  );
}
const styles = StyleSheet.create({
  labelSectionHeader: {
    fontSize: 16,
    fontWeight: 500,
  },
  labelSectionHeaderFocus: {
    color: Colors.primary,
  },
  valueTextInput: {
    height: 80,
  },
  popUpMenu: {
    ...StyleSheet.margin(-28, -6, 0, -2),
  },
});
