import React from "react";
import R14 from "../R14";
import { Provider } from "mobx-react";
import StyleSheet from "./StyleSheet";
import PropTypes from "prop-types";
import { Colors } from "./Theme";
import View from "./View";
import Text from "./Text";
import ScrollView from "./ScrollView";
import FadeView from "./FadeView";
import IconButton from "./IconButton";
import ScrollerItem from "./ScrollerItem";
import InfiniteList from "./InfiniteList";
import InfiniteList2 from "./InfiniteList2";

let Scroller = R14.connect(
  class Scroller extends React.Component {
    static propTypes = {
      // /** The external url to open */
      // label: PropTypes.string.isRequired,
      // /** The function to be called when the chip is pressed. */
      // onPress: PropTypes.func,
      // /** The chip variant to use, defaults to filled */
      // variant: PropTypes.oneOf(["outlined", "filled"]),
      // /** The name of the icon or component to be displayed to the left of the chip label  */
      // iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      // /** The name of the icon or component to be displayed to the right of the chip label  */
      // iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      // /** A StyleSheet object, or array of StyleSheet objects to apply to the chip */
      // style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      // /** A StyleSheet object, or array of StyleSheet objects to apply to the chip label */
      // labelStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      // /** The LabelComponent to be used inside the chip. */
      // LabelComponent: PropTypes.node,
      // /** The background color of the chip. */
      // color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      // /** The color of the chip label. */
      // labelColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      // /** A StyleSheet object, or array of StyleSheet objects to apply to the chip label text */
      // labelTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      // /** Whether the chip label text should wrap */
      // noWrap: PropTypes.bool,
      // /** Whether the chip is selected */
      // selected: PropTypes.bool,
    };
    static defaultProps = {
      direction: "horizontal",
    };
    constructor(props) {
      super(props);
      this.handleScroll = this.handleScroll.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.handleContentLayout = this.handleContentLayout.bind(this);
      this.handleControlLeftPress = this.handleControlLeftPress.bind(this);
      this.handleControlRightPress = this.handleControlRightPress.bind(this);
      this.handleControlTopPress = this.handleControlTopPress.bind(this);
      this.handleControlBottomPress = this.handleControlBottomPress.bind(this);
      this.lastScrollEvent = null;
      this.scroller = this.props.app.ui.scroller.create(this.props);
      this._height = null;
      this._items = {};
      // this.state = {
      //   controlLeftVisible: false,
      //   controlRightVisible: false,
      //   controlTopVisible: false,
      //   controlBottomVisible: false,
      //   layout: null,
      // };
    }
    // static get Item() {
    //   return ScrollerItem;
    // }
    componentWillUnmount() {
      this.props.app.ui.scroller.remove(this.props.name);
    }
    handleScroll(ev) {
      if (this.props.onScroll) this.props.onScroll(ev);
      this.scroller.handleScroll(ev);
      // this.lastScrollEvent = ev.nativeEvent;
      // let control =
      //   this.props.direction === "horizontal"
      //     ? {
      //         size: "width",
      //         axis: "x",
      //         control1: "controlLeftVisible",
      //         control2: "controlRightVisible",
      //       }
      //     : {
      //         size: "height",
      //         axis: "y",
      //         control1: "controlTopVisible",
      //         control2: "controlBottomVisible",
      //       };

      // let size = ev.nativeEvent.layout[control.size];
      // let contentSize = ev.nativeEvent.contentSize[control.size];
      // let offset = ev.nativeEvent.contentOffset;
      // let control1Visible = false;
      // let control2Visible = false;

      // if (contentSize > size) {
      //   if (offset[control.axis] > 16) control1Visible = true;
      //   if (offset[control.axis] + size < contentSize) control2Visible = true;
      // }
      // if (
      //   this.state[control.control1] !== control1Visible ||
      //   this.state[control.control2] !== control2Visible
      // )
      //   this.setState({
      //     [control.control1]: control1Visible,
      //     [control.control2]: control2Visible,
      //   });
      // // if (this.props.position === "horizontal") {
      // //   let width = ev.nativeEvent.layout.width;
      // //   let contentWidth = ev.nativeEvent.contentSize.width;
      // //   let offset = ev.nativeEvent.contentOffset;
      // //   let controlLeftVisible = false;
      // //   let controlRightVisible = false;
      // //   if (contentWidth > width) {
      // //     if (offset.x > 16) controlLeftVisible = true;
      // //     if (offset.x + width < contentWidth) controlRightVisible = true;
      // //   }
      // //   if (
      // //     this.state.controlLeftVisible !== controlLeftVisible ||
      // //     this.state.controlRightVisible !== controlRightVisible
      // //   )
      // //     this.setState({
      // //       controlLeftVisible: controlLeftVisible,
      // //       controlRightVisible: controlRightVisible,
      // //     });
      // // }
    }
    async addPageData(page, items = []) {
      await this.scroller.addPageData(page, items);
    }
    scrollTo(params) {
      this.scroller.scrollTo(params);
    }
    updateScrollEvent() {
      this.scroller.triggerOnScroll();
    }
    handleLayout(ev) {
      this.updateScrollEvent();
    }
    handleContentLayout(width, height) {
      this.scroller.handleContentLayout(width, height);
    }
    handleControlLeftPress() {
      this.scroller.handleControlLeftPress();
    }
    handleControlRightPress() {
      this.scroller.handleControlRightPress();
    }
    handleControlTopPress() {
      this.scroller.handleControlTopPress();
    }
    handleControlBottomPress() {
      this.scroller.handleControlBottomPress();
    }
    calculateHeight() {
      let height = this.props.height || null;
      if (
        this.props.maxHeight &&
        this.props.direction === "vertical" &&
        this.scroller.state.layout &&
        this.scroller.state.layout.height
      ) {
        let newHeight = null;
        let maxHeight = this.props.maxHeight;
        if (
          !this._height ||
          this.scroller.state.layout.height !== this._height
        ) {
          newHeight =
            this.scroller.state.layout.height > maxHeight
              ? maxHeight
              : this.scroller.state.layout.height;
          if (newHeight !== this._height) height = newHeight;
        }
      }
      return height;
    }
    renderInfiniteList() {
      const InfiniteListComponent =
        this.props.itemSize === "auto" ? InfiniteList2 : InfiniteList;
      return (
        <InfiniteListComponent
          name={this.props.name}
          ref={this.scroller.scrollRef}
          initialPage={this.props.initialPage}
          initialData={this.props.initialData}
          onScroll={this.handleScroll}
          onLayout={this.handleLayout}
          onContentSizeChange={this.handleContentLayout}
          horizontal={this.props.direction === "horizontal"}
          dataLoader={this.props.dataLoader}
          onRemoveItem={this.props.onRemoveItem}
          itemRenderer={this.props.itemRenderer}
          itemLoadingRenderer={this.props.itemLoadingRenderer}
          keyExtractor={this.props.keyExtractor}
          getItemLayout={(data, index) => ({
            length: 32,
            offset: 32 * index,
            index,
          })}
          itemSize={this.props.itemSize}
          //ItemLoadingComponent={<Text>Loading...</Text>}
          totalItems={this.props.totalItems}
          itemsPerPage={this.props.itemsPerPage}
          height={this.calculateHeight()}
        />
      );
    }
    renderScrollView() {
      return (
        <ScrollView
          name={this.props.name}
          horizontal={this.props.direction === "horizontal"}
          onScroll={this.handleScroll}
          onLayout={this.handleLayout}
          height={this.calculateHeight()}
          ref={this.scroller.scrollRef}
          // onContentSizeChange={(width, height) =>
          //   console.log("trigger onContentSizeChange", width, height)
          // }
          onContentSizeChange={this.handleContentLayout}
          // onContentLayout={this.handleContentLayout}
          contentStyle={[
            this.props.direction === "horizontal"
              ? styles.scrollViewContentHorizontal
              : styles.scrollViewContentVertical,
            this.props.contentStyle,
          ]}
        >
          {/* <View
      style={[
        this.props.direction === "horizontal"
          ? styles.scrollViewContentHorizontal
          : styles.scrollViewContentVertical,
      ]}
      onLayout={this.handleContentLayout}
    > */}
          {/* <Provider scroller={this.scroller}> */}

          {this.props.children}
          {/* </Provider> */}
          {/* {this.props.children.map((child) => {
      console.log("scroller CHeck child item", child.props.name);
      return React.cloneElement(child, {
        scroller: this,
      });
    })} */}
          {/* </View> */}
        </ScrollView>
      );
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      let scrollComponent = this.props.dataLoader
        ? this.renderInfiniteList()
        : this.renderScrollView();
      let height = this.props.height || null;
      return (
        <Provider scroller={this.scroller}>
          <View
            style={[styles.scroller, dynamicStyles.scroller, this.props.style]}
          >
            {scrollComponent}
            <FadeView
              visible={this.scroller.state.controlLeftVisible}
              style={[styles.controlsHorizontal, styles.controlsLeft]}
              unmountOnExit
            >
              <IconButton
                style={styles.controlIcon}
                onPress={this.handleControlLeftPress}
                icon='keyboardArrowLeft'
              />
            </FadeView>
            <FadeView
              visible={this.scroller.state.controlRightVisible}
              style={[styles.controlsHorizontal, styles.controlsRight]}
              unmountOnExit
            >
              <IconButton
                style={styles.controlIcon}
                onPress={this.handleControlRightPress}
                icon='keyboardArrowRight'
              />
            </FadeView>
            <FadeView
              visible={this.scroller.state.controlTopVisible}
              style={[styles.controlsVertical, styles.controlsTop]}
              unmountOnExit
            >
              <IconButton
                style={styles.controlIcon}
                onPress={this.handleControlTopPress}
                icon='keyboardArrowUp'
              />
            </FadeView>
            <FadeView
              visible={this.scroller.state.controlBottomVisible}
              style={[styles.controlsVertical, styles.controlsBottom]}
              unmountOnExit
            >
              <IconButton
                style={styles.controlIcon}
                onPress={this.handleControlBottomPress}
                icon='keyboardArrowDown'
              />
            </FadeView>
          </View>
        </Provider>
      );
    }
    createDynamicStyles() {
      let dynamicStyles = { scroller: {} };
      if (
        this.props.direction === "horizontal" &&
        this.scroller.state.layout &&
        this.scroller.state.layout.height
      ) {
        dynamicStyles.scroller.height = this.scroller.state.layout.height;
      }

      return StyleSheet.create(dynamicStyles);
    }
  }
);
Scroller.Item = ScrollerItem;
export default Scroller;

const styles = StyleSheet.create({
  scroller: {
    position: "relative",
    // height: 100,
    // flex:0,
    // flexDirection:"column"
    flex: 1,
    flexDirection: "column",
    backgroundColor: Colors.surface,
    // backgroundColor: "#FFF"
  },
  scrollViewContentVertical: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "flex-start",
  },
  scrollViewContentHorizontal: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
  },
  controlsHorizontal: {
    position: "absolute",
    flex: 1,
    bottom: 0,
    top: 0,
    flexDirection: "column",
    // height: 56,
    justifyContent: "space-around",
    // screen: ({ width, height }) => {
    //   if (width <= 640) {
    //     return { height: 48 };
    //   }
    // },
  },
  controlsLeft: {
    left: 0,
    screen: ({ width }) =>
      width <= 640
        ? {
            backgroundImage: `linear-gradient(to right, ${Colors.surface}, ${Colors.surface}, transparent)`,
          }
        : {
            backgroundImage: `linear-gradient(to right, ${Colors.surface}, ${Colors.surface}, transparent)`,
          },
  },
  controlsRight: {
    right: 0,
    screen: ({ width }) =>
      width <= 640
        ? {
            backgroundImage: `linear-gradient(to left, ${Colors.surface}, ${Colors.surface}, transparent)`,
          }
        : {
            backgroundImage: `linear-gradient(to left, ${Colors.surface}, ${Colors.surface}, transparent)`,
          },
  },
  controlsVertical: {
    position: "absolute",
    flex: 1,
    left: 0,
    right: 0,
    flexDirection: "row",
    // height: 56,
    justifyContent: "space-around",
    // screen: ({ width, height }) => {
    //   if (width <= 640) {
    //     return { height: 48 };
    //   }
    // },
  },
  controlsTop: {
    top: 0,
    screen: ({ width }) =>
      width <= 640
        ? {
            backgroundImage: `linear-gradient(to bottom, ${Colors.surface}, ${Colors.surface}, transparent)`,
          }
        : {
            backgroundImage: `linear-gradient(to bottom, ${Colors.surface}, ${Colors.surface}, transparent)`,
          },
  },
  controlsBottom: {
    bottom: 0,
    screen: ({ width }) =>
      width <= 640
        ? {
            backgroundImage: `linear-gradient(to top, ${Colors.surface}, ${Colors.surface}, transparent)`,
          }
        : {
            backgroundImage: `linear-gradient(to top, ${Colors.surface}, ${Colors.surface}, transparent)`,
          },
  },
  // chip: {
  //   flex: 0,
  //   flexDirection: "row",
  //   alignItems: "center",
  //   borderRadius: 16,
  //   height: 32,
  //   ...StyleSheet.padding(4, 4, 4, 4),
  //   ...StyleSheet.margin(0, 8, 8, 0),
  // },
  // label: {
  //   color: Colors.secondary,
  //   fontSize: 14,
  //   ...StyleSheet.padding(0, 8, 0, 8),
  // },
  // iconLeft: {
  //   ...StyleSheet.padding(0, 0, 0, 4),
  //   marginRight: -4,
  // },
  // iconRight: {
  //   ...StyleSheet.padding(0, 4, 0, 0),
  //   marginLeft: -4,
  // },
  // selected: {
  //   backgroundColor: StyleSheet.color(Colors.primary).rgba(0.1),
  // },
  // selectedLabel: {
  //   color: Colors.primary,
  // },
});
