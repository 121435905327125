import { observer, inject } from "mobx-react";

export default class R14Base {
  static getGlobalVariable() {
    return window;
  }
  static connect(Component, r14) {
    const R14WrappedComponent = class R14WrappedComponent extends Component {
      constructor(props) {
        super(props);
        
        /** @todo R14 Web base connect, fix this hacky componentDidFocus code. Should probably hooked into navigation, like on native */
        // if (this.componentDidFocus || this.componentWillFocus) {
        //   this.handleMount = null;

        //   console.log("check check ch3eck", super.componentDidMount);
        //   this.handleMount = this.componentDidMount;
        //   this.componentDidMount = this._componentDidMount;
        //   this.handleUpdate = this.componentDidUpdate;
        //   this.componentDidUpdate = this._componentDidUpdate;
        // }
      }
      componentDidMount() {
        if (super.componentDidMount) super.componentDidMount();
        if (this.componentWillFocus) this.componentWillFocus();
        if (this.componentDidFocus) this.componentDidFocus();
      }
      componentDidUpdate(prevProps, prevState, snapshot) {
        if (super.componentDidUpdate)
          super.componentDidUpdate(prevProps, prevState, snapshot);
        if (this.componentWillFocus) this.componentWillFocus();
        if (this.componentDidFocus) this.componentDidFocus();
      }
      render() {
        //r14.rendering = true;
        let ret = super.render();
        //r14.rendering = false;
        return ret;
      }
    };

    let wrappedComponent = inject("app", "r14")(observer(R14WrappedComponent));
    return wrappedComponent;
  }
}
