import React from "react";
import R14, {
  Redirect,
  TextInputField,
  SubmitButton,
  StyleSheet,
  FadeView,
  CenteredForm,
  View
} from "../core";
export default R14.connect(
  class LoginScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    async handleSubmit(loginForm) {
      try {
        this.props.app.ui.progressIndicator.show();
        let authState = await this.props.app.dm.userSession.auth(
          loginForm.values
        );
        let redirectRoute = null;
        switch (authState) {
          case this.props.app.dm.userSession.AUTH_STATE_LOGGED_IN:
            redirectRoute = "landing";
            break;
          case this.props.app.dm.userSession.AUTH_STATE_VERIFY_MFA_CODE:
            redirectRoute = "loginVerify";
            break;
        }
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        let params = this.props.f ? { f: this.props.f } : null;
        return redirectRoute ? (
          <Redirect to={redirectRoute} params={params} />
        ) : (
          false
        );
      } catch (err) {
        this.props.app.ui
          .form("loginForm")
          .addError("Error logging in. Please check username / password.");
      }
    }
    render() {
      return (
        <CenteredForm
          name='loginForm'
          title='Login'
          style={styles.loginForm}
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          controlsBottomRight={[<SubmitButton title='Continue' key='submit' />]}
        >
          <TextInputField
            name='username'
            label='Username'
            icon='account'
            required='Enter Username'
            validator='username'
            autoFocus
          />
          <TextInputField
            name='password'
            label='Password'
            icon='lock'
            secure
            required='Enter Password'
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 320,
  },
});
