import PropTypes from 'prop-types';
import FlatListBase from './base/FlatListBase';
/**
 * 
 * Component view that animates.
 * 
 */
export default class FlatList extends FlatListBase {
  static propTypes = {
    /** A simple array containing the list data.  */
    data: PropTypes.array.isRequired,
    /** Takes an item from data and renders it into the list  */
    renderItem: PropTypes.func.isRequired,
    /** Accepts a method that should return a key based on the item.  */
    keyExtractor: PropTypes.func,
    /** The number cells to render per row.  */
    numColumns: PropTypes.number,
    /** Content to be rendered at the top of the list  */
    ListHeaderComponent: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
      PropTypes.elementType
    ]),
    /** Style of the internal view for the ListHeaderComponent  */
    ListHeaderComponent: PropTypes.object
  };
  static defaultProps = {};
}