import React from 'react';
import ReactDOM from 'react-dom';
import AnimatedView from '../AnimatedView';
import View from '../View';
import StyleSheet from '../StyleSheet';

export default class Modal extends React.Component {
  render() {
    let animationType = this.props.animationType || "none";
    let modalView = null;
    if (animationType === "none") {
      if (!this.props.visible) return null;
      else {
        modalView = (
          <View
            style={[styles.modal, this.props.style]}
          >
            {this.props.children}
          </View>
        );
      }
    }
    else {
      //if (animationType === "slide") throw ("Modal Error: Slide animation not implemented.");
      let animation = animationType === "slide" ?
        {
          enter: {
            transform: [{ translateY: 0 }],
            opacity: 1
          },
          exit: {
            transform: [{ translateY: 400 }],
            opacity: 0
          }
        } :
        {
          enter: {
            opacity: 1
          },
          exit: {
            opacity: 0
          }
        };
      modalView = (
        <AnimatedView
          style={[styles.modal, this.props.style]}
          in={this.props.visible}
          animation={animation}
          animateOnMount
          unmountOnExit
          duration={100}
        >
          {this.props.children}
        </AnimatedView>
      );
    }
    if (!modalView) return null;
    return ReactDOM.createPortal(
      modalView,
      document.getElementById('root')
    );
  }
}
const styles = StyleSheet.create({
  modal: {
    zIndex: 7,
    flex: 1,
    flexDirection: "column",
    position: "absolute",
    ...StyleSheet.absoluteFill,
    overflow: "hidden"
  },
});