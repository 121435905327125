import R14, { StyleSheet, Colors } from "../core";
export default {
  disableHistory: false,
  sideSheetPosition: "RIGHT",
  showFieldValues: false,
  values: [
    {
      name: "sideSheetPosition",
      value: "RIGHT",
    },
  ],
  features: [
    {
      name: "history",
      enabled: true,
    },
    {
      name: "showFieldValues",
      enabled: false,
    },
  ],
  styles: [
    {
      name: "field",
      backgroundColor: { themeColor: "onSurface", alpha: 0.01 },
    },
    {
      name: "fieldActive",
      backgroundColor: {
        themeColor: "onSurface",
        alpha: 0.04,
      },
    },
    {
      name: "fieldValueText",
      color: { themeColor: "onSurface" },
      fontSize: 12,
      maxWidth: 128,
    },
    {
      name: "fieldValueTextComplete",
      color: { themeColor: "onSurface", alpha: 0.6 },
    },
    {
      name: "fieldLabelText",
      color: { themeColor: "onSurface" },
    },
    {
      name: "fieldLabelTextComplete",
      color: { themeColor: "onSurface", alpha: 0.6 },
    },
    {
      name: "fieldIndicatorActive",
      backgroundColor: { themeColor: "yellow", alpha: 0.8 },
    },
    {
      name: "fieldIndicatorAccept",
      backgroundColor: { themeColor: "primary", alpha: 0.6 },
    },
    {
      name: "fieldIndicatorAcceptActive",
      backgroundColor: { themeColor: "primary", alpha: 0.8 },
    },
    {
      name: "fieldIndicatorReject",
      backgroundColor: { themeColor: "secondary", alpha: 0.6 },
    },
    {
      name: "fieldIndicatorRejectActive",
      backgroundColor: { themeColor: "secondary", alpha: 0.8 },
    },
    {
      name: "fieldIndicatorChanged",
      backgroundColor: { themeColor: "green", alpha: 0.6 },
    },
    {
      name: "fieldIndicatorChangedActive",
      backgroundColor: { themeColor: "green", alpha: 0.8 },
    },
    {
      name: "annotation",
      borderColor: { themeColor: "onSurface", alpha: 0.2 },
      backgroundColor: { themeColor: "onSurface", alpha: 0.0 },
    },
    {
      name: "annotationActive",
      borderColor: { themeColor: "primary", alpha: 0.8 },
      backgroundColor: { themeColor: "primary", alpha: 0.1 },
    },
    {
      name: "annotationPreview",
      borderColor: { themeColor: "primary", alpha: 0.8 },
      backgroundColor: { themeColor: "primary", alpha: 0.1 },
    },
    {
      name: "annotationSelection",
      borderColor: { themeColor: "primary", alpha: 0.8 },
      backgroundColor: { themeColor: "primary", alpha: 0.15 },
      borderWidth: 1,
      borderStyle: "dashed",
    },
    {
      name: "sideSheet",
      width: 360,
    },
    {
      name: "formDescription",
      marginBottom: 16,
    },
    {
      name: "formDescriptionText",
      fontSize: 14,
    },
  ],
};
