import React from "react";
import PropTypes from "prop-types";
import R14, {
  TextInput,
  StyleSheet,
  Colors,
  AnimatedView,
  View,
  Text,
} from "../core";

export default class TimeDurationLabel extends React.Component {
  static propTypes = {
    startAt: PropTypes.instanceOf(Date).isRequired,
    endAt: PropTypes.instanceOf(Date).isRequired,
  };
  constructor(props) {
    super(props);
  }
  render() {
    let diffTime = this.props.endAt.getTime() - this.props.startAt.getTime();
    if (diffTime === null) return null;
    let hours = Math.floor(diffTime / 1000 / 60 / 60);
    let minutes = Math.floor(diffTime / 1000 / 60) - hours * 60;
    let seconds =
      Math.round(diffTime / 1000) - minutes * 60 - hours * 60 * 60;
    let timer = [];
    hours &&
      timer.push(
        <View key="hours">
          <Text style={[styles.numberValue, this.props.numberValueTextStyle]}>{hours}</Text>
          <Text style={[styles.numberValue, this.props.numberValueTextStyle]}>h</Text>
        </View>
      );
    hours &&
      timer.push(
        <View key="hours">
          <Text style={[styles.numberValue, this.props.numberValueTextStyle]}>{hours}</Text>
          <Text style={[styles.numberValue, this.props.numberValueTextStyle]}>h</Text>
        </View>
      );

    return (
      <View style={[this.props.style, styles.timeDurationLabel]}>
        {hours > 0 && (
          <View style={styles.number}>
            <Text style={[styles.numberValue, this.props.numberValueTextStyle]}>{hours}</Text>
            <Text style={[styles.numberValue, this.props.numberLabelTextStyle]}>h</Text>
          </View>
        )}
        {minutes > 0 && (
          <View style={styles.number}>
            <Text style={[styles.numberValue, this.props.numberValueTextStyle]}>{minutes}</Text>
            <Text style={[styles.numberValue, this.props.numberLabelTextStyle]}>m</Text>
          </View>
        )}
        <View style={styles.number}>
          <Text style={[styles.numberValue, this.props.numberValueTextStyle]}>{seconds}</Text>
          <Text style={[styles.numberValue, this.props.numberLabelTextStyle]}>s</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  numberValue: {
    color: Colors.onSurface,
    paddingRight: 1,
  },
  numberLabel: {
    color: Colors.onSurface
  },
  number: {
    paddingLeft: 4,
  },
  timeDurationLabel: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});
