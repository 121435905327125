export default class DimensionsBase{
  // constructor(){
  //   this._resizeListener = null;
  //   this._resizeHandlers = [];
  //   this._handleResize = this._handleResize.bind(this);
  // }
  static get(dim){
    return { width: window.innerWidth, height: window.innerHeight };
  }
  static addEventListener(type, handler){
    if(type !== 'change') throw new Error(`Dimensions Error: Unknown type ${type}.`);
    /** @todo: Should we limit the threshold for resize callbacks? Keep Passive, Only use one event handler */
    window.addEventListener('resize', ()=>{DimensionsBase._handler(type, handler)});
  }
  static removeEventListener(type, handler){
    window.removeEventListener('resize', ()=>{DimensionsBase._handler(type, handler)});
  }
  static _handler(type, handler){
    let dimensions = { width: window.innerWidth, height: window.innerHeight };
    handler({
      window: dimensions,
      screen: dimensions
    });
  }
}