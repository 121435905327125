import React from 'react';
import StyleSheet from '../StyleSheet';
import View from '../View';
import AnimatedView from '../AnimatedView';
import { Colors } from '../Theme';

export default class SwitchBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (this.props.autoFocus) this.autoFocus();
  }
  autoFocus() {
    setTimeout(() => {
      if (!this.elmt.ref.current) return;
      this.ref.current.focus();
    }, 350);
  }
  handleValueChange(value) {
    if(this.props.onValueChange) this.props.onValueChange(value);
  }
  handleChange(event) {
    this.handleValueChange(event.target.checked);
  }
  toggleValue(){
    this.handleValueChange(! this.props.value);
  }
  handleKeyDown(e) {
    if (this.props.handleKeyDown) this.props.handleKeyDown(e);
    if (!e.nativeEvent || !e.nativeEvent.key) return false;
    switch (e.nativeEvent.key) {
      case "Enter":
        this.toggleValue();
        break;
    }
  }
  render() {
    let styles = this.styles();
    return (
      <View
        style={styles.switch}
        tabIndex={0}
        onFocus={this.props.onFocus || null}
        onBlur={this.props.onBlur || null}
        onKeyDown={this.handleKeyDown}
      >
        <AnimatedView 
          style={[styles.track, this.props.value && styles.trackOn]}
          in={this.props.value ? true : false}
          enter={{
            backgroundColor: StyleSheet.color(Colors.primary).rgba(.6)
          }}
          exit={{
            backgroundColor: StyleSheet.color(Colors.onBackground).rgba(.5)
          }}
          duration={100}
        />
        <AnimatedView
          style={[styles.handle, this.props.value && styles.handleOn]}
          in={this.props.value ? true : false}
          enter={{
            transform: [{ translateX: 20 }],
            backgroundColor: Colors.primary
          }}
          exit={{
            transform: [{ translateX: 0 }],
            backgroundColor: '#EFEFEF',
          }}
          duration={100}
        />
        <input
          tabIndex={-1}
          className={StyleSheet.className(styles.input)}
          name={this.props.name}
          ref={this.ref}
          type='checkbox'
          checked={this.props.value ? true : false}
          onChange={this.handleChange}
        />
      </View>
    );
  }
  styles() {
    return StyleSheet.create({
      switch: {
        position: "relative",
        width: 32,
        height: 14,
        overflow: "visible"
      },
      input: {
        position: "absolute",
        left: -18,
        top: -17,
        width: 68,
        height: 48,
        margin: 0,
        opacity: 0,
        cursor: "pointer"
      },
      track: {
        position: "absolute",
        top: 0,
        left: 0,
        height: 14,
        width: 32,
        borderRadius: 7,
      },
      handle: {
        position: "absolute",
        top: -3,
        left: -3,
        height: 20,
        width: 20,
        borderRadius: 10,
        backgroundColor: '#EFEFEF',
        elevation: 2,
      }
    });
  }
}