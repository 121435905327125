import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";

import View from "./View";
import Text from "./Text";
import ScrollView from "./ScrollView";
import StyleSheet from "./StyleSheet";
import AnimatedView from "./AnimatedView";
import { Colors } from "./Theme";

export default forwardRef(function InfiniteList(
  {
    itemsPerPage,

    // size,
    itemSize = "auto",
    defaultItemSize = 50,
    dataLoader,
    itemRenderer,
    initialPage = 1,
    initialData = null,
    itemLoadingRenderer = null,
    maxCachedPages = 2,
    ItemLoadingComponent = null,
    pageLoadDelay = 500,
    initialTotalItems = null,
    animateLoading = true,
    initialItemIndex = null,
    reverse = false,
    horizontal = false,
    onScroll = null,
    onLayout = null,
    onContentSizeChange = null,
    onRemoveItem = null,
    style = null,
    contentStyle = null,
    ListFooterComponent = null,
  },
  ref
) {
  const [totalItems, setTotalItems] = useState(initialTotalItems || 0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [refreshToken, setRefreshToken] = useState(false);
  const [initialized, setInitialized] = useState(false);
  // const [scrollEnabled, setScrollEnabled] = useState(true);
  const [visiblePages, setVisiblePages] = useState(
    initialPage ? [initialPage] : []
  );
  const [loadedPages, setLoadedPages] = useState(
    initialData && initialPage ? [initialPage] : []
  );

  const pageMapRef = useRef(
    initialData && initialPage
      ? {
          [initialPage]: {
            loaded: true,
            data: initialData,
            at: new Date(),
            page: initialPage,
            itemMap: {},
          },
        }
      : {}
  );
  // const totalItemsRef = useRef(totalItems || 0);
  const pageLoaderRef = useRef();
  const addPageDataRef = useRef();
  const lastScrollRef = useRef();
  const lastLoadedPagesRef = useRef([]);
  const scrollViewLayoutRef = useRef(null);
  const pageLayoutInfoRef = useRef([]);
  const sizeField = horizontal ? "width" : "height";
  const axis = horizontal ? "x" : "y";
  const scrollRef = useRef(null);
  const forceRefresh = () => setRefreshToken(!refreshToken);
  const placeHolderItemSize = itemSize === "auto" ? defaultItemSize : itemSize;
  const placeHolderPageSize = placeHolderItemSize * itemsPerPage;
  const itemAutoSize = itemSize === "auto";

  useEffect(() => {
    if (!initialized) return;
    // Without knowing the layout of the scroll view it is
    // impossible to scroll to a specific page in reverse
    const scrollViewSize = scrollViewLayoutRef.current
      ? scrollViewLayoutRef.current[sizeField]
      : 0;
    if (!scrollRef.current)
      throw new Error("Unable to initialize infinite list.");
    let scrollTo = { x: 0, y: 0, animated: false };
    if (initialPage && initialPage !== 1) {
      // if reverse, scroll to the end of the revered page
      scrollTo[axis] = reverse
        ? (totalPages - initialPage - 1) * placeHolderPageSize + scrollViewSize
        : (initialPage - 1) * placeHolderPageSize;
    } else if (initialItemIndex)
      // if reverse scroll to the end of the translated index
      scrollTo[axis] = reverse
        ? (totalItems - initialItemIndex) * placeHolderItemSize - scrollViewSize
        : initialItemIndex * placeHolderItemSize;
    else if (reverse) {
      //scrollRef.current.scrollToEnd({ animated: true, timeout: 1000 });
      scrollTo = { scrollToEnd: true, animated: false };
    } else scrollTo = null;
    scrollTo
      ? scrollRef.current.scrollTo(scrollTo)
      : scrollRef.current.triggerOnScroll();
  }, [initialized]);

  useEffect(() => {
    //updatePageLayoutInfo();
    // if (lastScrollRef.current.pageInfo && pageLayoutInfoRef.current) {
    //   scrollRef.current.scrollTo({
    //     [axis]: 0,
    //     animated: false,
    //   });
    // }
  }, [refreshToken, loadedPages]);

  useEffect(() => {
    visiblePageLoader();
    return () => {
      clearPageLoaderRefTimeout();
      //clearCleanupPageMapRefTimeout();
    };
  }, [refreshToken, visiblePages]);

  useEffect(() => {
    addPageDataRef.current &&
      visiblePages.includes(addPageDataRef.current.page) &&
      loadedPages.includes(addPageDataRef.current.page) &&
      addPageDataRef.current.callback();

    // Check for change in before
    // Test if it should use translatePage?
    updatePageLayoutInfo();
    // updateScrollOnLoad();

    // console.log("CHECK PAGE DIFF?", { loadedPages, visiblePages });
  }, [refreshToken, loadedPages, visiblePages]);

  // Share scroll methods with the component
  useImperativeHandle(
    ref,
    () => {
      return {
        forceRefresh() {
          forceRefresh();
        },
        scrollTo(params) {
          scrollRef.current.scrollTo(params);
        },
        scrollToIndex(index, options = {}) {
          const autoScroll = options.autoScroll || false;
          const autoAnimate = options.autoAnimate || false;
          const itemStart = index * itemSize;
          const itemEnd = itemStart + itemSize;
          const currentOffset = lastScrollRef.current
            ? lastScrollRef.current.contentOffset[axis]
            : 0;
          let params = {
            [axis]: itemStart - 64,
            animated: options.animated !== false,
          };
          if (autoAnimate) {
            const scrollDiff = Math.abs(params[axis] - currentOffset);
            if (scrollDiff > itemSize * 15) params.animated = false;
          }
          if (autoScroll && lastScrollRef.current) {
            const { contentOffset, layout } = lastScrollRef.current;
            scrollRef.current.measure((x, y, width, height) => {
              let layoutSize = horizontal ? width : height;
              if (itemStart < contentOffset[axis]) {
                params[axis] = itemStart - 64;
              } else if (itemEnd > contentOffset[axis] + layoutSize) {
                params[axis] = itemEnd - layoutSize + 64;
              } else params = null;
              params && scrollRef.current.scrollTo(params);
            });
          } else scrollRef.current.scrollTo(params);
        },
        scrollToEnd(params) {
          scrollRef.current.scrollToEnd(params);
        },
        triggerOnScroll() {
          scrollRef.current.triggerOnScroll();
        },
        measureInWindow(callback) {
          scrollRef.current.measureInWindow(callback);
        },
        measure(callback) {
          scrollRef.current.measure(callback);
        },
        // reset(options = {}) {
        //   clearPageLoaderRefTimeout();
        //   setLoadedPages([]);
        //   const page = options.page || initialPage;
        //   const data = options.data || initialData;
        //   if (options.totalItems) totalItems = options.totalItems;
        //   console.log("THIS IS SO HACKY REWRITE");
        //   pageMapRef.current =
        //     data && page
        //       ? {
        //           [page]: {
        //             loaded: true,
        //             data,
        //             at: new Date(),
        //             page,
        //           },
        //         }
        //       : {};
        //   setLoadedPages([page]);
        //   setVisiblePages([]);
        //   scrollRef.current.scrollTo({
        //     [axis]: (page - 1) * itemsPerPage * itemSize,
        //     animated: false,
        //   });
        //   scrollRef.current.triggerOnScroll();
        // },
        reload(options = {}) {
          clearPageLoaderRefTimeout();
          Object.keys(pageMapRef.current).forEach((key) => {
            onRemoveItem &&
              pageMapRef.current[key].data.forEach((item) =>
                onRemoveItem(item)
              );
            delete pageMapRef.current[key];
          });
          lastLoadedPagesRef.current = [];
          setLoadedPages([]);
          setVisiblePages([]);
          throw new Error("DEAL WITH ITEM SIZE");
          if (options.scrollToPage) {
            scrollRef.current.scrollTo({
              [axis]: (options.scrollToPage - 1) * itemsPerPage * itemSize,
              animated: false,
            });
          }
          scrollRef.current.triggerOnScroll();
        },
        addItemByPage(item, page, options = {}) {
          if (!loadedPages.includes(page) || !pageMapRef.current[page])
            return false;
          if ("index" in options)
            pageMapRef.current[page].data.splice(options.index, 0, item);
          else pageMapRef.current[page].data.push(item);
          if (pageMapRef.current[page].data.length > itemsPerPage) {
            // Get the last element and remove it
            let removedItem = pageMapRef.current[page].data.pop();
            loadedPages
              .filter((p) => p > page)
              .forEach((p) => {
                console.log("Add removed item to page", p, removedItem);
                pageMapRef.current[p].data.unshift(removedItem);
                removedItem = pageMapRef.current[p].data.pop();
              });
          }
          incrementTotalItems();
          //forceRefresh();
        },
        addPageData(page, items = [], options = {}) {
          return new Promise((resolve, reject) => {
            options.reset &&
              Object.keys(pageMapRef.current)
                .filter((key) => parseInt(key) !== parseInt(page))
                .forEach((key) => {
                  onRemoveItem &&
                    pageMapRef.current[key].data.forEach((item) => {
                      onRemoveItem(item);
                    });
                  delete pageMapRef.current[key];
                });
            pageMapRef.current[page] = {
              loaded: true,
              data: Array.isArray(items) ? items : [],
              at: new Date(),
              page,
              itemMap: {},
            };

            const callbackTimeout = setTimeout(() => {
              addPageDataRef.current = null;
              reject("addPageData has timed out");
            }, 10000);
            addPageDataRef.current = {
              page,
              callback: () => {
                callbackTimeout && clearTimeout(callbackTimeout);
                addPageDataRef.current = null;
                resolve();
              },
            };

            // if (options.reset) {
            //   console.log("addPageData loaded reset", loadedPages, [page]);
            // } else if (!loadedPages.includes(page))
            //   console.log("addPageData loaded", loadedPages, [
            //     ...loadedPages,
            //     page,
            //   ]);
            // else console.log("loaded pages not changed", loadedPages);

            if (options.reset) {
              lastLoadedPagesRef.current = loadedPages;
              setLoadedPages([page]);
            } else if (!loadedPages.includes(page)) {
              lastLoadedPagesRef.current = loadedPages;
              setLoadedPages([...loadedPages, page]);
            }

            throw new Error("ITEM SIZEvv");

            scrollRef.current.scrollTo({
              [axis]: (page - 1) * itemsPerPage * itemSize,
              animated: false,
            });
            forceRefresh();
          });
        },
      };
    },
    []
  );

  // const updateScrollOnLoad = () => {
  //   //setScrollEnabled(false);

  //   visiblePages.forEach((visiblePage) => {
  //     if (
  //       !loadedPages.includes(visiblePage) &&
  //       lastScrollRef.current &&
  //       lastScrollRef.current.pageLayoutInfo.page === visiblePage
  //     ) {
  //       scrollOnLoadRef.current = lastScrollRef.current;
  //     } else if (loadedPages.includes(visiblePage)) {
  //       if (
  //         scrollOnLoadRef.current &&
  //         scrollOnLoadRef.current.pageLayoutInfo.page === visiblePage
  //       ) {
  //         // Check the pageMap and get the diff
  //         let visiblePageLayoutInfo = pageLayoutInfoRef.current.find(
  //           ({ page }) => page === visiblePage
  //         );

  //         // let visiblePageLayoutInfo = scrollOnLoadRef.current.pageLayoutInfo;
  //         let scrollToAxis = null;

  //         if (
  //           visiblePageLayoutInfo.height !==
  //           scrollOnLoadRef.current.pageLayoutInfo.height
  //         ) {
  //           if (
  //             scrollOnLoadRef.current.pageLayoutInfo.height <
  //             visiblePageLayoutInfo.height
  //           ) {
  //             // Check if there has a scroll change
  //             let pageOffsetAxis =
  //               lastScrollRef.current.pageLayoutInfo.page === visiblePage
  //                 ? lastScrollRef.current.pageLayoutInfo.contentOffset[axis]
  //                 : scrollOnLoadRef.current.pageLayoutInfo.contentOffset[axis];

  //             // console.log(
  //             //   "Get most recent?",
  //             //   lastScrollRef.current.pageLayoutInfo.page === visiblePage,
  //             //   lastScrollRef.current.pageLayoutInfo.contentOffset[axis],
  //             //   scrollOnLoadRef.current.pageLayoutInfo.contentOffset[axis]
  //             // );
  //             scrollToAxis +=
  //               visiblePageLayoutInfo[axis] +
  //               pageOffsetAxis +
  //               (visiblePageLayoutInfo.height -
  //                 scrollOnLoadRef.current.pageLayoutInfo.height);
  //           }
  //         }
  //         // console.log("AUTO SCROLL DISABLED!!!!", scrollToAxis);
  //         // if (scrollToAxis !== null) {
  //         //   scrollRef.current.scrollTo({
  //         //     [axis]: scrollToAxis,
  //         //     animated: false,
  //         //     timeout: false,
  //         //   });
  //         // }

  //         scrollOnLoadRef.current = null;
  //       } else if (
  //         lastScrollRef.current &&
  //         lastScrollRef.current.pageLayoutInfo.page === visiblePage
  //       ) {
  //         // console.log(
  //         //   "111 VISIBLE PAGE CURRENT IS ",
  //         //   visiblePage,
  //         //   lastScrollRef.current,
  //         //   pageMapRef.current,
  //         //   loadedPages,
  //         //   visiblePages
  //         // );
  //       }
  //     }
  //   });
  //   // setScrollEnabled(true);
  // };
  const incrementTotalItems = (increment = 1) => {
    setTotalItems((prevTotalItems) => prevTotalItems + increment);
  };
  const updatePageLayoutInfo = () => {
    let sections = [];
    if (visiblePages.length) {
      let lastPageOffset = 0;
      if (visiblePages[0] > 1) {
        sections.push({
          type: "placeHolderBefore",
          [axis]: 0,
          [sizeField]: (visiblePages[0] - 1) * placeHolderPageSize,
          page: visiblePages[0] - 1,
        });
        lastPageOffset = (visiblePages[0] - 1) * placeHolderPageSize;
      }
      visiblePages.forEach((page) => {
        let sectionParams = {
          page,
          type: "placeHolderPage",
          [axis]: lastPageOffset,
          [sizeField]: placeHolderPageSize,
        };
        if (
          pageMapRef.current &&
          pageMapRef.current[page] &&
          pageMapRef.current[page].layout
        ) {
          // console.log("CHECK LAYOUT", page, pageMapRef.current[page].layout);
          sectionParams.type = "page";
          sectionParams[sizeField] = pageMapRef.current[page].layout[sizeField];
        }
        lastPageOffset += sectionParams[sizeField];
        sections.push(sectionParams);
      });

      if (visiblePages[visiblePages.length - 1] < totalPages) {
        let remainingItems = totalItems - visiblePages.length * itemsPerPage;
        sections.push({
          type: "placeHolderAfter",
          page: visiblePages[visiblePages.length - 1] + 1,
          [axis]: lastPageOffset,
          [sizeField]: remainingItems * placeHolderItemSize,
        });
      }
    }
    pageLayoutInfoRef.current = sections;
    return pageLayoutInfoRef.current;
  };
  const calculatePageInfoByOffset = (offset) => {
    let pageType = null;
    let page = 1;
    let pageAxis = 0;
    let pageAxisOffset = 0;
    let pageSize = placeHolderPageSize;
    //let pageLayoutInfo = updatePageLayoutInfo();

    // Translate the offset if in reverse
    // Get the total size of the pages
    const totalSize = pageLayoutInfoRef.current.reduce(
      (ret, section) => (ret += section[sizeField]),
      0
    );
    if (!pageLayoutInfoRef.current) return page;
    let section = pageLayoutInfoRef.current.find((section) => {
      return (
        // Check if it is the last page and offset is greater than the last page
        (section.page &&
          section.page === totalPages &&
          offset > section[axis] + section[sizeField]) ||
        // Check if offset inside section
        (offset >= section[axis] &&
          offset <= section[axis] + section[sizeField])
      );
    });
    if (section) {
      switch (section.type) {
        case "page":
        case "placeHolderPage":
          page = section.page;
          break;
        case "placeHolderAfter":
          page =
            Math.ceil((offset - section[axis]) / placeHolderPageSize) +
            section.page -
            1;
          break;
        case "placeHolderBefore":
          page = Math.ceil((offset - section[axis]) / placeHolderPageSize);
          break;
      }
      pageAxisOffset = offset - section[axis];
      pageAxis = section[axis];
      pageSize = section[sizeField];
      pageType = section.type;
    }
    return {
      page,
      [axis]: pageAxis,
      [sizeField]: pageSize,
      type: pageType,
      contentOffset: { [axis]: pageAxisOffset },
    };
  };
  const updateVisiblePages = (newVisiblePages) => {
    if (compareArrays(visiblePages, newVisiblePages)) return false;
    // setScrollEnabled(false);
    setVisiblePages(newVisiblePages);
  };
  const handleScroll = (e) => {
    // if (!scrollEnabled) return false;
    const { nativeEvent } = e;
    const { contentOffset, layout, contentSize } = nativeEvent;
    // translate the offset if reverse
    const translatedOffset = reverse
      ? contentSize[sizeField] - contentOffset[axis] - layout[sizeField]
      : contentOffset[axis];

    const visiblePageStartInfo = calculatePageInfoByOffset(translatedOffset);
    const visiblePageEndInfo = calculatePageInfoByOffset(
      translatedOffset + layout[sizeField]
    );
    let newVisiblePages = [];
    for (let i = visiblePageStartInfo.page; i <= visiblePageEndInfo.page; i++) {
      newVisiblePages.push(i);
    }
    updateVisiblePages(newVisiblePages);

    onScroll && onScroll(e);
  };

  const handleLayout = (e) => {
    scrollViewLayoutRef.current = e.nativeEvent.layout;
    setInitialized(true);
    onLayout && onLayout(e);
  };

  const visiblePageLoader = () => {
    clearPageLoaderRefTimeout();
    let loaded = visiblePages.every(
      (page) => pageMapRef.current[page] && pageMapRef.current[page].loaded
    );
    if (loaded) {
      // Check if it addPageData is scrolling
      if (
        addPageDataRef.current &&
        !visiblePages.includes(addPageDataRef.current.page)
      ) {
        // Do nothing, wait for scroll
        return false;
      } else if (!compareArrays(visiblePages, loadedPages)) {
        lastLoadedPagesRef.current = loadedPages;
        setLoadedPages(visiblePages);
        cleanupPageMap();
      }
    } else
      pageLoaderRef.current = {
        visiblePages,
        timeout: setTimeout(() => {
          // Figure out pages to load
          let promises = visiblePages
            .filter((page) => !pageMapRef.current[page])
            .map((page) => {
              pageMapRef.current[page] = {
                loaded: false,
                data: [],
                at: new Date(),
                page,
                itemMap: {},
              };

              return new Promise((resolve, reject) => {
                dataLoader(page)
                  .then((loadedData) => {
                    if (!Array.isArray(loadedData))
                      console.error(
                        `InfiniteList ignoring loaded data of type ${typeof loadedData}.`
                      );
                    pageMapRef.current[page] = {
                      loaded: true,
                      data: Array.isArray(loadedData) ? loadedData : [],
                      at: new Date(),
                      page,
                      itemMap: {},
                    };
                    resolve(true);
                  })
                  .catch((err) => reject(err));
              });
            });

          promises.length &&
            Promise.all(promises).then(() => {
              const newLoadedPages = visiblePages.filter(
                (page) =>
                  pageMapRef.current[page] && pageMapRef.current[page].loaded
              );
              if (!compareArrays(loadedPages, newLoadedPages)) {
                lastLoadedPagesRef.current = loadedPages;
                setLoadedPages(newLoadedPages);
              }

              cleanupPageMap();
            });
        }, pageLoadDelay),
      };
  };

  const cleanupPageMap = () => {
    // Timeout before cleanup incase they scroll back to a page that exists
    // cleanupPageMapTimeoutRef.current = setTimeout(() => {
    if (Object.keys(pageMapRef.current).length <= maxCachedPages) return;
    // Remove cached pageMaps
    Object.keys(pageMapRef.current)
      .map((key) => pageMapRef.current[key])
      .filter((p) => !visiblePages.includes(p.page))
      .sort((a, b) => a.at - b.at)
      .forEach((p) => {
        if (Object.keys(pageMapRef.current).length > maxCachedPages) {
          //console.log("REMOVING CACHED PAGE MAP", p.page);
          onRemoveItem &&
            pageMapRef.current[p.page].data.forEach((item) =>
              onRemoveItem(item)
            );
          delete pageMapRef.current[p.page];
        }
      });
  };

  const dynamicStyles = useMemo(() => {
    let startPage = visiblePages.length ? visiblePages[0] : 1;
    let endPage = visiblePages.length
      ? visiblePages[visiblePages.length - 1]
      : 1;
    return StyleSheet.create({
      placeHolderBefore: {
        flexShrink: 0,
        flexGrow: 0,
        [sizeField]:
          endPage > 1
            ? placeHolderItemSize * itemsPerPage * (startPage - 1)
            : 0,
      },
      placeHolderAfter: {
        flexShrink: 0,
        flexGrow: 0,
        [sizeField]:
          endPage < totalPages
            ? totalItems * placeHolderItemSize -
              endPage * itemsPerPage * placeHolderItemSize
            : 0,
      },
      page: {
        [sizeField]: itemAutoSize ? "auto" : totalItems * itemSize,
      },
      endPage: {
        [sizeField]: itemAutoSize ? "auto" : totalItems * itemSize,
      },
      item: {
        [sizeField]: itemAutoSize ? "auto" : itemSize,
        flex: 0,
      },
      placeHolderItem: {
        [sizeField]: placeHolderItemSize,
        flex: 0,
      },
      scrollViewContent: {
        [sizeField]: itemAutoSize ? "auto" : totalItems * itemSize,
        flex: 1,
        flexShrink: 0,
        flexDirection: horizontal ? "row" : "column",
        // flexGrow: 0,
        justifyContent: "flex-start",
      },
      visiblePages: {
        // [sizeField]: totalPages * itemsPerPage * itemSize,
        flex: 0,
        flexGrow: 0,
        flexDirection: horizontal ? "row" : "column",
        justifyContent: "flex-start",
      },
      itemLoading: {
        flex: 1,
        [sizeField]: placeHolderItemSize - 8,
        ...StyleSheet.margin(4),
        backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.02),
        borderRadius: 4,
      },
    });
  }, [visiblePages]);

  const keyExtractor = (item, index) => item.label;
  const compareArrays = (a1, a2) =>
    a1.length === a2.length && a1.every((elmt, idx) => elmt === a2[idx]);

  const clearPageLoaderRefTimeout = () => {
    pageLoaderRef.current &&
      pageLoaderRef.current.timeout &&
      clearTimeout(pageLoaderRef.current.timeout);
  };
  // const clearCleanupPageMapRefTimeout = () => {
  //   cleanupPageMapTimeoutRef.current &&
  //     cleanupPageMapTimeoutRef.current &&
  //     clearTimeout(cleanupPageMapTimeoutRef.current);
  // };

  const renderPages = () => {
    let pageComponents = [];
    for (let page of visiblePages) {
      let pageItems = [];
      let pageLoaded = loadedPages.includes(page);
      if (pageLoaded) {
        pageItems = pageMapRef.current[page].data.map((item, idx) => (
          <View
            // key={keyExtractor(item, page * itemsPerPage + idx)}
            key={`_p${page}-i${idx}`}
            style={dynamicStyles.item}
            // onLayout={(e) => {
            //   if (pageMapRef.current[page]) {
            //     pageMapRef.current[page].itemMap[idx] = e.nativeEvent;
            //     pageMapRef.current[page].layout = {
            //       // [axis]: null,
            //       [sizeField]: 0,
            //     };
            //     Object.keys(pageMapRef.current[page].itemMap).forEach((idx) => {
            //       let itemLayout = pageMapRef.current[page].itemMap[idx].layout;
            //       pageMapRef.current[page].layout[sizeField] +=
            //         itemLayout[sizeField];

            //       // if (
            //       //   pageMapRef.current[page].layout[axis] === null ||
            //       //   itemLayout[axis] <
            //       //     pageMapRef.current[page].layout[axis]
            //       // )
            //       //   pageMapRef.current[page].layout[axis] =
            //       //     itemLayout[axis];
            //     });
            //   }
            // }}
          >
            {itemRenderer(item, (page - 1) * itemsPerPage + idx, page)}
          </View>
        ));
      } else {
        // let loadingItems = [];
        for (let i = 0; i < itemsPerPage; i++) {
          const itemNum = (page - 1) * itemsPerPage + i;
          if (itemNum >= totalItems) break;
          pageItems.push(
            <View
              key={`_pl_p${page}-i${i}`}
              style={dynamicStyles.placeHolderItem}
            >
              {itemLoadingRenderer
                ? itemLoadingRenderer((page - 1) * itemsPerPage + i, page)
                : ItemLoadingComponent || (
                    <View style={dynamicStyles.itemLoading} />
                  )}
            </View>
          );
        }
      }

      reverse && pageItems.reverse();

      if (!pageLoaded && animateLoading) {
        pageComponents.push(
          <AnimatedView
            key={`_pl_p${page}-loading`}
            animation={{
              from: {
                opacity: 0.8,
              },
              to: {
                opacity: 0.4,
              },
            }}
            // in={true}
            style={dynamicStyles.visiblePages}
            iterationCount='infinite'
            duration={2000}
          >
            {pageItems}
          </AnimatedView>
        );
      } else
        pageComponents.push(
          <View
            key={`_pl_p${page}`}
            onLayout={
              pageLoaded
                ? (e) => {
                    if (pageMapRef.current[page]) {
                      pageMapRef.current[page].layout = {
                        // [axis]: null,
                        [sizeField]: e.nativeEvent.layout[sizeField],
                      };
                      // Update the page layout info
                      updatePageLayoutInfo();
                    }
                  }
                : null
            }
          >
            {pageItems}
          </View>
        );
    }
    reverse && pageComponents.reverse();
    return pageComponents;
  };
  const renderedPages = renderPages();
  return (
    <ScrollView
      // {...{ [sizeField]: size }}
      horizontal={horizontal}
      ref={scrollRef}
      contentStyle={[dynamicStyles.scrollViewContent, contentStyle]}
      onContentSizeChange={onContentSizeChange}
      onScroll={handleScroll}
      onLayout={handleLayout}
      style={style}
      // scrollEnabled={scrollEnabled}
    >
      <View
        style={
          reverse
            ? dynamicStyles.placeHolderAfter
            : dynamicStyles.placeHolderBefore
        }
      />
      <View style={dynamicStyles.visiblePages}>{renderedPages}</View>
      <View
        style={
          reverse
            ? dynamicStyles.placeHolderBefore
            : dynamicStyles.placeHolderAfter
        }
      />
      {ListFooterComponent}
    </ScrollView>
  );
});
