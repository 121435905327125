import R14 from "./R14";
export default class R14Portal extends R14.Domain {
  constructor(name, type, options = {}) {
    super();
    this._name = name;
    this._data = options.data || {};
    this._type = type;
    this._parentPortalName = options.parentPortalName || null;
    this._initialParams = options.initialParams || null;
    this._activeRouteName = options.route || null;
    this._activeRoutePath = options.path || null;
    this._initialized = false;
    this.state = {
      activeRouteName: options.route || null,
      activeRoutePath: options.path || null,
    };
  }
  createAppInstance(extendProps = {}) {
    return new Proxy(this, {
      get: function (obj, prop) {
        switch (prop) {
          case "route":
          case "rt":
            return obj.route;
            break;
          default:
            return R14.instance.app[prop];
        }
      },
    });
  }
  get initialized() {
    return this._initialized;
  }
  get initialParams() {
    return this._initialParams;
  }
  get activeRouteName() {
    return this.state.activeRouteName || this._activeRouteName;
  }
  get activeRoutePath() {
    return this.state.activeRoutePath || this._activeRoutePath;
  }
  setActiveRoute(route) {
    this.setState({
      activeRouteName: route ? route.name : null,
      activeRoutePath: route ? route.path : null,
    });

    this._route = route;
    this._initialized = true;
    return this;
  }
  setParentPortalName(parentPortalName) {
    this._parentPortalName = parentPortalName;
  }
  get data() {
    return this._route ? this._route.data : {};
  }
  get params() {
    return this._route ? this._route.params : {};
  }
  get query() {
    return this._route ? this._route.query : {};
  }
  get route() {
    return this._route;
  }
  get name() {
    return this._name;
  }
  get type() {
    return this._type;
  }
  get parentPortalName() {
    return this._parentPortalName;
  }
}
