import React from 'react';
import View from '../View';
import StyleSheet from '../StyleSheet';
import { Colors } from '../Theme';

export default class ActivityIndicatorBase extends React.PureComponent {
    render() {
        let styles = this.styles();
        let circleSpinnerWrapperStyles = [styles.circleSpinnerWrapper];
        let circleSpinnerStyles = [styles.circleSpinner];
        if (this.props.size){
            circleSpinnerWrapperStyles.push(styles[`${this.props.size}Wrapper`]);
            circleSpinnerStyles.push(styles[this.props.size]);
        } 
        return (
            <View style={styles.loadingSpinner}>
                <View style={circleSpinnerWrapperStyles}>
                    <View style={circleSpinnerStyles}></View>
                </View>
            </View>
        );
    }
    styles() {
        return StyleSheet.create({
            loadingSpinner: {
                display: "block",
                position: "relative"
            },
            circleSpinnerWrapper: {
                display: "block",
                position: "relative",
                // left: 14,
                // top: 14,
                fontSize: 0,
                color: this.props.color || Colors.primary,
                width: 36,
                height: 36,
                boxSizing: "border-box",
            },
            circleSpinner: {
                display: "inline-block",
                width: 36,
                height: 36,
                boxSizing: "border-box",
                float: "none",
                backgroundColor: "transparent",
                ...StyleSheet.border(1, 'solid', this.props.color || Colors.primary),
                background: "transparent",
                borderBottomColor: "transparent",
                borderWidth: 3,
                borderRadius: "100%",
                animationName: {
                    "0%": {
                        transform: "rotate(0deg)"
                    },
                    "50%": {
                        transform: "rotate(180deg)"
                    },
                    "100%": {
                        transform: "rotate(360deg)"
                    }
                },
                animationDuration: "750ms",
                animationIterationCount: 'infinite',
                animationTimingFunction: "linear"
            },
            smallWrapper: {
                width: 20,
                height: 20,
            },
            small: {
                width: 20,
                height: 20,
                borderWidth: 2
            }
        });
    }
}