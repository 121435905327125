import React from "react";
import R14 from "../R14";
import AnimatedView from "./AnimatedView";
import View from "./View";
import Text from "./Text";
import IconButton from "./IconButton";
import StyleSheet from "./StyleSheet";
import Touchable from "./Touchable";
import { Colors } from "./Theme";

export default class TimePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dateUtils = R14.utils.date;
    this.shouldAnimateIndicator = false;
    this.indicatorAngle = null;
    this.mode = this.props.mode;
    this.handleLabelPress = this.handleLabelPress.bind(this);
  }
  handleLabelPress(val) {
    if (!this.props.time) return;
    let timeInfo = this.timeInfo;
    timeInfo[this.props.mode] = val;
    if (this.props.onTimeChange)
      this.props.onTimeChange(this.dateUtils.toTime(timeInfo));
  }
  get value() {
    return this.props.value || (this.props.mode === "hour" ? 12 : "00");
  }
  get size() {
    return this.props.size || 248;
  }
  get radius() {
    return this.size / 2;
  }
  get time() {
    return this.props.time || "12:00 AM";
  }
  get timeInfo() {
    return this.dateUtils.parseTime(this.time);
  }
  getValueByIdx(idx) {
    let val = null;
    if (this.props.mode === "hour") {
      val = idx + 6;
      if (val > 12) val -= 12;
    } else if (this.props.mode === "minute") {
      val = idx * 5 - 30;
      if (val < 0) val += 60;
      if (val < 10) val = `0${val}`;
    }
    return `${val}`;
  }
  getIndicatorAngleByValue(val) {
    let ret = val;
    if (this.props.mode === "hour") {
      if (val === 12) ret = 0;
      ret *= 30;
    }
    if (this.props.mode === "minute") {
      // if(val === 12) ret = 0;
      ret *= 6;
    }
    return ret;
  }
  createDynamicStyles() {
    //let numVals = this.props.mode === 'hour' ? 12 : 60;
    let numVals = 12;

    let dynamicStyles = {
      timePicker: {
        position: "relative",
        height: this.size,
        width: this.size
      }
    };
    let radius = this.radius - 18;
    // Where does .46 come from?
    let width = radius;
    for (let i = 1; i <= numVals; i++) {
      let num = null;

      let angle = (i / (numVals / 2)) * Math.PI; // Calculate the angle at which the element will be placed.
      // For a semicircle, we would use (i / numNodes) * Math.PI.
      let x = radius * Math.cos(angle) + width; // Calculate the x position of the element.
      let y = radius * Math.sin(angle) + width; // Calculate the y position of the element.

      dynamicStyles[`label${i}`] = {
        position: "absolute",
        top: x,
        right: y
      };
    }

    dynamicStyles.selectionIndicator = {
      position: "absolute",
      top: 0,
      right: this.radius - 18,
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      height: this.size,
      width: 36
    };
    dynamicStyles.selectionIndicatorLine = {
      height: this.radius - 36 - 8,
      width: 3,
      backgroundColor: Colors.secondary
    };
    dynamicStyles.selectionIndicatorCircle = {
      flexGrow: 0,
      flexShrink: 0,
      height: 36,
      width: 36,
      borderRadius: 18,
      backgroundColor: Colors.secondary
    };
    dynamicStyles.selectionIndicatorCenter = {
      flexGrow: 0,
      flexShrink: 0,
      height: 8,
      width: 8,
      borderRadius: 4,
      backgroundColor: Colors.secondary
    };
    return StyleSheet.create(dynamicStyles);
  }
  getLabelArr() {
    let ret = ["7", "8", "9", "10", "11", "12", "1", "2", "3", "4", "5", "6"];
    return ret;
  }
  getIdxByValue(val) {
    let labels = this.getLabelArr();
    return labels.indexOf(val) + 1;
  }
  renderFace(dynamicStyles) {
    let ret = [];
    let timeInfo = this.timeInfo;
    let val = this.props.mode === "hour" ? timeInfo.hour : timeInfo.minute;

    let indicatorAngle =  this.getIndicatorAngleByValue(val);
    let fromIndicatorAngle = indicatorAngle;
    if(this.props.mode !== this.mode){
      fromIndicatorAngle = this.indicatorAngle;
    }
    this.mode = this.props.mode;
    this.indicatorAngle = indicatorAngle;

    ret.push(
      <AnimatedView
        to={{transform: [{ rotate: `${this.indicatorAngle}deg` }]}}
        from={{transform: [{ rotate: `${fromIndicatorAngle}deg` }]}}
        duration={200}
        key='selectionIndicator'
        style={dynamicStyles.selectionIndicator}
      >
        <View style={dynamicStyles.selectionIndicatorCircle} />
        <View style={dynamicStyles.selectionIndicatorLine} />
        <View style={dynamicStyles.selectionIndicatorCenter} />
      </AnimatedView>
    );

    for (let i = 1; i <= 12; i++) {
      let val = this.getValueByIdx(i);
      ret.push(
        <Touchable
          key={`label${i}`}
          onPress={() => {
            this.handleLabelPress(val);
          }}
          style={[dynamicStyles[`label${i}`], styles.label]}
        >
          <Text style={[styles.labelText]}>{val}</Text>
        </Touchable>
      );
    }
    return ret;
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <View style={[dynamicStyles.timePicker]}>
        {this.renderFace(dynamicStyles)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  label: {
    height: 36,
    width: 36,
    borderRadius: 18,
    flex: 0,
    justifyContent: "space-between",
    alignItems: "center"
  },
  labelText: {
    width: 36,
    height: 36,
    lineHeight: 36,
    fontWeight: 500,
    textAlign: "center"
  }
});
