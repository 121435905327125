import React from "react";
import PropTypes from "prop-types";
import R14, {
  TextInput,
  StyleSheet,
  Colors,
  AnimatedView,
  View,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Text,
} from "../core";
import CharacterImage from "./CharacterImage";
import CharacterCompareView from "./CharacterCompareView";
import FloatingLabel from "../components/FloatingLabel";

export default R14.connectForm(
  /**
   * A hidden form field field component
   */
  class CharaterEntryField extends React.Component {
    static propTypes = {
      /** Name for the character entry fields. */
      name: PropTypes.string.isRequired,
      /** Value of the character entry fields. */
      value: PropTypes.any,
      /** KeyMapper instance from screen. */
      //keyMapper: PropTypes.instanceOf("KeyMapper").isRequired,
    };
    constructor(props) {
      super(props);
      //this.handleKeyMapperAction = this.handleKeyMapperAction.bind(this);
      this.elmt = this.props.elmt;
      this.state = {
        focusedChars: [],
        //editCharUid: null,
        //focusUid: this.elmt.value[0].uid,
      };
      this.charRefs = {};
      // for (let char of this.elmt.value) {
      //   this.charRefs[`char${char.uid}`] = React.createRef();
      //   this._refTest = `char${char.uid}`;
      // }
      this.firstUid = this.elmt.value[0].uid;
      this.currUid = this.elmt.value[0].uid;

      this.charactersEntryField = this.props.app.ui.charactersEntryField.getInstance(
        this.props.annotationSetUid
      );

      // this.keyMappings = {
      //   ArrowUp: "reject",
      //   ArrowDown: "edit",
      // };
      //this.freeEditEnabled = false;
      //this.initKeyMapper();
    }
    // initKeyMapper() {
    //   let keyMapper = this.props.keyMapper;
    //   keyMapper
    //     .onKeyDown(async ({ key, e }) => this.handleKeyDown(key, e))
    //     .addAction("rejectCharacter", ["ArrowUp"], this.handleKeyMapperAction, {
    //       label: "Reject Character",
    //     })
    //     .addAction(
    //       "previousCharacter",
    //       ["ArrowLeft", "ShiftTab"],
    //       this.handleKeyMapperAction,
    //       { label: "Previous Character" }
    //     )
    //     .addAction(
    //       "nextCharacter",
    //       ["ArrowRight", "Tab"],
    //       this.handleKeyMapperAction,
    //       { label: "Next Character" }
    //     );
    //   if (this.freeEditEnabled)
    //     keyMapper.addAction(
    //       "freeEditCharacter",
    //       ["ArrowDown"],
    //       this.handleKeyMapperAction,
    //       { label: "Free Edit Character" }
    //     );

    //   this.elmt.value.forEach((char, idx) => {
    //     let charNum = idx + 1;
    //     if (idx > 12) return;
    //     keyMapper.addAction(
    //       `gotoChar${charNum}`,
    //       [`F${charNum}`],
    //       this.handleKeyMapperAction,
    //       { label: `Character ${charNum}` }
    //     );
    //   });
    // }
    get keyMapper() {
      return this.props.keyMapper;
    }
    // async handleKeyMapperAction({ e, key, actionName }) {
    //   e.preventDefault();
    //   let uid = this.state.focusUid;
    //   switch (actionName) {
    //     case "rejectCharacter":
    //       uid && this.handleToggleRejectPress(uid);
    //       break;
    //     case "freeEditCharacter":
    //       this.freeEditEnabled && uid && this.handleToggleEditPress(uid);
    //       break;
    //     case "nextCharacter":
    //       uid && this.selectNext(uid);
    //       break;
    //     case "previousCharacter":
    //       uid && this.selectPrevious(uid);
    //       break;
    //     default:
    //       if (key.startsWith("F") && [2, 3].includes(key.length)) {
    //         // Check if should skip to char
    //         let idx = 0;
    //         for (let char of this.elmt.value) {
    //           idx++;
    //           if (key === `F${idx}`) {
    //             this.select(char.uid);
    //           }
    //         }
    //       }
    //   }
    // }
    // handleKeyDown(key, e) {
    //   if (this.props.keyMapper.isActionKey(key)) return;
    //   let uid = this.state.focusUid;
    //   if (!uid) return false;
    //   if (this.focusedChars.includes(uid)) return false;
    //   if (!this.charRefs[`char${uid}`].current) return false;
    //   console.log(key, this.getValue(uid));
    //   switch (key) {
    //     case this.getValue(uid):
    //       e.preventDefault();
    //       this.selectNext(uid);
    //       break;
    //     case "ArrowDown":
    //       e.preventDefault();
    //       break;
    //   }
    // }
    get focusedChars() {
      return this.state.focusedChars;
    }
    handleChangeText(uid, value) {
      let characters = this.elmt.value.map((char) => {
        if (uid === char.uid) {
          let updatedValue = value;
          if (this.state.editCharUid !== uid) {
            if (this.props.upperCase) updatedValue = value.toUpperCase();
            if (updatedValue.length > 1) updatedValue = updatedValue[updatedValue.length - 1];
          }
          char.updatedValue = updatedValue;
        }
        return char;
      });
      this.elmt.setValue(characters);
      this.state.editCharUid !== uid && this.selectNext(uid);
    }
    handleToggleRejectPress(character) {
      character.toggleReject();
      // this.selectNext
      // this.selectNext();
      // let characters = this.elmt.value.map((char) => {
      //   if (uid === char.uid) {
      //     char.reject = char.reject ? false : true;
      //   }
      //   return char;
      // });
      // this.elmt.setValue(characters);
      // this.state.editCharUid !== uid && this.selectNext(uid);
    }
    handleToggleEditPress(uid) {
      let isCurrEditCharUid = this.state.editCharUid === uid;
      this.setState({
        editCharUid: isCurrEditCharUid ? null : uid,
      });
      !isCurrEditCharUid && this.focus(uid);
      isCurrEditCharUid && this.selectNext(uid);
    }
    handleFocus(uid) {
      // this.focus(uid);
    }
    handleBlur(uid) {
      // let nState = {};
      // if (!this.focusedChars.includes(uid)) {
      //   let focusedChars = this.focusedChars;
      //   focusedChars.push(uid);
      //   nState.focusedChars = focusedChars;
      //   if (this.state.focusUid === uid) nState.focusUid = null;
      // }
      // if (this.state.editCharUid) nState.editCharUid = null;
      // this.setState(nState);
    }
    focus(uid) {
      // if (this.charRefs[`char${uid}`].current) {
      //   this.charRefs[`char${uid}`].current.focus();
      //   this.charRefs[`char${uid}`].current.select();
      // }
      // this.setState({ focusUid: uid });
    }
    getValue(uid) {
      let value = null;
      for (let char of this.elmt.value) {
        if (uid === char.uid) {
          value = this.parseValue(char);

          break;
        }
      }
      return value;
    }
    parseValue(char) {
      return char.updatedValue !== null && char.updatedValue !== undefined
        ? char.updatedValue
        : char.value;
    }
    hasChanged(char) {
      return this.parseValue(char) !== char.value;
    }
    hasFocused(char) {
      return this.focusedChars.includes(char.uid);
    }
    hasFocus(char) {
      return this.state.focusUid === char.uid;
    }

    getCharIdx(uid) {
      let idx = 0;
      for (let char of this.elmt.value) {
        if (char.uid === uid) return idx;
        idx++;
      }
      return null;
    }
    getChar(uid) {
      for (let char of this.elmt.value) {
        if (char.uid === uid) return char;
      }
      return null;
    }

    select(uid) {
      if (uid && this.charRefs[`char${uid}`].current) {
        this.focus(uid);
      }
    }

    selectNext(uid) {
      this.selectNextPrevious(uid, "next");
    }
    selectPrevious(uid) {
      this.selectNextPrevious(uid, "previous");
    }

    selectNextPrevious(uid, mode) {
      let prevChar = null;
      let isCurrUid = false;
      let toChar = null;
      let idx = 0;
      for (let char of this.elmt.value) {
        if (char.uid === uid) {
          if (mode === "previous") {
            toChar = prevChar;
            break;
          } else isCurrUid = true;
        } else if (mode === "next" && isCurrUid) {
          toChar = char;
          break;
        }
        prevChar = char;
        idx++;
      }
      if (!toChar) idx = null;
      if (mode === "next" && this.props.onSelectNext)
        this.props.onSelectNext(toChar, idx);
      if (mode === "previous" && this.props.onSelectPrevious)
        this.props.onSelectPrevious(toChar, idx);

      toChar && this.select(toChar.uid);
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    renderRejectButton(character, idx) {
      let rejectButton = (
        <IconButton
          key='rejectChar'
          onPress={() => this.handleToggleRejectPress(character)}
          icon={"closeCircle"}
          size='small'
          // variant="circle"
          style={styles.characterButton}
          color={
            character.reject
              ? StyleSheet.color(Colors.secondary).rgba(0.8)
              : StyleSheet.color(Colors.onSurface).rgba(0.4)
          }
        />
      );
      if (idx === 0)
        rejectButton = (
          <FloatingLabel
            label={this.keyMapper.getActionKeyLabels("rejectCharacter")[0]}
            visible={this.props.app.dm.manualEntry.keyOverlayMode}
            key='rejectCharacter'
            position='bottom'
            offset={-8}
          >
            {rejectButton}
          </FloatingLabel>
        );
      return rejectButton;
    }
    render() {
      return (
        <View style={styles.characterEntryField}>
          {this.elmt.value.map((char, idx) => {
            let character = this.charactersEntryField.characters.getInstance(
              char.uuid
            );
            return (
              <CharacterCompareView
                key={`char${character.uid}`}
                character={char}
                imageSize={48}
                imagePadding={10}
                focused={this.hasFocused(char)}
                focus={character.focus}
                changed={character.changed}
                reject={character.reject}
                edit={char.uid === this.state.editCharUid}
                controlsTop={[
                  <View
                    key={"characterKeyIndicator"}
                    style={styles.characterKeyIndicator}
                  >
                    <Text style={styles.characterKeyIndicatorText}>
                      F{idx + 1}
                    </Text>
                  </View>,
                ]}
                controlsBottom={[
                  this.freeEditEnabled && (
                    <PopUpMenu
                      controlIcon='dotsHorizontal'
                      key='menu'
                      controlIconSize='small'
                      direction='downLeft'
                      onSelect={(value, label) => {}}
                    >
                      <PopUpMenuItem
                        icon={character.reject ? "checkCircle" : "closeCircle"}
                        iconSize='small'
                        label={character.reject ? "Accept" : "Reject"}
                        onPress={() => this.handleToggleRejectPress(char.uid)}
                        key='acceptReview'
                      />
                      <PopUpMenuItem
                        icon='edit'
                        iconSize='small'
                        // iconColor={Colors.secondary}
                        //labelTextStyle={styles.rejectReviewLabelText}
                        label='Free Edit'
                        onPress={() =>
                          this.handleToggleEditPress(character.uid)
                        }
                        key='rejectReview'
                      />
                    </PopUpMenu>
                  ),
                  !this.freeEditEnabled &&
                    this.renderRejectButton(character, idx),
                ]}
              >
                <View>
                  <TextInput
                    // key={`char${char.uid}`}
                    // name={`char${char.uid}`}
                    // ref={this.charRefs[`char${char.uid}`]}
                    // onChangeText={(value) =>
                    //   this.handleChangeText(char.uid, value)
                    // }
                    // // onPress={()=>console.log('pres press press')}
                    // // onKeyDown={(e) => this.handleKeyDown(char.uid, e)}
                    style={[
                      styles.textInput,
                      this.focusedChars.includes(char.uid) &&
                        styles.textInputFocused,
                    ]}
                    // value={this.parseValue(char)}
                    // autoSelect={char.uid === this.firstUid}
                    // autoFocus={char.uid === this.firstUid}
                    // onFocus={() => this.handleFocus(char.uid)}
                    // onBlur={() => this.handleBlur(char.uid)}

                    key={`char${character.uid}`}
                    name={`char${character.uid}`}
                    ref={character.ref}
                    value={character.value}
                    // style={[
                    //   styles.characterTextInput,
                    //   dynamicStyles.characterTextInput,
                    // ]}
                    onChangeText={(value) =>
                      this.props.onChangeText(character, value)
                    }
                    autoSelect={character.focus}
                    autoFocus={character.focus}
                    onFocus={character.onFocus}
                    onBlur={character.onBlur}
                    // onPress={this.character.onFocus}
                    autoComplete={false}
                  />
                </View>
              </CharacterCompareView>
            );
          })}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  textInput: {
    fontSize: 36,
    //fontFamily: "monospace",
    fontFamily: "Arial, Helvetica, sans-serif",
    color: Colors.onSurface,
    backgroundColor: Colors.surface,
    width: 48,
    height: 48,
    textAlign: "center",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  characterEntryField: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginLeft: -8,
    marginBottom: 32,
  },
  characterKeyIndicator: {
    // height: 36,
    // width: 36
    marginBottom: 8,
  },
  characterKeyIndicatorText: {
    fontSize: 12,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  prevNextCharLabels: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    height: 0,
  },
  prevNextCharLabel: {
    height: 0,
  },
});
