export default class AsyncStorageBase{
  static async setItem(key, val){
    return localStorage.setItem(key, val);
  }
  static async getItem(key){
    return localStorage.getItem(key);
  }
  static async removeItem(key){
    return localStorage.removeItem(key);
  }
  static async clear(){
    return localStorage.clear();
  }
  static async getAllKeys(){
    return Object.keys(localStorage);
  }
}
