import React from "react";
import R14 from "../../R14";
import Button from "../Button";

const DownloadButton = R14.connect(
  class DownloadButton extends React.Component {
    constructor(props) {
      super(props);
      this.handlePress = this.handlePress.bind(this);
    }
    async handlePress() {
      // Create the file
      let source = this.props.source;
      if (source.file) {
        if (!source.file.content)
          throw new Error("Source file type requires content string");
        const file = new File([source.file.content], source.file.name, {
          type: source.file.type,
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(file);

        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.props.onComplete && this.props.onComplete()
      }
    }
    render() {
      return <Button {...this.props} onPress={this.handlePress} />;
    }
  }
);
export default DownloadButton;
