import R14 from "../R14";

export default class ProgressIndicatorUIDomain extends R14.Domain {
  constructor() {
    super();
    this.state = {
      instances: {},
    };
    this._prevProgress = 0;
  }
  show(name = "default", options = {}) {
    if (typeof name === "object") {
      options = name;
      name = options.name || "default";
    }
    if (!this.state.instances[name]) {
      let instances = this.state.instances;
      instances[name] = options;
      instances[name]._progress = 0;
      instances[name]._prevProgress = 0;
      this.setState({
        instances: instances,
      });
    }
  }
  hide(name = "default", options = {}) {
    if (typeof name === "object") {
      options = name;
      name = options.name || "default";
    }
    if (this.state.instances[name]) {
      // Check for timeout
      let timeout = options.timeout || 0;
      let _hide = (name) => {
        let instances = this.state.instances;
        delete instances[name];
        this.setState({
          instances: instances,
        });
      };
      timeout ? setTimeout(() => _hide(name), timeout) : _hide(name);
    }
  }
  setProgress(progress, name = "default") {
    if (!this.state.instances[name]) return;
    let instances = this.state.instances;
    instances[name]._prevProgress = instances[name]._progress;
    instances[name]._progress = progress;
    this.setState({
      instances: instances,
    });
  }
  getProgress(name = "default") {
    return this.state.instances[name]
      ? this.state.instances[name]._progress
      : 0;
  }
  getPrevProgress(name = "default") {
    return this.state.instances[name]
      ? this.state.instances[name]._prevProgress
      : 0;
  }
  isVisible(name = "default") {
    return this.state.instances[name] ? true : false;
  }
  getOptions(name = "default") {
    return this.state.instances[name] || {};
  }
  shouldOverlay(name = "default") {
    if (!this.isVisible(name)) return false;
    return this.state.instances[name].overlay === false ? false : true;
  }
}
