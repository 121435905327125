import R14 from "../R14";
import AsyncStorage from "../components/AsyncStorage";

export default class NavigatorUiDomain extends R14.DomainInstances {
  create(props) {
    let dialog = new NavigatorInstanceUiDomain(this, props);
    this.addInstance(props.name, dialog);
    return dialog;
  }
  remove(name) {
    this.removeInstance(name);
  }
}

export class NavigatorInstanceUiDomain extends R14.Domain {
  constructor(navigatorDomain, props) {
    super();
    //console.log("CHECK PROPS navigator ui props", Object.keys(props.navigation), Object.keys(props.navigator));
    if (!props.name) throw "Navigator Error: Navigator must have a name.";
    this._name = props.name;
    this._navigatorDomain = navigatorDomain;
    this._onToggledCallback = null;
    this._navigation = props.navigation || null;
    let options = {};
    switch (props.navigator.type) {
      // case "header":
      //   options = props.navigator.header || {};
      //   break;
      // case "tab":
      //   options = props.navigator.header || {};
      //   break;
      case "drawer":
        options = props.navigator.drawerNavigator || {};
        break;
    }
    this._navigatorType = props.navigator.type;
    this._onToggledCallback = props.onToggled || null;
    this.state = {
      visible: props.visible || false,
      layoutStyles: null,
      options: options,
      toggle: false,
    };
  }
  setLayoutStyles(layoutStyles) {
    this.setState({ layoutStyles: layoutStyles });
    return this;
  }
  get isVisible() {
    return this.state.visible;
  }
  set visible(visible) {
    return this.setVisible(visible);
  }
  get asyncStorageKey() {
    return `r14-navigator-drawer-${this._name}`;
  }
  async deleteAsyncStorage() {
    if (typeof obj !== "object") throw new Error();
    // AsyncStorage.getItem(`r14App-${this._name}-visible`).then(val=>{
    //   let visible = props.visible || false;
    //   if(val) visible = val === 'true';
    //   if(val && val === 'true') this.setState({
    //     visible: visible
    //   });
    // });
    // if(options.saveState){
    //   alert(options.saveState);
    //   AsyncStorage.setItem(
    //     `r14App-${this._name}-visible`,
    //     options.saveState ? "true" : "false"
    //   );
    // }
  }

  async setAsyncStorage(asyncStorage) {
    if (typeof asyncStorage !== "object")
      throw new Error(
        "Navigator Error: setAsyncStorage must be passed an object."
      );
    return await AsyncStorage.setItem(
      this.asyncStorageKey,
      JSON.stringify(asyncStorage)
    );
  }
  async getAsyncStorage() {
    let asyncStorage = await AsyncStorage.getItem(this.asyncStorageKey);
    return asyncStorage ? JSON.parse(asyncStorage) : null;
  }
  async removeAsyncStorage() {
    return await AsyncStorage.removeItem(this.asyncStorageKey);
  }
  async setAsyncStorageItem(key, value) {
    let asyncStorage = (await this.getAsyncStorage()) || {};
    asyncStorage[key] = value;
    return this.setAsyncStorage(asyncStorage);
  }
  async getAsyncStorageItem(key) {
    let asyncStorage = (await this.getAsyncStorage()) || {};
    return asyncStorage[key] || null;
  }
  async removeAsyncStorageItem(key) {
    let asyncStorage = (await this.getAsyncStorage()) || {};
    if(key in asyncStorage) delete asyncStorage[key];
    return this.setAsyncStorage(asyncStorage);
  }

  setVisible(visible, options = {}) {
    this.setState({
      visible: visible,
      toggled: options.toggled ? true : false,
    });
    return this;
  }
  show() {
    this.setVisible(true);
  }
  hide() {
    this.setVisible(false);
  }
  setOption(name, value) {
    let options = this.state.options;
    if (options[name] === value) return;
    options[name] = value;
    this.setState({ options: options });
  }
  get options() {
    return this.state.options;
  }
  hide() {
    this.setVisible(false);
  }
  toggle() {
    if (this._onToggledCallback) this._onToggledCallback(!this.isVisible);
    if (this._navigation) this._navigation.toggleDrawer();
    this.setVisible(!this.isVisible, { toggled: true });
  }
  onToggled(callback) {
    this._onToggledCallback = callback;
  }
  get isToggled() {
    return this.state.toggled;
  }
  get toggled() {
    return this.isToggled;
  }
  remove() {
    this._navigatorDomain.remove(this._name);
  }
}
