import React from "react";
import R14, { View, Text, StyleSheet, CenteredForm } from "../core";
export default R14.connect(
  class ManualEntryScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit() {
      return false;
    }
    renderMessage() {
      return (
        this.props.message ||
        "Please use Google Chrome browser at this time."
      );
    }
    renderButtonTitle() {
      return this.props.buttonTitle || "Refresh";
    }
    render() {
      return (
        <CenteredForm
          onKeyDown={this.handleKeyDown}
          name="manualEntryRefreshForm"
          title={"Incompatible Browser"}
          style={styles.centeredForm}
          onSubmit={this.handleSubmit}
        >
          <View style={styles.noResults}>
            <Text>{this.renderMessage()}</Text>
          </View>
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 480)
        return {
          width: "100%",
        };
    },
  },
  noResults: {
    marginBottom: 24,
  },
});
