import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import Touchable from "./Touchable";
import AnimatedView from "./AnimatedView";
import FadeView from "./FadeView";

import { Colors } from "./Theme";

export default class ShowHideText extends React.PureComponent {
  static propTypes = {
    /** Boolean to show ripple effect. */
    in: PropTypes.bool,
    /** Top offset of touch to start ripple. */
    offsetTop: PropTypes.bool,
    /** Left offset of touch to start ripple. */
    offsetLeft: PropTypes.bool,
  };
  static defaultProps = {
    in: false,
  };
  constructor(props) {
    super(props);
    this.handlePressIn = this.handlePressIn.bind(this);
    this.handlePressOut = this.handlePressOut.bind(this);
    this.handlePressCancel = this.handlePressCancel.bind(this);
    this.handleLayout = this.handleLayout.bind(this);
    this.state = {
      fadeIn: false,
      scale: 0,
      locationX: 0,
      locationY: 0,
    };
    this._layout = null;
    this._animationType = null;
  }
  handlePressIn(event) {
    let scale = this.calculateScale(
      event.nativeEvent.locationX,
      event.nativeEvent.locationY
    );
    this._animationType = "scale";
    this.setState({
      scale,
      locationX: event.nativeEvent.locationX,
      locationY: event.nativeEvent.locationY,
      fadeIn: true,
    });
  }
  handlePressOut(event) {
    if (this._animationType === "scale" && this.state.fadeIn)
      this.setState({ fadeIn: false });
  }
  handlePressCancel(event) {
    this.handlePressOut(event);
  }
  handleLayout(event) {
    this._layout = event.nativeEvent.layout;
  }
  calculateScale(x, y) {
    if (!this._layout) return 0;
    let radius = this.props.size ? this.props.size / 2 : null;
    if (radius === null) {
      let distance = (x1, y1, x2, y2) =>
        Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
      let { width, height } = this._layout;
      radius = Math.max(
        distance(0, 0, x, y),
        distance(0, height, x, y),
        distance(width, 0, x, y),
        distance(width, height, x, y)
      );
    }
    let scale = (radius * 2) / 24;
    return scale;
  }

  createDynamicStyles() {
    let dynamicStyles = {
      ripple: {
        overflow: this.props.bounded === false ? null : "hidden",
      },
      animatedView: {
        top: this.state.locationY - 12,
        left: this.state.locationX - 12,
        backgroundColor: StyleSheet.color(
          this.props.color || Colors.onSurface
        ).rgba(0.12),
      },
    };
    return StyleSheet.create(dynamicStyles);
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <Touchable
        timingFunction='ease-in'
        style={[styles.ripple, dynamicStyles.ripple, this.props.style]}
        onPressIn={this.handlePressIn}
        onPressOut={this.handlePressOut}
        onPressCancel={this.handlePressCancel}
        onLayout={this.handleLayout}
        // onPress={(event) => {
        //   event.stopPropagation();
        //   event.preventDefault();
        // }}
        feedback={false}
      >
        <FadeView visible={this.state.fadeIn} duration={150}>
          <AnimatedView
            style={[
              styles.animatedView,
              dynamicStyles.animatedView,
              this.props.styles,
            ]}
            from={{
              transform: [{ scale: 1 }],
            }}
            to={{
              transform: [{ scale: this.state.scale }],
            }}
            duration={250}
            pointerEvents='none'
          />
        </FadeView>
      </Touchable>
    );
  }
}
const styles = StyleSheet.create({
  ripple: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
    cursor: "inherit",
    //flex: 1,
    // overflow: "hidden",
  },
  animatedView: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.12),
  },
});
