import React from "react";
import PropTypes from 'prop-types';
import R14 from '../R14';
import DataTable from './DataTable';

/** DataTable that connects directly to an entity */
export default R14.connect(
  class EntityDataTable extends React.Component {
    static propTypes = {
      /** The name of the entity */
      entity: PropTypes.string.isRequired,
      /** The name of the entities id field */
      entityIdField: PropTypes.string.isRequired,
    }
    constructor(props) {
      super(props);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.state = {};
    }
    get entityFields(){
      let ret = [];
      for(let f in this.props.columns){
        ret.push(f);
      }
      if(!ret.includes(this.props.entityIdField)) ret.push(this.props.entityIdField);
      return ret;
    }
    // async getPageData(
    //   { page, rowsPerPage, sortColumnName, sortDirection, filters = {}, search = null },
    //   options = {}
    // ) {

    //   let qry = this.props.app.utils.gql.createEntityFindQuery(
    //     this.props.entity,
    //     this.entityFields
    //   );

    //   filters = {};
    //   if(search !== null && this.props.searchField){
    //     filters[this.props.searchField] = {
    //       like: `%${search}%`
    //     }
    //   }

    //   let result = await this.props.app.api.qry(qry, {
    //     page: page,
    //     resultsPerPage: rowsPerPage,
    //     filter: filters,
    //     sort: [
    //       {
    //         field: sortColumnName,
    //         order: sortDirection.toUpperCase()
    //       }
    //     ],
    //     totalCount: options.totalCount || false
    //   });
    //   let res = result[`${this.props.entity}s`]
    //   return {
    //     pageData: res.nodes,
    //     totalRows: res.totalCount || null
    //   };
    // }
    async getPageData() {
      throw new Error('FIX WITH ROLLUP');
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    render() {
      return (
        <DataTable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          {...this.props}
        />
      );
    }
  }
);
