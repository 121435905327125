import React from "react";
import PropTypes from "prop-types";
import R14, {
  Dialog,
  Text,
  Button,
  Colors,
  StyleSheet,
  View,
  FadeView,
} from "../core";
import Document from "./Document";
import DocumentAnnotation from "./DocumentAnnotation";
export default R14.connectForm(
  /**
   * A hidden form field field component
   */
  class DocumentAnnotationOffsetField extends React.Component {
    static propTypes = {
      /** Name for the hidden field. */
      name: PropTypes.string.isRequired,
      /** Value of the field offset. */
      value: PropTypes.any,
    };
    constructor(props) {
      super(props);
      this.handleScrollViewLayout = this.handleScrollViewLayout.bind(this);
      this.handleSelectionChange = this.handleSelectionChange.bind(this);
      this.handleDialogOkPress = this.handleDialogOkPress.bind(this);
      this.handleDialogCancelPress = this.handleDialogCancelPress.bind(this);
      this.handleZoomInPress = this.handleZoomInPress.bind(this);
      this.handleZoomOutPress = this.handleZoomOutPress.bind(this);
      this.handleZoonResetPress = this.handleZoomResetPress.bind(this);
      this.handleRotateLeftPress = this.handleRotateLeftPress.bind(this);
      this.handleRotateRightPress = this.handleRotateRightPress.bind(this);
      this.handleSelectEditPress = this.handleSelectEditPress.bind(this);
      this.elmt = this.props.form.addElement(props);
      this.ref = React.createRef();
      this.okButtonRef = React.createRef();
      this.state = {
        documentScale: 1,
        // annotationOffset: null,
        dialogVisible: false,
        documentRotate: props.document.image.rotate || 0,
        documentZoomLevel: 1,
        annotationOffset: props.formData.values.offset || null,
      };
    }
    setZoomLevel(documentZoomLevel) {
      this.setState({
        documentZoomLevel,
      });
    }
    handleSelectEditPress() {
      this.updateDialogVisible(true);
    }
    handleZoomInPress() {
      this.setZoomLevel(this.state.documentZoomLevel + 0.2);
    }
    handleZoomOutPress() {
      this.setZoomLevel(this.state.documentZoomLevel - 0.2);
    }
    handleZoomResetPress() {
      this.setZoomLevel(1);
    }
    handleRotateRightPress() {
      return this.rotate(90);
    }
    handleRotateLeftPress() {
      return this.rotate(-90);
    }
    get isActive() {
      return this.state.dialogVisible || this.elmt.isFocused;
    }
    triggerEnterPress() {
      this.state.dialogVisible
        ? this.handleDialogOkPress()
        : this.updateDialogVisible(true);
    }
    rotate(amount) {
      let rotate = this.state.documentRotate || 0;
      rotate += amount;
      if (rotate < -270) rotate = 360 + (rotate % 360);
      if (rotate > 270) rotate = rotate % 360;
      this.setState({ documentRotate: rotate });
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setFocus(false);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    handleSelectionChange(offset) {
      this.okButtonRef && this.okButtonRef.current.focus();
      this.setState({
        annotationOffset: offset,
      });
    }
    // componentDidMount() {
    //   // (this.props.autoFocus || this.props.initialDialogVisible) && this.focus();
    //   // this.props.initialDialogVisible && this.updateDialogVisible(true);
    // }
    focus() {
      this.ref.current && this.ref.current.focus();
      this.elmt.setFocus(true);
    }
    componentWillUnmount() {
      this.elmt.remove();
    }

    updateDialogVisible(dialogVisible) {
      const changed = dialogVisible !== this.state.dialogVisible;
      this.setState({
        dialogVisible,
      });
      changed &&
        (dialogVisible
          ? this.props.onDialogOpen && this.props.onDialogOpen()
          : this.props.onDialogClose && this.props.onDialogClose());
    }

    handleDialogCancelPress() {
      this.focus();
      this.updateDialogVisible(false);
    }
    triggerValueChange(offset) {
      this.elmt.setValue(offset);
      // this.updateDialogVisible(false);
      // this.focus();
      this.props.onValueChange &&
        this.props.onValueChange(offset, this.elmt, this.elmt.form);
    }
    handleDialogOkPress() {
      this.elmt.setValue(this.state.annotationOffset);
      this.updateDialogVisible(false);
      this.focus();
      this.props.onValueChange &&
        this.props.onValueChange(
          this.state.annotationOffset,
          this.elmt,
          this.elmt.form
        );
    }
    handleScrollViewLayout(event) {
      const padding = 32;
      const layout = event.nativeEvent.layout;

      // Scale available based on padding
      const height = layout.height - padding * 2;
      const width = layout.width - padding * 2;

      const documentScale = this.props.document.calculateScale(
        height,
        width,
        this.props.app.dm.manualEntry.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_COVER
      );

      this.setState({
        documentScale,
      });
      // this.setState({
      //   layout: true,
      //   height,
      //   width,
      //   scale: this.calculateScale(
      //     height,
      //     width,
      //     this.documentSet.initialDocumentScaleMode
      //   ),
      //   //prevScale: this.state.scale
      // });
    }
    renderAnnotations() {
      // if (!this.props.annotation) return null;
      // let annotations = [this.props.annotation];
      // return annotations.map((annotation) => {
      //   let offset = this.document.getAnnotationOffset(annotation);
      //   if (!offset) return null;
      //   let props = {
      //     key: annotation.uuid,
      //     width:
      //       offset.width *
      //       this.document.image.width *
      //       this.state.annotationPreviewScale,
      //     height:
      //       offset.height *
      //       this.document.image.height *
      //       this.state.annotationPreviewScale,
      //     top:
      //       offset.top *
      //       this.document.image.height *
      //       this.state.annotationPreviewScale,
      //     left:
      //       offset.left *
      //       this.document.image.width *
      //       this.state.annotationPreviewScale,
      //     onPress: this.handleAnnotationPress,
      //     active: true,
      //     activeStyle: this.getAnnotationStyle(annotation),
      //   };
      //   return <DocumentAnnotation {...props} />;
      // });
    }
    renderDocument(options = {}) {
      return (
        <View
          style={styles.documentPreview}
          onLayout={this.handleAnnotationPreviewLayout}
        >
          <Document
            // ref={this.documentRef}
            scale={this.state.documentScale}
            onScrollViewLayout={this.handleScrollViewLayout}
            onScroll={this.props.onDocumentScroll}
            onZoomInPress={this.handleZoomInPress}
            onZoomOutPress={this.handleZoomInPress}
            onZoomResetPress={this.handleZoomResetPress}
            onRotateLeftPress={this.handleRotateLeftPress}
            onRotateRightPress={this.handleRotateRightPress}
            // onSelectionEditPress={() => console.log("TEST")}
            selectionModeEnabled={true}
            selectionOffset={this.state.annotationOffset}
            
            onSelectionChange={this.handleSelectionChange}
            // onFocus={options.onFocus || null}
            // onBlur={options.onBlur || null}
            toolbarStyle={this.props.documentToolbarStyle}
            toolbarPosition='bottomCenter'
            // height={128}
            // width={328}
            imageHeight={this.props.document.image.height}
            imageWidth={this.props.document.image.width}
            imageSource={{
              uri: this.props.document.image.url,
            }}
            imageAuth={
              this.props.formData.documentSet.documentImageStorageMode !==
              this.props.app.dm.manualEntry.DOCUMENT_IMAGE_STORAGE_MODE_DATASET
            }
            zoomLevel={this.state.documentZoomLevel}
            rotate={this.state.documentRotate}
            annotations={this.renderAnnotations()}
          />
        </View>
      );
    }
    renderDialog() {
      return (
        <Dialog
          name='documentPreviewDialog'
          unmountOnExit
          style={styles.documentPreviewDialog}
          contentStyle={styles.documentPreviewDialogContent}
          title={`Select ${this.props.label} Field`}
          visible={this.state.dialogVisible}
          onClosePress={this.handleDialogCancelPress}
          onBackPress={this.handleDialogCancelPress}
          onCancelPress={this.handleDialogCancelPress}
          onBackdropPress={this.handleDialogCancelPress}
          // onOkPress={this.handleDialogOkPress}
          OkButtonComponent={
            <Button
              ref={this.okButtonRef}
              title='Update'
              // variant='text'
              onPress={this.handleDialogOkPress}
            />
          }
          // hideOkButton
          // hideCancelButton
        >
          {this.renderDocument()}
        </Dialog>
      );
    }
    render() {
      //const showOverlay = this.props.selectionModeEnabled && !this.elmt.value;
      const showOverlay = !this.elmt.value || this.state.dialogVisible;
      // If offset editing is enabled for the document, but it is not required
      // Show a different message and button title
      // let overlayMessage = `Please highlight the '${this.props.label}' field in the document and press update.`;
      // let buttonTitle = "Update";
      // let buttonDisabled = this.props.disableSelect;
      // let buttonStyle = styles.documentPreviewOverlayUpdateButton;
      // if (!this.elmt.value) {
      //   buttonTitle = "Select";
      //   overlayMessage = `Press select to highlight the '${this.props.label}' field in the document.`;
      //   buttonDisabled = false;
      //   buttonStyle = styles.documentPreviewOverlaySelectButton;
      // }
      const previewDocument = this.props.previewDocumentRenderer
        ? this.props.previewDocumentRenderer({
            //onSelectionEditPress: this.handleSelectEditPress,
            hideToolbar: showOverlay,
            // selectionEditIconColor: this.elmt.changed
            //   ? Colors.green
            //   : Colors.primary,
          })
        : null;
      const focused = this.elmt.isFocused || this.state.dialogVisible;
      return (
        <View
          style={styles.documentAnnotationOffsetField}
          ref={this.ref}
          onFocus={() => this.handleFocus()}
          onBlur={() => this.handleBlur()}
          // tabIndex={buttonDisabled ? 0 : -1}
          tabIndex={1}
        >
          {this.renderDialog()}
          {previewDocument}
          <FadeView
            visible={showOverlay}
            style={[
              styles.documentPreviewOverlay,
              focused && styles.documentPreviewOverlayFocus,
            ]}
          >
            <Text style={[styles.documentPreviewOverlayText]}>
              Please highlight the{" "}
              <Text style={styles.bold}>{this.props.label}</Text> field in the
              document.
            </Text>
            {/* <Text style={[styles.documentPreviewOverlayText]}>
              {overlayMessage}
            </Text> */}
            {/* <View style={styles.documentPreviewOverlayButtons}>
              <Button
                title={buttonTitle}
                size='small'
                disabled={buttonDisabled}
                // tabIndex={buttonDisabled ? -1 : 0}
                // onFocus={() => this.handleFocus}
                // onBlur={() => this.handleBlur}
                // variant='outlined'
                color={Colors.onSurface}
                style={buttonStyle}
                onPress={this.handleSelectEditPress}
              />
            </View> */}
          </FadeView>
          {this.elmt.error && (
            <View>
              <Text style={styles.offsetFieldErrorText}>{this.elmt.error}</Text>
            </View>
          )}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  documentAnnotationOffsetField: {
    position: "relative",
  },
  documentPreview: {
    // height: 144,
    // width: "100%",
    position: "absolute",
    ...StyleSheet.absoluteFill,
    flex: 1,
  },
  documentPreviewDialog: {
    maxWidth: 800,
    width: "100%",
    // position: "relative",
    flex: 1,
  },
  documentPreviewDialogContent: {
    flex: 1,
    marginBottom: 0,
    position: "relative",
  },
  documentPreviewOverlay: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    ...StyleSheet.absoluteFill,
    top: 0,
    right: 0,
    bottom: 32,
    left: 0,
    backgroundColor: StyleSheet.color(Colors.surface).rgba(0.9),
    boxSizing: "border-box",
  },
  documentPreviewOverlayFocus: {
    borderWidth: 2,
    borderColor: Colors.primary,
    borderStyle: "solid",
    borderRadius: 4,
  },
  documentPreviewOverlayText: {
    // flex: 1,
    // flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    // color: Colors.onSurface,
    fontSize: 14,
    ...StyleSheet.padding(16, 16, 16, 16),
  },
  bold: {
    fontWeight: 600,
  },
  documentPreviewOverlayError: {},
  offsetFieldErrorText: {
    width: "100%",
    fontSize: 13,
    lineHeight: 20,
    color: Colors.error,
    position: "absolute",
    bottom: 8,
  },
  documentPreviewOverlayButtons: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...StyleSheet.padding(8, 16, 16, 16),
  },
  documentPreviewOverlaySelectButton: {
    borderWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.6),
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2),
    borderRadius: 32,
  },
  documentPreviewOverlayUpdateButton: {
    borderWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.6),
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.2),
    borderRadius: 32,
  },
});
