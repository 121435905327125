import { Platform } from "../core/";
const getBaseUrl = () => {
  if (!window || !window.location) return "";
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) url = `${url}:${window.location.port}`;
  return url;
};
const getR14LoginUrl = () => {
  let ret = "https://login.teletype.team";
  if (!window || !window.location) return ret;
  const hostname = window.location.hostname;
  if (!hostname) return ret;
  switch (hostname) {
    case "xbp.teletype.team":
      ret = "https://login.xbp.teletype.team";
      break;
    case "dev.teletype.team":
    case "exeladev.teletype.team":
      ret = "http://logindev.teletype.team";
      break;
    default:
    // Do nothing
  }
  return ret;
};
let baseUrl = getBaseUrl();
let devBaseUrl = baseUrl;
// let devBaseUrl = "http://3.15.132.137:3005";
const Config = {
  name: "r14-dev-app",
  api: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
    onAuthError: async (app) => {
      await app.dm.userSession.handleAuthError();
    },
  },
  io: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  metadata: {
    r14Login: {
      url: getR14LoginUrl(),
    },
  },
};
export default Config;
