import React from "react";
import R14 from "../R14";
import View from "./View";
// import RootPortal from './RootPortal';
import Dimensions from "./Dimensions";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import AnimatedView from "./AnimatedView";
import Modal from "./Modal";
const useUi = R14.useUi;
const withAppObserver = R14.withAppObserver;
function ProgressIndicator({ name, overlayStyle = null, variant = null }) {
  const progressIndicator = useUi("progressIndicator");
  const windowWidth = Dimensions.get("window").width;
  const options = progressIndicator.getOptions(name);
  const throbberHeaderStyle =
    variant === "header" && styles.throbberHeader
      ? styles.throbberHeader
      : null;
  const progress = progressIndicator.getProgress(name);
  const prevProgress = progressIndicator.getPrevProgress(name);
  const showProgress = progress !== prevProgress && progress !== 100;
  return (
    <Modal
      visible={progressIndicator.isVisible(name)}
      transparent
      animationType='fade'
    >
      <View style={styles.progressIndicator}>
        {progressIndicator.shouldOverlay(name) && (
          <View style={[styles.overlay, overlayStyle, options.overlayStyle]} />
        )}
        <View style={styles.title} />
        <View style={[styles.throbberTrack, throbberHeaderStyle]} />
        {showProgress ? (
          <AnimatedView
            style={[styles.throbber, throbberHeaderStyle]}
            animation={{
              from: {
                transform: [
                  // { scaleX: 1 },
                  {
                    translateX:
                      -windowWidth + windowWidth * (prevProgress / 100),
                  },
                ],
              },
              to: {
                transform: [
                  // { scaleX: 0.4 },
                  {
                    translateX: -windowWidth + windowWidth * (progress / 100),
                  },
                ],
              },
            }}
            timingFunction='ease-in'
            // direction='alternate'
            duration={1000}
          />
        ) : (
          <AnimatedView
            style={[styles.throbber, throbberHeaderStyle]}
            animation={{
              from: {
                transform: [
                  // { scaleX: 1 },
                  { translateX: -windowWidth },
                ],
              },
              to: {
                transform: [
                  // { scaleX: 0.4 },
                  { translateX: windowWidth },
                ],
              },
            }}
            timingFunction='ease-in'
            iterationCount='infinite'
            // direction='alternate'
            duration={2000}
          />
        )}
      </View>
    </Modal>
  );
}
export default withAppObserver(ProgressIndicator);
//   class ProgressIndicator2 extends React.Component {
//     constructor(props) {
//       super(props);
//     }
//     render() {
//       console.log("ProgressIndicator", this.props.name);
//       // Todo: Bind props.visible to domain.isVisible
//       const windowWidth = Dimensions.get("window").width;

//       const options = this.props.app.ui.progressIndicator.getOptions(
//         this.props.name
//       );
//       console.log("ProgressIndicator options", options.overlayStyle);
//       return (
//         // \\<RootPortal>
//         <Modal
//           visible={this.props.app.ui.progressIndicator.isVisible(
//             this.props.name
//           )}
//           transparent
//           animationType='fade'
//         >
//           <View style={styles.progressIndicator}>
//             {this.props.app.ui.progressIndicator.shouldOverlay(
//               this.props.name
//             ) && (
//               <View
//                 style={[
//                   styles.overlay,
//                   this.props.overlayStyle,
//                   options.overlayStyle,
//                 ]}
//               />
//             )}
//             <View style={styles.title} />
//             <AnimatedView
//               style={styles.throbber}
//               animation={{
//                 from: {
//                   transform: [
//                     // { scaleX: .1 },
//                     { translateX: -windowWidth },
//                   ],
//                 },
//                 to: {
//                   transform: [
//                     // { scaleX: 2 },
//                     { translateX: windowWidth },
//                   ],
//                 },
//               }}
//               iterationCount='infinite'
//               duration={2000}
//             />
//           </View>
//         </Modal>
//         // </RootPortal>
//       );
//     }
//   }
// );
const styles = StyleSheet.create({
  progressIndicator: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: "hidden",
  },
  visible: {
    opacity: 1,
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 6,
    // backgroundColor: StyleSheet.color(Colors.background).rgba(0.5),
  },
  title: {
    display: "none",
  },
  throbberTrack: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    // right: 0,
    // left: 0,
    zIndex: 7,
    height: 4,
    backgroundColor: Colors.primaryLight,
  },
  throbberHeader: {
    top: 60,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          top: 52,
        };
    },
  },
  throbber: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    // right: 0,
    // left: 0,
    zIndex: 8,
    height: 4,
    backgroundColor: Colors.primary,
  },
});
// export default ProgressIndicator;
